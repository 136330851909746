import React, { useState, useEffect } from "react";
import { Box, TextField, FormControl, Checkbox, Autocomplete, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import WorkRequestFacilityApi from "../../../../api/WorkRequest/WorkRequestFacility";
import { GlobalButton } from "../../../styles";
import { StyledTableRow, StyledTableBody, StyledTableCell, UXDataTableWithoutBody } from "../../../../components/UXDataTable";
import PiscesWorkRequest from "../../../../api/Reporting/PiscesWorkRequest";
import { convertToLocalTime, formatMidasNumber, exportToExcel, DatePickerKeyDownEvent } from "../../../../global";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import './styles.css';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  alignItems: 'start'
}));

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '20px'
}));

const StyledShortTextField = styled(TextField)({
  id: "outlined-normal",
  width: "20rem",
  marginRight: "15px"
});

const defaultSearchFields = {
  startDate: null,
  endDate: null,
  facility: null,
  facilities: []
}

const StyledAutocomplete = styled(Autocomplete)(() => ({
  width: "20rem",
  marginRight: "15px"
}));

const AnalyticalMetrics = ({ ...props }) => {
  const [searchFields, setSearchFields] = useState(defaultSearchFields);
  const [workRequestFacilities, setWorkRequestFacilities] = useState(null);
  const [listWorkRequest, setListWorkRequest] = useState([]);
  const [loadingWorkRequest, setLoadingWorkRequest] = useState(false);

  const isMountedRef = React.useRef(true);

  const colsWorkRequest = ['#', 'Facility', 'Category', 'Priority', 'Requester', 'Submitted', 'Requested Completion', 'Completed', 'Description', 'Priority Reason', 'Completed By', 'WBS'];
  const fieldsWorkRequest = [
            { key: 'workRequestID' },
            { key: 'facility' },
            { key: 'category' },
            { key: 'priority' },
            { key: 'requester' },
            { key: 'submittedDate', type: 'dateTime' },
            { key: 'requestedCompletionDate', type: 'dateTime' },
            { key: 'completedDate', type: 'dateTime' },
            { key: 'description', noWrap: true },
            { key: 'priorityReason' },
            { key: 'completedBy' },
            { key: 'billingInfo' },
          ]

  function loadFacilityFilter() {
    WorkRequestFacilityApi.getAll().then((result) => {
      if ((result !== undefined && result !== null)) {
        setWorkRequestFacilities(result.sort((a, b) => a.facilityName.localeCompare(b.facilityName)));
      }
    });
  }

  useEffect(() => {
    loadFacilityFilter();

    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    }
  }, [])

  async function handleSearch() {
      setListWorkRequest([])
      setLoadingWorkRequest(true)
      getWorkRequest(200, 1);
  }

  async function getWorkRequest(resultsPerPage, page, allData = []) {
    if (isMountedRef.current) {
      const response = await PiscesWorkRequest.get(
        searchFields.startDate.toISOString(),
        searchFields.endDate.toISOString(),
        searchFields.facilities,
        page,
        resultsPerPage)

      if (response) {
        allData = [...allData, ...response]
      } else {
        setListWorkRequest([]);
      }

      if (response === null || response.length === 0) {
        setLoadingWorkRequest(false)
        return allData;
      } else {
        page++;
        setListWorkRequest(allData)
        return getWorkRequest(resultsPerPage, page, allData);
      }
    }
  }

  const DownloadExcel = ({list, cols, fields, name}) => {
    return <IconButton onClick={e => {
      e.preventDefault();
      exportToExcel(list.map(item => {
        const object = {};
        cols.forEach((col, index) => {
          object[col] = fields[index].type === 'dateTime' ? convertToLocalTime(item[fields[index].key]) : fields[index].key === 'sampleName' ? formatMidasNumber(item[fields[index].key]) : item[fields[index].key]
        })
        return object;
      }), name)
    }}>
      <FileDownloadIcon sx={{ m: 1 }} style={{ cursor: "pointer" }} />
    </IconButton>
  }

  const Table = (props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const { colHeaders, loading, fields, list = [] } = props;

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    return (
      <div style={{marginTop: 30}}>
          <UXDataTableWithoutBody
            tableWidth='100%'
            cols={colHeaders}
            blankFirstHeader={false}
            tableId="simpleResultTable"
            rowLength={list.length}
            enablePaging={true}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            noDataFoundMessage={'There is no data available'}
            enableAddIcon={false}
            addFunction={null}
            addToolTipText={null}
            enableSorteable={false}
            isDataLoading={loading}
          >
            <StyledTableBody key={"myTablebody"}>
              {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, rowIndex) => {
                return (
                  [
                    <StyledTableRow
                      hover
                      key={`custom-row-${rowIndex}`}>
                      {fields.map(field => {
                        return <StyledTableCell style={{ minWidth: field.width ?? '', whiteSpace: field.noWrap ? 'nowrap' : '' }}>{field.type === 'dateTime' ? convertToLocalTime(item[field.key]) : field.key === 'sampleName' ? formatMidasNumber(item[field.key]) : item[field.key]}</StyledTableCell>
                      })}
                    </StyledTableRow>
                  ]
                );
              })}
            </StyledTableBody>
          </UXDataTableWithoutBody>
      </div>
    )
  }

  return (
    <div style={{ paddingBottom: '20px' }}>
      <h4>Work Request Metrics</h4>
      <StyledFormControl fullWidth>
        <StyledBox>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              size="small"
              inputFormat="MM/dd/yyyy"
              label="Start Date"
              onChange={e => setSearchFields(() => ({
                ...searchFields,
                startDate: e
              }))}
              maxDate={searchFields.endDate}
              value={searchFields.startDate}
              renderInput={(params) => <StyledShortTextField onKeyDown={DatePickerKeyDownEvent} {...params} margin="normal" variant="outlined" size="small" InputLabelProps={{ shrink: true }} inputProps={{ ...params.inputProps }} InputProps={{ ...params.InputProps }} />}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              size="small"
              inputFormat="MM/dd/yyyy"
              label="End Date"
              onChange={e => setSearchFields(() => ({
                ...searchFields,
                endDate: e
              }))}
              minDate={searchFields.startDate}
              maxDate={new Date()}
              value={searchFields.endDate}
              renderInput={(params) => <StyledShortTextField onKeyDown={DatePickerKeyDownEvent} {...params} margin="normal" variant="outlined" size="small"  InputLabelProps={{ shrink: true }} inputProps={{ ...params.inputProps }} InputProps={{ ...params.InputProps }} />}
            />
          </LocalizationProvider>
        </StyledBox>
        <StyledBox>
          <StyledAutocomplete
            size="small"
            id="facility-select"
            disablePortal
            noOptionsText={"Loading Facilities..."}
            options={workRequestFacilities !== null ?
              workRequestFacilities
                .map((facility) => facility.facilityName.toString())
                .filter(f => !searchFields.facilities?.some(s => s === f)) : []}
            getOptionLabel={(option) => option}
            onChange={(e, value) => {
              value = value === null ? "" : value
              setSearchFields(() => ({
                ...searchFields,
                facility: value,
              }))
            }}
            autoHighlight
            autoSelect
            value={searchFields.facility === '' ? null : searchFields.facility}
            renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" size="small" label="Facility" InputProps={{ ...params.InputProps }} />}
          />
          <GlobalButton
            style={{ marginTop: '8px', width: "12rem" }}
            variant='contained'
            onClick={() => {
              setSearchFields(() => ({
                ...searchFields,
                facilities: [...searchFields.facilities.filter(f => f !== searchFields.facility), searchFields.facility],
                facility: null
              }))
            }}
            disabled={!searchFields.facility}
          >Add Facility</GlobalButton>
          <StyledAutocomplete
            size="small"
            id="facilities-select"
            multiple
            limitTags={10}
            disableCloseOnSelect
            noOptionsText={"No Options"}
            options={searchFields.facilities}
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              setSearchFields(() => ({
                ...searchFields,
                facilities: newValue
              }))
            }}
            value={searchFields.facilities}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            style={{ width: 400 }}
            autoHighlight
            freeSolo
            renderInput={(params) => <TextField
              rows={1}
              multiline
              {...params}
              margin="normal"
              variant="outlined"
              size="small"
              label="Facilities to Search"
              inputProps={{ ...params.inputProps, readOnly: true }}
            />}
          />
          <GlobalButton
            style={{ marginTop: '8px', width: "12rem" }}
            variant='contained'
            onClick={handleSearch}
            disabled={(!searchFields.startDate || !searchFields.endDate)}
          >Get Metrics</GlobalButton>
          {listWorkRequest.length > 0 && <DownloadExcel list={listWorkRequest} cols={colsWorkRequest} fields={fieldsWorkRequest} name={'Work Requests'} />}
        </StyledBox>
      </StyledFormControl>
      <div>
        <Table
          colHeaders={colsWorkRequest}
          list={listWorkRequest}
          loading={loadingWorkRequest}
          fields={fieldsWorkRequest}
        />
      </div>
    </div>
  );
};

export default AnalyticalMetrics; 