import React from "react";
import { styled } from "@mui/material/styles";
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
  } from "@mui/material";
  
import { Table, TableCell, TableRow } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Roles = ({ ...props }) => {
    const StyledAccordion = styled(Accordion)(() => ({
        width: "50rem",
        margin:"0.25rem 0"
    }));
    
    const StyledAccordionDetails = styled(AccordionDetails)(() => ({
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap"
    }));

    const StyledAccordionSummary = styled(AccordionSummary)(() => ({
        color: "#1890ff"
    }));

    const StyledTableCellInfo = styled(TableCell)({
        border: "none",
        paddingBottom:"0px",
        paddingTop:"0px",
        color:"#00000099",
        fontFamily:"EMprint",
        fontWeight:"400",
        fontSize: "14px"
    });

  return (
    <div>
      <p>
      By default, everyone with an ExxonMobil email address (connecting through a GME computer) has an account in MIDAS 3; MIDAS 3 supports Single Sign-On with the company Active Directory.  However, Role assignments are required for specific functionality throughout the MIDAS 3 system.  These Roles, or higher level Personas (base collection of Roles), are assigned through Active Directory group membership. 
      <br/><br/>
      Click <a
            href='https://emprod.service-now.com/itsp?id=sc_cat_item&sys_id=aa73c5761b1170108b18326ecc4bcb97&referrer=popular_items'
            target='_blank'
            rel='noopener noreferrer'
          >here</a> to request to join a Persona or Role group.  Refer to the training material on how to use the form.
      </p>
      <StyledAccordion >
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography><h3>Personas</h3></Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
            <p>Personas are designed around a specific job functionality and come with a predefined set of Roles for the job.</p>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Analytical Technician</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.AnalyticalTechnician.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Roles:</b> Analyst, Test Activator, Instrument Administrator, Container Mover, SQC Report, SQC Viewer</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Details TBD</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Analytical Test Owner</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.TestOwner.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Roles:</b> Analyst, Analytical Data Reviewer, Test Activator, Test Priority Maintainer, Method Administrator, Instrument Administrator, SQC Administrator, SQC Reviewer, SQC Viewer</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Details TBD</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Analytical Supervisor</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.AnalyticalSupervisor.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                                  <StyledTableCellInfo><b>MIDAS 3 Roles:</b> Test Priority Maintainer, Test Activator, Analytical Data Reviewer, Method Administrator, Instrument Administrator, Result Component Administrator, SQC Administrator, SQC Reviewer, SQC Viewer</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Details TBD</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Blending Technician</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.BlendTechnician.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Roles:</b> Batch Component Substituter, Container Mover, Batch Preparer</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Details TBD</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Blending Supervisor</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.BlendSupervisor.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Roles:</b> Batch Assigner, Batch Test Status Maintainer, Blend Maintainer, Instrument Administrator, Batch Component Substituter</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Details TBD</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Distillation Technician</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.DistillationTechnician.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Roles:</b> Distillation Experiment Maintainer, Distillation Template Maintainer, Container Mover</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Details TBD</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Distillation Supervisor</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.DistillationSupervisor.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Roles:</b> Distillation Corrector, Distillation Experiment Maintainer, Distillation Template Maintainer</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Details TBD</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>SHE Personel</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.Safety.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Roles:</b> New Chemical Authorization Approver, TSCA Approver, Substance Maintainer, Substance Safety Maintainer, Substance Structure Maintainer</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Details TBD</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Stationary Testing Technician</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.StationaryTestingTechnician.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Roles:</b> ST Request Engineer, Container Mover</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Details TBD</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Stationary Testing Supervisor</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.StationaryTestingSupervisor.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Roles:</b> ST Request Engineer, ST Procedure Administrator, ST Schedule Administrator, ST Stand Administrator</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Details TBD</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Work Request Technician</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.WorkRequestTechnician.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Roles:</b> Work Request Technician, Container Mover, Drum Field Maintainer, Shipping Coordinator</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Details TBD</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Work Request Supervisor</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.WorkRequestSupervisor.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Roles:</b> Work Request Technician, Work Request Assigner, Work Request Facility Assigner, Work Request Reporter, Container Mover, Drum Field Maintainer</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Details TBD</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>MIDAS Master Data Administrator</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.Administrator.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Roles:</b> Analytical Master Data Administrator, Chemical Inventory/Safety Master Data Administrator, Distillation Master Data Administrator, Formulations Master Data Administrator, Location/Site Master Data Administrator, Stationary Testing Master Data Administrator, Stockroom Master Data Administrator, Work Request Master Data Administrator</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Details TBD</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion >
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography><h3>Roles</h3></Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
            <p>Roles are related to specific functionality in MIDAS 3.  Generally, necessary Roles will come with membership in your Persona(s), but Roles can also be added individually.</p>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Analytical Data Reviewer</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.TESTREVIEWER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> TEST.REVIEWER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                                  <StyledTableCellInfo>Allows the User to review Analytical Test data, Correct Test submissions, and mark Test data as Validated or Corrected</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Analytical Master Data Administrator</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.MASTERDATA.ANALYTICAL.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> MASTERDATA.ANALYTICAL</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to edit master data tables specific to the Analytical area - Method Category, Test Facility, Test Priority, Test Status, Method Facility Status, Test Condition Name</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Batch Assigner</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.BLEND.BATCHASSIGNER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> BATCH.ASSIGNER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to assign a technician, other than themselves, to a submitted Batch request</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Batch Component Substituter</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.BLEND.COMPONENTSUBSTITUTER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> BATCH.COMPONENTSUBSTITUTER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to substitute a requested Batch Component for another approved Substance</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Batch Preparer</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.BLEND.BATCHPREPARER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> BATCH.PREPARER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to process a formulations Batch request</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Batch Test Status Maintainer</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.BLEND.BATCHSTATUSMAINTAINER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> BATCH.STATUSMAINTAINER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to manually change the Status of a Blend Request or Batch, outside of the automatic process</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Blend Maintainer</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.BLEND.MAINTAINER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> BLEND.MAINTAINER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to update any Blend Request in the Formulations space.  (A User can update their own Blend Request after submission up until work on the Blend Request has started)</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Chemical Inventory/Safety Master Data</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.MASTERDATA.CHEMICAL.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> MASTERDATA.CHEMICAL</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to update Master Data elements pertaining to Chemical Safety and Inventory: ChemIDCategory, JapanFireSafetyCode, JapanSDSCode, SubstanceStatus, SubstanceSubstitue, AuthorizationRequestStatus, SpecialHandlingStatus, ExperimentalSubstanceType, ExperimentalSubstanceSubtype1, ExperimentalSubstanceSubtype2, ExperimentalSubstanceSubtype3</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Container Disposition Manager</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.CONTAINER.DISPOSITIONMAINTAINER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> CONTAINER.DISPOSITIONMAINTAINER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to manager the Container Disposition process, including adding a Site to the Container Disposition process (future use)</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Container Editor</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.CONTAINER.EDITOR.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> CONTAINER.EDITOR</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to edit properties of a container that they do not own.</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Container Mover</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.CONTAINER.RECEIVER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> CONTAINER.RECEIVER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to receive a Container to a new Location that does not belong to them.  (A User can freely move their own Containers to a new Location)</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Distillation Corrector</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.DISTILLATION.CORRECTOR.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> DISTILLATION.CORRECTOR</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to Correct a Distillation Experiments data after the completion of the experiment</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            {/* <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Distillation Experiment Maintainer</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.DISTILLATION.EXPERIMENTMAINTAINER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> DISTILLATION.EXPERIMENTMAINTAINER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allow the User to edit any Distillation Experiment after submission.  (A User can always update their own submissions up until work has started)</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion> */}
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Distillation Master Data Administrator</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.MASTERDATA.DISTILLATION.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> MASTERDATA.DISTILLATION</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to manage Master Data elements in the Distillation space: DistillationCutType, DistillationExperimentPriority, DistillationStatus, DistillationType</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            {/* <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Distillation Template Maintainer</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.DISTILLATION.TEMPLATEMAINTAINER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> DISTILLATION.TEMPLATEMAINTAINER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to create and maintainer Distillation Template for use in a Distillation Experiment</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion> */}
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Drum Field Maintainer</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.CONTAINER.DRUMMAINTAINER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> CONTAINER.DRUMMAINTAINER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to receive a Container to and from the drum storage areas (CDRUM)</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>EMBSI User</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> **See your supervisor</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> EMBSIUSER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to create and maintain EMBSI Use Only Samples and Containers</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Formulations Master Data Administrator</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.MASTERDATA.FORMULATIONS.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> MASTERDATA.FORMULATIONS</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to maintain Master Data specific to the Formulations area: BatchCertificationStatus, BatchStatus, BlendFacility, BlendPriority, BlendProductGroup, BlendStatus, BlendStudyStatus, BlendStudyType</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>General SRA Access</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> *See your supervisor*</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> GENERALSRA</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                                  <StyledTableCellInfo>Allows the User to access data for Projects marked as General SRA Access, regardless of individual membership in those Projects</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Instrument Adminstrator</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.INSTRUMENT.ADMINISTRATOR.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> INSTRUMENT.ADMINISTRATOR</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to create and maintain Instrument connection data for use in QC and serial connectivity</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Inventory Coordinator</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.INVENTORY.COORDINATOR.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> INVENTORY.COORDINATOR</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to manager the MIDAS based inventory process (future use)</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Location/Site Master Data Administrator</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.MASTERDATA.SITE.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> MASTERDATA.SITE</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to maintain Master Data related to the application-wide Location and Site objects</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Lot Certifier</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.LOT.CERTIFIER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> LOT.CERTIFIER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to certify and enter CoA data for a Lot received from an external vendor</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Method Administrator</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.METHODADMINISTRATOR.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> METHOD.ADMINISTRATOR</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to create and maintain Method and MethodFacility setup data for the Analytical features</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>New Chemical Authorization Approver</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.NCA.APPROVER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> NCA.APPROVER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to edit and approve, or deny, a New Chemical Authorization request</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Precious Metals User</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.PMUSER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> PMUSER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to create and maintain Samples and Containers flagged for Controlled Precious Metal</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Result Component Administrator</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.RESULTCOMPONENT.ADMINISTRATOR.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> RESULTCOMPONENT.ADMINISTRATOR</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to create and maintainer Result Component (formerly IDNUM) data for use in Analytical features</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Shipping Coordinator</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.WORKREQUEST.SHIPPINGCOORDINATOR.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> WORKREQUEST.SHIPPINGCOORDINATOR</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to process Shipping Work Requests</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>SQC Administrator</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.SQC.ADMINISTRATOR.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> SQC.ADMINISTRATOR</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to add and maintain SQC/QC setup data to existing MethodFacilities</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>SQC Reporter</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.SQC.REPORTER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> QC.REPORTER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to enter SQC/QC data in the MIDAS SQC system</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>SQC Reviewer</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.SQC.REVIEWER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> QC.REVIEWER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to review and update SQC/QC data in the MIDAS SQC system</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>SQC Viewer</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.SQC.VIEWER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> QC.VIEWER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to view SQC/QC data in the MIDAS SQC system</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>ST Procedure Administrator</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.ST.PROCEDUREADMIN.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> STATIONARYTESTING.PROCEDUREADMINISTRATOR</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to create and maintain the Stationary Testing Procedure and Test templates</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>ST Request Engineer</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.ST.ENGINEER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> STATIONARYTESTING.ENGINEER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to process a Stationary Test experiment</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>ST Schedule Administrator</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.ST.SCHEDULEADMIN.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> STATIONARYTESTING.SCHEDULEADMINISTRATOR</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to adjust the test schedule for a submitted Stationary Test experiment</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>ST Stand Administrator</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.ST.STANDADMIN.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> STATIONARYTESTING.STANDADMINISTRATOR</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to create and maintain Stationary Testing Stands</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Stationary Testing Master Data</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.MASTERDATA.ST.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> MASTERDATA.STATIONARYTESTING</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to maintain Master Data relevant to the Stationary Testing area: STEngineer, STFacility, STIntervalResultOption, STMethodClassification, STParameter, STQueue, STStandType, STStatus, STType</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>STLC Maintainer</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.STLCMAINTAINER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> STLC.MAINTAINER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to maintain Supplier, Tradename, and Test Plan data ingested from ProMIS</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Stockroom Administrator</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.STOCKROOM.ADMIN.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> STOCKROOM.ADMINISTRATOR</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to perform basic functions included with the Stockroom Worker role and also allows for administrative management in stockroom.  This includes adding new items, managing vendors and working with POs.</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Stockroom Master Data Administrator</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.MASTERDATA.STOCKROOM.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> MASTERDATA.STOCKROOM</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to maintain Master Data objects relevant to the Stockroom area:  StockroomCategory, StockroomContractWorker, StockroomVendor, EquipmentStatus, EquipmentType</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Stockroom Worker</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.STOCKROOM.WORKER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> STOCKROOM.WORKER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to perform basic functions in the stockroom module such as fullfilling orders, receiving stock, and returning items.</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Substance Maintainer</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.SUBSTANCE.MAINTAINER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> SUBSTANCE.MAINTAINER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to create and maintainer base data for all Substances (excludes safety).  A User can always update Substances that they own</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Substance Safety Maintainer</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.SUBSTANCE.SAFETYMAINTAINER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> SUBSTANCE.SAFETYMAINTAINER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to update and validate safety information for any Substance</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Substance Structure Maintainer</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.SUBSTANCE.STRUCTUREMAINTAINER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> SUBSTANCE.STRUCTUREMAINTAINER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to create and maintain Structures (SMILES) for use with Substances</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Test Activator</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.TESTACTIVATOR.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> TEST.ACTIVATOR</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to set the Status of a Test from Pending to Backlogged</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Test Analyst</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.ANALYST.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> ANALYST</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to access the Analytical Services-Simple Result Entry Page, access the Analytical Services-Worksheet Result Entry Page, post data to a Pending Test (through all sources), and set the status of a Test to In Progress</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Test Priority Maintainer</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.TEST.PRIORITYMAINTAINER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> TEST.PRIORITYMAINTAINER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to change the Priority of a submitted Test request</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>TSCA Approver</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.TSCA.APPROVER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> TSCA.APPROVER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to update, approve, or deny a TSCA Approval request for a Shipping Work Request</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Work Request Assigner</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.WORKREQUEST.ASSIGNER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> WORKREQUEST.ASSIGNER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to manually update the AssignedTo value of a Work Request</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Work Request Facility Assigner</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.WORKREQUEST.FACILITYASSIGNER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> WORKREQUEST.FACILITYASSIGNER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to assign a Work Request to a specific Facility</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Work Request Master Data Administrator</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.MASTERDATA.WORKREQUEST.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> MASTERDATA.WORKREQUEST</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to maintain Master Data objects relevant to the Work Request area: WorkRequestBusinessUnit, WorkRequestFacility, WorkRequestPriority, WorkRequestStatus, WorkRequestType, Shipping Reason</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Work Request Reporter</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.WORKREQUEST.REPORTER.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> WORKREQUEST.REPORTER</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to run reports and metrics (PISCES) on the Work Request processes</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography><h4>Work Request Technician</h4></Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Table>
                        <TableRow>
                            <StyledTableCellInfo><b>Active Directory group:</b> MIDAS.ROLE.WORKREQUEST.TECHNICIAN.UG</StyledTableCellInfo>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellInfo><b>MIDAS 3 Role Name:</b> WORKREQUEST.TECHNICIAN</StyledTableCellInfo>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow >
                            <StyledTableCellInfo>Allows the User to process a Work Request request</StyledTableCellInfo>
                        </TableRow>
                    </Table>
                </StyledAccordionDetails>
            </StyledAccordion>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion >
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography><h3>Projects</h3></Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
            <p>Projects in MIDAS 3 are groupings of samples, specific to MIDAS 3 to limit access to Sample data for members of a specific development project, e.g. F1M or Military projects</p>
        </StyledAccordionDetails>
      </StyledAccordion>
    </div>
  );
};

export default Roles;