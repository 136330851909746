import API from "..";

export default class BatchQueue {

  constructor({
    blendFacilityName,
    blendPriorityName,
    requestedCompletionDate,
    requestedAmount,
    requestedAmountUoM,
    batchStatusName,
    batchSequenceNumber,
    blendTypeName,
    blendName,
    studyName,
    preparedByEmail,
    age,
    id,
    ownerEmail,
    studyID
}) 
    
  {
    this.blendFacilityName = blendFacilityName;
    this.blendPriorityName = blendPriorityName;
    this.requestedCompletionDate = requestedCompletionDate;
    this.requestedAmount = requestedAmount;
    this.requestedAmountUoM = requestedAmountUoM;
    this.batchStatusName = batchStatusName;
    this.batchSequenceNumber = batchSequenceNumber;
    this.blendTypeName = blendTypeName;
    this.blendName = blendName;
    this.studyName = studyName;
    this.preparedByEmail = preparedByEmail;
    this.age = age;
    this.id = id;
    this.ownerEmail = ownerEmail;
    this.studyID = studyID
  }

  static async getBatchActiveQueue() {
    const api = await API();
    const { data } = await api.get(`/batchQueue/getActive`);
    if (data.result) {
        return data.result.map((d) => new BatchQueue(d));
    }
    else {
        return [];
    }
  }

  static async getBatchActiveAssignedQueueByUser(userName) {
    const api = await API();
    const { data } = await api.get(`/batchQueue/getActiveAssignedBatches?userName=${userName}`);
    if (data.result) {
        return data.result.map((d) => new BatchQueue(d));
    }
    else {
        return [];
    }
  }

}
