import API from "..";
export default class Backlog {

  constructor({
    testID,
    sampleName,
    testSequenceNumber,
    methodName,
    testStatusName,
    testPriorityName,
    testEstimate,
    conditionOfferingName,
    requesterEmail,
    submitterEmail,
    sampleOwnerEmail,
    testOwnerEmail,
    submittedDate,
    age,
    requestedCompletionDate,
    testFacilityAbv,
    isRetest,
    containerNumber
  }) {
    this.testID = testID;
    this.sampleName = sampleName;
    this.testSequenceNumber = testSequenceNumber;
    this.methodName = methodName;
    this.testStatusName = testStatusName;
    this.testPriorityName = testPriorityName;
    this.testEstimate = testEstimate;
    this.conditionOfferingName = conditionOfferingName;
    this.requesterEmail = requesterEmail;
    this.submitterEmail = submitterEmail;
    this.sampleOwnerEmail = sampleOwnerEmail;
    this.testOwnerEmail = testOwnerEmail;
    this.submittedDate = submittedDate;
    this.age = age;
    this.requestedCompletionDate = requestedCompletionDate;
    this.testFacilityAbv = testFacilityAbv;
    this.isRetest = isRetest;
    this.containerNumber = containerNumber
  }

  static async get(methodName, facility, sampleName, submitterEmail, requesterEmail, ownerEmail, testOwnerEmail, condition, showPending) {
    const api = await API();
    const { data } = await api.get(`/backlog/?methodName=${methodName}&facility=${facility}&sampleName=${sampleName}&submitterEmail=${submitterEmail}&requesterEmail=${requesterEmail}&ownerEmail=${ownerEmail}&testOwnerEmail=${testOwnerEmail}&condition=${condition}&showPending=${showPending}`);
    return data.result.map((d) => new Backlog(d));
  }

}