import React, { useState, useEffect } from "react";
import { Box, TextField, FormControl, FormControlLabel, Checkbox, FormGroup, Autocomplete, CircularProgress, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TestFacilityApi from "../../../../api/Admin/TestFacility";
import { GlobalButton } from "../../../styles";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StyledTableRow, StyledTableBody, StyledTableCell, UXDataTableWithoutBody } from "../../../../components/UXDataTable";
import PiscesTest from "../../../../api/Reporting/PiscesTest";
import PiscesBacklog from "../../../../api/Reporting/PiscesBacklog";
import { convertToLocalTime, formatMidasNumber, exportToExcel, DatePickerKeyDownEvent } from "../../../../global";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import './styles.css';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  alignItems: 'start'
}));

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '20px'
}));

const StyledShortTextField = styled(TextField)({
  id: "outlined-normal",
  width: "20rem",
  marginRight: "15px"
});

const defaultSearchFields = {
  startDate: null,
  endDate: null,
  tests: false,
  backlogs: false,
  facility: null,
  facilities: []
}

const StyledAutocomplete = styled(Autocomplete)(() => ({
  width: "20rem",
  marginRight: "15px"
}));

const AnalyticalMetrics = ({ ...props }) => {
  const [searchFields, setSearchFields] = useState(defaultSearchFields);
  const [testFacilities, setTestFacilities] = useState(null);
  const [listTest, setListTest] = useState([]);
  const [listBacklog, setListBacklog] = useState([]);
  const [loadingTest, setLoadingTest] = useState(false);
  const [loadingBacklog, setLoadingBacklog] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const isMountedRef = React.useRef(true);

  const colsTest = ['Method', 'Requested', 'Activated', 'Completed', 'Status', 'Requester', 'MIDAS Number', 'Method Description', 'Submitter', 'Analyst', 'Condition', 'Estimate', 'Priority', 'TestGroup', 'Facility', 'WBS'];
  const colsBacklog = ['Method', 'Requested', 'Activated', 'Completed', 'Pending', 'Backlog', 'InProgress', 'Description', 'Facility'];

  function loadFacilityFilter() {
    TestFacilityApi.getAll().then((result) => {
      if ((result !== undefined && result !== null)) {
        setTestFacilities(result.sort((a, b) => a.abreviation.localeCompare(b.abreviation)));
      }
    });
  }

  useEffect(() => {
    loadFacilityFilter();

    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    }
  }, [])

  async function handleSearch() {
    if (searchFields.tests) {
      setListTest([])
      setLoadingTest(true)
      getTest(200, 1);
    }
    if (searchFields.backlogs) {
      setListBacklog([])
      setLoadingBacklog(true)
      getBacklog(200, 1);
    }
  }

  async function getTest(resultsPerPage, page, allData = []) {
    if (isMountedRef.current) {
      const response = await PiscesTest.get(
        searchFields.startDate.toISOString(),
        searchFields.endDate.toISOString(),
        searchFields.facilities,
        page,
        resultsPerPage)

      if (response) {
        allData = [...allData, ...response]
      } else {
        setListTest([]);
      }

      if (response === null || response.length === 0) {
        setLoadingTest(false)
        return allData;
      } else {
        page++;
        setListTest(allData)
        return getTest(resultsPerPage, page, allData);
      }
    }
  }

  async function getBacklog(resultsPerPage, page, allData = []) {
    if (isMountedRef.current) {
      const response = await PiscesBacklog.get(
        searchFields.startDate.toISOString(),
        searchFields.endDate.toISOString(),
        searchFields.facilities,
        page,
        resultsPerPage)

      if (response) {
        allData = [...allData, ...response]
      } else {
        setListBacklog([]);
      }

      if (response === null || response.length === 0) {
        setLoadingBacklog(false)
        return allData;
      } else {
        page++;
        setListBacklog(allData)
        return getBacklog(resultsPerPage, page, allData);
      }
    }
  }

  const DownloadExcel = ({list, cols, fields, name}) => {
    return <IconButton onClick={e => {
      e.preventDefault();
      exportToExcel(list.map(item => {
        const object = {};
        cols.forEach((col, index) => {
          object[col] = fields[index].type === 'dateTime' ? convertToLocalTime(item[fields[index].key]) : fields[index].key === 'sampleName' ? formatMidasNumber(item[fields[index].key]) : item[fields[index].key]
        })
        return object;
      }), name)
    }}>
      <FileDownloadIcon sx={{ m: 1 }} style={{ cursor: "pointer" }} />
    </IconButton>
  }

  const AccordionTable = (props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const { name, colHeaders, loading, fields, list = [] } = props;

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    return (
      <Accordion expanded={expanded === name} onChange={handleChange(name)} onClick={(e) => {e.preventDefault();}}>
        <AccordionSummary
          className="pisces-accordion-sumamry"
          expandIcon={<>
            {expanded !== name && loading && <CircularProgress size={20} style={{ marginRight: "10px" }} />}
            <ExpandMoreIcon /></>}
          aria-controls="panel1a-content"
          id={`${name}-header`}
        >
          {/* <div> */}
            <Typography>{name}</Typography>
            {list.length > 0 && <DownloadExcel list={list} cols={colHeaders} fields={fields} name={name} />}
          {/* </div> */}
        </AccordionSummary>
        <AccordionDetails>
          <UXDataTableWithoutBody
            tableWidth='100%'
            cols={colHeaders}
            blankFirstHeader={false}
            tableId="simpleResultTable"
            rowLength={list.length}
            enablePaging={true}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            noDataFoundMessage={'There is no data available'}
            enableAddIcon={false}
            addFunction={null}
            addToolTipText={null}
            enableSorteable={false}
            isDataLoading={loading}
          >
            <StyledTableBody key={"myTablebody"}>
              {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, rowIndex) => {
                return (
                  [
                    <StyledTableRow
                      hover
                      key={`custom-row-${rowIndex}`}>
                      {fields.map(field => {
                        return <StyledTableCell style={{ minWidth: field.width ?? '', whiteSpace: field.noWrap ? 'nowrap' : '' }}>{field.type === 'dateTime' ? convertToLocalTime(item[field.key]) : field.key === 'sampleName' ? formatMidasNumber(item[field.key]) : item[field.key]}</StyledTableCell>
                      })}
                    </StyledTableRow>
                  ]
                );
              })}
            </StyledTableBody>
          </UXDataTableWithoutBody>
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <div style={{ paddingBottom: '20px' }}>
      <h4>Analytical Metrics</h4>
      <StyledFormControl fullWidth>
        <StyledBox>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              size="small"
              inputFormat="MM/dd/yyyy"
              label="Start Date"
              onChange={e => setSearchFields(() => ({
                ...searchFields,
                startDate: e
              }))}
              maxDate={searchFields.endDate}
              value={searchFields.startDate}
              renderInput={(params) => <StyledShortTextField onKeyDown={DatePickerKeyDownEvent} {...params} margin="normal" variant="outlined" size="small" InputLabelProps={{ shrink: true }} inputProps={{ ...params.inputProps }} InputProps={{ ...params.InputProps }} />}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              size="small"
              inputFormat="MM/dd/yyyy"
              label="End Date"
              onChange={e => setSearchFields(() => ({
                ...searchFields,
                endDate: e
              }))}
              minDate={searchFields.startDate}
              maxDate={new Date()}
              value={searchFields.endDate}
              renderInput={(params) => <StyledShortTextField onKeyDown={DatePickerKeyDownEvent} {...params} margin="normal" variant="outlined" size="small" InputLabelProps={{ shrink: true }} inputProps={{ ...params.inputProps }} InputProps={{ ...params.InputProps }} />}
            />
          </LocalizationProvider>
        </StyledBox>
        <StyledBox>
          <StyledAutocomplete
            size="small"
            id="facility-select"
            disablePortal
            noOptionsText={"Loading Facilities..."}
            options={testFacilities !== null ?
              testFacilities
                .map((facility) => facility.abreviation.toString())
                .filter(f => !searchFields.facilities?.some(s => s === f)) : []}
            getOptionLabel={(option) => option}
            onChange={(e, value) => {
              value = value === null ? "" : value
              setSearchFields(() => ({
                ...searchFields,
                facility: value,
              }))
            }}
            autoHighlight
            autoSelect
            value={searchFields.facility === '' ? null : searchFields.facility}
            renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" size="small" label="Facility" InputProps={{ ...params.InputProps }} />}
          />
          <GlobalButton
            style={{ marginTop: '8px', width: "12rem" }}
            variant='contained'
            onClick={() => {
              setSearchFields(() => ({
                ...searchFields,
                facilities: [...searchFields.facilities.filter(f => f !== searchFields.facility), searchFields.facility],
                facility: null
              }))
            }}
            disabled={!searchFields.facility}
          >Add Facility</GlobalButton>
          <StyledAutocomplete
            size="small"
            id="facilities-select"
            multiple
            limitTags={10}
            disableCloseOnSelect
            noOptionsText={"No Options"}
            options={searchFields.facilities}
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              setSearchFields(() => ({
                ...searchFields,
                facilities: newValue
              }))
            }}
            value={searchFields.facilities}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            style={{ width: 400 }}
            autoHighlight
            freeSolo
            renderInput={(params) => <TextField
              rows={1}
              multiline
              {...params}
              margin="normal"
              variant="outlined"
              size="small"
              label="Facilities to Search"
              inputProps={{ ...params.inputProps, readOnly: true }}
            />}
          />
        </StyledBox>
        <StyledBox>
          <FormGroup>
            <FormControlLabel control={
              <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />} label="Tests"
              onClick={e => setSearchFields(() => ({
                ...searchFields,
                tests: !searchFields.tests
              }))}
              checked={searchFields.tests}
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel control={
              <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />} label="Backlogs"
              onClick={e => setSearchFields(() => ({
                ...searchFields,
                backlogs: !searchFields.backlogs
              }))}
              checked={searchFields.backlogs}
            />
          </FormGroup>
          <GlobalButton
            style={{ marginTop: '8px', width: "12rem" }}
            variant='contained'
            onClick={handleSearch}
            disabled={(!searchFields.startDate || !searchFields.endDate) || (!searchFields.tests && !searchFields.backlogs)}
          >Get Metrics</GlobalButton>
        </StyledBox>
      </StyledFormControl>
      <div>
        {searchFields.tests && <AccordionTable
          name={'Tests'}
          colHeaders={colsTest}
          list={listTest}
          loading={loadingTest}
          fields={[
            { key: 'methodName' },
            { key: 'submittedDate', type: 'dateTime' },
            { key: 'receivedDate', type: 'dateTime' },
            { key: 'reportedDate', type: 'dateTime' },
            { key: 'status' },
            { key: 'requesterEmail' },
            { key: 'sampleName', noWrap: true },
            { key: 'methodDescription', width: '280px' },
            { key: 'submitterEmail' },
            { key: 'analystEmail' },
            { key: 'conditionOfferingName' },
            { key: 'testEstimate' },
            { key: 'priority' },
            { key: 'testGroup' },
            { key: 'testFacilityAbv' },
            { key: 'billingInfo' },
          ]}
        />}
        {searchFields.backlogs && <AccordionTable
          name={'Backlog'}
          colHeaders={colsBacklog}
          list={listBacklog}
          loading={loadingBacklog}
          fields={[
            { key: 'methodName' },
            { key: 'numberRequest' },
            { key: 'numberActivated' },
            { key: 'numberCompleted' },
            { key: 'numberPending' },
            { key: 'currentBacklog' },
            { key: 'numberInProgress' },
            { key: 'methodDescription' },
            { key: 'testFacilityAbv' },
          ]}
        />}
      </div>
    </div>
  );
};

export default AnalyticalMetrics; 