import { useEffect, useState, useContext } from "react";
import { styled } from "@mui/material/styles";
import { Box, Checkbox, FormControlLabel, FormControl, Switch, CircularProgress } from "@mui/material";
import { formatMidasNumber, hasRole, Roles } from "../../../global";
import { StyledTableRow, StyledTableBody, StyledTableCell, UXDataTableWithoutBody } from "../../../components/UXDataTable";
import ContainerApi from '../../../api/LIMS/Container';
import LocationApi from '../../../api/Admin/Location';
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import LocationField from "../../../components/LocationField";
import ValidatedMidasNumberTextField from "../../../components/ValidatedMidasNumberTextField";
import UserContext from "../../../context/UserContext";
import { GlobalSecondaryButton } from "../../styles";

const StyledContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
}));

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px'
}));

const DiscardContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px'
}));

const StyledBox = styled(Box)(() => ({
  display: 'inline-box'
}));

const DiscardContainersAutoScan = ({ ...props }) => {
  const [searchSampleName, setSearchSampleName] = useState(null);
  const [tempHasErrors, setTempHasErrors] = useState(true);
  const [newLocation, setNewLocation] = useState('');
  const [cancelTests, setCancelTests] = useState(false);
  const [listContainer, setListContainer] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [modalSimpleButton, setModalSimpleButton] = useState(false);
  const [modalSimpleButtonText, setModalSimpleButtonText] = useState('');
  const [modalSimpleTitle, setModalSimpleTitle] = useState('');
  const [modalSimpleText, setModalSimpleText] = useState('');

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [listLocation, setListLocation] = useState([]);

  const currentUser = useContext(UserContext)
  const roles = currentUser?.idTokenClaims.roles
  const developer = hasRole(Roles.Developer, roles);
  const containerDrummaintainer = hasRole(Roles.ContainerDrummaintainer, roles);

  useEffect(() => {
    LocationApi.getAll().then((resp) => {
      setListLocation(resp)
    })

    const containers = props.containersDiscard;
    if (containers && containers.length > 0) {
      setListContainer(containers.filter(function (item) { return item['containerStatusName'] !== "Discarded" }).map(item => {
        return {
          ...item,
          selected: false
        }
      }));
      props.setContainersDiscard([]);
    }
  }, [props])

  function sortContainer(a, b) {
    if (a.sampleName < b.sampleName) return -1;
    if (a.sampleName > b.sampleName) return 1;

    if (a.containerNumber < b.containerNumber) return -1;
    if (a.containerNumber > b.containerNumber) return 1;

    return 0;
  }

  const handleSearch = (value, containerNumber) => {
    if (value?.sampleName) {
      setDataIsLoading(true)

      handleContainer(value, containerNumber)
    }
  }

  function handleContainer(value, containerNumber = null) {
    setTimeout(() => {
      const containers = value?.containers?.filter(f => f.containerNumber === containerNumber || containerNumber === null);

      const list = [...listContainer.filter(c => !containers.some(s => s.sampleName === c.sampleName && c.containerNumber === s.containerNumber)), 
        ...containers.map(item => {
        return {
          ...item,
          selected: true
        }
      })]

      setListContainer(list.sort(sortContainer));
      setSearchSampleName(null)
      setDataIsLoading(false)
    }, 1000);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = () => {
    const displayed = determineStartArray(listContainer)

    const allSelected = listContainer.filter(note => note.selected).length === listContainer.length
    const allDisplayedSelected = displayed.filter(note => note.selected).length === displayed.length

    if (allSelected) {
      setListContainer(prevState => {
        const updatedArray = prevState.map(container => {
          return { ...container, selected: false }
        })

        return updatedArray
      })

    } else if (allDisplayedSelected) {
      setListContainer(prevState => {
        const updatedArray = prevState.map(container => {
          if (displayed.filter(item => item.id === container.id).length > 0) {
            return { ...container, selected: false }
          }
          return container
        })

        return updatedArray
      })

    } else {
      setListContainer(prevState => {
        const updatedArray = prevState.map(container => {
          if (container.locationName === "CDRUMS" && !containerDrummaintainer && !developer) {
            openModalSimpleButton('Unauthorized', 'You are not allowed to receive containers from CDRUMS', 'Ok');
            return container;
          } 
          
          if (displayed.filter(item => item.id === container.id).length > 0) {
            return { ...container, selected: true }
          }
          
          return container
        })

        return updatedArray
      })
    }

  }

  function determineStartArray(arrayContainers) {
    if (arrayContainers) {
      return stableSort(arrayContainers, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    } else {
      return []
    }
  }

  const colHeaders = [
    { id: 'sampleName', label: 'MIDAS Number' },
    { id: 'containerNumber', label: 'Container Number' },
    { id: 'locationName', label: 'Current Location' },
    { id: 'subLocation', label: 'Current Sub Location' },
    { id: 'site', label: 'Site' },
    { id: 'ownerEmail', label: 'Owner' },
    { id: 'containerSizeUoM', label: 'Current Amount - UoM' },
    { id: 'testsBackloggedOrPending', label: '# of Pending Backlogged Tests' },
  ];

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function handleSubmit() {
    let itensToDiscard = listContainer.filter(function (item) {
      return item['selected'] === true
    });

    let containerWithTestInProgress = ""
    itensToDiscard.forEach((item) => {
      if (item.testsInProgress > 0) {
        containerWithTestInProgress = containerWithTestInProgress === "" ? `${item.containerNumber}` : `${containerWithTestInProgress}, ${item.containerNumber}`
      }
    })

    if (containerWithTestInProgress !== "") {
      openModalSimpleButton('Warning', `There are tests in progress, the Container Number ${containerWithTestInProgress} cannot be Discarded until the Test is completed.`, 'Ok');
      return;
    }

    ContainerApi.discardContainers(itensToDiscard.map((item) => item.id.toString()), newLocation, cancelTests, "Discarded").then((res) => {
      if (res && res.message === 'Success') {
        openModalSimpleButton('Success', 'Discarded successfully', 'Ok');
        setListContainer([])
        setSearchSampleName(null)
        setNewLocation('')
      }
      else {
        const message = res.message && res.message !== '' ? res.message : 'Something went wrong, please try again later.'
        openModalSimpleButton('Fail', message, 'Ok');
      }
    });

  }

  function openModalSimpleButton(title, text, buttonText) {
    setModalSimpleButtonText(buttonText)
    setModalSimpleButton(true);
    setModalSimpleTitle(title);
    setModalSimpleText(text);
  }

  function closeModalSimpleButton() {
    setModalSimpleButton(false);
  }

  function handleChangeSelected(id) {
    let newList = listContainer.map(container => {
      if (container.id === id) {
        return {
          ...container,
          selected: container.selected ? false : true
        }
      } else {
        return container;
      }
    });
    setListContainer([...newList]);
  }

  return (
    <StyledContainer>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
      }}>
        <StyledBox>
          <ValidatedMidasNumberTextField
            margin={"none"}
            showLabel={true}
            fontSize={16}
            fieldWidth={"18rem"}
            midasNumberObject={searchSampleName}
            setMidasNumberObject={(e, containerNumber) => {
              setSearchSampleName(e)
              handleSearch(e, containerNumber)
            }}
            hasErrors={tempHasErrors}
            setHasErrors={setTempHasErrors}
            chemIDToMatch={null}
          ></ValidatedMidasNumberTextField>
        </StyledBox>
        <FormControlLabel control={<Switch onChange={() => props.setAutoScan(false)} checked={true} />} label="Auto Scan" />
      </div>
      <div>
        <UXDataTableWithoutBody
          tableWidth='100%'
          cols={colHeaders}
          blankFirstHeader={true}
          tableId="simpleResultTable"
          rowLength={listContainer.length}
          enablePaging={true}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          noDataFoundMessage={'There is no container data available'}
          enableAddIcon={false}
          addFunction={null}
          addToolTipText={null}
          enableCheckbox={true}
          enableSorteable={true}
          selected={listContainer.filter(c => c.selected)}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
          handleSelectAllClick={handleSelectAllClick}
          isDataLoading={dataIsLoading}
        >

          <StyledTableBody key={"myTablebody"}>
            {determineStartArray(listContainer).map((container, rowIndex) => {
                return (
                  <StyledTableRow
                    hover
                    key={`custom-row-${rowIndex}`}>
                    <StyledTableCell padding="checkbox"><Checkbox checked={container.selected} disabled={false}
                      onChange={() => {
                        if (container.locationName === "CDRUMS" && !containerDrummaintainer && !developer) openModalSimpleButton('Unauthorized', 'You are not allowed to receive containers from CDRUMS', 'Ok');
                        else handleChangeSelected(container.id)
                      }}
                    /></StyledTableCell>
                    <StyledTableCell style={{ width: '8rem' }}>{formatMidasNumber(container.sampleName)}</StyledTableCell>
                    <StyledTableCell style={{ width: '8rem' }}>{container.containerNumber}</StyledTableCell>
                    <StyledTableCell>{container.locationName}</StyledTableCell>
                    <StyledTableCell style={{ width: '9rem' }}>{container.subLocation}</StyledTableCell>
                    <StyledTableCell>{listLocation.find(l => l.locationName === container.locationName)?.siteName}</StyledTableCell>
                    <StyledTableCell>{container.ownerEmail}</StyledTableCell>
                    <StyledTableCell style={{ width: '13rem' }}>{`${container.currentAmount} ${container.containerSizeUoM}`}</StyledTableCell>
                    <StyledTableCell style={{ width: '11rem' }}>{container.testsBackloggedOrPending}</StyledTableCell>
                  </StyledTableRow>
                );
              })}
            {listContainer?.length > 0 && dataIsLoading && <StyledTableRow
              hover
              key={`custom-row-loading`}>
              <StyledTableCell colSpan={9} style={{ textAlign: 'center' }}><CircularProgress size={20} /></StyledTableCell>
            </StyledTableRow>}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </div>
      <StyledFormControl fullWidth>
        <StyledBox>
          <LocationField
            isDisabled={!listContainer.filter(c => c.selected === true).length > 0}
            value={newLocation === '' ? null : newLocation}
            handleChange={(value) => {
              if (value === "CDRUMS" && !containerDrummaintainer && !developer) openModalSimpleButton('Unauthorized', 'You are not allowed to receive containers from CDRUMS', 'Ok');
              else {
                setNewLocation(value)
              }
            }}
            label="Discard Location"
          />
        </StyledBox>
        <StyledBox>
          <FormControlLabel control={
            <Checkbox checked={cancelTests}
              onChange={() => setCancelTests(!cancelTests)}
              disabled={!listContainer.filter(c => c.selected === true).length > 0}
            ></Checkbox>} label={"Cancel Backlogged/Pending tests"} />
        </StyledBox>
        <DiscardContainer>
          <GlobalSecondaryButton
            variant='contained'
            type="submit"
            onClick={handleSubmit}
            disabled={!listContainer.filter(c => c.selected === true).length > 0}
          >Discard</GlobalSecondaryButton>
        </DiscardContainer>
      </StyledFormControl>
      <ModalSimpleButton title={modalSimpleTitle} buttonText={modalSimpleButtonText} buttonAction={closeModalSimpleButton} open={modalSimpleButton} setOpen={setModalSimpleButton}>
        <label>
          {modalSimpleText}
        </label>
      </ModalSimpleButton>
    </StyledContainer >
  );
};

export default DiscardContainersAutoScan;

