import React, { useState, useEffect } from "react";
import { MenuItem, Menu, FormControlLabel, Checkbox, Autocomplete, Box, FormControl, TextField, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { ChemIDSearchField } from '../../../components/ChemIDSearch/index';
import { GlobalButton, GlobalSecondaryButton } from "../../styles";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ComponentProcurementReportApi from "./../../../api/Inventory/ComponentProcurementReport";
import SiteApi from '../../../api/Admin/Site';
import { StyledTableRow, StyledTableBody, StyledTableCell, UXDataTableWithoutBody, UXDataTableNested } from "../../../components/UXDataTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { formatMidasNumber, applyFiltersToArray, RoundNumber, DatePickerKeyDownEvent, exportToCsv, ConvertUOMs } from './../../../global';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MoreOptions from "@mui/icons-material/MoreVert";
import FilterMenu from "../../../components/FilterMenu";
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const colHeaders = [
  { id: 'none', label: '' },
  { id: 'chemID', label: 'Chem ID' },
  { id: 'description', label: 'Description' },
  { id: 'timesUsed', label: 'Times Used' },
  { id: 'amountUsedInBatches', label: 'Amount Used in Batches' },
  { id: 'quantityAvailable', label: 'Available Quantity' },
  { id: 'isoAvailable', label: 'ISO Quantity Available' },
  { id: 'expiringInOneMonth', label: 'Exp < 1mo' },
  { id: 'expiringInTwoMonth', label: 'Exp < 2mo' },
  { id: 'expiringInSixMonths', label: 'Exp < 6mo' },
];

const reportContainerCols = ['MIDAS #', 'Container #', 'Current Amount', 'Current Location / Sublocation', 'Site', 'Shelf Life End Date', 'Certification Status']

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  alignItems: 'start'
}));

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '20px'
}));

const StyledShortTextField = styled(TextField)({
  id: "outlined-normal",
  width: "20rem",
  marginRight: "15px"
});

const StyledAutocomplete = styled(Autocomplete)(() => ({
  width: "20rem",
  marginRight: "15px"
}));

const defaultSearchFields = {
  startDate: null,
  endDate: null,
  siteName: '',
  substances: [],
  includeDefaultComponents: true,
  returnUoM: 'kg'
}
const allSitesName = "All Sites"

const Reporting = ({ ...props }) => {

  const [selectedChemID, setSelectedChemID] = useState(null);
  const [hasChemIDErrors, setHasChemIDErrors] = useState(false);
  const [searchFields, setSearchFields] = useState(defaultSearchFields);
  const [listReport, setListReport] = useState([]);
  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [listSite, setListSite] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [rowOpen, setRowOpen] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreOptionsSelected, setMoreOptionsSelected] = useState(null);

  const [availableUOMs, setAvailableUOMs] = useState([]);
  const [searchedUOM, setSearchedUOM] = useState(null)

  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([{ name: null, displayName: null, operator: null, enumValues: [], value: '' }])
  const [filteredRequests, setFilteredRequests] = useState([])

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false)
  const [modalMessagesText, setModalMessagesText] = useState('')
  const [modalMessagesTitle, setModalMessagesTitle] = useState('')
  const modalMessagesButtonText = 'Ok'

  const isMountedRef = React.useRef(true);

  const isRowOpen = (componentName) => rowOpen.indexOf(componentName) !== -1;

  const handleRowExpand = (componentName) => {
    const rowOpenIndex = rowOpen.indexOf(componentName);
    let newTestRowOpen = [];

    if (rowOpenIndex === -1) {
      newTestRowOpen = newTestRowOpen.concat(rowOpen, componentName);
    } else if (rowOpenIndex === 0) {
      newTestRowOpen = newTestRowOpen.concat(rowOpen.slice(1));
    } else if (rowOpenIndex === rowOpen.length - 1) {
      newTestRowOpen = newTestRowOpen.concat(rowOpen.slice(0, -1));
    } else if (rowOpenIndex > 0) {
      newTestRowOpen = newTestRowOpen.concat(
        rowOpen.slice(0, rowOpenIndex),
        rowOpen.slice(rowOpenIndex + 1)
      );
    }
    setRowOpen(newTestRowOpen);
  };

  async function loadReport() {
    setDataIsLoading(true);
    setListReport([]);
    getData(200000, 1); //set this to a really high number because paging isn't necessary after indexing
  }

  async function getData(resultsPerPage, page, allData = []) {
    if (isMountedRef.current) {
      const uom = availableUOMs.find(a => a.uoMName === searchFields?.returnUoM);
      setSearchedUOM(uom ?? null);
      
      const res = await ComponentProcurementReportApi.get(
        searchFields.startDate ? searchFields.startDate.toISOString() : '',
        searchFields.endDate ? searchFields.endDate.toISOString() : '',
        searchFields.siteName === allSitesName ? '' : searchFields.siteName,
        searchFields.includeDefaultComponents,
        searchFields.substances,
        searchFields.returnUoM,
        page,
        resultsPerPage
      )

      if (res && res.message === 'Success') {
        allData = [...allData, ...res.result.sort((a,b) => b.timesUsed - a.timesUsed).map(item => {
          return {
            ...item
          }
        })]
      } else {
        openModalMessages("Error Getting Data", res.message ? res.message : "Undefined Error, contact support if this continues.")
        setListReport([]);
      }

      if (res?.result === null || res?.result.length === 0) {
        setDataIsLoading(false)
        return allData;
      } else if (res && res.message === 'Success') {
        page++;
        setListReport(allData)
        return getData(resultsPerPage, page, allData);
      }
    }
  }

  useEffect(() => {
    SiteApi.getAll().then((resp) => {
      let emptyOption = {siteName: allSitesName, abreviation:"", isActive:true}
      setListSite([emptyOption, ...resp.filter(result => result.isActive === true && result.siteName !== 'Global Usage - No Site').sort((a, b) => a.siteName.localeCompare(b.siteName))])
    })

    UnitOfMeasure.getAll().then((res) => {
      setAvailableUOMs(res.filter(result => (result.metricStandardConversion !== null || result.type === 'weight' || result.type === 'volume') && result.isActive === true).sort((a, b) => a.uoMName.localeCompare(b.uoMName)))
    });

    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    }

  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function handleOpenMoreOptions(e, workRequest) {
    setMoreOptionsSelected(workRequest)

    setAnchorEl(e.currentTarget)
  }

  function handleCloseMoreOptions() {
    setMoreOptionsSelected(null)

    setAnchorEl(null)
  }

  function sentToRequestBacklog(request) {
    props.handleNewRequest(request);
  }

  const filterOptions = [{ name: "chemID", displayName: "Chem ID", type: "string", enumValues: [] },
  { name: "description", displayName: "Description", type: "string", enumValues: [] },
  { name: "timesUsed", displayName: "Times Used", type: "integer", enumValues: [] },
  { name: "amountUsedInBatches", displayName: "Amount Used", type: "integer", enumValues: [] },
  { name: "quantityAvailable", displayName: "Quantity Available", type: "integer", enumValues: [] },
  { name: "isoAvailable", displayName: "ISO Quantity Available", type: "string", enumValues: [] },
  { name: "expiringInOneMonth", displayName: "Exp < 1mo", type: "string", enumValues: [] },
  { name: "expiringInTwoMonth", displayName: "Exp < 2mo", type: "string", enumValues: [] },
  { name: "expiringInSixMonths", displayName: "Exp < 6mo", type: "string", enumValues: [] },
  ]

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  const filterClick = (event) => {
    setFilteringOpen(true);
  }

  const clearFiltersClick = (event) => {
    setFilters([{ name: null, displayName: null, operator: null, enumValues: [], value: null }])
  }

  useEffect(() => {
    var filteredArray = applyFiltersToArray(filters, listReport)
    setFilteredRequests(filteredArray)
  }, [filters, listReport])

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessagesText('')
    setModalMessagesTitle('')
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  const getReportList = () => {
    const group = [];
    listReport.forEach(report => {
      report.containers.sort((a, b) => a.containerNumber - b.containerNumber).forEach(container => {
        group.push({
          chemID: report.chemID,
          description: report.description,
          timesUsed: report.timesUsed,
          amountUsed: `${RoundNumber(report.amountUsedInBatches, 2, true)} ${searchFields.returnUoM}`,
          quantityAvailable: `${RoundNumber(report.quantityAvailable, 2, true)} ${searchFields.returnUoM}`,
          isoAvailable: `${report.isoAvailable} ${searchFields.returnUoM}`,
          expiringInOneMonth: report.expiringInOneMonth,
          expiringInTwoMonth: report.expiringInTwoMonth,
          expiringInSixMonths: report.expiringInSixMonths,
          sampleName: formatMidasNumber(container.sampleName),
          containerNumber: container.containerNumber,
          currentAmount: `${RoundNumber(container.currentAmount, 2, true)}  ${container.containerSizeUoM}`,
          location: `${container.locationName}${container.subLocation === null ? '' : `/${container.subLocation}`}`,
          site: container.siteName === null ? '' : container.siteName,
          shelfLifeEndDate: container.shelfLifeEndDate === null ? '' : container.shelfLifeEndDate,
          certificationStatusName: container.certificationStatusName === null || container.certificationStatusName.trim() === '' ? '' : container.certificationStatusName
        })
      });
      
    })

    return group;
  }

  return (
    <div style={{ paddingBottom: '20px' }}>
      <h4>Reporting</h4>
      <StyledFormControl fullWidth>
        <StyledBox>
          <ChemIDSearchField
            fontSize={16}
            fieldWidth="100%"
            selectedChemIDObject={selectedChemID}
            setSelectedChemIDObject={(e) => setSelectedChemID(e)}
            hasErrors={hasChemIDErrors}
            setHasErrors={setHasChemIDErrors}
            isDisabled={false}
            labelCustom={null}
            allowInactiveChemIDs={false}
            returnSubstanceStrucureData={false}
          />
          <GlobalButton
            style={{ marginLeft: '15px', width: "12rem" }}
            variant='contained'
            onClick={() => {
              setSearchFields(() => ({
                ...searchFields,
                substances: [...searchFields.substances.filter(f => f !== selectedChemID.chemID), selectedChemID.chemID]
              }))

              setSelectedChemID(null)
            }}
            disabled={hasChemIDErrors || selectedChemID === '' || selectedChemID === null}
          >Add Chem ID</GlobalButton>
        </StyledBox>
        <StyledBox>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              size="small"
              inputFormat="MM/dd/yyyy"
              label="Start Date"
              onChange={e => setSearchFields(() => ({
                ...searchFields,
                startDate: e
              }))}
              maxDate={searchFields.endDate}
              value={searchFields.startDate}
              renderInput={(params) => <StyledShortTextField onKeyDown={DatePickerKeyDownEvent} {...params} margin="normal" variant="outlined" size="small" InputLabelProps={{ shrink: true }} inputProps={{ ...params.inputProps }} InputProps={{ ...params.InputProps }} />}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              size="small"
              inputFormat="MM/dd/yyyy"
              label="End Date"
              onChange={e => setSearchFields(() => ({
                ...searchFields,
                endDate: e
              }))}
              minDate={searchFields.startDate}
              value={searchFields.endDate}
              renderInput={(params) => <StyledShortTextField onKeyDown={DatePickerKeyDownEvent} {...params} margin="normal" variant="outlined" size="small" InputLabelProps={{ shrink: true }} inputProps={{ ...params.inputProps }} InputProps={{ ...params.InputProps }} />}
            />
          </LocalizationProvider>
          <StyledAutocomplete
            size="small"
            id="site-select"
            disablePortal
            noOptionsText={"Loading Sites..."}
            options={listSite.map((site) => site.siteName)}
            getOptionLabel={(option) => option}
            onChange={(e, value) => {
              value = value === null ? "" : value
              setSearchFields(() => ({
                ...searchFields,
                siteName: value,
                //facility: ''
              }))
            }}
            autoHighlight
            autoSelect
            value={searchFields.siteName === '' ? null : searchFields.siteName}
            renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" size="small" label="Site" InputProps={{ ...params.InputProps }} />}
          />

          <StyledAutocomplete
            size="small"
            id="UOM-select"
            disablePortal
            noOptionsText={"Loading UoMs..."}
            options={availableUOMs.map((uom) => uom.uoMName)}
            //options={availableUOMs}
            getOptionLabel={(option) => option}
            //isOptionEqualToValue={(option, value) => value.uoMName === option.uoMName}
            // onChange={(e, value) => {
            //   setSelectedUOM(value)
            // }}
            onChange={(e, value) => {
              value = value === null ? "" : value
              setSearchFields(() => ({
                ...searchFields,
                returnUoM: value,
              }))
            }}
            autoHighlight
            autoSelect
            value={searchFields.returnUoM === '' ? null : searchFields.returnUoM}
            //value={selectedUOM}
            renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" size="small" label="Return UoM" InputProps={{ ...params.InputProps }} />}
          />

          {/* {
            searchFields.siteName === 'Global Usage - No Site' &&
            <StyledAutocomplete
              size="small"
              autoHighlight
              autoSelect
              noOptionsText={"Loading Facilities..."}
              value={searchFields.facility === '' ? null : searchFields.facility}
              onChange={(e, value) => {
                value = value === null ? "" : value
                setSearchFields(() => ({
                  ...searchFields,
                  facility: value
                }))
              }}
              disablePortal
              options={methodFacilities.map((method) => method.testFacilityAbv.toString())}
              sx={{ width: "10rem" }}
              renderInput={(params) => <TextField margin="normal" size="small" {...params} label="Facility"
              />}
            />
          } */}
        </StyledBox>
        <StyledAutocomplete
          size="small"
          id="substances-select"
          multiple
          limitTags={10}
          disableCloseOnSelect
          noOptionsText={"No Options"}
          options={searchFields.substances}
          getOptionLabel={(option) => option}
          onChange={(event, newValue) => {
            setSearchFields(() => ({
              ...searchFields,
              substances: newValue
            }))
          }}
          value={searchFields.substances}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          style={{ width: 400 }}
          autoHighlight
          freeSolo
          renderInput={(params) => <TextField
            rows={3}
            multiline
            {...params}
            margin="normal"
            variant="outlined"
            size="small"
            label="Adhoc Components to Search"
            inputProps={{ ...params.inputProps, readOnly: true }}
          />}
        />
        <FormControlLabel control={
          <Checkbox checked={searchFields.includeDefaultComponents}
            onChange={e => setSearchFields(() => ({
              ...searchFields,
              includeDefaultComponents: !searchFields.includeDefaultComponents
            }))}
          ></Checkbox>} label={"Include default components"} />
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}>
          <GlobalButton
            disabled = {searchFields.startDate === null || searchFields.endDate === null || searchFields.returnUoM === '' || (searchFields.includeDefaultComponents === false && searchFields.substances?.length === 0)}
            style={{ marginBottom: '15px' }}
            variant='contained'
            onClick={() => loadReport()}
          >Search</GlobalButton>
          <div style={{ display: 'flex' }}>
            <Box display="flex" alignItems={"center"} marginLeft="auto" marginTop="-.8rem">
              <GlobalButton style={{ marginRight: "1rem" }} variant="contained"
                onClick={() => filterClick()}>Filters</GlobalButton>

              {!(filters[0].name === null) &&
                <GlobalSecondaryButton variant="contained"
                  onClick={() => clearFiltersClick()}>Clear Filters</GlobalSecondaryButton>}
            </Box>
            {/* <FileDownloadIcon sx={{ m: 1 }} style={{ cursor: "pointer" }} onClick={e => exportToCsv(listReport.map(report => {
              return {
                chemID: report.chemID,
                description: report.description,
                timesUsed: report.timesUsed,
                amountUsed: `${RoundNumber(report.amountUsedInBatches, 2).toLocaleString()} ${searchFields.returnUoM}`,
                quantityAvailable: `${RoundNumber(report.quantityAvailable, 2).toLocaleString()} ${searchFields.returnUoM}`,
                isoAvailable: report.isoAvailable,
                expiringInOneMonth: report.expiringInOneMonth,
                expiringInTwoMonth: report.expiringInTwoMonth,
                expiringInSixMonths: report.expiringInSixMonths,
                containers: report.containers && report.containers.sort((a, b) => a.containerNumber - b.containerNumber).map((container) => {
                  return {
                    sampleName: formatMidasNumber(container.sampleName),
                    containerNumber: container.containerNumber,
                    currentAmount: `${RoundNumber(container.currentAmount, 2).toLocaleString()}  ${container.containerSizeUoM}`,
                    location: `${container.locationName}${container.subLocation === null ? '' : `/${container.subLocation}`}`,
                    site: container.siteName === null ? '' : container.siteName,
                    shelfLifeEndDate: container.shelfLifeEndDate === null ? '' : container.shelfLifeEndDate,
                    certificationStatusName: container.certificationStatusName === null || container.certificationStatusName.trim() === '' ? '' : container.certificationStatusName
                  }
                })
              }
            }), "Reporting Procurement")} /> */}
            <FileDownloadIcon sx={{ m: 1 }} style={{ cursor: "pointer" }} onClick={e => exportToCsv(getReportList(), "Reporting Procurement")} />
          </div>
        </div>
      </StyledFormControl>
      <UXDataTableWithoutBody
        tableWidth='100%'
        cols={colHeaders}
        tableId="componentProcurementReportingTable"
        rowLength={filteredRequests.length}
        enablePaging={true}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        noDataFoundMessage={'There is no data available'}
        enableAddIcon={false}
        addFunction={null}
        addToolTipText={null}
        enableCheckbox={false}
        enableSorteable={true}
        order={order}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        isDataLoading={dataIsLoading}
      >

        <StyledTableBody key={"myTablebody"}>
          {stableSort(filteredRequests, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((report, rowIndex) => {
              let amountUsedInBatches = report.amountUsedInBatches;
              let quantityAvailable = report.quantityAvailable;
              
              if (searchFields?.returnUoM !== searchedUOM?.uoMName) {
                const newUoM = availableUOMs.find(a => a.uoMName === searchFields.returnUoM);
                amountUsedInBatches = ConvertUOMs(null, null, amountUsedInBatches, searchedUOM, newUoM);
                quantityAvailable = ConvertUOMs(null, null, quantityAvailable, searchedUOM, newUoM);
              }

              return (
                [
                  <StyledTableRow
                    hover
                    key={`custom-row-${rowIndex}`}>
                    <StyledTableCell
                      component="th" scope="row" style={{ width: "10px" }}>
                      <MoreOptions onClick={(e) => handleOpenMoreOptions(e, report)} color='black' />
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '12rem' }}>
                      {report.chemID}
                      <IconButton
                        style={{ paddingBottom: '0px', paddingTop: '0px' }}
                        aria-label='expand row'
                        size='small'
                        onClick={() => handleRowExpand(report.chemID)}>
                        {isRowOpen(report.chemID) ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '18rem' }}>{report.description}</StyledTableCell>
                    <StyledTableCell>{report.timesUsed}</StyledTableCell>
                    <StyledTableCell>{`${RoundNumber(amountUsedInBatches, 2, true)} ${searchFields.returnUoM}`}</StyledTableCell>
                    <StyledTableCell>
                      {`${RoundNumber(quantityAvailable, 2, true)} ${searchFields.returnUoM}`}
                    </StyledTableCell>
                    <StyledTableCell style={{background: report.isBelowThresholdValue ? 'pink' : '#90EE90'}}>{`${report.isoAvailable} ${searchFields.returnUoM}`}</StyledTableCell>
                    <StyledTableCell>{report.expiringInOneMonth}</StyledTableCell>
                    <StyledTableCell>{report.expiringInTwoMonth}</StyledTableCell>
                    <StyledTableCell>{report.expiringInSixMonths}</StyledTableCell>
                  </StyledTableRow>,
                  isRowOpen(report.chemID) && <UXDataTableNested
                    tableWidth={'100%'}
                    cols={reportContainerCols}
                    blankFirstHeader={false}
                    colSpan={reportContainerCols.length + 5}
                    isOpenComparator={report.chemID}
                    isOpenArray={rowOpen}>

                    <StyledTableBody key={`containerTable${rowIndex}`}>
                      {report.containers && report.containers.sort((a, b) => a.containerNumber - b.containerNumber).map((container, containerIndex) => {
                        return (
                          <StyledTableRow key={`reportContainer${rowIndex}-${containerIndex}`}>
                            <StyledTableCell style={{ width: '10rem' }}>{formatMidasNumber(container.sampleName)}</StyledTableCell>
                            <StyledTableCell style={{ width: '10rem' }}>{container.containerNumber}</StyledTableCell>
                            <StyledTableCell style={{ width: '10rem' }}>{`${RoundNumber(container.currentAmount, 2, true)}  ${container.containerSizeUoM}`}</StyledTableCell>
                            <StyledTableCell style={{ width: '10rem' }}>{`${container.locationName}${container.subLocation === null ? '' : `/${container.subLocation}`}`}</StyledTableCell>
                            <StyledTableCell style={{ width: '10rem' }}>{container.siteName === null ? '' : container.siteName}</StyledTableCell>
                            <StyledTableCell style={{ width: '10rem' }}>{container.shelfLifeEndDate === null ? '' : container.shelfLifeEndDate}</StyledTableCell>
                            <StyledTableCell style={{ width: '10rem' }}>{container.certificationStatusName === null || container.certificationStatusName.trim() === '' ? '' : container.certificationStatusName}</StyledTableCell>
                          </StyledTableRow>
                        )
                      })}
                    </StyledTableBody>
                  </UXDataTableNested>
                ]
              );
            })}
        </StyledTableBody>
      </UXDataTableWithoutBody>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl && moreOptionsSelected ? true : false}
        onClose={() => handleCloseMoreOptions(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => sentToRequestBacklog(moreOptionsSelected)}>Add this to Procurement</MenuItem>
      </Menu>
      <FilterMenu
        open={filteringOpen}
        setOpen={setFilteringOpen}
        applyBtnAction={applyFilters}
        cancelButtonAction={closeFiltering}
        filteringInfo={filterOptions}
        appliedFilters={filters}
        setAppliedFilters={setFilters}
      />
      <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
        <label>
          {modalMessagesText}
        </label>
      </ModalSimpleButton>
    </div>
  );
};

export default Reporting;