import API from "../../api";
export default class Notification {
  /**
   * The Instrument dimension table for M3
   * @param {int} ID
   * @param {String} NotificationToEmail
   * @param {String} NotificationFromEmail
   * @param {String} NotificationType
   * @param {String} NotificationContent
   * @param {Date} CreatedDate
   * @param {String} CreatedByEmail
   * @param {Date} AcknowledgedDate
   * @param {boolean} IsAcknowledged
   */
  constructor({
    id,
    notificationToEmail,
    notificationFromEmail,
    notificationType,
    notificationContent,
    notificationSubject,
    createdDate,
    createdByEmail,
    acknowledgedDate,
    isAcknowledged
  }) {
    this.id = id;
    this.notificationFromEmail = notificationFromEmail;
    this.notificationToEmail = notificationToEmail;
    this.notificationType = notificationType;
    this.notificationContent = notificationContent;
    this.notificationSubject = notificationSubject;
    this.createdDate = createdDate;
    this.createdByEmail = createdByEmail;
    this.acknowledgedDate = acknowledgedDate;
    this.isAcknowledged = isAcknowledged
  }

  static async getByEmail(id, userEmail){
    const api = await API();
    const { data } = await api.get(`/notification/?id=${id}&userEmail=${userEmail}`);
    if (data.message === 'Success') 
    {
      return data.result.map((d) => new Notification(d));
    } else{
      return [{
        id: -1,
        notificationToEmail: "user@exxonmobil.com",
        notificationFromEmail: "midas-noreply@exxonmobil.com",
        notificationType: "System",
        notificationContent: "<b>Notifications Loading Error!</b><br/>You notifications failed to load, refresh to try again.  Contact support if this continues.",
        notificationSubject: "Notification Error",
        createdDate: null,
        createdByEmail: "midas-noreply@exxonmobil.com",
        acknowledgedDate: null,
        isAcknowledged: false
      }]
    }
 
  }

  static async acknowledgeNotifications(arrayIDs) {
    const api = await API();
    const { data } = await api.post(`/notification/acknowledgeNotifications`, arrayIDs);
    return data
  }

  static async update(info) {
    const api = await API();
    const { data } = await api.post(`/notification/`, info);
    return new Notification(data.result);
  }
  
  static async delete(arrayIDs) {
    const api = await API();
    const { data } = await api.post(`/notification/delete`, arrayIDs);
    return data;
  }
}