import React, { useState, useEffect, useContext, useCallback } from "react";
import { StyledTableRow, StyledTableBody, StyledTableCell, UXDataTableWithoutBody } from "../../../components/UXDataTable";
import MoreOptions from "@mui/icons-material/MoreVert";
import { MenuItem, Menu, Button, Grid, TextField, Autocomplete, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import ModalTwoButtons from '../../../components/Modal/ModalTwoButtons';
import UnitOfMeasureApi from "../../../api/Admin/UnitOfMeasure";
import SubstanceApi from "../../../api/LIMS/Substance";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ChemIDSearchField } from '../../../components/ChemIDSearch/index';
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import ComponentProcurementApi from "./../../../api/Inventory/ComponentRequest"
import { getMessage, convertToLocalTime, convertDateFormat, isColor, exportToCsv, applyFiltersToArray, DatePickerKeyDownEvent, hasRole, Roles } from "../../../global";
import UserContext from "../../../context/UserContext";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterMenu from "../../../components/FilterMenu";
import { GlobalButton, GlobalSecondaryButton } from "../../styles";

const sampleGridFontSize = 16

const colHeaders = [
  { id: 'none', label: '' },
  { id: 'chemID', label: 'Chem ID' },
  { id: 'substanceName', label: 'Description' },
  { id: 'requestStatusName', label: 'Status' },
  { id: 'requestedForEmail', label: 'Requested For' },
  { id: 'createdDate', label: 'Requested Date' },
  { id: 'needByDate', label: 'Need By Date' },
  { id: 'quantityRequestedDescription', label: 'Quantity' },
  { id: 'submitterComments', label: 'Submitter Comments' },
];

const StyledButton = styled(Button)(() => ({
  marginTop: '2px',
  widht: '200px',
  textTransform: 'none',
  height: '2.2rem'
}));

const StyledTextField = styled(TextField)(() => ({
  width: '100%'
}));

const HeadContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  paddingBottom: '15px',
  justifyContent: 'space-between',
}));

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
    fontSize: 16,
  },
  width: "100%",
});

const StyledBodyModalEmail = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  marginTop: '5px'
}));

const Option = styled('li')({
  fontSize: sampleGridFontSize,
});

const initialRequestBacklog = {
  chemID: null,
  quantityRequested: null,
  quantityRequestedUoMName: '',
  submitterComments: '',
  transactionComments: '',
  needByDate: '',
  requestStatusName: 'Submitted',
  requestedForEmail: ''
}

const initialValidations =
{
  chemID: '',
  quantityRequested: '',
  quantityRequestedUoMName: '',
  submitterComments: '',
  needByDate: ''
};

const RequestBacklog = ({ ...props }) => {
  const currentUser = useContext(UserContext);
  const userRoles = currentUser?.idTokenClaims.roles;

  const [listRequest, setListRequest] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreOptionsSelected, setMoreOptionsSelected] = useState(null);
  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [modalTwoButtonsOpen, setModalTwoButtonsOpen] = useState(false);
  const [unitOfMeasureList, setUnitOfMeasureList] = useState([]);
  const [unitOfMeasureLoading, setUnitOfMeasureLoading] = useState(true);
  const [requestBacklog, setRequestBacklog] = useState({ ...initialRequestBacklog, requestedForEmail: currentUser.username });
  const [hasChemIDErrors, setHasChemIDErrors] = useState(false);
  const [invalidField, setInvalidField] = useState(initialValidations);
  const [modalChangeStatusOpen, setModalChangeStatusOpen] = useState(false);
  const [statusToSend, setStatusToSend] = useState('');
  const [commentToSend, setCommentToSend] = useState('');

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false)
  const [modalMessagesText, setModalMessagesText] = useState('')
  const [modalMessagesTitle, setModalMessagesTitle] = useState('')
  const [modalMessagesButtonText, setModalMessagesButtonText] = useState('Ok')

  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([{ name: null, displayName: null, operator: null, enumValues: [], value: '' }])
  const [filteredRequests, setFilteredRequests] = useState([])

  const isComponentRequestAdministrator = hasRole(Roles.Developer, userRoles) || hasRole(Roles.ComponentRequestAdministrator, userRoles);

  useEffect(() => {
    let unmounted = false;
    setUnitOfMeasureLoading(true)

    if (unitOfMeasureList.length === 0) {
      UnitOfMeasureApi.getAll().then((res) => {
        if (!unmounted && res.length > 0) {
          setUnitOfMeasureList(res.filter(f => f.isActive === true && (f.type === 'volume' || f.type === 'weight')).sort((a, b) => a.uoMName.localeCompare(b.uoMName)));
        }
        setUnitOfMeasureLoading(false)
      });
    }

    return () => { unmounted = true };
  }, [unitOfMeasureList]);

  async function loadBacklog() {
    setDataIsLoading(true)
    const res = await ComponentProcurementApi.getAll()
    if (res.length > 0) {
      setListRequest(res.map(item => {
        return {
          ...item,
          substanceName: item.requestSubstance && item.requestSubstance.substanceName ? item.requestSubstance.substanceName : '',
          quantityRequestedDescription: ` ${item.quantityRequested} ${item.quantityRequestedUoMName}`
        }
      }));
    }
    setDataIsLoading(false)
  }

  useEffect(() => {
    loadBacklog()
  }, []);

  const handleNewRequest = useCallback(async () => {
    const substance = await SubstanceApi.getByChemID(props.newRequest.chemID, false, false);
    setRequestBacklog({
      ...requestBacklog,
      chemID: substance && substance.message === 'Success' ? substance.result : null
    })

    setModalTwoButtonsOpen(true)
    props.setNewRequest(null)
  }, [requestBacklog, setRequestBacklog, props])

  useEffect(() => {
    if (props.newRequest) {
      handleNewRequest()
    }
  }, [props.newRequest, handleNewRequest])

  const filterOptions = [{ name: "chemID", displayName: "Chem ID", type: "string", enumValues: [] },
  { name: "substanceName", displayName: "Description", type: "string", enumValues: [] },
  { name: "requestStatusName", displayName: "Status", type: "string", enumValues: [] },
  { name: "requestedForEmail", displayName: "Requested For", type: "string", enumValues: [] },
  { name: "createdDate", displayName: "Requested Date", type: "date", enumValues: [] },
  { name: "needByDate", displayName: "Need By Date", type: "date", enumValues: [] },
  { name: "quantityRequestedDescription", displayName: "Quantity", type: "string", enumValues: [] },
  { name: "submitterComments", displayName: "Submitter Comments", type: "string", enumValues: [] }
  ]

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  const filterClick = (event) => {
    setFilteringOpen(true);
  }

  const clearFiltersClick = (event) => {
    setFilters([{ name: null, displayName: null, operator: null, enumValues: [], value: null }])
  }

  useEffect(() => {
    var filteredArray = applyFiltersToArray(filters, listRequest)
    setFilteredRequests(filteredArray)
  }, [filters, listRequest])

  const validateFields = () => {
    const invalidFields = {
      ...invalidField,
      chemID: requestBacklog.chemID === "" || requestBacklog.chemID === null ? getMessage('REQUIRED_FIELD') : "",
      needByDate: requestBacklog.needByDate === "" ? getMessage('REQUIRED_FIELD') : "",
      quantityRequested: requestBacklog.quantityRequested === null ? getMessage('REQUIRED_FIELD') : "",
      quantityRequestedUoMName: requestBacklog.quantityRequestedUoMName === "" ? getMessage('REQUIRED_FIELD') : "",
    }
    setInvalidField(invalidFields);
    if (invalidFields.chemID !== "") {
      setHasChemIDErrors(true)
    }

    return (invalidFields.chemID === "" && invalidFields.needByDate === "" && invalidFields.quantityRequested === "" && invalidFields.quantityRequestedUoMName === "");
  }

  async function createRequest() {
    if (!validateFields()) return;

    const payload = {
      ...requestBacklog,
      chemID: requestBacklog.chemID?.chemID
    }

    var myResult = await ComponentProcurementApi.create(payload)

    if (myResult.message === 'Success')
    {
      openModalMessages("Request Created Successfully", "Your request has been created!")
      closeModalTwoButtonsOpen()
    } else {
      if (myResult.message)
      {
        openModalMessages("Request Failed", `Your request failed to save.  ${myResult.message}. Contact support if you feel this is an error.`);
      } else {
        openModalMessages('Request Failed', `Your request failed to save.  Unspecified Error, Contact support if you feel this is an error.`);
      }
    }

    loadBacklog()
  }

  async function updateRequest(request, status) {
    const payload = {
      ...request,
      requestStatusName: status !== '' ? status : request.requestStatusName,
      transactionComments: commentToSend !== '' ? commentToSend : request.transactionComments
    }

    var myResult = await ComponentProcurementApi.update(payload)

    if (myResult.message === 'Success')
    {
      openModalMessages("Request Updated Successfully", "You request has been updated!")
      closeModalTwoButtonsOpen()
      handleCloseMoreOptions()
    } else {
      if (myResult.message)
      {
        openModalMessages("Request Update Failed", `Your request failed to update.  ${myResult.message}. Contact support if you feel this is an error.`);
      } else {
        openModalMessages('Request Update Failed', `Your request failed to update.  Unspecified Error, Contact support if you feel this is an error.`);
      }
    }

    loadBacklog()
  }

  function sendComment() {
    updateRequest(moreOptionsSelected, statusToSend, commentToSend)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function handleOpenMoreOptions(e, workRequest) {
    setMoreOptionsSelected(workRequest)

    setAnchorEl(e.currentTarget)
  }

  function handleCloseMoreOptions() {
    setMoreOptionsSelected(null)

    setAnchorEl(null)
  }

  function closeModalTwoButtonsOpen() {
    setModalTwoButtonsOpen(false);
    setRequestBacklog({
      ...initialRequestBacklog,
      requestedForEmail: currentUser.username
    });
    setInvalidField(initialValidations);
    setHasChemIDErrors(false);
  }

  function closeModalChangeStatusOpen() {
    setModalChangeStatusOpen(false);
    setStatusToSend('');
    setCommentToSend('');
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessagesText('')
    setModalMessagesButtonText('Ok')
    setModalMessagesTitle('')
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  const getColor = (color) => {
    const colorHex = `#${color}`;
    if (color) {
      return isColor(colorHex) ? colorHex : color
    } else {
      return ''
    }
  }

  const isOwner = (moreOptionsSelected) => {
    if (moreOptionsSelected?.requestedForEmail === currentUser.username || moreOptionsSelected?.createdByEmail === currentUser.username) {
      return true;
    }

    return false;
  }

  return (
    <div>
      <HeadContainer>
        <StyledButton sx={{ width: "12rem" }}
          variant='contained'
          type="button"
          onClick={() => setModalTwoButtonsOpen(true)}
        >Submit a Request</StyledButton>
        <div style={{ display: 'flex' }}>
          <Box display="flex" alignItems={"center"} marginLeft="auto" marginTop="-.8rem">
            <GlobalButton style={{ marginRight: "1rem" }} variant="contained"
              onClick={() => filterClick()}>Filters</GlobalButton>

            {!(filters[0].name === null) &&
              <GlobalSecondaryButton variant="contained"
                onClick={() => clearFiltersClick()}>Clear Filters</GlobalSecondaryButton>}
          </Box>
          <FileDownloadIcon sx={{ m: 1 }} style={{ cursor: "pointer" }} onClick={e => exportToCsv(listRequest.map(element => {
            return {
              chemID: element.chemID,
              Description: element.Description,
              requestStatusName: element.requestStatusName,
              requestedForEmail: element.requestedForEmail,
              createdDate: convertToLocalTime(element.createdDate),
              needByDate: convertDateFormat(element.needByDate),
              quantity: `${element.quantityRequested} ${element.quantityRequestedUoMName}`,
              submitterComments: element.submitterComments,
            }
          }), "Request Backlogs")} />
        </div>
      </HeadContainer>
      <UXDataTableWithoutBody
        tableWidth='100%'
        cols={colHeaders}
        blankFirstHeader={true}
        tableId="simpleResultTable"
        rowLength={filteredRequests.length}
        enablePaging={true}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        noDataFoundMessage={'There is no request backlog data available'}
        enableAddIcon={false}
        addFunction={null}
        addToolTipText={null}
        enableCheckbox={false}
        enableSorteable={true}
        order={order}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        isDataLoading={dataIsLoading}
      >

        <StyledTableBody key={"myTablebody"}>
          {stableSort(filteredRequests, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((request, rowIndex) => {
              return (
                [
                  <StyledTableRow
                    hover
                    key={`custom-row-${rowIndex}`}>
                    <StyledTableCell
                      component="th" scope="row" style={{ width: "10px" }}>
                      <MoreOptions onClick={(e) => handleOpenMoreOptions(e, request)} color='black' />
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '5rem' }}>{request.chemID}</StyledTableCell>
                    <StyledTableCell style={{ width: '7rem' }}>{request.substanceName}</StyledTableCell>
                    <StyledTableCell style={{ width: '5rem', backgroundColor: getColor(request.requestStatus?.displayColor) }}>{request.requestStatusName}</StyledTableCell>
                    <StyledTableCell style={{ width: '10rem' }}>{request.requestedForEmail}</StyledTableCell>
                    <StyledTableCell style={{ width: '9rem' }}>{convertToLocalTime(request.createdDate)}</StyledTableCell>
                    <StyledTableCell style={{ width: '9rem' }}>{convertDateFormat(request.needByDate)}</StyledTableCell>
                    <StyledTableCell style={{ width: '5rem' }}>{request.quantityRequestedDescription}</StyledTableCell>
                    <StyledTableCell style={{ width: '10rem' }}>{request.submitterComments}</StyledTableCell>
                  </StyledTableRow>
                ]
              );
            })}
        </StyledTableBody>
      </UXDataTableWithoutBody>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl && moreOptionsSelected ? true : false}
        onClose={() => handleCloseMoreOptions(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => updateRequest(moreOptionsSelected, 'Cancelled')} disabled={!isComponentRequestAdministrator && !isOwner(moreOptionsSelected)}>Cancel Request</MenuItem>
        <MenuItem onClick={() => {
          setModalChangeStatusOpen(true)
          setStatusToSend('Rejected')
        }} disabled={!isComponentRequestAdministrator && !isOwner(moreOptionsSelected)}>Reject Request</MenuItem>
        <MenuItem onClick={() => {
          setModalChangeStatusOpen(true)
          setStatusToSend('Inquiring')
        }} disabled={!isComponentRequestAdministrator && !isOwner(moreOptionsSelected)}>Send Inquiry</MenuItem>
        <MenuItem onClick={() => updateRequest(moreOptionsSelected, 'OnOrder')} disabled={!isComponentRequestAdministrator}>Set Status to Ordered</MenuItem>
        <MenuItem onClick={() => {
          setModalChangeStatusOpen(true)
        }} disabled={!isComponentRequestAdministrator && !isOwner(moreOptionsSelected)}>Add Comments</MenuItem>
        <MenuItem onClick={() => updateRequest(moreOptionsSelected, 'Complete')} disabled={!isComponentRequestAdministrator && !isOwner(moreOptionsSelected)}>Complete Request</MenuItem>
      </Menu>

      <ModalTwoButtons title={'Submit a Request'} button1Text={'Submit'} button1Action={() => createRequest()} button2Text={'Cancel'} button2Action={closeModalTwoButtonsOpen} open={modalTwoButtonsOpen} setOpen={setModalTwoButtonsOpen}>
        <Grid container spacing={{ md: 2 }} columns={{ sm: 6, md: 12 }} maxWidth={'600px'}>
          <Grid item md={6} key={"SubstanceSharedComponent"}>
            <ChemIDSearchField
              fontSize={16}
              fieldWidth="100%"
              selectedChemIDObject={requestBacklog.chemID}
              setSelectedChemIDObject={(e) => setRequestBacklog({
                ...requestBacklog,
                chemID: e
              })
              }
              hasErrors={hasChemIDErrors}
              setHasErrors={setHasChemIDErrors}
              allowInactiveChemIDs={false}
              returnSubstanceStrucureData={false}
            />
          </Grid>
          <Grid item md={12} key={"SubstanceDescription"}>
            <StyledTextField
              label="Substance Description"
              disabled
              size="small"
              value={requestBacklog.chemID ? requestBacklog.chemID?.substanceName : ''}
            />
          </Grid>
          <Grid item md={4} key={"QuantityRequested"}>
            <StyledTextField
              onChange={(e) => setRequestBacklog({
                ...requestBacklog,
                quantityRequested: e.target.value
              })}
              label="Quantity Requested"
              size="small"
              value={requestBacklog.quantityRequested}
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              error={invalidField.quantityRequested !== '' ? true : false}
              helperText={invalidField.quantityRequested}
            />
          </Grid>
          <Grid item md={4} key={"QuantityUoM"}>
            <StyledAutocomplete
              renderOption={(props2, option) => (
                <Option {...props2}>{option.uoMName}</Option>
              )}
              disablePortal
              noOptionsText={unitOfMeasureLoading ? "Loading UoMs..." : "No UoMs Found"}
              options={unitOfMeasureList}
              onChange={(e, value) => setRequestBacklog({
                ...requestBacklog,
                quantityRequestedUoMName: value?.uoMName
              })}
              value={requestBacklog.quantityRequestedUoMName === '' ? null : requestBacklog.quantityRequestedUoMName}
              getOptionLabel={(option) => option?.uoMName ? option.uoMName : unitOfMeasureList.length > 0 ? unitOfMeasureList.find(f => f.uoMName === option)?.uoMName : ``}
              isOptionEqualToValue={(option, value) => value?.uoMName === option?.uoMName}
              autoHighlight
              renderInput={(params) => <StyledTextField {...params} style={{ width: "100%" }} label="Quantity UoM" variant="outlined" size="small"
                error={invalidField.quantityRequestedUoMName !== '' ? true : false}
                helperText={invalidField.quantityRequestedUoMName} inputProps={{ ...params.inputProps, style: { fontSize: sampleGridFontSize } }} InputProps={{ ...params.InputProps }} />}
            />
          </Grid>
          <Grid item md={4} key={"NeededByDate"}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                size="small"
                inputFormat="MM/dd/yyyy"
                minDate={new Date()}
                onChange={(e) => setRequestBacklog({
                  ...requestBacklog,
                  needByDate: e
                })}
                value={requestBacklog.needByDate}
                renderInput={(params) => <StyledTextField onKeyDown={DatePickerKeyDownEvent} {...params} label="Needed By Date" style={{ width: "100%" }} variant="outlined" size="small"
                  error={invalidField.needByDate !== '' ? true : false}
                  helperText={invalidField.needByDate} inputProps={{ ...params.inputProps, style: { fontSize: sampleGridFontSize } }} InputProps={{ ...params.InputProps }} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={12} key={"Comments"}>
            <StyledTextField
              onChange={(e) => setRequestBacklog({
                ...requestBacklog,
                submitterComments: e.target.value
              })}
              label="Comments"
              size="small"
              value={requestBacklog.submitterComments}
              inputProps={{ maxLength: 200 }}
            />
          </Grid>
        </Grid>
      </ModalTwoButtons>

      <ModalTwoButtons title={'Update a Request'} button1Text={'Submit'} button1Action={() => sendComment()} button2Text={'Cancel'} button2Action={closeModalChangeStatusOpen} open={modalChangeStatusOpen} setOpen={setModalChangeStatusOpen}>
        {/* <div style={{width: '1000px'}}>dawdwa</div> */}
        <StyledBodyModalEmail style={{minWidth: '450px'}}>
            <StyledTextField
              onChange={(e) => setCommentToSend(e.target.value)}
              label="Comments"
              size="small"
              value={commentToSend}
              inputProps={{ maxLength: 200 }}
              multiline={true}
              rows={3}
            />
        </StyledBodyModalEmail>
      </ModalTwoButtons>
      <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
        <label>
          {modalMessagesText}
        </label>
      </ModalSimpleButton>
      <FilterMenu
        open={filteringOpen}
        setOpen={setFilteringOpen}
        applyBtnAction={applyFilters}
        cancelButtonAction={closeFiltering}
        filteringInfo={filterOptions}
        appliedFilters={filters}
        setAppliedFilters={setFilters}
      />
    </div>
  );
};

export default RequestBacklog;