import API from "..";

export default class Sample {
  /**
   * The Sample dimension table for M3
   * @param {String} SampleName The MIDAS of the Sample
   */
  constructor({
    sampleName,
    description,
    additionalSampleInformation,
    chargeCode,
    parentSampleReason,
    ppmReference,
    createdByEmail,
    createdDate,
    lastModifiedByEmail,
    lastModifiedDate,
    sampleOwnerEmail,
    userDefinedSafetyInformation,
    densityAtSTP,
    sampleConcentration,
    sampleConcentrationUoM,
    uom,
    isCommodityChemical,
    supplierTradenameLotID,
    supplierLot,
    isSampleDataLocked,
    isTestingAllowed,
    projectID,
    project,
    isRestrictedAccess,
    chemID,
    substance,
    initialLocation,
    location,
    containers,
    parentSampleContainerID,
    parentSampleContainer,
    forInformaticsUse,
    requiresBlindCode,
    productUnit,
    productRun,
    productBalance,
    productStream,
    productLowCutTemp,
    productHighCutTemp,
    feedstockCode,
    feedstockNumber1,
    feedstockInitials,
    feedstockNumber2,
    feedstockLowCutTemp,
    feedstockHighCutTemp,
    catalystYear,
    catalystGroup,
    catalystApp,
    catalystNumber,
    mrdNumber,
    embsiUseOnly,
    isRMCSample,
    fileAttachments,
    totalCurrentContainerVolInGrams,
    totalCurrentContainerVolInMililiters,
    psimsid,
    comments,
  }) 
  
  {
    this.sampleName = sampleName;
    this.description = description;
    this.additionalSampleInformation = additionalSampleInformation;
    this.chargeCode = chargeCode;
    this.parentSampleReason = parentSampleReason;
    this.ppmReference = ppmReference;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.sampleOwnerEmail = sampleOwnerEmail;
    this.userDefinedSafetyInformation = userDefinedSafetyInformation;
    this.densityAtSTP = densityAtSTP;
    this.sampleConcentration = sampleConcentration;
    this.sampleConcentrationUoM = sampleConcentrationUoM;
    this.uom = uom;
    this.isCommodityChemical = isCommodityChemical;
    this.supplierTradenameLotID = supplierTradenameLotID;
    this.supplierLot = supplierLot;
    this.isSampleDataLocked = isSampleDataLocked;
    this.isTestingAllowed = isTestingAllowed;
    this.projectID = projectID;
    this.project = project;
    this.isRestrictedAccess = isRestrictedAccess;
    this.chemID = chemID;
    this.substance = substance;
    this.initialLocation = initialLocation;
    this.location = location;
    this.containers = containers;
    this.parentSampleContainerID = parentSampleContainerID;
    this.parentSampleContainer = parentSampleContainer;
    this.forInformaticsUse = forInformaticsUse;
    this.requiresBlindCode = requiresBlindCode;
    this.productUnit = productUnit;
    this.productRun = productRun;
    this.productBalance = productBalance;
    this.productStream = productStream;
    this.productLowCutTemp = productLowCutTemp;
    this.productHighCutTemp = productHighCutTemp;
    this.feedstockCode = feedstockCode;
    this.feedstockNumber1 = feedstockNumber1;
    this.feedstockInitials = feedstockInitials;
    this.feedstockNumber2 = feedstockNumber2;
    this.feedstockLowCutTemp = feedstockLowCutTemp;
    this.feedstockHighCutTemp = feedstockHighCutTemp;
    this.catalystYear = catalystYear;
    this.catalystGroup = catalystGroup;
    this.catalystApp = catalystApp;
    this.catalystNumber = catalystNumber;
    this.mrdNumber = mrdNumber;
    this.embsiUseOnly = embsiUseOnly;
    this.isRMCSample = isRMCSample;
    this.fileAttachments = fileAttachments;
    this.totalCurrentContainerVolInGrams = totalCurrentContainerVolInGrams;
    this.totalCurrentContainerVolInMililiters = totalCurrentContainerVolInMililiters;
    this.psimsid = psimsid;
    this.comments = comments;
  }

  static async get(id) {
    const api = await API();
    const { data } = await api.get(`sample/${id}`);
    return data;
  }

  static async getSamplesWithContainers(id) {
    const api = await API();
    const { data } = await api.get(`sample/${id}?onlySamplesAndContainers=true`);
    return data;
  }

  static async quickSearch(searchCriteria) {
    const api = await API();
    const { data } = await api.get(`sample/quickSearch?searchCriteria=${searchCriteria}`);
    return data;
  }

  static async history(searchCriteria) {
    const api = await API();
    const { data } = await api.get(`sample/history?searchCriteria=${searchCriteria}`);
    return data;
  }
 
  static async searchByChemID(chemID, siteName) {
    const api = await API();
    const { data } = await api.get(`sample/searchByChemID?chemID=${chemID}&siteName=${siteName}`); //&onlyISOSamples=${onlyISOSamples}
    return data;
  }

  static async getByContainerID(containerID) {
    const api = await API();
    const { data } = await api.get(
      `sample/getByContainerID?containerID=${containerID}`
    );
    return data.result;
  }

  static async myTests(ownerEmail, page, resultsPerPage) {
    const api = await API();
    const { data } = await api.get(`sample/samplesWithTestsByOwners?ownerEmail=${ownerEmail}&page=${page}&resultsPerPage=${resultsPerPage}`);
    return data
  }

  static async mySamples(sampleOwnerEmail, page, resultsPerPage) {
    const api = await API();
    const { data } = await api.get(`sample/samplesByOwner?ownerEmail=${sampleOwnerEmail}&page=${page}&resultsPerPage=${resultsPerPage}`);
    return data;
  }

  static async addUpdate_MidasRecommendation(info) {
    const api = await API();
    const { data } = await api.post(`/test/midasRecommendation`, info);
    return (data);
  }
  
  static async RegisterSampleAndContainers(sampleInfo) {
    const api = await API('multipart/form-data');
    const { data } = await api.post('sample/registerSamplesAndContainers', sampleInfo);
    return (data);
  }
}
