import React from "react";
import {
  Box,
  Button,
  FormControl,
  styled,
  TextField,
  Autocomplete,
  TableRow,
  IconButton,
  MenuItem,
  Menu,
  Typography,
} from "@mui/material";
import MethodApi from "../../api/LIMS/Method";
import { convertToLocalTime, formatMidasNumber, getMessage } from "../../global";
import TestApi from '../../api/LIMS/Test';
import SampleApi from '../../api/LIMS/Sample';
import { StyledTableBody, StyledTableCell, StyledTableCellLink, UXDataTableNested, UXDataTableWithoutBody } from "../../components/UXDataTable";
import MoreOptions from "@mui/icons-material/MoreVert";
import MethodResultComponentApi from "../../api/LIMS/MethodResultComponent";
import DetailsResults from "./DetailsResults";
import ModalSimpleButton from '../../components/Modal/ModalSimpleButton';
import ModalTwoButtons from '../../components/Modal/ModalTwoButtons';
import EmailAddressTextField from "../../components/EmailAddressField";
import MethodFacilityApi from "../../api/LIMS/MethodFacility";

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

const StyledBox = styled(Box)(() => ({
  display: 'inline-box',
  marginRight: "25px"
}));

const StyledTextField = styled(TextField)(() => ({
  width: "20rem"
}));

const StyledContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
}));

const StyledCard = styled(Box)(() => ({
  margin: '20px 0px'
}));

const StyledList = styled('ul')(() => ({
  paddingInlineStart: '40px'
}));

const StyledButton = styled(Button)(() => ({
  widht: '200px',
  textTransform: 'none',
  height: '2.2rem'
}));

const StyledBeforeContent = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '20px 0px'
}));

const StyleBeforeRow = styled(Box)(() => ({
  width: '16.6%'
}));

const StyledBodyModal = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  marginTop: '5px'
}));

const ReviewCorrectResults = ({ ...props }) => {
  const searchCategory = [
    {
      name: "",
      id: 0
    },
    {
      name: "Method",
      id: 1
    },
    {
      name: "MIDAS Number",
      id: 2
    },
    {
      name: "Requester",
      id: 3
    },
    {
      name: "Submitter",
      id: 4
    },
    {
      name: "Sample Owner",
      id: 5
    },
    {
      name: "Test Owner",
      id: 6
    },
    {
      name: "Facility",
      id: 7
    }
  ]

  const searchCriteriaInitialState = {
    methodName: "",
    facility: "",
    sampleName: "",
    submitterEmail: "",
    requesterEmail: "",
    ownerEmail: "",
    testOwnerEmail: "",
    condition: "",
    showPending: false
  }

  const searchCriteriaReviewInitialState = {
    methodName: "",
    facility: "",
    condition: "",
  }

  const validationsInitialState =
  {
    methodName: "",
    sampleName: "",
    submitterEmail: "",
    requesterEmail: "",
    ownerEmail: "",
    testOwnerEmail: "",
    facility: ""
  };

  const validationsReviewInitialState =
  {
    methodName: "",
    facility: ""
  };

  const [listTestResult, setListTestResult] = React.useState([]);
  const [listTest, setListTest] = React.useState([]);
  const [listTestReviewResult, setListTestReviewResult] = React.useState([]);
  const [listTestReview, setListTestReview] = React.useState([]);
  const [listMethodResult, setListMethodResult] = React.useState([]);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const [rowsPerPageReview, setRowsPerPageReview] = React.useState(10);
  const [pageReview, setPageReview] = React.useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [moreOptionsSelected, setMoreOptionsSelected] = React.useState(null);

  const reviewCorrectColHeaders = ['TSN', 'Method', 'Facility', 'MIDAS Number', 'Condition', 'Multple Results', 'Method Description', 'Status'];
  const subColHeaders = ['Result Order', 'Result Description', 'Result', 'Unit']

  const [invalidField, setInvalidField] = React.useState(validationsInitialState);
  const [searchCategorySelected, setSearchCategorySelected] = React.useState(null); 
  const [searchCriteria, setSearchCriteria] = React.useState(searchCriteriaInitialState);
  const [methodFacilities, setMethodFacilities] = React.useState(null);
  const [methodList, setMethodList] = React.useState(null);
  const [testRowOpen, setSampleRowOpen] = React.useState([]);
  const [sample, setSample] = React.useState(null);
  const [conditionList, setConditionList] = React.useState(null);

  const [invalidFieldReview, setInvalidFieldReview] = React.useState(validationsReviewInitialState);
  const [searchCriteriaReview, setSearchCriteriaReview] = React.useState(searchCriteriaReviewInitialState);
  const [methodFacilitiesReview, setMethodFacilitiesReview] = React.useState(null);
  const [conditionListReview, setConditionListReview] = React.useState(null);

  const [modalSimpleButton, setModalSimpleButton] = React.useState(false);
  const [modalSimpleButtonText, setModalSimpleButtonText] = React.useState('');
  const [modalSimpleTitle, setModalSimpleTitle] = React.useState('');
  const [modalSimpleText, setModalSimpleText] = React.useState('');

  const [modalTwoButtonsOpen, setModalTwoButtonsOpen] = React.useState(false);
  const [messageSubmitter, setMessageSubmitter] = React.useState(null);
  const [isSubmit, setIsSubmit] = React.useState(false);

  const [requesterInvalid, setRequesterInvalid] = React.useState(false);
  const [submitterInvalid, setSubmitterInvalid] = React.useState(false);
  const [ownerInvalid, setOwnerInvalid] = React.useState(false);
  const [testOwnerInvalid, setTestOwnerInvalid] = React.useState(false);

  const [dataIsLoading, setDataIsLoading] = React.useState(false);
  const [dataReviewIsLoading, setDataReviewIsLoading] = React.useState(false);

  React.useEffect(() => {
    MethodApi.getAll().then((res) => {
      if (res.length > 0) {
        let array = [];
        array.push({ id: undefined, name: "" });
        array.push(...res.filter(f => f.isActive));

        setMethodList(array);
      }
    });
  }, [])

  const determineStartArray = () => {
    return (listTest.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const determineStartArrayReview = () => {
    return (listTestReview.slice(pageReview * rowsPerPageReview, pageReview * rowsPerPageReview + rowsPerPageReview))
  }

  const handleChangePageReview = (event, newPage) => {
    setPageReview(newPage);
  };

  const handleChangeRowsPerPageReview = (event) => {
    setRowsPerPageReview(parseInt(event.target.value, 10));
    setPageReview(0);
  };

  async function handleSearch() {
    if (validateFields()) {
      setListTestResult([])
      setListTest([]);
      setRowsPerPage(10);
      setPage(0);
      setDataIsLoading(true)
      if (searchCriteria.sampleName && searchCriteria.sampleName !== '') {
        SampleApi.get(searchCriteria.sampleName).then((res) => {
          if (res) {
            setSample(res);
          }
          else {
            setSample(null)
          }
        });
      }
      await TestApi.getReviewCorrectResult(
        searchCriteria.methodName,
        searchCriteria.facility,
        searchCriteria.sampleName,
        searchCriteria.submitterEmail,
        searchCriteria.requesterEmail,
        searchCriteria.ownerEmail,
        searchCriteria.testOwnerEmail,
        searchCriteria.condition,
        "status=Completed").then((res) => {
          if (res.length > 0) {
            let newListTestResult = [];

            const tests = res.map((el, index) => {
              if (el.testResults && el.testResults.length > 0) {
                newListTestResult = [
                  ...newListTestResult,
                  ...el.testResults.filter(t => t.blobTestResult === null)
                ]
              }

              el.testResults = [];
              return {
                ...el
              }
            })

            setListTest(tests);
            setListTestResult([...newListTestResult])

            loadMethodResult(tests)
          }
          else {
            setListTestResult([])
            setListTest([]);
          }

          setDataIsLoading(false)
        });
    }
  }

  async function handleSearchReview() {
    if (validateFieldsReview()) {
      setListTestReviewResult([])
      setListTestReview([]);
      setPageReview(0);
      setRowsPerPageReview(10);
      setDataReviewIsLoading(true)
      await TestApi.getReviewCorrectResult(
        searchCriteriaReview.methodName,
        searchCriteriaReview.facility,
        "",
        "",
        "",
        "",
        "",
        searchCriteriaReview.condition,
        "status=UnderReview").then((res) => {
          if (res.length > 0) {
            let newListTestResult = [];

            const tests = res.map((el, index) => {
              if (el.testResults && el.testResults.length > 0) {
                newListTestResult = [
                  ...newListTestResult,
                  ...el.testResults.filter(t => t.blobTestResult === null)
                ]
              }

              el.testResults = [];
              return {
                ...el
              }
            })

            setListTestReview(tests);
            setListTestReviewResult([...newListTestResult])

            loadMethodResult(tests)
          }
          else {
            setListTestReviewResult([])
            setListTestReview([]);
          }

          setDataReviewIsLoading(false)
        });
    }
  }

  function handleChangeMethod(methodName) {

    if (methodName) {
      setSearchCriteria(() => ({
        ...searchCriteria,
        methodName: methodName,
        facility: "",
        condition: ""
      }));

      setInvalidField({
        ...invalidField,
        methodName: ''
      });

      setMethodFacilities(null);
      setConditionList(null);

      if (methodName) {
        MethodApi.get(methodName).then((methodExists) => {
          if ((methodExists !== undefined && methodExists !== null)) {
            let array = [];
            array.push({ testFacilityAbv: "" });
            array.push(...methodExists.methodFacilities.filter(f => f.methodStatusName === 'Active'));
            setMethodFacilities(array);
          }
        });
      }
    }
    else {
      setSearchCriteria(() => ({
        ...searchCriteria,
        methodName: "",
        facility: "",
        condition: ""
      }));

      setInvalidField({
        ...invalidField,
        methodName: getMessage('REQUIRED_FIELD')
      });

    }
  }

  function handleChangeMethodReview(methodName) {

    if (methodName) {
      setSearchCriteriaReview(() => ({
        ...searchCriteriaReview,
        methodName: methodName,
        facility: "",
        condition: ""
      }));

      setInvalidFieldReview({
        ...invalidFieldReview,
        methodName: ''
      });

      setMethodFacilitiesReview(null);
      setConditionListReview(null);

      if (methodName) {
        MethodApi.get(methodName).then((methodExists) => {
          if ((methodExists !== undefined && methodExists !== null)) {
            let array = [];
            array.push({ testFacilityAbv: "" });
            array.push(...methodExists.methodFacilities.filter(f => f.methodStatusName === 'Active'));
            setMethodFacilitiesReview(array);
          }
        });
      }
    }
    else {
      setSearchCriteriaReview(() => ({
        ...searchCriteriaReview,
        methodName: "",
        facility: "",
        condition: ""
      }));

      setInvalidFieldReview({
        ...invalidFieldReview,
        methodName: getMessage('REQUIRED_FIELD')
      });

    }
  }

  function validateFields(field) {
    if (field) {
      setInvalidField({
        ...invalidField,
        [field]: searchCriteria[field] === "" || searchCriteria[field] === undefined ? getMessage('REQUIRED_FIELD') : "",
      });

      return (
        (searchCriteria[field] !== "")
      );

    }
    else {
      setInvalidField({
        ...invalidField,
        methodName: searchCriteria.methodName === "" || searchCriteria.methodName === undefined ? getMessage('REQUIRED_FIELD') : "",
        sampleName: searchCriteria.sampleName === "" || searchCriteria.sampleName === undefined ? getMessage('REQUIRED_FIELD') : "",
        requesterEmail: searchCriteria.requesterEmail === "" || searchCriteria.requesterEmail === undefined ? getMessage('REQUIRED_FIELD') : "",
        submitterEmail: searchCriteria.submitterEmail === "" || searchCriteria.submitterEmail === undefined ? getMessage('REQUIRED_FIELD') : "",
        ownerEmail: searchCriteria.ownerEmail === "" || searchCriteria.ownerEmail === undefined ? getMessage('REQUIRED_FIELD') : "",
        testOwnerEmail: searchCriteria.testOwnerEmail === "" || searchCriteria.testOwnerEmail === undefined ? getMessage('REQUIRED_FIELD') : "",
        facility: searchCriteria.facility === "" || searchCriteria.facility === undefined ? getMessage('REQUIRED_FIELD') : ""
      });

      return (
        (searchCriteria.methodName !== "")
        || (searchCriteria.sampleName !== "")
        || (searchCriteria.requesterEmail !== "")
        || (searchCriteria.submitterEmail !== "")
        || (searchCriteria.ownerEmail !== "")
        || (searchCriteria.testOwnerEmail !== "")
        || (searchCriteria.facility !== "")
      );
    }
  }

  function validateFieldsReview(field) {
    if (field) {
      setInvalidFieldReview({
        ...invalidFieldReview,
        [field]: searchCriteriaReview[field] === "" || searchCriteriaReview[field] === undefined ? getMessage('REQUIRED_FIELD') : "",
      });

      return (
        (searchCriteriaReview[field] !== "")
      );

    }
    else {
      setInvalidFieldReview({
        ...invalidFieldReview,
        methodName: searchCriteriaReview.methodName === "" || searchCriteriaReview.methodName === undefined ? getMessage('REQUIRED_FIELD') : "",
        facility: searchCriteriaReview.facility === "" || searchCriteriaReview.facility === undefined ? getMessage('REQUIRED_FIELD') : ""
      });

      return (
        (searchCriteriaReview.methodName !== "")
        || (searchCriteriaReview.facility !== "")
      );
    }
  }

  async function loadMethodResult(tests) {
    let methodNames = [];
    tests.forEach(async test => {
      if (!methodNames.find(m => m === test.methodFacility?.methodName) && test.methodFacility?.methodName) {
        methodNames.push(test.methodFacility?.methodName);
      }
    })

    if (methodNames.length > 0) {
      await MethodResultComponentApi.getByNames(methodNames).then((res) => {
        if (res.length > 0) {
          setListMethodResult(res)
        }
      });
    }
  }

  function handleOpenMoreOptions(e, test) {
    setMoreOptionsSelected(test)

    setAnchorEl(e.currentTarget)
  }

  function handleCloseMoreOptions() {
    setMoreOptionsSelected(null)

    setAnchorEl(null)
  }

  const handleTestRowClick = async (test) => {
    const id = test.id;
    const testRowOpenIndex = testRowOpen.indexOf(id);
    let newTestRowOpen = [];

    if (testRowOpenIndex === -1) {
      newTestRowOpen = newTestRowOpen.concat(testRowOpen, id);
    } else if (testRowOpenIndex === 0) {
      newTestRowOpen = newTestRowOpen.concat(testRowOpen.slice(1));
    } else if (testRowOpenIndex === testRowOpen.length - 1) {
      newTestRowOpen = newTestRowOpen.concat(testRowOpen.slice(0, -1));
    } else if (testRowOpenIndex > 0) {
      newTestRowOpen = newTestRowOpen.concat(
        testRowOpen.slice(0, testRowOpenIndex),
        testRowOpen.slice(testRowOpenIndex + 1)
      );
    }
    setSampleRowOpen(newTestRowOpen);
  };

  const mountComments = (comments) => {
    return <StyledList>
      {comments.map(comment => {
        return <li>{comment}</li>
      })
      }
    </StyledList>
  }

  const mountBeforeChild = (test) => {
    return (
      <StyledBeforeContent>
        <StyleBeforeRow>
          <Typography>Requested: {convertToLocalTime(test.submittedDate)}</Typography>
          <Typography>Activated: {convertToLocalTime(test.receivedDate)}</Typography>
        </StyleBeforeRow>
        <StyleBeforeRow>
          <Typography>Priority: {test.testPriorityName}</Typography>
          <Typography>Completed: {convertToLocalTime(test.reportedDate)}</Typography>
        </StyleBeforeRow>
        <StyleBeforeRow>
          <Typography>Submitter: {test.submitterEmail ? test.submitterEmail : ''}</Typography>
          <Typography>Requester: {test.requesterEmail ? test.requesterEmail : ''}</Typography>
        </StyleBeforeRow>
        <StyleBeforeRow>
          <Typography>Estimate: {test.testEstimate ? test.testEstimate : '<none>'}</Typography>
          <Typography>Condition: {test.testConditionOffering?.conditionOfferingName}</Typography>
        </StyleBeforeRow>
        <StyleBeforeRow>
          <Typography>Container: {`${test.container?.containerNumber}`}</Typography>
          <Typography>ChargeCode: {test.chargeCode ? test.chargeCode : ''}</Typography>
        </StyleBeforeRow>
        <StyleBeforeRow>
          <Typography>Test Comment: {test.testComment ? mountComments(test.testComment.split('|')) : '<none>'}</Typography>
          <Typography>Analyst: {test.analystEmail ? test.analystEmail : ''}</Typography>
        </StyleBeforeRow>
      </StyledBeforeContent>
    )
  };

  const rowShowDetailsResults = !sample ? [] : [
    {
      title: 'MIDAS Number',
      description: formatMidasNumber(sample.sampleName)
    },
    {
      title: 'Data Createad',
      description: convertToLocalTime(sample.createdDate)
    },
    {
      title: 'ChemID',
      description: sample.chemID
    },
    {
      title: 'Owner',
      description: sample.sampleOwnerEmail
    },
    {
      title: 'Sample Description',
      description: sample.description
    },
  ]

  async function updateStatus(id, status) {
    await TestApi.updateStatus([id], status).then(async (res) => {
      handleSearch();
      handleSearchReview();
      if (res.errors) {
        openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
      } else {
        openModalSimpleButton('Success', 'Tests Saved with success', 'Ok');
        handleCloseMoreOptions(null)
      }
    });
  }

  async function save(test) {
    test.testComment = test.testComment && test.testComment !== "" ? `${test.testComment}|${messageSubmitter}` : messageSubmitter;
    test.testStatusName = "Corrected";
    test.isFitForModeling = false;
    await TestApi.update(test).then(async (res) => {
      handleSearch();
      handleSearchReview();
      if (res.message === "Success") {
        openModalSimpleButton('Success', 'Tests Saved with success', 'Ok');
        handleCloseMoreOptions(null)
        closeModalTwoButtonsOpen()
      } else {
        const message = res.message && res.message !== '' ? res.message : 'Something went wrong, please try after sometime'
        openModalSimpleButton('Fail', message, 'Ok');
      }
    });
  }

  async function submit(test) {
    let data = test
    data.methodFacility.method = null
    data.testStatusName = "Corrected"; //this is set to alert the endpoint that the test addition is for a corrected test

    const result = await TestApi.addTestToContainerWithoutVolumeCheck(data, data.containerID)
    if (result && result.message === "Success") {
      await save(test);
    } else {
      openModalSimpleButton('Fail', result.message, 'Ok');
    }
  }

  function openModalSimpleButton(title, text, buttonText) {
    setModalSimpleButtonText(buttonText)
    setModalSimpleButton(true);
    setModalSimpleTitle(title);
    setModalSimpleText(text);
  }

  function closeModalSimpleButton() {
    setModalSimpleButton(false);
  }

  function closeModalTwoButtonsOpen() {
    setMessageSubmitter(null);
    setModalTwoButtonsOpen(false);
    setIsSubmit(false)
  }

  function handleChangeFacilities(selected) {
    if (selected) {
      setSearchCriteria((facility) => ({
        ...searchCriteria,
        facility: selected,
        condition: ""
      }));
      getConditions(selected);
    } else {
      setSearchCriteria((facility) => ({
        ...searchCriteria,
        facility: "",
        condition: ""
      }));
    }
  }

  function handleChangeFacilitiesReview(selected) {
    if (selected) {
      setSearchCriteriaReview((facility) => ({
        ...searchCriteriaReview,
        facility: selected,
        condition: ""
      }));
      getConditionsReview(selected);
    } else {
      setSearchCriteriaReview((facility) => ({
        ...searchCriteriaReview,
        facility: "",
        condition: ""
      }));
    }
  }

  function handleChangeOtherFilters(field, value) {
    setSearchCriteria(() => ({
      ...searchCriteria,
      [field]: value
    }));
  }

  function handleChangeOtherFiltersReview(field, value) {
    setSearchCriteriaReview(() => ({
      ...searchCriteriaReview,
      [field]: value
    }));
  }

  function getConditions(facility) {

    if (facility === undefined || facility === null || facility === "") {
      setConditionList(null);
    }
    else {
      let conditions = methodFacilities.filter(function (item) {
        return item.testFacilityAbv === facility
      });

      let conditionsResult = [];

      if (conditions.length > 0) {
        conditions.forEach(testCond => {
          testCond.testConditionOfferings.forEach((condition) => {
            conditionsResult.push(`${condition.conditionOfferingName}`)
          });
        });

        if (conditionsResult.length > 0 && conditionsResult[0].length > 0)
          setConditionList(conditionsResult);
        else
          setConditionList(null);
      }
    }
  }

  function getConditionsReview(facility) {

    if (facility === undefined || facility === null || facility === "") {
      setConditionListReview(null);
    }
    else {
      let conditions = methodFacilitiesReview.filter(function (item) {
        return item.testFacilityAbv === facility
      });

      let conditionsResult = [];

      if (conditions.length > 0) {
        conditions.forEach(testCond => {
          testCond.testConditionOfferings.forEach((condition) => {
            conditionsResult.push(`${condition.conditionOfferingName}`)
          });
        });

        if (conditionsResult.length > 0 && conditionsResult[0].length > 0)
          setConditionListReview(conditionsResult);
        else
          setConditionListReview(null);
      }
    }
  }

  const searchComponent = () => {

    switch (searchCategorySelected) {
      case 1:
        return <>
          <StyledBox>
            <Autocomplete
              size="small"
              autoHighlight
              autoSelect
              noOptionsText={"Loading Methods..."}
              value={searchCriteria.methodName === '' ? null : searchCriteria.methodName}
              onChange={(e, value) => handleChangeMethod(value)}
              disablePortal
              options={methodList ? methodList.map((method) => method.name.toString()) : []}
              sx={{ width: "15rem" }}
              renderInput={(params) => <TextField size="small" {...params} label="Method Name"
                error={invalidField.methodName.length === 0 ? false : true}
                helperText={invalidField.methodName} />}
            />
          </StyledBox>

          <StyledBox>
            {
              methodFacilities && searchCriteria.methodName &&
              <Autocomplete
                size="small"
                autoHighlight
                autoSelect
                noOptionsText={"Loading Facilities..."}
                value={searchCriteria.facility === '' ? null : searchCriteria.facility}
                onChange={(e, value) =>
                  handleChangeFacilities(value)
                }
                disablePortal
                options={methodFacilities.sort((a, b) => a.testFacilityAbv.localeCompare(b.testFacilityAbv)).map((method) => method.testFacilityAbv.toString())}
                sx={{ width: "10rem" }}
                renderInput={(params) => <TextField size="small" {...params} label="Facility"
                />}
              />
            }

          </StyledBox>

          <StyledBox>
            {
              conditionList && searchCriteria.methodName &&
              <Autocomplete
                size="small"
                autoHighlight
                autoSelect
                noOptionsText={"Loading Conditions..."}
                value={searchCriteria.condition}
                onChange={(e, value) => {
                  handleChangeOtherFilters("condition", value === null ? "" : value)
                }}
                disablePortal
                options={conditionList}
                sx={{ width: "15rem" }}
                renderInput={(params) => <TextField size="small" {...params} label="Conditions"
                />}
              />
            }

          </StyledBox>

        </>
      case 2:
        return <StyledBox>
          <StyledTextField
            label="MIDAS Number"
            variant="outlined"
            size="small"
            value={searchCriteria.sampleName}
            error={invalidField.sampleName.length === 0 ? false : true}
            helperText={invalidField.sampleName}
            onBlur={e => validateFields("sampleName")}
            onChange={e =>
              handleChangeOtherFilters("sampleName", e.target.value)
            }
          />
        </StyledBox>
      case 3:
        return <StyledBox>
          <EmailAddressTextField
            fontSize={16}
            fieldWidth={"20rem"}
            validatedUserEmail={searchCriteria.requesterEmail}
            setValidatedUserEmail={value => {
              handleChangeOtherFilters('requesterEmail', value)
            }}
            setHasErrors={(value) => setRequesterInvalid(value)}
            hasErrors={requesterInvalid}
            labelText="Requester"
            placeholderText="Requester"
            margin={"none"}
            showPlusMeButton={true}>
          </EmailAddressTextField>
        </StyledBox>

      case 4:
        return <StyledBox>
          <EmailAddressTextField
            fontSize={16}
            fieldWidth={"20rem"}
            validatedUserEmail={searchCriteria.submitterEmail}
            setValidatedUserEmail={value => {
              handleChangeOtherFilters('submitterEmail', value)
            }}
            setHasErrors={(value) => setSubmitterInvalid(value)}
            hasErrors={submitterInvalid}
            labelText="Submitter"
            placeholderText="Submitter"
            margin={"none"}
            showPlusMeButton={true}>
          </EmailAddressTextField>
        </StyledBox>

      case 5:
        return <StyledBox>
          <EmailAddressTextField
            fontSize={16}
            fieldWidth={"20rem"}
            validatedUserEmail={searchCriteria.ownerEmail}
            setValidatedUserEmail={value => {
              handleChangeOtherFilters('ownerEmail', value)
            }}
            setHasErrors={(value) => setOwnerInvalid(value)}
            hasErrors={ownerInvalid}
            labelText="Sample Owner"
            placeholderText="Sample Owner"
            margin={"none"}
            showPlusMeButton={true}>
          </EmailAddressTextField>
        </StyledBox>

      case 6:
        return <StyledBox>
          <EmailAddressTextField
            fontSize={16}
            fieldWidth={"20rem"}
            validatedUserEmail={searchCriteria.testOwnerEmail}
            setValidatedUserEmail={value => {
              handleChangeOtherFilters('testOwnerEmail', value)
            }}
            setHasErrors={(value) => setTestOwnerInvalid(value)}
            hasErrors={testOwnerInvalid}
            labelText="Test Owner"
            placeholderText="Test Owner"
            margin={"none"}
            showPlusMeButton={true}>
          </EmailAddressTextField>
        </StyledBox>
      case 7:
        return <StyledBox>
          <Autocomplete
            size="small"
            autoHighlight
            autoSelect
            noOptionsText={"Loading Facilities..."}
            value={searchCriteria.facility}
            onChange={(e, value) =>
              handleChangeFacilities(value)
            }
            disablePortal
            options={methodFacilities !== null ? methodFacilities.sort((a, b) => a.testFacilityAbv.localeCompare(b.testFacilityAbv)).map((method) => method.testFacilityAbv.toString()) : []}
            sx={{ width: "10rem" }}
            renderInput={(params) => <TextField size="small" {...params} label="Facility"
            />}
          />
        </StyledBox>
      default:
        return <></>
    }
  }

  React.useEffect(() => {
    if (searchCategorySelected === 7) {
      loadFacilityFilter();
    }
  }, [searchCategorySelected])

  function loadFacilityFilter() {
    MethodFacilityApi.getFacilitiesByMethodName("").then((result) => {
      if ((result !== undefined && result !== null)) {
        let array = [];
        array.push({ testFacilityAbv: "" });
        result.forEach(item => {
          if (!array.find(i => i.testFacilityAbv === item.testFacilityAbv) && item.methodStatusName === 'Active')
            array.push(item);
        })

        setMethodFacilities(array);
      }
    });
  }

  return (
    <StyledContainer>
      <h4>Show Test Information to Correct</h4>
      <StyledFormControl fullWidth>
        <StyledBox>
          <Autocomplete
            onChange={(e, value) => {
              const selected = searchCategory.find(s => s.name === value)?.id
              if (selected) {
                setListTest([]);
                setSearchCriteria(() => ({ ...searchCriteriaInitialState }))
                setSearchCategorySelected(() => (selected))
                setInvalidField({
                  ...validationsInitialState
                });
              }
            }}
            label="Search Category"
            size="small"
            value={searchCategory.length > 0 ? searchCategory.find(s => s.id === searchCategorySelected)?.name : ''}
            disablePortal
            options={searchCategory.map((search) => search.name)}
            sx={{ width: "35rem" }}
            renderInput={(params) => <TextField size="small" {...params} label="Search Category" />}
            autoSelect
            disableClearable
          />
        </StyledBox>
        {
          searchCategorySelected ?
            searchComponent()
            : <></>
        }

        {
          searchCategorySelected ?
            <>
              <StyledButton sx={{ width: "8rem" }}
                variant='contained'
                type="submit"
                onClick={handleSearch}
              >Search</StyledButton>
            </>
            : <></>
        }
      </StyledFormControl>
      {sample &&
        <StyledCard>
          <DetailsResults rowShow={rowShowDetailsResults} rowsHidden={[]} />
        </StyledCard>
      }
      <div>
        <UXDataTableWithoutBody
          tableWidth='100%'
          cols={reviewCorrectColHeaders}
          blankFirstHeader={true}
          tableId="simpleResultTable"
          rowLength={listTest.length}
          enablePaging={true}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          noDataFoundMessage={'There is no test data available'}
          enableAddIcon={false}
          addFunction={null}
          addToolTipText={null}
          isDataLoading={dataIsLoading}
        >

          <StyledTableBody key={"myTablebody"}>
            {determineStartArray().map((test, rowIndex) => {
              let listResultByTest = listTestResult.filter(t => t.testID === test.id);
              // let countListMethodResult = listMethodResult.filter(t => t.methodName === test.methodFacility?.methodName).length;
              return (
                [
                  <TableRow
                    hover
                    key={`custom-row-${rowIndex}`}>
                    <StyledTableCell><IconButton aria-label="more options" onClick={(e) => handleOpenMoreOptions(e, test)}><MoreOptions color='black' /></IconButton></StyledTableCell>
                    <StyledTableCell>{test.testSequenceNumber}</StyledTableCell>
                    <StyledTableCell>{test.methodFacility?.methodName}</StyledTableCell>
                    <StyledTableCell>{test.methodFacility?.testFacilityAbv}</StyledTableCell>
                    <StyledTableCell>{formatMidasNumber(test.container?.sampleName)}</StyledTableCell>
                    <StyledTableCell>{test.testConditionOffering?.conditionOfferingName}</StyledTableCell>
                    <StyledTableCellLink
                      onClick={() => handleTestRowClick(test)}
                    >
                      View Results {listResultByTest.length > 0 && `(${listResultByTest.length})`}
                    </StyledTableCellLink>
                    <StyledTableCell>{test.methodFacility?.method?.description}</StyledTableCell>
                    <StyledTableCell>{test.testStatusName}</StyledTableCell>
                  </TableRow>,
                  <UXDataTableNested
                    key={`data-table-nested-${rowIndex}`}
                    tableWidth={'100%'}
                    cols={subColHeaders}
                    blankFirstHeader={false}
                    colSpan={10}
                    isOpenComparator={test.id}
                    isOpenArray={testRowOpen}
                    maxWidth={600}
                    beforeChild={mountBeforeChild(test)}>
                    <StyledTableBody key={`sub-table-body-${rowIndex}`}>
                      {listResultByTest && listResultByTest.length > 0 && listResultByTest.map((testResult, rowIndex) => {
                        const methodResult = listMethodResult.find(m => m.methodName === test.methodFacility?.methodName && m.resultComponentName === testResult.pointTestResult?.resultComponentName);
                        testResult.isOptionalResult = methodResult?.isOptionalResult;

                        return (
                          <TableRow key={rowIndex + 'sub'}>
                            <StyledTableCell>
                              {
                                testResult.resultDisplayOrder
                              }
                            </StyledTableCell>
                            <StyledTableCell>
                              {
                                testResult.pointTestResult?.resultComponentName
                              }
                            </StyledTableCell>
                            <StyledTableCell>
                              {
                                testResult.pointTestResult?.resultValue != null ? testResult.pointTestResult?.resultValue : testResult.pointTestResult?.resultText
                              }
                            </StyledTableCell>
                            <StyledTableCell>
                              {
                                testResult.pointTestResult?.uoMName
                              }
                            </StyledTableCell>
                          </TableRow>
                        )
                      })
                      }
                    </StyledTableBody>
                  </UXDataTableNested>
                ]
              );
            })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </div>

      <h4>Show Test Information to Review</h4>
      <StyledFormControl fullWidth>
        <StyledBox>
          <Autocomplete
            size="small"
            autoHighlight
            autoSelect
            noOptionsText={"Loading Methods..."}
            value={searchCriteriaReview.methodName === '' ? null : searchCriteriaReview.methodName}
            onChange={(e, value) => handleChangeMethodReview(value)}
            disablePortal
            options={methodList ? methodList.map((method) => method.name.toString()) : []}
            sx={{ width: "15rem" }}
            renderInput={(params) => <TextField size="small" {...params} label="Method Name"
              error={invalidFieldReview.methodName.length === 0 ? false : true}
              helperText={invalidFieldReview.methodName} />}
          />
        </StyledBox>

        <StyledBox>
          {
            methodFacilitiesReview && searchCriteriaReview.methodName &&
            <Autocomplete
              size="small"
              autoHighlight
              autoSelect
              noOptionsText={"Loading Facilities..."}
              value={searchCriteriaReview.facility === '' ? null : searchCriteriaReview.facility}
              onChange={(e, value) =>
                handleChangeFacilitiesReview(value)
              }
              disablePortal
              options={methodFacilitiesReview.sort((a, b) => a.testFacilityAbv.localeCompare(b.testFacilityAbv)).map((method) => method.testFacilityAbv.toString())}
              sx={{ width: "10rem" }}
              renderInput={(params) => <TextField size="small" {...params} label="Facility"
              />}
            />
          }

        </StyledBox>

        <StyledBox>
          {
            conditionListReview && searchCriteriaReview.methodName &&
            <Autocomplete
              size="small"
              autoHighlight
              autoSelect
              noOptionsText={"Loading Conditions..."}
              value={searchCriteriaReview.condition}
              onChange={(e, value) => {
                handleChangeOtherFiltersReview("condition", value === null ? "" : value)
              }}
              disablePortal
              options={conditionListReview}
              sx={{ width: "15rem" }}
              renderInput={(params) => <TextField size="small" {...params} label="Conditions"
              />}
            />
          }

        </StyledBox>
        <>
          <StyledButton sx={{ width: "8rem" }}
            variant='contained'
            type="submit"
            onClick={handleSearchReview}
          >Search</StyledButton>
        </>
      
      </StyledFormControl>
      <div>
        <UXDataTableWithoutBody
          tableWidth='100%'
          cols={reviewCorrectColHeaders}
          blankFirstHeader={true}
          tableId="simpleResultTable"
          rowLength={listTestReview.length}
          enablePaging={true}
          rowsPerPage={rowsPerPageReview}
          page={pageReview}
          handleChangePage={handleChangePageReview}
          handleChangeRowsPerPage={handleChangeRowsPerPageReview}
          noDataFoundMessage={'There is no test data available'}
          enableAddIcon={false}
          addFunction={null}
          addToolTipText={null}
          isDataLoading={dataReviewIsLoading}
        >

          <StyledTableBody key={"myTablebody"}>
            {determineStartArrayReview().map((test, rowIndex) => {
              let listResultByTest = listTestReviewResult.filter(t => t.testID === test.id);
              let countListMethodResult = listMethodResult.filter(t => t.methodName === test.methodFacility?.methodName).length;
              return (
                [
                  <TableRow
                    hover
                    key={`custom-row-${rowIndex}`}>
                    <StyledTableCell><IconButton aria-label="more options" onClick={(e) => handleOpenMoreOptions(e, test)}><MoreOptions color='black' /></IconButton></StyledTableCell>
                    <StyledTableCell>{test.testSequenceNumber}</StyledTableCell>
                    <StyledTableCell>{test.methodFacility?.methodName}</StyledTableCell>
                    <StyledTableCell>{test.methodFacility?.testFacilityAbv}</StyledTableCell>
                    <StyledTableCell>{formatMidasNumber(test.container?.sampleName)}</StyledTableCell>
                    <StyledTableCell>{test.testConditionOffering?.conditionOfferingName}</StyledTableCell>
                    <StyledTableCellLink
                      onClick={() => handleTestRowClick(test)}
                    >
                      View Results {countListMethodResult > 0 && `(${countListMethodResult})`}
                    </StyledTableCellLink>
                    <StyledTableCell>{test.methodFacility?.method?.description}</StyledTableCell>
                    <StyledTableCell>{test.testStatusName}</StyledTableCell>
                  </TableRow>,
                  <UXDataTableNested
                    key={`data-table-nested-${rowIndex}`}
                    tableWidth={'100%'}
                    cols={subColHeaders}
                    blankFirstHeader={false}
                    colSpan={10}
                    isOpenComparator={test.id}
                    isOpenArray={testRowOpen}
                    maxWidth={600}
                    beforeChild={mountBeforeChild(test)}>
                    <StyledTableBody key={`sub-table-body-${rowIndex}`}>
                      {listResultByTest && listResultByTest.length > 0 && listResultByTest.map((testResult, rowIndex) => {
                        const methodResult = listMethodResult.find(m => m.methodName === test.methodFacility?.methodName && m.resultComponentName === testResult.pointTestResult?.resultComponentName);
                        testResult.isOptionalResult = methodResult?.isOptionalResult;

                        return (
                          <TableRow key={rowIndex + 'sub'}>
                            <StyledTableCell>
                              {
                                testResult.resultDisplayOrder
                              }
                            </StyledTableCell>
                            <StyledTableCell>
                              {
                                testResult.pointTestResult?.resultComponentName
                              }
                            </StyledTableCell>
                            <StyledTableCell>
                              {
                                testResult.pointTestResult?.resultValue != null ? testResult.pointTestResult?.resultValue : testResult.pointTestResult?.resultText
                              }
                            </StyledTableCell>
                            <StyledTableCell>
                              {
                                testResult.pointTestResult?.uoMName
                              }
                            </StyledTableCell>
                          </TableRow>
                        )
                      })
                      }
                    </StyledTableBody>
                  </UXDataTableNested>
                ]
              );
            })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl && moreOptionsSelected ? true : false}
        onClose={() => handleCloseMoreOptions(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {moreOptionsSelected?.testStatusName === "UnderReview" && <MenuItem onClick={() => updateStatus(moreOptionsSelected.id, "Validated")}>Validate Result</MenuItem>}
        <MenuItem onClick={() => setModalTwoButtonsOpen(true)}>Correct Result</MenuItem>
        <MenuItem onClick={() => {
          setModalTwoButtonsOpen(true);
          setIsSubmit(true);
        }}>Correct And Submit</MenuItem>
      </Menu>

      <ModalSimpleButton title={modalSimpleTitle} buttonText={modalSimpleButtonText} buttonAction={closeModalSimpleButton} open={modalSimpleButton} setOpen={setModalSimpleButton}>
        <label>
          {modalSimpleText}
        </label>
      </ModalSimpleButton>

      <ModalTwoButtons title={'Please provide a message.'} button1Text={'Send'} button1Action={() => isSubmit ? submit(moreOptionsSelected) : save(moreOptionsSelected)} button2Text={'Back'} button2Action={closeModalTwoButtonsOpen} open={modalTwoButtonsOpen} setOpen={setModalTwoButtonsOpen} setClose={closeModalTwoButtonsOpen}>
        <StyledBodyModal style={{minWidth: '450px'}}>
          <TextField
            multiline={true}
            onChange={e => setMessageSubmitter(e.target.value)}
            label="Message"
            size="small"
            minRows={4}
            required={true}
            value={messageSubmitter === null ? '' : messageSubmitter} />
        </StyledBodyModal>
      </ModalTwoButtons>
    </StyledContainer >

  );
};

export default ReviewCorrectResults;