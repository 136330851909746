import React, { useState, useEffect } from "react";
import { Box, FormControl, TextField, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { GlobalButton, GlobalSecondaryButton } from "../../styles";
import { StyledTableRow, StyledTableBody, StyledTableCell, UXDataTableWithoutBody } from "../../../components/UXDataTable";
import { exportToCsv, applyFiltersToArray, getMessage } from '../../../global';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterMenu from "../../../components/FilterMenu";
import ComponentProcurementThresholdApi from "./../../../api/Inventory/ComponentProcurementThreshold";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import ModalSimpleButton from '../../../components/Modal/ModalSimpleButton';
import { ChemIDSearchField } from '../../../components/ChemIDSearch/index';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const colHeaders = [
  { id: 'chemID', label: 'Chem ID' },
  { id: 'substanceName', label: 'Substance Name' },
  { id: 'description', label: 'Description' },
  { id: 'thresholdValueKG', label: 'Threshold Value (kg)' },
  { id: 'actions', label: 'Actions' },
];

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  alignItems: 'start'
}));

const validationsInitialState = {
  chemID: "",
  thresholdValueKG: ""
};


const ManageThresholds = ({ ...props }) => {

  const [listManageThreshold, setListManageThreshold] = useState([]);
  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([{ name: null, displayName: null, operator: null, enumValues: [], value: '' }])
  const [filteredRequests, setFilteredRequests] = useState([])

  const [modalOpen, setModalOpen] = React.useState(false);
  const [thresholdSelected, setThresholdSelected] = React.useState(null);
  const [selectedChemID, setSelectedChemID] = useState(null);
  const [hasChemIDErrors, setHasChemIDErrors] = useState(false);
  const [thresholdValueKG, setThresholdValueKG] = useState('');
  const [invalidField, setInvalidField] = React.useState(validationsInitialState);

  const [modalSimpleOpen, setModalSimpleOpen] = React.useState(false);
  const [modalSimpleButtonText, setModalSimpleButtonText] = React.useState('');
  const [modalSimpleTitle, setModalSimpleTitle] = React.useState('');
  const [modalSimpleText, setModalSimpleText] = React.useState('');

  const [modalDeleteOpen, setModalDeleteOpen] = React.useState(false);
  const [thresholdToDelete, setThresholdToDelete] = React.useState(null);

  function load() {
    setDataIsLoading(true)
    ComponentProcurementThresholdApi.getAll().then((resp) => {
      setListManageThreshold(resp)
      setDataIsLoading(false)
    })
  }

  useEffect(() => {
    load()
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const filterOptions = [{ name: "chemID", displayName: "Chem ID", type: "string", enumValues: [] },
  { name: "substanceName", displayName: "Substance Name", type: "string", enumValues: [] },
  { name: "description", displayName: "Description", type: "string", enumValues: [] },
  { name: "thresholdValueKG", displayName: "Threshold Value (KG)", type: "integer", enumValues: [] },
  ]

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  const filterClick = (event) => {
    setFilteringOpen(true);
  }

  const clearFiltersClick = (event) => {
    setFilters([{ name: null, displayName: null, operator: null, enumValues: [], value: null }])
  }

  useEffect(() => {
    var filteredArray = applyFiltersToArray(filters, listManageThreshold)
    setFilteredRequests(filteredArray)
  }, [filters, listManageThreshold])

  function handleOpenModal(row) {
    if (row) {
      setThresholdSelected(row);
      setThresholdValueKG(row.thresholdValueKG);
    }
    setModalOpen(true)
  }

  function handleCloseModal() {
    setThresholdSelected(null);
    setModalOpen(false);
    setSelectedChemID('');
    setThresholdValueKG('');
    setInvalidField(validationsInitialState);
    setHasChemIDErrors(false);
  }

  async function save() {
    if (validateFields()) {
      if (thresholdSelected) {
        const response = await ComponentProcurementThresholdApi.update({
          chemID: thresholdSelected.chemID,
          thresholdValueKG: thresholdValueKG
        })

        if (response) {
          openModalSimple('Success', 'Updated with success', 'Ok');
          handleCloseModal();
          load();
        } else openModalSimple('Fail', 'Something went wrong, please try after sometime', 'Ok');
      } else {
        const response = await ComponentProcurementThresholdApi.create({
          chemID: selectedChemID.chemID,
          thresholdValueKG: thresholdValueKG
        })

        if (response) {
          openModalSimple('Success', 'Create with success', 'Ok');
          handleCloseModal();
          load();
        }
        else openModalSimple('Fail', 'Something went wrong, please try after sometime', 'Ok');
      }
    }
  }

  function closeModalSimple() {
    setModalSimpleOpen(false)
  }

  function openModalSimple(title, text, buttonText) {
    setModalSimpleButtonText(buttonText)
    setModalSimpleOpen(true)
    setModalSimpleTitle(title)
    setModalSimpleText(text)
  }

  function validateFields() {
    let chemID = ''
    if (!thresholdSelected) {
      chemID = selectedChemID !== null && selectedChemID !== '' ?
        listManageThreshold.some(s => s.chemID === selectedChemID.chemID) ? 'Threshold already created with this Chem ID' : '' : getMessage('REQUIRED_FIELD');

      if (chemID !== '') setHasChemIDErrors(true)
      else setHasChemIDErrors(false)
    }
    const threshold = thresholdValueKG === '' ? getMessage('REQUIRED_FIELD') : parseInt(thresholdValueKG) > 0 ? '' : 'Value minimum is 0.01';

    setInvalidField({
      chemID: chemID,
      thresholdValueKG: threshold
    })

    return chemID === '' && threshold === '';
  }

  function handleChemIDError(value) {
    if (!value) {
      setInvalidField({
        ...invalidField,
        chemID: ''
      })
    }
    setHasChemIDErrors(value)
  }

  function handleDeleteRow(chemID) {
    if (chemID) {
      setThresholdToDelete(chemID)
      setModalDeleteOpen(true)
    }
  }

  function handleCloseModalDelete() {
    setModalDeleteOpen(false)
    setThresholdToDelete(null)
  }

  async function sendDelete() {
    if (thresholdToDelete) {
      const response = await ComponentProcurementThresholdApi.delete(thresholdToDelete);

      if (response && response.result === 'Success') {
        openModalSimple('Success', 'Deleted with success', 'Ok');
        handleCloseModalDelete();
        load();
      } else {
        const message = response?.message && response?.message !== '' ? response?.message : 'Something went wrong, please try after sometime';

        openModalSimple('Fail', message, 'Ok');
        handleCloseModalDelete();
      } 
    }
  }

  return (
    <div style={{ paddingBottom: '20px' }}>
      <h4 style={{ paddingBottom: '20px' }}>Manage Thresholds</h4>
      <StyledFormControl fullWidth>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}>
          <GlobalButton
            style={{ marginBottom: '15px' }}
            variant='contained'
            onClick={() => handleOpenModal()}
          >Add new</GlobalButton>
          <div style={{ display: 'flex' }}>
            <Box display="flex" alignItems={"center"} marginLeft="auto" marginTop="-.8rem">
              <GlobalButton style={{ marginRight: "1rem" }} variant="contained"
                onClick={() => filterClick()}>Filters</GlobalButton>

              {!(filters[0].name === null) &&
                <GlobalSecondaryButton variant="contained"
                  onClick={() => clearFiltersClick()}>Clear Filters</GlobalSecondaryButton>}
            </Box>
            <FileDownloadIcon sx={{ m: 1 }} style={{ cursor: "pointer" }} onClick={e => exportToCsv(listManageThreshold.map(report => {
              return {
                chemID: report.chemID,
                description: report.substance?.description,
                thresholdValueKG: report.thresholdValueKG,
              }
            }), "ManageThresholds Procurement")} />
          </div>
        </div>
      </StyledFormControl>
      <UXDataTableWithoutBody
        tableWidth='100%'
        cols={colHeaders}
        tableId="componentProcurementManageThresholdsTable"
        rowLength={filteredRequests.length}
        enablePaging={true}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        noDataFoundMessage={'There is no data available'}
        enableAddIcon={false}
        addFunction={null}
        addToolTipText={null}
        enableCheckbox={false}
        enableSorteable={true}
        order={order}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        isDataLoading={dataIsLoading}
      >

        <StyledTableBody key={"myTablebody"}>
          {stableSort(filteredRequests, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((report, rowIndex) => {
              return (
                [
                  <StyledTableRow
                    hover
                    key={`custom-row-${rowIndex}`}>
                    <StyledTableCell style={{ width: '12rem' }}>
                      {report.chemID}
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '18rem' }}>{report.substance?.substanceName}</StyledTableCell>
                    <StyledTableCell style={{ width: '18rem' }}>{report.substance?.description}</StyledTableCell>
                    <StyledTableCell>{report.thresholdValueKG}</StyledTableCell>
                    <StyledTableCell style={{ width: '7rem' }}>
                      <IconButton
                        style={{ paddingBottom: '0px', paddingTop: '0px' }}
                        aria-label='expand row'
                        size='small'
                        onClick={() => handleOpenModal(report)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        style={{ paddingBottom: '0px', paddingTop: '0px' }}
                        aria-label='expand row'
                        size='small'
                        onClick={() => handleDeleteRow(report.chemID)}>
                        <DeleteIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ]
              );
            })}
        </StyledTableBody>
      </UXDataTableWithoutBody>
      <FilterMenu
        open={filteringOpen}
        setOpen={setFilteringOpen}
        applyBtnAction={applyFilters}
        cancelButtonAction={closeFiltering}
        filteringInfo={filterOptions}
        appliedFilters={filters}
        setAppliedFilters={setFilters}
      />
      <ModalTwoButtons
        title={thresholdSelected ? 'Edit Threshold' : 'Create Threshold'}
        button1Text={'Save'}
        button1Action={() => save()}
        button2Text={'Cancel'}
        button2Action={handleCloseModal}
        open={modalOpen}
        setOpen={setModalOpen}
        setClose={handleCloseModal}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px'
        }}>
          {thresholdSelected ? (
            <TextField
              size={'small'}
              value={thresholdSelected.chemID}
              label='Chem ID'
              disabled={true}
            >
            </TextField>
          ) : (
            <ChemIDSearchField
              fontSize={16}
              fieldWidth="100%"
              selectedChemIDObject={selectedChemID}
              setSelectedChemIDObject={(e) => setSelectedChemID(e)}
              hasErrors={hasChemIDErrors}
              setHasErrors={handleChemIDError}
              helperText={invalidField.chemID !== '' ? invalidField.chemID : ''}
              allowInactiveChemIDs={true}
              returnSubstanceStrucureData={false}
            />
          )
          }
          <TextField className='decimal-input'
            inputProps={{ step: 0.01, min: 0.01 }}
            type="number"
            size={'small'}
            onChange={e => {
              setThresholdValueKG(e.target.value);
            }}
            value={thresholdValueKG}
            label='Threshold (KG)'
            error={invalidField.thresholdValueKG !== '' ? true : false}
            helperText={invalidField.thresholdValueKG !== '' ? invalidField.thresholdValueKG : ''}
          >
          </TextField>
        </div>
      </ModalTwoButtons>
      <ModalTwoButtons
        title={''}
        button1Text={'Delete'}
        button1Action={() => sendDelete(thresholdToDelete)}
        button2Text={'Cancel'}
        button2Action={handleCloseModalDelete}
        open={modalDeleteOpen}
        setOpen={setModalDeleteOpen}
        setClose={handleCloseModalDelete}
      >
        <label>
          Are you want to delete?
        </label>
      </ModalTwoButtons>
      <ModalSimpleButton title={modalSimpleTitle} buttonText={modalSimpleButtonText} buttonAction={closeModalSimple} open={modalSimpleOpen} setOpen={setModalOpen}>
        <label>
          {modalSimpleText}
        </label>
      </ModalSimpleButton>
    </div>
  );
};

export default ManageThresholds;