import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, FormControl, FormControlLabel, Switch, Checkbox } from "@mui/material";
import { StyledTableRow, StyledTableBody, StyledTableCell, UXDataTableWithoutBody } from "../../../components/UXDataTable";
import ContainerApi from '../../../api/LIMS/Container';
import { formatMidasNumber, exportToExcel, isColor } from "../../../global";
import AdvancedLocateContainers from "./AdvancedLocateContainers";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ValidatedMidasNumberTextField from "../../../components/ValidatedMidasNumberTextField";
import ModalTwoButtons from '../../../components/Modal/ModalTwoButtons';
import { GlobalSecondaryButton } from "../../styles";

const StyledContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
}));

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: ' space-between'
}));

const StyledDivSearch = styled('div')(() => ({
  display: 'flex'
}));

const StyledBox = styled(Box)(() => ({
  display: 'inline-box',
  marginRight: "25px"
}));

const StyledDivAdvancedExport = styled('div')(() => ({
  display: 'flex',
}));

const StyledButton = styled(Button)(() => ({
  //marginTop: '2px',
  widht: '200px',
  textTransform: 'none',
  height: '2.2rem',
  marginRight: "25px"
}));

const LocateContainers = ({ ...props }) => {
  const [searchMidas, setSearchMidas] = React.useState(null);
  const [listContainer, setListContainer] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [tempHasErrors, setTempHasErrors] = React.useState(true);
  const [advancedSearch, setAdvancedSearch] = React.useState(false);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');

  const [dataIsLoading, setDataIsLoading] = React.useState(false);

  const [modalTwoButtonsOpen, setModalTwoButtonsOpen] = React.useState(false);
  const [messageModal, setMessageModal] = React.useState('');
  const [typeSend, setTypeSend] = React.useState('');
  const [containersToSend, setContainersToSend] = React.useState();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const colHeaders = [
    { id: 'sampleName', label: 'MIDAS Number' },
    { id: 'containerNumber', label: 'Container Number' },
    { id: 'ownerEmail', label: 'Current Owner' },
    { id: 'locationName', label: 'Location' },
    { id: 'subLocation', label: 'Sub Location' },
    { id: 'description', label: 'Sample Description' },
    { id: 'additionalSampleInformation', label: 'Additional Information' },
    { id: 'chemID', label: 'ChemID' },
    { id: 'containerStatusName', label: 'Status' },
    { id: 'currentAmount', label: 'Container Size UoM' },
    { id: 'currentAmountPercent', label: '% Full' },
  ];

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const validSearchField = () => {
    const invalidMidas = tempHasErrors || !searchMidas?.sampleName;
    setTempHasErrors(invalidMidas);
    return !invalidMidas
  }

  async function handleSearch() {
    if (validSearchField()) {
      setListContainer([])
      setDataIsLoading(true)
      ContainerApi.getByMidasNumber(searchMidas?.sampleName).then((res) => {
        if (res) {
          setListContainer(res.map(item => {
            return {
              ...item,
              selected: false
            }
          }));
        }
        else {
          setListContainer([])
        }
        setDataIsLoading(false)
      });
    }
  }

  const handleSelectAllClick = () => {
    const displayed = determineStartArray(listContainer)

    const allSelected = listContainer.filter(note => note.selected).length === listContainer.length
    const allDisplayedSelected = displayed.filter(note => note.selected).length === displayed.length

    if (allSelected) {
      setListContainer(prevState => {
        const updatedArray = prevState.map(container => {
          return { ...container, selected: false }
        })

        return updatedArray
      })

    } else if (allDisplayedSelected) {
      setListContainer(prevState => {
        const updatedArray = prevState.map(container => {
          if (displayed.filter(item => item.id === container.id).length > 0) {
            return { ...container, selected: false }
          }
          return container
        })

        return updatedArray
      })

    } else {
      setListContainer(prevState => {
        const updatedArray = prevState.map(container => {
          if (displayed.filter(item => item.id === container.id).length > 0) {
            return { ...container, selected: true }
          }
          return container
        })

        return updatedArray
      })
    }

  }

  function determineStartArray(arrayContainers) {
    if (arrayContainers) {
      return stableSort(arrayContainers, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    } else {
      return []
    }
  }

  function handleChangeSelected(id) {
    let newList = listContainer.map(container => {
      if (container.id === id) {
        return {
          ...container,
          selected: container.selected ? false : true
        }
      } else {
        return container;
      }
    });
    setListContainer([...newList]);
  }

  function closeModalTwoButtonsOpen() {
    setModalTwoButtonsOpen(false);
    setMessageModal('');
    setTypeSend('');
    setContainersToSend();
  }

  function openModal(containers, message, type) {
    setModalTwoButtonsOpen(true);
    setMessageModal(message);
    setTypeSend(type);
    setContainersToSend(containers)
  }

  function validateBeforeSend(containers, type) {
    if (containers.some(i => i.containerStatusName === "Discarded")) {
      openModal(containers, `Discarded containers are not allowed to be ${type} do you still want to move the ones not discarded?`, type);
    } else if (type === 'discard') {
      sendToDiscard(containers)
    } else {
      sendToTransfer(containers)
    }
  }

  function sendToTransfer(containers) {
    props.setContainersTransfer(containers);
    props.setTabValue(3)
  }

  function sendToDiscard(containers) {
    props.setContainersDiscard(containers)
    props.setTabValue(1)
  }

  const roundNumber = (number) => {
    if (number % 1 !== 0) return number.toFixed(2);
    else return number;
  }

  const getColor = (color) => {
    const colorHex = `#${color}`;
    if (color) {
      return isColor(colorHex) ? colorHex : color
    } else {
      return ''
    }
  }

  const listContainerSelected = listContainer.filter(c => c.selected);

  if (advancedSearch) return <>
    <AdvancedLocateContainers
      setAdvancedSearch={setAdvancedSearch}
      advancedSearch={advancedSearch}
      sendToTransfer={(containers) => validateBeforeSend(containers, 'transfer')}
      sendToDiscard={(containers) => validateBeforeSend(containers, 'discard')}
      getColor={getColor}
    />
    <ModalTwoButtons title={'Warning'} button1Text={'No'} button1Action={closeModalTwoButtonsOpen} button2Text={'Yes'} button2Action={() => typeSend === 'discard' ? sendToDiscard(containersToSend) : sendToTransfer(containersToSend)} open={modalTwoButtonsOpen} setOpen={setModalTwoButtonsOpen}>
      <label>
        {messageModal}
      </label>
    </ModalTwoButtons>
  </>

  return (
    <StyledContainer>
      <StyledFormControl fullWidth>
        <StyledDivSearch>
          <StyledBox>
            <ValidatedMidasNumberTextField
              margin={"none"}
              showLabel={true}
              fontSize={16}
              fieldWidth={"20rem"}
              midasNumberObject={searchMidas}
              setMidasNumberObject={(e) => {
                setSearchMidas(e)
              }}
              hasErrors={tempHasErrors}
              setHasErrors={setTempHasErrors}
              chemIDToMatch={null}
            ></ValidatedMidasNumberTextField>
          </StyledBox>
          <StyledButton sx={{ width: "8rem" }}
            variant='contained'
            type="submit"
            disabled={tempHasErrors || !searchMidas?.sampleName}
            onClick={handleSearch}
          >Search</StyledButton>
          <GlobalSecondaryButton
            variant='contained'
            type="submit"
            onClick={() => validateBeforeSend(listContainerSelected, 'transfer')}
            disabled={listContainerSelected.length < 1}
          > Send to Transfer</GlobalSecondaryButton>
          <GlobalSecondaryButton sx={{ marginLeft: "25px" }}
            variant='contained'
            type="submit"
            onClick={() => validateBeforeSend(listContainerSelected, 'discard')}
            disabled={listContainerSelected.length < 1}
          > Send to Discard</GlobalSecondaryButton>
        </StyledDivSearch>
        <StyledDivAdvancedExport>
          <FormControlLabel control={<Switch onChange={() => setAdvancedSearch(true)} checked={false} />} label="Advanced Search" />
          <FileDownloadIcon sx={{ m: 1 }} style={{ cursor: "pointer" }} onClick={e => exportToExcel(listContainer.map(container => {
            return {
              'MIDAS Number': formatMidasNumber(container.sampleName),
              'Container Number': container.containerNumber,
              'Current Owner': container.ownerEmail,
              'Location': container.locationName,
              'Sub Location': container.subLocation,
              'Sample Description': container.sample?.description,
              'Additional Information': container.sample?.additionalSampleInformation,
              'ChemID': `${container.sample?.chemID}`,
              'Status': container.containerStatusName,
              'Container Size UoM': `${container.size} ${container.containerSizeUoM}`,
              '% Full': container.currentAmount && container.size ? roundNumber((container.currentAmount / container.size) * 100.00) : 0,
            }
          }), "Locate Containers")} />
        </StyledDivAdvancedExport>
      </StyledFormControl>
      <div>
        <UXDataTableWithoutBody
          tableWidth='100%'
          cols={colHeaders}
          blankFirstHeader={true}
          tableId="simpleResultTable"
          rowLength={listContainer.length}
          enablePaging={true}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          noDataFoundMessage={'There is no container data available'}
          enableAddIcon={false}
          addFunction={null}
          addToolTipText={null}
          enableSorteable={true}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
          isDataLoading={dataIsLoading}
          enableCheckbox={true}
          selected={listContainer.filter(c => c.selected)}
          handleSelectAllClick={handleSelectAllClick}
        >

          <StyledTableBody key={"myTablebody"}>
            {determineStartArray(listContainer).map((container, rowIndex) => {
              return (
                [
                  <StyledTableRow
                    key={`custom-row-${rowIndex}`}>
                    <StyledTableCell padding="checkbox"><Checkbox checked={container.selected} disabled={false}
                      onChange={(e) => {
                        handleChangeSelected(container.id)
                      }}
                    /></StyledTableCell>
                    <StyledTableCell style={{ width: '8rem', minWidth: '8rem' }}>{formatMidasNumber(container.sampleName)}</StyledTableCell>
                    <StyledTableCell style={{ width: '6rem' }}>{container.containerNumber}</StyledTableCell>
                    <StyledTableCell>{container.ownerEmail}</StyledTableCell>
                    <StyledTableCell style={{ width: '6rem' }}>{container.locationName}</StyledTableCell>
                    <StyledTableCell style={{ width: '6rem' }}>{container.subLocation}</StyledTableCell>
                    <StyledTableCell>{container.sample?.description}</StyledTableCell>
                    <StyledTableCell>{container.sample?.additionalSampleInformation}</StyledTableCell>
                    <StyledTableCell>{container.sample?.chemID}</StyledTableCell>
                    <StyledTableCell>{container.containerStatusName}</StyledTableCell>
                    <StyledTableCell>{`${container.size} ${container.containerSizeUoM}`}</StyledTableCell>
                    <StyledTableCell>{container.currentAmount && container.size ? roundNumber((container.currentAmount / container.size) * 100.00) : 0}</StyledTableCell>
                  </StyledTableRow>
                ]
              );
            })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </div>
      <ModalTwoButtons title={'Warning'} button1Text={'No'} button1Action={closeModalTwoButtonsOpen} button2Text={'Yes'} button2Action={() => typeSend === 'discard' ? sendToDiscard(containersToSend) : sendToTransfer(containersToSend)} open={modalTwoButtonsOpen} setOpen={setModalTwoButtonsOpen}>
        <label>
          {messageModal}
        </label>
      </ModalTwoButtons>
    </StyledContainer >
  );
};

export default LocateContainers;