import API from "../../api";

export default class UnitOfMeasure {
  /**
   * The UnitOfMeasure dimension table for M3
   * @param {String} uoMName The UoMName of the UnitOfMeasure
   * @param {String} metricStandardUoMName The MetricStandardUoMName for the UnitOfMeasure
   * @param {Number} metricStandardConversion The MetricStandardConversion for the UnitOfMeasure
   */

  constructor({
    uoMName,
    type,
    metricStandardUoMName,
    metricStandardConversion,
    isActive }) 
    
    {
    this.uoMName = uoMName;
    this.type = type;
    this.metricStandardUoMName = metricStandardUoMName;
    this.metricStandardConversion = metricStandardConversion;
    this.isActive = isActive;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/unitOfMeasure`);
    return data.result.map((d) => new UnitOfMeasure(d));
  }

  static async getAllActive() {
    const api = await API();
    const { data } = await api.get(`/unitOfMeasure`);
    if (data && data.result && data.result.length > 0) {
      return data.result.filter(u => u.isActive);
    }
    return [];
  }

  static async get(id) {
    const api = await API();
    const { data } = await api.get(`unitOfMeasure/getByName?name=${id}`);
    if (data.result) {
      return new UnitOfMeasure(data.result);
    }
    else {
      return null;
    }

  }

  static async add(info) {
    const api = await API();
    const { data } = await api.post(`/unitOfMeasure`, info);
    return new UnitOfMeasure(data.result);
  }

  static async update(id, info) {
    const api = await API();
    const { data } = await api.put(`/unitOfMeasure/${id}`, info);
    return new UnitOfMeasure(data.result);
  }
}
