import React, { useState, useEffect, useContext } from "react";
import { UXDataTable } from "../../components/UXDataTable";
import { applyFiltersToArray, hasRole, Roles } from "../../global";
import ModalMessages from "../../components/Modal/ModalSimpleButton";
import Batch from "../../api/Formulations/Batch";
import UserContext from "../../context/UserContext";
import ModalTwoButtons from "../../components/Modal/ModalTwoButtons";
import EmailAddressTextField from "../../components/EmailAddressField";

const MyBatchCols = [
  { field: 'studyName', headerName: 'Study Name', type: 'label'},
  { field: 'blendName', headerName: 'Blend Name', type: 'label' },
  { field: 'batchSequenceNumber', headerName: 'Batch #', type: 'number' },
  { field: 'blendPriorityName', headerName: 'Priority', type: 'label' },
  { field: 'ownerEmail', headerName: 'Study Owner', type: 'label' },
  { field: 'requestedCompletionDate', headerName: 'Required Date', type: 'date' },
  { field: 'requestedAmount', headerName: 'Requested Amount', type: 'number' },
  { field: 'requestedAmountUoM', headerName: 'UoM', type: 'label' },
  { field: 'preparedByEmail', headerName: 'Assigned Blender', type: 'label' },
  { field: 'batchStatusName', headerName: 'Status', type: 'label' },
  { field: 'age', headerName: 'Age (days)', type: 'number' },
  { field: 'blendFacilityName', headerName: 'Facility', type: 'label'}
  ];


const TableMyBatches = ({filters, myBatches, isDataLoading, refreshData, setRefreshData}) => {
    const [filteredBatches, setFilteredBatches] = useState([])

    const currentUser = useContext(UserContext) 
    const roles = currentUser?.idTokenClaims.roles;

    const hasBatchAssigner = hasRole(Roles.BatchAssigner, roles) || hasRole(Roles.Developer, roles)
    const hasBatchPrepareer = hasRole(Roles.BatchPreparer, roles) || hasRole(Roles.Developer, roles)

    let inputUserName = currentUser ? currentUser.username : null

    const noDataMessage = `No batches found for owner = ${inputUserName} or blender = ${inputUserName}`
    
    const [unassignBlender, setUnassignBlender] = useState(false)
    const [tempBatchID, setTempBatchID] = useState(0);
    const [tempBlenderEmail, setTempBlenderEmail] = useState('')
    const [blenderEmailError, setBlenderEmailError] = useState(false)

    const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
    const modalMessagesButtonText = 'Ok'
    const [modalMessagesTitle, setModalMessagesTitle] = useState('');
    const [modalMessagesText, setModalMessagesText] = useState('');

    const [modalTwoBtnOpen, setModalTwoBtnOpen] = useState(false);
    const [modalTwoBtnButton1Text, setModalTwoBtnButton1Text] = useState('');
    const [modalTwoBtnButton2Text, setModalTwoBtnButton2Text] = useState('');
    const [modalTwoBtnTitle, setModalTwoBtnTitle] = useState('');
    const [modalTwoBtnText, setModalTwoBtnText] = useState('');

    const [modalUnassignOpen, setModalUnassignOpen] = useState(false);
    const [modalUnassignButton1Text, setModalUnassignButton1Text] = useState('');
    const [modalUnassignButton2Text, setModalUnassignButton2Text] = useState('');
    const [modalUnassignTitle, setModalUnassignTitle] = useState('');
    const [modalUnassignText, setModalUnassignText] = useState('');
  
    function closeModalMessages() {
      setModalMessagesOpen(false);
    }
  
    function openModalMessages(title, text) {
      setModalMessagesOpen(true);
      setModalMessagesTitle(title);
      setModalMessagesText(text);
    }

    function closeModalTwoButtons() {
      setModalTwoBtnOpen(false)
    }
  
    function openModalTwoButtons(title, text, buttonText, button2Text) {
      setModalTwoBtnButton1Text(buttonText)
      setModalTwoBtnButton2Text(button2Text)
      setModalTwoBtnOpen(true)
      setModalTwoBtnTitle(title)
      setModalTwoBtnText(text)
    }

    function closeModalUnassign() {
      setModalUnassignOpen(false)
    }
  
    function openModalUnassign(title, text, buttonText, button2Text) {
      setModalUnassignButton1Text(buttonText)
      setModalUnassignButton2Text(button2Text)
      setModalUnassignOpen(true)
      setModalUnassignTitle(title)
      setModalUnassignText(text)
    }

    const menuItems = [{
      menuType: 'link-editBatch',
      redirectPath: '/prepareBatch',
      text: 'Work on Batch',
      onClickFunction: null,
      isDisabled: !(hasBatchPrepareer)
    },
    {
      menuType: 'text',
      redirectPath: '',
      text: 'Assign Blender',
      onClickFunction: AssignBlenderClick,
      isDisabled: !(hasBatchAssigner)
    },
    {
      menuType: 'text',
      redirectPath: '',
      text: 'Unassign Blender',
      onClickFunction: UnAssignBlenderClick,
      isDisabled: !(hasBatchAssigner)
    }
    ]

    function AssignBlenderClick(batchQueue){
      if (batchQueue){
        if (batchQueue.preparedByEmail !== '' && batchQueue.preparedByEmail !== null)
        {
          openModalMessages("Batch Error", "You cannot set the blender because there is already an assigned blender!")
          return
        }

        setTempBatchID(batchQueue.id)
        openModalTwoButtons("Set Blender", "Enter the email address of the blender you want to assign.","Ok", "Cancel")
      }
    }

    function UnAssignBlenderClick(batchQueue){
      setTempBatchID(batchQueue.id)
      openModalUnassign("Confirm Unassign", "Are you sure you want to unassign this batch?", "Yes", "No")
    }

    function ConfirmUnassign() {
      setTempBlenderEmail('')
      setUnassignBlender(true)
      setModalUnassignOpen(false)
    }

    function ValidateEnteredBlender(){
      if (!(blenderEmailError))
      {
        UpdateAssignedBlender(false)
      }
    }

    function UpdateAssignedBlender(allowNullAssignee){
      if (tempBatchID > 0){  
  
        if (allowNullAssignee === false && (tempBlenderEmail === null || tempBlenderEmail === ''))
        {
          openModalMessages("Batch Error", "The blender email must be valid!")
          return
        }
  
        Batch.updateAssignedBlender(tempBatchID, tempBlenderEmail).then((res) => {
          if (res.message === 'Success')
          {
            Batch.updateBatchStatus(tempBatchID, tempBlenderEmail === '' ? 'Submitted' : 'Assigned', '').then((res2) => {
              if (res.message === 'Success')
              {
                openModalMessages("Batch Updated", "Batch successfully updated!")
                setRefreshData(!refreshData)
              } else {
                openModalMessages('Batch Failed to Update', `${res.message}. Contact support if you feel this is an error.`);
              }

              closeModalTwoButtons()
              setTempBlenderEmail('')
              setTempBatchID(0)
            })
          } else {
            openModalMessages('Batch Failed to Update', `${res.message}. Contact support if you feel this is an error.`);
            
            closeModalTwoButtons()
            setTempBlenderEmail('')
            setTempBatchID(0)
          }
        })
      }
    }

  useEffect(() => {
    var filteredArray = applyFiltersToArray(filters, myBatches)
    setFilteredBatches(filteredArray)
  }, [filters, myBatches])

  useEffect(() => {
    if (unassignBlender === true)
    {
      UpdateAssignedBlender(true)
      setUnassignBlender(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unassignBlender])
 
  return (
       <div>
         <UXDataTable tableWidth='100%' cols={MyBatchCols} rows={filteredBatches.sort((a, b) => new Date(a.age) - new Date(b.age))} moreOptionsCell={true} enablePaging={true} 
                      noDataMessage={noDataMessage} menuProps={menuItems} defaultRowsPerPage={10} isDataLoading={isDataLoading} tableName={'myBatches'} enableSorting={true}>
         </UXDataTable>

           {/* Informational Messages */}
        <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
          <label>
              {modalMessagesText}
          </label>     
        </ModalMessages>

         {/* Assigned Blender */}
         <ModalTwoButtons title={modalTwoBtnTitle} button1Text={modalTwoBtnButton1Text} button1Action={ValidateEnteredBlender} isButton1Disabled={(tempBlenderEmail === null || tempBlenderEmail === '')} button2Text={modalTwoBtnButton2Text} button2Action={closeModalTwoButtons} open={modalTwoBtnOpen} setOpen={setModalTwoBtnOpen}>
            <label>
                {modalTwoBtnText}
            </label>

            <div></div>

            <EmailAddressTextField
              fontSize = {16}
              fieldWidth = {"350px"} 
              validatedUserEmail = {tempBlenderEmail}
              setValidatedUserEmail = {(e) => setTempBlenderEmail(e)}
              hasErrors={blenderEmailError}
              setHasErrors = {(e) => setBlenderEmailError(e)}
              isDisabled={false}
              labelText="Blender Email"
              placeholderText="Blender Email"
              showPlusMeButton={true}
              size="small"
              margin="normal">
            </EmailAddressTextField>
          </ModalTwoButtons>

          <ModalTwoButtons title={modalUnassignTitle} button1Text={modalUnassignButton1Text} button1Action={ConfirmUnassign} button2Text={modalUnassignButton2Text} button2Action={closeModalUnassign} open={modalUnassignOpen} setOpen={setModalUnassignOpen}>
                <label>
                    {modalUnassignText}
                </label>
          </ModalTwoButtons>
       </div>
  );
};

export default TableMyBatches;