import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, FormControl, TextField, FormControlLabel, Switch, Autocomplete, Checkbox } from "@mui/material";
import { StyledTableRow, StyledTableBody, StyledTableCell, UXDataTableWithoutBody } from "../../../components/UXDataTable";
import ContainerApi from '../../../api/LIMS/Container';
import { formatMidasNumber, exportToExcel, displayPHSValue, convertDateFormat, isDate } from "../../../global";
import SiteApi from '../../../api/Admin/Site';
import ContainerTypeApi from '../../../api/LIMS/ContainerType';
import ContainerStatusApi from '../../../api/LIMS/ContainerStatus'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FilterQueryMenu from "../../../components/FilterQueryMenu";
import { GlobalSecondaryButton, GlobalButton } from "../../styles";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CanceledError } from "axios";
import JapanFireSafetyCode from "../../../api/LIMS/JapanFireSafetyCode";
import JapanSDSCode from "../../../api/LIMS/JapanSDSCode";
import JapanGHSCode from "../../../api/LIMS/JapanGHSCode";
import LotCertificationStatus from "../../../api/ChemInv/LotCertificationStatus";

const StyledContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
}));

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: ' space-between'
}));

const StyledDivSearch = styled('div')(() => ({
  display: 'flex'
}));

const StyledDivAdvancedSearch = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const StyledBox = styled(Box)(() => ({
  display: 'inline-box',
  marginRight: "25px"
}));

const StyledAutocomplete = styled(Autocomplete)(() => ({
  width: "20rem"
}));

const certificationStatusISO = [
  'OnSpec',
  'Recertified',
  'Recertified-Waivered',
  'Waivered',
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AdvancedLocateContainers = ({ ...props }) => {
  const [listContainer, setListContainer] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [searchSite, setSearchSite] = React.useState('All Site');
  const [searchContainerTypes, setSearchContainerTypes] = React.useState([]);
  const [includeDiscards, setIncludeDiscards] = React.useState(false);

  const [dataIsLoading, setDataIsLoading] = React.useState(false);
  const [listSite, setListSite] = React.useState([]);
  const [listContainerType, setListContainerType] = React.useState([]);
  const [listContainerStatus, setListContainerStatus] = React.useState([])
  const [listCertificationStatus, setListCertificationStatus] = React.useState([])

  const [filteringOpen, setFilteringOpen] = React.useState(false);
  const [filters, setFilters] = React.useState([{ name: null, displayName: null, operator: null, enumValues: [], join: '&&', value: '' }])

  const [listJapanFireSafetyCode, setListJapanFireSafetyCode] = React.useState([]);
  const [listJapanSDSCode, setListJapanSDSCode] = React.useState([]);
  const [listJapanGHSCode, setListJapanGHSCode] = React.useState([]);

  const isMountedRef = React.useRef(true);
  const abortControllerRef = React.useRef(null);

  const filterOptions = [{ name: "Sample.ChemID", displayName: "Chem ID", type: "string", enumValues: [] },
  { name: "Sample.ParentSampleContainerID", displayName: "Parent Sample", type: "string", enumValues: [] },
  { name: "Sample.IsCommodityChemical", displayName: "Is Commodity Chemical", type: "boolean", enumValues: [] },
  { name: "Sample.ChargeCode", displayName: "Charge Code", type: "string", enumValues: [] },
  { name: "Sample.Substance.NFPAF", displayName: "Flammability Code", type: "integer", enumValues: [] },
  { name: "Sample.Substance.NFPAH", displayName: "Health Code", type: "integer", enumValues: [] },
  { name: "Sample.Substance.NFPAR", displayName: "Reactivity Code", type: "integer", enumValues: [] },
  { name: "ContainerStatusName", displayName: "Container Status", type: "enum", enumValues: listContainerStatus},
  { name: "HFRSummary", displayName: "HFR Summary", type: "string", enumValues: [] }, // Where from
  { name: "LocationName", displayName: "Location Name", type: "string", enumValues: [] },
  { name: "SampleName", displayName: "MIDAS #", type: "midasNumber", enumValues: [] },
  { name: "OwnerEmail", displayName: "Owner Email", type: "string", enumValues: [] },
  { name: "Sample.Substance.IsPeroxidizable", displayName: "Is Peroxidizable", type: "boolean", enumValues: [] },
  { name: "Sample.Description", displayName: "Sample Description", type: "string", enumValues: [] },
  { name: "Sample.AdditionalSampleInformation", displayName: "Additional Sample Information", type: "string", enumValues: [] },
  { name: "SubLocation", displayName: "Sub Location", type: "string", enumValues: [] },
  { name: "Sample.UserDefinedSafetyInformation", displayName: "User Defined Safety Information", type: "string", enumValues: [] },
  { name: "Size", displayName: "Size", type: "integer", enumValues: [] },
  { name: "SuspectHFRList", displayName: "Suspect HFR List", type: "string", enumValues: [] }, // Where from
  { name: "Sample.EMBSIUseOnly", displayName: "EMBSI Use Only", type: "boolean", enumValues: [] },
  { name: "Sample.IsRMCSample", displayName: "RMC Only", type: "boolean", enumValues: [] },
  { name: "Sample.SupplierLot.ShelfLifeStartDate", displayName: "Shelf Life Start Date", type: "date", enumValues: [] },
  { name: "Sample.SupplierLot.ShelfLifeExpirationDate", displayName: "Shelf Life Expiration Date", type: "date", enumValues: [], nullable: true },
  { name: "Sample.Substance.jFireSafetyCode", displayName: "Japan Fire/Safety Code", type: "enum", enumValues: listJapanFireSafetyCode},
  { name: "Sample.Substance.jghsCode", displayName: "Japan GHS Code", type: "enum", enumValues: listJapanSDSCode},
  { name: "Sample.Substance.jsdsCode", displayName: "Japan SDS Code", type: "enum", enumValues: listJapanGHSCode},
  { name: "Sample.Substance.jBlendDescription", displayName: "Japan Blend Description", type: "string", enumValues: []},
  { name: "Sample.Substance.jDescription", displayName: "Japan Substance Description", type: "string", enumValues: []},
  { name: "Sample.Substance.jToxicMaterialCode", displayName: "Japan Toxic Material Code", type: "string", enumValues: []},
  { name: "Sample.Substance.jShelfLife", displayName: "Japan Shelf Life", type: "string", enumValues: []},
  { name: "Sample.SupplierLot.CertificationStatusName", displayName: "Certification Status", type: "enum", enumValues: listCertificationStatus},
  { name: "ExpirationDate", displayName: "Expiration Date", type: "date", enumValues: [], nullable: true },
  { name: "ContainerTypeName", displayName: "Container Type", type: "string", enumValues: [] },
  ]

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  const filterClick = (event) => {
    setFilteringOpen(true);
  }

  const clearFiltersClick = (event) => {
    setFilters([{ name: null, displayName: null, operator: null, enumValues: [], join: '&&', value: null }])
  }

  const colHeaders = [
    'MIDAS Number', //SampleName
    'Container Number', //ContainerNumber
    'Container Type', //containerTypeName
    'Sample Description', //Sample Description
    'Additional Info', //Sample AdditionalSampleInformation
    'Container Status', //ContainerStatusName
    'Site',
    'Current Location', //LocationName
    'Current Sublocation', //SubLocation
    'ChemID', //Sample ChemID
    'Substance Name', //Sample Substance SubstanceName
    'NFPA H', //Sample Substance nfpah
    'NFPA F', //Sample Substance nfpaf
    'NFPA R', //Sample Substance nfpar
    'PHS Warning', // ???
    'Peroxidizable', //Sample Substance IsPeroxidizable
    'Additional Safety Information', // ??
    'PSIMSID', //Sample Substance PSIMSID
    'Certification Status', // Sample SupplierLot CertificationStatusName
    'Supplier', //Sample SupplierLot SupplierTradename SupplierName
    'Tradename',  //Sample SupplierLot SupplierTradename Tradename TradenameName
    'Lot Number', // Sample SupplierLot LotName
    'Shelf Life Start Date', //Sample SupplierLot ShelfLifeStartDate
    'Shelf Life Expiration Date', //Sample SupplierLot ShelfLifeExpirationDate
    'Sample Owner', //Sample SampleOwnerEmail
    'Container Owner', //OwnerEmail
    'Container Size + UoM',//ContainerSizeUoM e CurrentAmount
    '% Full', // ???
    'EMBSI Use Only', //ContainerStatusName
    'RMC Only', //ContainerStatusName
    'Japan Fire/Safety Code',
    'Japan GHS Code',
    'Japan SDS Code',
    'Japan Blend Description',
    'Japan Substance Description',
    'Japan Toxic Material Code',
    'Japan Shelf Life',
    'Expiration Date',
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    SiteApi.getAll().then((resp) => {
      setListSite([{
        siteName: 'All Site',
        abreviation: 'All Site'
      }, ...resp])
    })
    ContainerTypeApi.getAll().then((resp) => {
      setListContainerType(resp)
      const list = resp.map((containerType) => containerType.name)
      setSearchContainerTypes(list)
    })

    ContainerStatusApi.getAll().then((res) => {
      setListContainerStatus(res.filter(result => result.isActive === true).map((item) => (item.name)).sort())
    });  

    JapanFireSafetyCode.getAll().then((resp) => {
      setListJapanFireSafetyCode(resp.map((item) => (item.fireSafetyCodeName)).sort())
    })

    JapanSDSCode.getAll().then((resp) => {
      setListJapanSDSCode(resp.map((item) => (item.sdsCodeName)).sort())
    })

    JapanGHSCode.getAll().then((resp) => {
      setListJapanGHSCode(resp.map((item) => (item.ghsCodeName)).sort())
    })

    LotCertificationStatus.getAll().then((resp) => {
      setListCertificationStatus(resp.filter(result => result.isActive === true).map((item) => (item.statusName)).sort())
    })

    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    }
  }, [])

  async function handleSearch() {
    setDataIsLoading(true)
    setListContainer([])

    if (abortControllerRef.current) abortControllerRef.current.abort();

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    getData(200, 1)
  }

  function getData(resultsPerPage, page) {
    if (isMountedRef.current) {
      ContainerApi.getAdvancedLocateContainers({
        site: searchSite === 'All Site' ? '' : listSite.find(s => s.abreviation === searchSite)?.siteName,
        containerTypes: searchContainerTypes,
        includeDiscards: includeDiscards,
        page,
        resultsPerPage,
        listCriteria: filters.filter(f => f.property !== null && f.value !== null && f.operator !== null).map((filter, index) => {
          if (filter.value && filter.value !== '' && isDate(filter.value)) filter.value = filter.value?.toISOString().substring(0, 10)
          
          return {
            property: filter.name,
            operator: filter.operator === '=' ? '==' : filter.operator,
            value: filter.value,
            join: filters[index + 1] ? filter.join : null,
            startGroup: filter.startGroup,
            endGroup: filter.endGroup,
            nullable: filter.nullable
          }
        })
      }, abortControllerRef.current?.signal).then((data) => {
        if (data === null || data.length === 0) {
          setDataIsLoading(false)
          return listContainer;
        } else {
          page++;
          setListContainer((prev) => {
            return [...prev, ...data.map(item => {
              return {
                ...item,
                selected: false
              }
            })]
          })
          return getData(resultsPerPage, page);
        }

      })
        .catch(error => {
          if (!(error instanceof CanceledError)) throw error;
        });
    }
  }

  const handleSelectAllClick = () => {
    const displayed = determineStartArray(listContainer)

    const allSelected = listContainer.filter(note => note.selected).length === listContainer.length
    const allDisplayedSelected = displayed.filter(note => note.selected).length === displayed.length

    if (allSelected) {
      setListContainer(prevState => {
        const updatedArray = prevState.map(container => {
          return { ...container, selected: false }
        })

        return updatedArray
      })

    } else if (allDisplayedSelected) {
      setListContainer(prevState => {
        const updatedArray = prevState.map(container => {
          if (displayed.filter(item => item.id === container.id).length > 0) {
            return { ...container, selected: false }
          }
          return container
        })

        return updatedArray
      })

    } else {
      setListContainer(prevState => {
        const updatedArray = prevState.map(container => {
          if (displayed.filter(item => item.id === container.id).length > 0) {
            return { ...container, selected: true }
          }
          return container
        })

        return updatedArray
      })
    }

  }

  function determineStartArray(arrayContainers) {
    if (arrayContainers) {
      return arrayContainers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    } else {
      return []
    }
  }

  function handleChangeSelected(id) {
    let newList = listContainer.map(container => {
      if (container.id === id) {
        return {
          ...container,
          selected: container.selected ? false : true
        }
      } else {
        return container;
      }
    });
    setListContainer([...newList]);
  }

  const roundNumber = (number) => {
    if (number % 1 !== 0) return number.toFixed(2);
    else return number;
  }

  const listContainerSelected = listContainer.filter(c => c.selected);

  return (
    <StyledContainer>
      <StyledFormControl fullWidth>
        <StyledDivSearch>
          <StyledBox>
            <StyledAutocomplete
              size="small"
              id="site-select"
              disablePortal
              noOptionsText={"Loading Sites..."}
              options={listSite.map((site) => site.abreviation)}
              getOptionLabel={(option) => option}
              onChange={(e, value) => {
                value = value === null ? "" : value
                setSearchSite(value)
              }}
              autoHighlight
              autoSelect
              disableClearable
              value={searchSite === '' ? null : searchSite}
              renderInput={(params) => <TextField {...params} variant="outlined" size="small" label="Site" InputProps={{ ...params.InputProps }} />}
            />
          </StyledBox>
          <StyledBox>
            <StyledAutocomplete
              size="small"
              id="containerType-select"
              multiple
              limitTags={3}
              disableCloseOnSelect
              noOptionsText={"Loading Container Types..."}
              options={listContainerType.map((containerType) => containerType.name)}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                setSearchContainerTypes(newValue);
              }}
              value={searchContainerTypes}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              style={{ width: 400 }}
              autoHighlight
              renderInput={(params) => <TextField {...params} variant="outlined" size="small" label="Container Type" InputProps={{ ...params.InputProps }} />}
            />
          </StyledBox>
          <StyledBox>
            <FormControlLabel control={
              <Checkbox checked={includeDiscards}
                onChange={e => setIncludeDiscards(!includeDiscards)}
              ></Checkbox>} label={"Include Discards"} />
          </StyledBox>
          <GlobalSecondaryButton sx={{ width: "10rem", marginRight: "25px" }}
            variant='contained'
            type="submit"
            onClick={() => props.sendToTransfer(listContainerSelected)}
            disabled={listContainerSelected.length < 1}
          > Send to Transfer</GlobalSecondaryButton>
          <GlobalSecondaryButton sx={{ width: "10rem" }}
            variant='contained'
            type="submit"
            onClick={() => props.sendToDiscard(listContainerSelected)}
            disabled={listContainerSelected.length < 1}
          > Send to Discard</GlobalSecondaryButton>
        </StyledDivSearch>
      </StyledFormControl>
      <StyledDivAdvancedSearch>
        <Box display="flex" gap="20px">
          <Box display="flex" alignItems={"center"} marginLeft="auto">
            <GlobalButton sx={{ width: "8rem" }}
              variant='contained'
              type="submit"
              onClick={handleSearch}
            >Search</GlobalButton>
          </Box>
          <GlobalSecondaryButton variant="contained"
            onClick={() => filterClick()}>Search Criteria</GlobalSecondaryButton>

          {!(filters[0].name === null) &&
            <GlobalSecondaryButton style={{ marginLeft: "20px" }} variant="contained"
              onClick={() => clearFiltersClick()}>Clear Criteria</GlobalSecondaryButton>}
        </Box>
        <Box display="flex" alignItems={"center"}>
          <FormControlLabel control={<Switch onChange={() => props.setAdvancedSearch(false)} checked={true} />} label="Advanced Search" />
          <FileDownloadIcon sx={{ m: 1 }} style={{ cursor: "pointer" }} onClick={e => exportToExcel(listContainer.map(container => {
            return {
              'MIDAS Number': formatMidasNumber(container.sampleName),
              'Container Number': container.containerNumber,
              'Container Type': container.containerTypeName,
              'Sample Description': container.sample?.description,
              'Additional Info': container.sample?.additionalSampleInformation,
              'Container Status': container.containerStatusName,
              'Site': container.location.siteName,
              'Current Location': container.locationName,
              'Current Sublocation': container.subLocation,
              'ChemID': `${container.sample?.chemID}`,
              'Substance Name': container.sample?.substance?.substanceName,
              'NFPA H': container.sample?.substance?.nfpah,
              'NFPA F': container.sample?.substance?.nfpaf,
              'NFPA R': container.sample?.substance?.nfpar,
              'PHS Warning': displayPHSValue(container?.sample?.substance ? container.sample.substance : null),
              'Peroxidizable': container.sample?.substance?.isPeroxidizable ? 'Yes' : 'No',
              'Additional Safety Information': container.sample?.userDefinedSafetyInformation,
              'PSIMSID': container.sample?.substance?.psimsid,
              'Certification Status': container.sample?.supplierLot?.certificationStatusName,
              'Supplier': container.sample?.supplierLot?.supplierTradename?.supplierName,
              'Tradename': container.sample?.supplierLot?.supplierTradename?.tradename?.tradenameName,
              'Lot Number': container.sample?.supplierLot?.lotName,
              'Shelf Life Start Date': convertDateFormat(container.sample?.supplierLot?.shelfLifeStartDate, false),
              'Shelf Life Expiration Date': convertDateFormat(container.sample?.supplierLot?.shelfLifeExpirationDate, false),
              'Sample Owner': container.sample?.sampleOwnerEmail,
              'Container Owner': container.ownerEmail,
              'Container Size + UoM': `${container.currentAmount} ${container.containerSizeUoM}`,
              '% Full': roundNumber((container.currentAmount / container.size) * 100.00),
              'EMBSI Use Only': container.sample?.embsiUseOnly ? 'Yes' : 'No',
              'RMC Only': container.sample?.isRMCSample ? 'Yes' : 'No',
              'Japan Fire/Safety Code': container.sample?.substance?.jFireSafetyCode,
              'Japan GHS Code': container.sample?.substance?.jghsCode,
              'Japan SDS Code': container.sample?.substance?.jsdsCode,
              'Japan Blend Description': container.sample?.substance?.jBlendDescription,
              'Japan Substance Description': container.sample?.substance?.jDescription,
              'Japan Toxic Material Code': container.sample?.substance?.jToxicMaterialCode,
              'Japan Shelf Life': container.sample?.substance?.jShelfLife,
              'Expiration Date': convertDateFormat(container.expirationDate, false),
              //'Japan Fire/Safety Code': container.sample?.substance?.japanFireSafetyCode ?  container.sample?.substance?.japanFireSafetyCode?.fireSafetyCodeName : ''
            }
          }), "Locate Containers")} />
        </Box>
      </StyledDivAdvancedSearch>
      <div>
        <UXDataTableWithoutBody
          tableWidth='100%'
          cols={colHeaders}
          blankFirstHeader={false}
          tableId="simpleResultTable"
          rowLength={listContainer.length}
          enablePaging={true}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          noDataFoundMessage={'There is no container data available'}
          enableAddIcon={false}
          addFunction={null}
          addToolTipText={null}
          enableSorteable={false}
          isDataLoading={dataIsLoading}
          enableCheckbox={true}
          selected={listContainerSelected}
          handleSelectAllClick={handleSelectAllClick}
        >

          <StyledTableBody key={"myTablebody"}>
            {determineStartArray(listContainer).map((container, rowIndex) => {
              const shelfExpirated = container.sample?.supplierLot?.shelfLifeExpirationDate && container.sample?.supplierLot?.shelfLifeExpirationDate !== '' ?
                new Date(container.sample?.supplierLot?.shelfLifeExpirationDate) < new Date() : false;
              return (
                [
                  <StyledTableRow
                    hover
                    key={`custom-row-${rowIndex}`}>
                    <StyledTableCell padding="checkbox"><Checkbox checked={container.selected} disabled={false}
                      onChange={(e) => {
                        handleChangeSelected(container.id)
                      }}
                    /></StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: shelfExpirated ? props.getColor('E74C3C') : certificationStatusISO.includes(container.sample?.supplierLot?.certificationStatusName) ? 
                      props.getColor(container.sample?.supplierLot?.lotCertificationStatus?.displayColor) : 'unset' }}>
                        {formatMidasNumber(container.sampleName)}
                    </StyledTableCell>
                    <StyledTableCell>{container.containerNumber}</StyledTableCell>
                    <StyledTableCell>{container.containerTypeName}</StyledTableCell>
                    <StyledTableCell style={{ minWidth: "180px" }}>{container.sample?.description}</StyledTableCell>
                    <StyledTableCell>{container.sample?.additionalSampleInformation}</StyledTableCell>
                    <StyledTableCell>{container.containerStatusName}</StyledTableCell>
                    <StyledTableCell>{container.location.siteName}</StyledTableCell>
                    <StyledTableCell>{container.locationName}</StyledTableCell>
                    <StyledTableCell>{container.subLocation}</StyledTableCell>
                    <StyledTableCell>{container.sample?.chemID}</StyledTableCell>
                    <StyledTableCell>{container.sample?.substance?.substanceName}</StyledTableCell>
                    <StyledTableCell>{container.sample?.substance?.nfpah}</StyledTableCell>
                    <StyledTableCell>{container.sample?.substance?.nfpaf}</StyledTableCell>
                    <StyledTableCell>{container.sample?.substance?.nfpar}</StyledTableCell>
                    <StyledTableCell>{displayPHSValue(container?.sample?.substance ? container.sample.substance : null)}
                    </StyledTableCell>
                    <StyledTableCell>{container.sample?.substance?.isPeroxidizable ? 'Yes' : 'No'}</StyledTableCell>
                    <StyledTableCell>{container.sample?.userDefinedSafetyInformation}</StyledTableCell>
                    <StyledTableCell>{container.sample?.substance?.psimsid}</StyledTableCell>
                    <StyledTableCell>{container.sample?.supplierLot?.certificationStatusName}</StyledTableCell>
                    <StyledTableCell>{container.sample?.supplierLot?.supplierTradename?.supplierName}</StyledTableCell>
                    <StyledTableCell>{container.sample?.supplierLot?.supplierTradename?.tradename?.tradenameName}</StyledTableCell>
                    <StyledTableCell>{container.sample?.supplierLot?.lotName}</StyledTableCell>
                    <StyledTableCell>{convertDateFormat(container.sample?.supplierLot?.shelfLifeStartDate, false)}</StyledTableCell>
                    <StyledTableCell>{convertDateFormat(container.sample?.supplierLot?.shelfLifeExpirationDate, false)}</StyledTableCell>
                    <StyledTableCell>{container.sample?.sampleOwnerEmail}</StyledTableCell>
                    <StyledTableCell>{container.ownerEmail}</StyledTableCell>
                    <StyledTableCell>{`${container.currentAmount} ${container.containerSizeUoM}`}</StyledTableCell>
                    <StyledTableCell>{roundNumber((container.currentAmount / container.size) * 100.00)}</StyledTableCell>
                    <StyledTableCell>{container.sample?.embsiUseOnly ? 'Yes' : 'No'}</StyledTableCell>
                    <StyledTableCell>{container.sample?.isRMCSample ? 'Yes' : 'No'}</StyledTableCell>
                    <StyledTableCell>{container.sample?.substance?.jFireSafetyCode}</StyledTableCell>
                    <StyledTableCell>{container.sample?.substance?.jGSHCode}</StyledTableCell>
                    <StyledTableCell>{container.sample?.substance?.jsdsCode}</StyledTableCell>
                    <StyledTableCell>{container.sample?.substance?.jBlendDescription}</StyledTableCell>
                    <StyledTableCell>{container.sample?.substance?.jDescription}</StyledTableCell>
                    <StyledTableCell>{container.sample?.substance?.jToxicMaterialCode}</StyledTableCell>
                    <StyledTableCell>{container.sample?.substance?.jShelfLife}</StyledTableCell>
                    <StyledTableCell>{convertDateFormat(container.expirationDate, false)}</StyledTableCell>
                  </StyledTableRow>
                ]
              );
            })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </div>
      <FilterQueryMenu
        open={filteringOpen}
        setOpen={setFilteringOpen}
        applyBtnAction={applyFilters}
        cancelButtonAction={closeFiltering}
        filteringInfo={filterOptions.sort((a, b) => a.displayName.localeCompare(b.displayName))}
        appliedFilters={filters}
        setAppliedFilters={setFilters}
      />
    </StyledContainer >
  );
};

export default AdvancedLocateContainers;
