import { React, useState } from "react";
import { Box, Table, TableBody, TableCell, TableRow, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { formatCreatedAndModifiedDateInfo } from "../../../global";
import { GlobalTabCollection, StyledTab } from "../../../pages/styles";

const fontSizeMinor = "13px"

const StyledTableCellMinor = styled(TableCell)({
    border: "none",
    paddingBottom:"0px",
    paddingTop:"0px",
    color:"#00000099",
    fontFamily:"EMprint",
    fontWeight:"400",
    fontSize: fontSizeMinor,
    width:"20%"
});

const StyledTableCellNoBorder = styled(TableCell)({
    border: "none",
    paddingBottom:"0px",
    paddingTop:"0px",
    color:"#000000DE",
    fontFamily:"EMprint",
    fontWeight:"400",
    fontSize: "16px",
    width:"20%"
});

const StyledTableHeaders = styled(TableCell)({
    paddingBottom:"0px",
    paddingTop:"0px",
    color:"#000000DE",
    fontFamily:"EMprint",
    fontWeight:"600",
    fontSize: fontSizeMinor,
});

const StyledTableCellMinor1 = styled(TableCell)({
    border: "none",
    paddingBottom:"0px",
    paddingTop:"0px",
    color:"#00000099",
    fontFamily:"EMprint",
    fontWeight:"400",
    fontSize: fontSizeMinor,
    width:"16.666%"
});

const StyledTableCellNoBorder1 = styled(TableCell)({
    border: "none",
    paddingBottom:"0px",
    paddingTop:"0px",
    color:"#000000DE",
    fontFamily:"EMprint",
    fontWeight:"400",
    fontSize: "16px",
    width:"16.666%"
});


const StyledTableCell = styled(TableCell)({
    border: "none",
    color:"#000000DE",
    fontFamily:"EMprint",
    fontWeight:"400",
    fontSize: fontSizeMinor
});

const StyledDivider = styled(Divider)({
    marginTop:"1rem"
});

const StyledP = styled('p')({
    color:"#000000DE",
    fontFamily:"EMprint",
    fontWeight:"600",
    fontSize: "16px",
    paddingTop:"2rem",
    paddingLeft:".6rem",
    paddingBottom:"2rem"
})

const StudySummary = ({ myStudy }) => {
    const [tabValue, setTabValue] = useState(0)
    const [tabValueBatch, setTabValueBatch] = useState(0)

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
     };

     const handleTabChangeBatch = (event, newValue) => {
        setTabValueBatch(newValue)
     };

  return (

    <div style={{paddingBottom:"2rem"}}>
        <div>
            <Box sx={{ boxShadow: 3,
            width: '95%',
            height: '19rem',
            p: 2,
            m: 1,
            borderRadius: 2,
            }}>
                <div style={{marginBottom:"1.5rem"}}> 
                    <p style={{
                        color:"#000000DE",
                        fontFamily:"EMprint",
                        fontWeight:"400",
                        fontSize: "20px",
                        paddingLeft:"1rem",
                    }}>{myStudy.studyName}</p>
                </div>

                    <Table>
                        <TableBody>
                            <TableRow>
                                <StyledTableCellMinor>Blend StudyID</StyledTableCellMinor>
                                <StyledTableCellMinor>Owner</StyledTableCellMinor>
                                <StyledTableCellMinor>Created Date</StyledTableCellMinor>
                                <StyledTableCellMinor>Created By</StyledTableCellMinor>
                                <StyledTableCellMinor>Project</StyledTableCellMinor>
                            </TableRow>
                            <TableRow>
                                <StyledTableCellNoBorder>{myStudy.id}</StyledTableCellNoBorder>
                                <StyledTableCellNoBorder>{myStudy.ownerEmail !== null ? myStudy.ownerEmail : '-'}</StyledTableCellNoBorder>
                                <StyledTableCellNoBorder>{myStudy.createdDate !== null ? myStudy.createdDate : '-'}</StyledTableCellNoBorder>
                                <StyledTableCellNoBorder>{myStudy.createdByEmail !== null  && myStudy.createdByEmail !== '' ? myStudy.createdByEmail : '-'}</StyledTableCellNoBorder>
                                <StyledTableCellNoBorder>{myStudy.project.name !== null ? myStudy.project.name : '-'}</StyledTableCellNoBorder>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Table style={{marginTop:"2rem"}}>
                        <TableBody>
                            <TableRow>
                                <StyledTableCellMinor>Keywords</StyledTableCellMinor>
                                <StyledTableCellMinor>Type</StyledTableCellMinor>
                                <StyledTableCellMinor>Last Modified Date</StyledTableCellMinor>
                                <StyledTableCellMinor>Last Modified By</StyledTableCellMinor>
                                <StyledTableCellMinor>Purpose</StyledTableCellMinor>
                            </TableRow>
                            <TableRow>
                                <StyledTableCellNoBorder>{myStudy.keywords !== null && myStudy.keywords !== '' ? myStudy.keywords : '-'}</StyledTableCellNoBorder>
                                <StyledTableCellNoBorder>{myStudy.blendStudyType.name !== null ? myStudy.blendStudyType.name : '-'}</StyledTableCellNoBorder>
                                <StyledTableCellNoBorder>{myStudy.lastModifiedDate !== null ? myStudy.lastModifiedDate : '-'}</StyledTableCellNoBorder>
                                <StyledTableCellNoBorder>{myStudy.lastModifiedByEmail !== null  && myStudy.lastModifiedByEmail !== '' ? myStudy.lastModifiedByEmail : '-'}</StyledTableCellNoBorder>
                                <StyledTableCellNoBorder>{myStudy.studyPurpose !== null && myStudy.studyPurpose !== '' ? myStudy.studyPurpose : '-'}</StyledTableCellNoBorder>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Table style={{marginTop:"2rem"}}>
                        <TableBody>
                            <TableRow>
                                <StyledTableCellMinor>Notes</StyledTableCellMinor>
                            </TableRow>
                            <TableRow>
                                <StyledTableCellNoBorder>{myStudy.notes !== null && myStudy.notes !== '' ? myStudy.notes : '-'}</StyledTableCellNoBorder>
                            </TableRow>
                        </TableBody>
                    </Table>
            </Box>
        </div>

        <div>
            <StyledP>Blends</StyledP>
           
            <GlobalTabCollection style={{paddingLeft:".6rem", width: myStudy.blendRequests.length > 10 ? "60%" :  `${myStudy.blendRequests.length * 6}%` }} value={tabValue} onChange={handleTabChange} variant={"scrollable"} scrollButtons="auto" aria-label='ant example'>
                {myStudy.blendRequests.map((oBlend, index) => {
                    return(
                        <StyledTab key={`${oBlend.blendName}${index}`}
                        label={`Blend ${oBlend.blendName}`} />
                    )
                })}
            </GlobalTabCollection>

            <Box sx={{ boxShadow: 3,
                width: '95%',
                minHeight:'20rem',
                p: 3,
                m: 1,
                borderRadius: 2,
            }}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <StyledTableCellMinor1>Blend Name</StyledTableCellMinor1>
                            <StyledTableCellMinor1>Product Group</StyledTableCellMinor1>
                            <StyledTableCellMinor1>Purpose</StyledTableCellMinor1>
                            <StyledTableCellMinor1>Comments</StyledTableCellMinor1>
                            <StyledTableCellMinor1>Metals Free</StyledTableCellMinor1>
                            <StyledTableCellMinor1>ISO Blend</StyledTableCellMinor1>
                        </TableRow>
                        <TableRow>
                            <StyledTableCellNoBorder1>{`Blend ${myStudy.blendRequests[tabValue].blendName}`}</StyledTableCellNoBorder1>
                            <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].blendProductGroup.name}</StyledTableCellNoBorder1>
                            <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].blendPurpose !== null  && myStudy.blendRequests[tabValue].blendPurpose !== '' ? myStudy.blendRequests[tabValue].blendPurpose : '-'}</StyledTableCellNoBorder1>
                            <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].blendComments !== null  && myStudy.blendRequests[tabValue].blendComments !== '' ? myStudy.blendRequests[tabValue].blendComments : '-'}</StyledTableCellNoBorder1>
                            <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].isMetalsFree === true ? 'Yes' : 'No'}</StyledTableCellNoBorder1>
                            <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].isISOBlend === true ? 'Yes' : 'No'}</StyledTableCellNoBorder1>
                        </TableRow>
                    </TableBody>
                </Table>

                <Table style={{marginTop:"2rem"}}>
                    <TableBody>
                    <TableRow>
                        <StyledTableCellMinor1>WBS Code</StyledTableCellMinor1>
                        <StyledTableCellMinor1>ProMIS R Code</StyledTableCellMinor1>
                        <StyledTableCellMinor1>CMA Oil Code</StyledTableCellMinor1>
                        <StyledTableCellMinor1>Safety Information</StyledTableCellMinor1>
                        <StyledTableCellMinor1>Keywords</StyledTableCellMinor1>
                        <StyledTableCellMinor1>Reference</StyledTableCellMinor1>
                    </TableRow>
                     <TableRow>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].chargeCode !== null  && myStudy.blendRequests[tabValue].chargeCode !== ''?  myStudy.blendRequests[tabValue].chargeCode : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].proMISRCode !== null  && myStudy.blendRequests[tabValue].proMISRCode !== '' ? myStudy.blendRequests[tabValue].proMISRCode : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].cmaOilCode !== null  && myStudy.blendRequests[tabValue].cmaOilCode !== '' ? myStudy.blendRequests[tabValue].cmaOilCode : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].warning !== null  && myStudy.blendRequests[tabValue].warning !== '' ? myStudy.blendRequests[tabValue].warning : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].keywords !== null  && myStudy.blendRequests[tabValue].keywords !== '' ? myStudy.blendRequests[tabValue].keywords : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].reference !== null  && myStudy.blendRequests[tabValue].reference !== '' ? myStudy.blendRequests[tabValue].reference : '-'}</StyledTableCellNoBorder1>
                    </TableRow>
                    </TableBody>
                </Table>

                <div style={{paddingTop: "1rem"}}>
                    {formatCreatedAndModifiedDateInfo(myStudy.createdDate === null ? 'N/A' : myStudy.createdDate, 
                        myStudy.createdByEmail === null ||  myStudy.createdByEmail.trim() === '' ? 'N/A' : myStudy.createdByEmail,
                        myStudy.lastModifiedDate === null ? 'N/A' : myStudy.lastModifiedDate, 
                        myStudy.lastModifiedByEmail === null || myStudy.lastModifiedByEmail.trim() === '' ? 'N/A' : myStudy.lastModifiedByEmail)}
                </div>

                <StyledDivider></StyledDivider>

                <StyledP>Blend Components</StyledP>

                <Table>
                    <TableBody>
                        <TableRow>
                            <StyledTableHeaders>Chem ID</StyledTableHeaders>
                            <StyledTableHeaders>Description</StyledTableHeaders>
                            <StyledTableHeaders>MIDAS Number</StyledTableHeaders>
                            <StyledTableHeaders>Alt. MIDAS Number</StyledTableHeaders>
                            <StyledTableHeaders>Target Amount</StyledTableHeaders>
                            <StyledTableHeaders>Target UoM</StyledTableHeaders>
                            <StyledTableHeaders>Addition Order</StyledTableHeaders>
                            <StyledTableHeaders>Addition Temp (C)</StyledTableHeaders>
                        </TableRow>
                            {myStudy.blendRequests[tabValue].blendComponents.map((oComponent, index) => {
                                return(
                                    <TableRow key={`blendComponentRow${index}-${tabValue}`}>
                                        <StyledTableCell>{oComponent.substance.chemID}</StyledTableCell>
                                        <StyledTableCell>{oComponent.substance.substanceName}</StyledTableCell>
                                        <StyledTableCell>{oComponent.preferredSample !== null ? oComponent.preferredSample.sampleName : '-'}</StyledTableCell>
                                        <StyledTableCell>{oComponent.secondaryPreferredSample !== null ? oComponent.secondaryPreferredSample.sampleName : '-'}</StyledTableCell>
                                        <StyledTableCell>{oComponent.targetAmountLower !== null ? oComponent.targetAmountLower : '-'}</StyledTableCell>
                                        <StyledTableCell>{oComponent.componentUoM !== null ? oComponent.componentUoM.uoMName : '-'}</StyledTableCell>
                                        <StyledTableCell>{oComponent.addOrder !== null ? oComponent.addOrder : '-'}</StyledTableCell>
                                        <StyledTableCell>{oComponent.blendAdditionTemperatureC !== null ? oComponent.blendAdditionTemperatureC : '-'}</StyledTableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </Box>

            <StyledP>Batches</StyledP>
           
            <GlobalTabCollection style={{paddingLeft:".6rem", width: myStudy.blendRequests[tabValue].batches.length > 10 ? "60%" :  `${myStudy.blendRequests[tabValue].batches.length * 6}%` }} value={tabValueBatch} onChange={handleTabChangeBatch} variant={"scrollable"} scrollButtons="auto" aria-label='ant example'>
                {myStudy.blendRequests[tabValue].batches.map((oBatch, index) => {
                    return(
                        <StyledTab key={`${oBatch.batchSequenceNumber}${index}`}
                        label={`Batch ${oBatch.batchSequenceNumber}`} />
                    )
                })}
            </GlobalTabCollection>

            <Box sx={{ boxShadow: 3,
                width: '95%',
                minHeight:'20rem',
                p: 3,
                m: 1,
                borderRadius: 2,
            }}>

                {/* <StyledDivider></StyledDivider> */}

                {/* <StyledP>Batch Information</StyledP> */}
                    <Table>
                        <TableBody>
                            <TableRow>
                                {/* <StyledTableHeaders>Batch Number</StyledTableHeaders> */}
                                <StyledTableHeaders>Target Amount</StyledTableHeaders>
                                <StyledTableHeaders>Precision</StyledTableHeaders>
                                <StyledTableHeaders>UoM</StyledTableHeaders>
                                <StyledTableHeaders>Requested Completion</StyledTableHeaders>
                                <StyledTableHeaders>Priority</StyledTableHeaders>
                                <StyledTableHeaders>Reason</StyledTableHeaders>
                                <StyledTableHeaders>Destination</StyledTableHeaders>
                                <StyledTableHeaders>Retain</StyledTableHeaders>
                                <StyledTableHeaders>Facility</StyledTableHeaders>
                            </TableRow>
                            <TableRow key={`batchesRow${tabValueBatch}-${tabValue}`}>
                                <StyledTableCell>{myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedAmount !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedAmount !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedAmount : '-'}</StyledTableCell>
                                <StyledTableCell>{myStudy.blendRequests[tabValue].batches[tabValueBatch].batchPrecision !== null&& myStudy.blendRequests[tabValue].batches[tabValueBatch].batchPrecision !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].batchPrecision : '-'}</StyledTableCell>
                                <StyledTableCell>{myStudy.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested !== null ? myStudy.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested.uoMName : '-'}</StyledTableCell>
                                <StyledTableCell>{myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedCompletionDate !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedCompletionDate !== '' ? new Date(myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedCompletionDate).toDateString().split(' ').slice(1).join(' '): '-'}</StyledTableCell>
                                <StyledTableCell>{myStudy.blendRequests[tabValue].batches[tabValueBatch].blendPriority !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].blendPriority !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].blendPriority.blendPriorityName : '-'}</StyledTableCell>
                                <StyledTableCell>{myStudy.blendRequests[tabValue].batches[tabValueBatch].priorityReason !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].priorityReason !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].priorityReason : '-'}</StyledTableCell>
                                <StyledTableCell>{myStudy.blendRequests[tabValue].batches[tabValueBatch].destination !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].destination !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].destination : '-'}</StyledTableCell>
                                <StyledTableCell>{myStudy.blendRequests[tabValue].batches[tabValueBatch].batchRetainLocation !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchRetainLocation !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].batchRetainLocation.locationName : '-'}</StyledTableCell>
                                <StyledTableCell>{myStudy.blendRequests[tabValue].batches[tabValueBatch].blendFacility !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].blendFacility !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].blendFacility.blendFacilityName : '-'}</StyledTableCell>
                            </TableRow>

                                {/* {myStudy.blendRequests[tabValue].batches.map((oBatch, index) => {
                                    return(
                                        <TableRow key={`batchesRow${index}-${tabValue}`}>
                                            {/* <StyledTableCell>{`Batch ${index + 1}`}</StyledTableCell> */}
                                            {/* <StyledTableCell>{oBatch.requestedAmount !== null && oBatch.requestedAmount !== '' ? oBatch.requestedAmount : '-'}</StyledTableCell>
                                            <StyledTableCell>{oBatch.batchPrecision !== null&& oBatch.batchPrecision !== '' ? oBatch.batchPrecision : '-'}</StyledTableCell>
                                            <StyledTableCell>{oBatch.unitOfMeasureRequested !== null ? oBatch.unitOfMeasureRequested.uoMName : '-'}</StyledTableCell>
                                            <StyledTableCell>{oBatch.requestedCompletionDate !== null && oBatch.requestedCompletionDate !== '' ? new Date(oBatch.requestedCompletionDate).toDateString().split(' ').slice(1).join(' '): '-'}</StyledTableCell>
                                            <StyledTableCell>{oBatch.blendPriority !== null && oBatch.blendPriority !== '' ? oBatch.blendPriority.blendPriorityName : '-'}</StyledTableCell>
                                            <StyledTableCell>{oBatch.priorityReason !== null && oBatch.priorityReason !== '' ? oBatch.priorityReason : '-'}</StyledTableCell>
                                            <StyledTableCell>{oBatch.destination !== null && oBatch.destination !== '' ? oBatch.destination : '-'}</StyledTableCell>
                                            <StyledTableCell>{oBatch.batchRetainLocation !== null && oBatch.batchRetainLocation !== '' ? oBatch.batchRetainLocation.locationName : '-'}</StyledTableCell>
                                            <StyledTableCell>{oBatch.blendFacility !== null && oBatch.blendFacility !== '' ? oBatch.blendFacility.blendFacilityName : '-'}</StyledTableCell>
                                        </TableRow> */}
                        </TableBody>
                    </Table>

                <StyledDivider></StyledDivider>

                <StyledP>Tests</StyledP>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <StyledTableHeaders>Method</StyledTableHeaders>
                                <StyledTableHeaders>Description</StyledTableHeaders>
                                <StyledTableHeaders>Facility</StyledTableHeaders>
                                <StyledTableHeaders>Offering</StyledTableHeaders>
                                <StyledTableHeaders>Estimate</StyledTableHeaders>
                                <StyledTableHeaders>Priority</StyledTableHeaders>
                            </TableRow>
                                {myStudy.blendRequests[tabValue].batches[tabValueBatch].analyticalTestingObject && myStudy.blendRequests[tabValue].batches[tabValueBatch].analyticalTestingObject !== '' && myStudy.blendRequests[tabValue].batches[tabValueBatch].analyticalTestingObject.map((oTestObj, index) => {
                                    return(
                                        <TableRow key={`testingRow${index}-${tabValue}`}>
                                            <StyledTableCell>{oTestObj.method !== null && oTestObj.method.name !== '' ? oTestObj.method.name : '-'}</StyledTableCell>
                                            <StyledTableCell>{oTestObj.method !== null && oTestObj.method.description !== '' ? oTestObj.method.description : '-'}</StyledTableCell>
                                            <StyledTableCell>{oTestObj.methodFacility !== null  && oTestObj.methodFacility.testFacilityAbv !== '' ? oTestObj.methodFacility.testFacilityAbv : '-'}</StyledTableCell>
                                            <StyledTableCell>
                                                {oTestObj.conditionOffering !== null ? oTestObj.conditionOffering.conditionOfferingName : '-'}
                                            
                                            </StyledTableCell>
                                            <StyledTableCell>{oTestObj.estimate !== null && oTestObj.estimate !== '' ? oTestObj.estimate : '-'}</StyledTableCell>
                                            <StyledTableCell>{oTestObj.testPriority !== null && oTestObj.testPriority.testPriorityName !== '' ? oTestObj.testPriority.testPriorityName : '-'}</StyledTableCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>

            </Box>
        </div>
    </div>
  );
};

export default StudySummary;