import { React, useState, useEffect } from "react";
import { Box, FormControlLabel, Checkbox, Typography, styled, Modal } from "@mui/material";
import { UXDataTable } from "../../../components/UXDataTable";
import CloseIcon from '@mui/icons-material/Close';

const StyledModalBox = styled(Box)(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid rgba(0, 0, 0, 0.3)',
    boxShadow: '0 3px 7px rgba(0, 0, 0, 0.3)',
    backgroundClip: 'padding-box'
}));

const StyledModalHeader = styled('div')({
    marginLeft: '15px',
    marginTop:"20px",
    display: 'flex',
    justifyContent: 'left'
});

const StyledModalBody = styled('div')({
    margin: '1rem'
});

const myComponentsCols = [
    { field: 'isBalance', headerName: 'Balance', type: 'checkbox'},
    { field: 'substance.chemID', headerName: 'Chem ID', type: 'object' },
    { field: 'substance.substanceName', headerName: 'Description', type: 'object' },
    { field: 'preferredSample.sampleName', headerName: 'MIDAS #', type: 'object-midasnumber' },
    { field: 'secondaryPreferredSample.sampleName', headerName: 'Alt MIDAS #', type: 'object-midasnumber' },
    { field: 'targetAmountUpper', headerName: 'Upper Amt.', type: 'number' },
    { field: 'targetAmountLower', headerName: 'Lower Amt.', type: 'number' },
    { field: 'addOrder', headerName: 'Order', type: 'number' },
  ];

  const myBatchCols = [
    { field: 'batchSequenceNumber', headerName: 'Sequence', type: 'batch-label'},
    { field: 'requestedAmount', headerName: 'Target Amt.', type: 'number' },
    { field: 'batchPrecision', headerName: 'Precision', type: 'number' },
    { field: 'unitOfMeasureRequested.uoMName', headerName: 'UoM', type: 'object' },
  ];

const CompareBlends = ({ open, setOpen, setClose, myBlends }) => {

    const handleClose = () => setClose ? setClose() : setOpen(false);
  
    const [checkedBlends, setCheckedBlends] = useState([])

    function HandleMasterCheckChange (event) {
        const copyChecks = [...checkedBlends]
            
        var i ;
        for(i=0; i < copyChecks.length; i++){
            copyChecks[i] = event.target.checked
        }

        setCheckedBlends(copyChecks)
    }

    function HandleCheckChange (event, index) {
        const copyChecks = [...checkedBlends]
            
        copyChecks[index] = event.target.checked

        setCheckedBlends(copyChecks)
    }

    function CheckMasterCheckBoxStatus() {

       if (checkedBlends.indexOf(true) > -1 && checkedBlends.indexOf(false) > -1)
       {
           return false
       } else if (checkedBlends.indexOf(true) === -1)
       {
           return false
       } else
       {
           return true
       }
    }

    useEffect(() => {
        let checkArray = []

        myBlends.forEach(element => {
            checkArray.push(true)
        });

        setCheckedBlends(checkArray)
    }, [myBlends])

    
    return (
        <>
            <Modal 
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-header"
                aria-describedby="modal-modal-body"
            >
                <StyledModalBox style={{width:"95%", maxHeight:"95%", overflow:"auto"}}>
                    <StyledModalHeader id="modal-modal-header" >
                        <Typography variant="h6" component="h6">Compare All Blends</Typography>
                            <Box display="flex" alignItems={"center"} marginLeft="auto" marginRight="20px">
                                <CloseIcon onClick={() => setOpen(false)}></CloseIcon>
                            </Box>
                    </StyledModalHeader>
                    <StyledModalBody id="modal-modal-body">


                <label>
                    {'You can select which blends you wnat to compare or visualize all of them at once.'}
                </label>


                <div style={{
                    display: "flex",
                    marginTop:"20px"
                }}>
                
                <Box sx={{ border:1, width:"15%" }}>
                    <div>
                        <FormControlLabel   
                            label={
                                <Typography
                                sx={{
                                    fontFamily:"EMprint",
                                    fontWeight:"600",
                               }}>All Blends</Typography>
                            }
                            labelPlacement="start"
                            control={
                            <Checkbox
                                checked={CheckMasterCheckBoxStatus()}
                                indeterminate={(checkedBlends.indexOf(true) > -1 && checkedBlends.indexOf(false) > -1)}
                                onChange={(event) => HandleMasterCheckChange(event)}
                            />}
                        />

                    <Box>      
                        {myBlends.map((oBlend, index) =>( 
                            <div key={`menu${index}`}>           
                                <FormControlLabel
                                key={`${index}-${oBlend.blendName}`}
                                label={`Blend ${oBlend.blendName} - ${oBlend.description}`}
                                labelPlacement="start"
                                control={<Checkbox checked={checkedBlends[index]}  />}
                                onChange={(event) => HandleCheckChange(event, index)}
                                />
                            </div> 
                        ))}
                    </Box>
                    </div>
                </Box>

                <Box sx={{ border: 1, marginLeft: "20px", width:"85%"}}>
                    {checkedBlends.map((item, index) => (
                    (item === true && myBlends[index]) &&
                        <Box key={index}>
                            <div style={{display:"flex"}}>
                                <Typography sx={{
                                    fontFamily:"EMprint",
                                    fontWeight:"600",
                                    paddingBottom:"20px",
                                    paddingLeft:"10px",
                                    paddingTop:"10px"}}>
                                    {`Blend ${myBlends[index].blendName} - ${myBlends[index].description}`}
                                </Typography>

                                <Box alignItems={"center"} marginLeft="auto">
                                        <Typography sx={{
                                            fontFamily:"EMprint",
                                            fontWeight:"600",
                                            paddingBottom:"20px",
                                            paddingRight:"10px",
                                            paddingTop:"10px"}}>
                                            Batch Information
                                        </Typography>
                                </Box>
                            </div>

                            <div style={{display:"flex", paddingLeft:"10px"}}>
                                <UXDataTable tableWidth='65%' cols={myComponentsCols} rows={myBlends[index].blendComponents} moreOptionsCell={false} enablePaging={false} 
                                                noDataMessage={'No Components Found'} menuProps={null} defaultRowsPerPage={100} isDataLoading={''} tableName={'compareBlendsComponents'} enableSorting={true}>
                                </UXDataTable>

                                <UXDataTable tableWidth='35%' cols={myBatchCols} rows={myBlends[index].batches} moreOptionsCell={false} enablePaging={false} 
                                         noDataMessage={'No Components Found'} menuProps={null} defaultRowsPerPage={100} isDataLoading={''} tableName={'compareBlendsBatches'} enableSorting={true}>
                                </UXDataTable>
                            </div>    
                        </Box>
                    ))}
                </Box>
                </div>

                </StyledModalBody>
                </StyledModalBox>
            </Modal>
        </>
    );
};

export default CompareBlends;