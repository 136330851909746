import API from "..";

export default class BlendRequest {

  static async CheckBlendComponentSiteVolumes(listBlendRequests) {
    const api = await API();
    const { data } = await api.post(`/blendRequest/CheckBlendComponentSiteVolumes`, listBlendRequests);
    return data;
  }

  static async GetBlendsByStudyID(studyID, page, resultsPerPage) {
    const api = await API();
    const { data } = await api.get(`blendRequest/getByStudyID?studyID=${studyID}&page=${page}&resultsPerPage=${resultsPerPage}`);
    return data
  }
}
