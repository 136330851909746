import { React, useState, useEffect } from "react";
import { Box, Autocomplete, Typography, TextField, styled, Modal, TableRow, Checkbox } from "@mui/material";
import { UXDataTableWithoutBody, StyledTableBody, StyledTableCell } from "../../../components/UXDataTable";
import Site from "../../../api/Admin/Site";
import Sample from "../../../api/LIMS/Sample";
import CloseIcon from '@mui/icons-material/Close';
import { formatMidasNumber, ConvertUOMs, RoundNumber } from "../../../global";
import { GlobalButton } from "../../../pages/styles";
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";
import ModalMessages from "../../../components/Modal/ModalSimpleButton"

const StyledModalBox = styled(Box)(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid rgba(0, 0, 0, 0.3)',
    boxShadow: '0 3px 7px rgba(0, 0, 0, 0.3)',
    backgroundClip: 'padding-box'
}));

const StyledModalHeader = styled('div')({
    marginLeft: '15px',
    marginTop:"20px",
    display: 'flex',
    justifyContent: 'left'
});

const StyledModalBody = styled('div')({
    margin: '1rem'
});

const gramUoMObject = {uoMName: 'g', type: 'weight', metricStandardUoMName: 'g', metricStandardConversion: 1}
const mililiterUoMObject = {uoMName: 'ml', type: 'volume', metricStandardUoMName: 'ml', metricStandardConversion: 1}

const LocateMidasNumber = ({ open, setOpen, setClose, myStudy, setMyStudy, blendIndex, blendComponentIndex, batchComponents, batchComponentID, setBatchComponents, bLocateContainers, incomingUoMObject }) => {

    const handleClose = () => setClose ? setClose() : setOpen(false)

    const mySampleCols = ['MIDAS Number', 'Lot Certification Status', 'Sample Description', 'Amount Available']
    const myContainerCols = ['MIDAS Number', 'Lot Certification Status',  'Container', 'Shelf Life End Date', 'Sample Description', 'Location', 'Sublocation', 'Amount Available']
    const [maxSelectableSamples, setMaxSelectableSamples] = useState(1)

    const [availableSites, setAvailableSites] = useState([])
    const [availableUOMs, setAvailableUOMs] = useState([])

    const [availableSamples, setAvailableSamples] = useState([])
    const [isDataLoading, setIsDataLoading] = useState(false)

    const [availableContainers, setAvailableContainers] = useState([])

    const [checkedRows, setCheckedRows] = useState([])

    const [selectedChemID, setSelectedChemID] = useState(null)
    const [selectedSite, setSelectedSite] = useState(null)

    const [selectedUoM, setSelectedUoM] = useState(incomingUoMObject ? incomingUoMObject : gramUoMObject)

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0); 

    const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
    const modalMessagesButtonText = 'Ok'
    const [modalMessagesTitle, setModalMessagesTitle] = useState('');
    const [modalMessagesText, setModalMessagesText] = useState('');
   
    function closeModalMessages() {
        setModalMessagesOpen(false);
    }
    
    function openModalMessages(title, text) {
        setModalMessagesOpen(true);
        setModalMessagesTitle(title);
        setModalMessagesText(text);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCheckChange = (isChecked, index) => {
        const copyChecks = structuredClone(checkedRows)

        copyChecks[index] = isChecked

        setCheckedRows(copyChecks)
    }

    function AddMidasNumbers() {
        const firstIndex = checkedRows.findIndex((x) => x === true)
        const lastIndex = checkedRows.findLastIndex((x) => x === true)

        if (maxSelectableSamples === 2)
        {
            const copyStudyData = structuredClone(myStudy)

            copyStudyData.blendRequests[blendIndex].blendComponents[blendComponentIndex].preferredSample = availableSamples[firstIndex]

            if (firstIndex < lastIndex)
            {
                copyStudyData.blendRequests[blendIndex].blendComponents[blendComponentIndex].secondaryPreferredSample = availableSamples[lastIndex]
            }
            setMyStudy(copyStudyData)
        } else {
            let container = availableContainers[firstIndex]
            let sample = availableContainers[firstIndex].sample

            let arrayContainers = []

            availableContainers[firstIndex].sample.containers.forEach(oContainer => {
                let tempContainer = oContainer

                delete tempContainer.sample

                arrayContainers.push(tempContainer)
            })

            sample.containers = arrayContainers

            delete container.sample
            setBatchComponents(sample, container, batchComponentID)
        }

        setOpen(false)
    }

    function SearchForSamples() {

        setIsDataLoading(true)
        setAvailableSamples([])
        setAvailableContainers([])

        let cancelPromise = false

        Sample.searchByChemID(selectedChemID.chemID, selectedSite.siteName).then((res) => {
            if (cancelPromise) return

            setIsDataLoading(false)
            if (res && res.message === 'Success')
            {
                if (bLocateContainers)
                {
                    //transform the return to be a list of containers with the sample property populated
                    let containerArray = []

                    res.result.forEach(oSample => {
                        oSample.containers.forEach(oContainer => {
                            let newContainer = oContainer

                            newContainer.sample = oSample

                            if (oContainer.containerStatusName === 'Confirmed' && oContainer.currentAmount > 0)
                            {
                                //var today = new Date();

                                // if (onlyISOSamples)
                                // {
                                //     if (oContainer.expirationDate !== null && oContainer.expirationDate >= today)
                                //     {
                                //         containerArray.push(newContainer)
                                //     }
                                // } else
                                // {
                                //     containerArray.push(newContainer)
                                // }

                                containerArray.push(newContainer)
                            }
                        })
                    });

                    setAvailableContainers(containerArray.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)))

                    setCheckedRows(Array(containerArray.length).fill(false))
                } else {
                    setAvailableSamples(res.result.sort((a, b) => b.totalCurrentContainerVolInGrams < a.totalCurrentContainerVolInGrams ? -1 : 1))
                    setCheckedRows(Array(res.length).fill(false))
                }
            } else {
                if (res.message)
                {
                    openModalMessages('Failed to Find Containers', `${res ? res.message : ''}. Contact support if you feel this is an error.`);
                } else {
                    openModalMessages('Failed to Find Containers', `Unspecified Error, Contact support if you feel this is an error.`);
                }
            }
        })
           
        return () => {
            cancelPromise = true
          }
    }

    function SetChemIDTextFieldValue() {
        let myValue = ''

        if (myStudy && myStudy.blendRequests) {
            myValue =  myStudy.blendRequests[blendIndex].blendComponents[blendComponentIndex].substance ? myStudy.blendRequests[blendIndex].blendComponents[blendComponentIndex].substance.chemID : ''
        } else if (batchComponents && batchComponents.length > 0 && batchComponentID !== null) {
            const index = batchComponents.findIndex(obj => obj.id === batchComponentID)
            myValue = index >= 0 && batchComponents[index].selectedSubstance ? batchComponents[index].selectedSubstance.chemID : ''
        }
       
        return myValue
    }

    useEffect(() => {
        let cancelPromise = false
            Site.getAll().then((res) => {
                if (cancelPromise) return
                setAvailableSites(res.filter(result => result.isActive === true).sort((a, b) => a.siteName < b.siteName ? -1 : 1))
            });

            UnitOfMeasure.getAll().then((res) => {
                if (cancelPromise) return
                setAvailableUOMs(res.filter(result => (result.type === 'weight' || result.type ==='volume') && result.metricStandardConversion !== null && result.isActive === true).sort((a, b) => a.uoMName.localeCompare(b.uoMName)))
            })
        
        return () => {
            cancelPromise = true
          }
    }, [])

    useEffect(() => {
        if (myStudy)
        {
            setSelectedChemID(myStudy.blendRequests[blendIndex].blendComponents[blendComponentIndex].substance)
            setMaxSelectableSamples(2)
        }
    }, [myStudy, blendIndex, blendComponentIndex])

    useEffect(() => {
        if (batchComponents && batchComponents.length > 0 && batchComponentID !== null && batchComponents.findIndex(obj => obj.id === batchComponentID) >= 0)
        {
            const index = batchComponents.findIndex(obj => obj.id === batchComponentID)

            setSelectedChemID(batchComponents[index].selectedSubstance)
            setMaxSelectableSamples(1)
        }
    }, [batchComponents, batchComponentID])

    useEffect(() => {
        if (open === false)
        {
            setAvailableSamples([])
            setAvailableContainers([])
            setCheckedRows([])
            setSelectedUoM((incomingUoMObject ? incomingUoMObject : gramUoMObject))
            setSelectedSite(null)
        }
    }, [incomingUoMObject, open])

    useEffect(() => {
        if (selectedUoM === null)
        {
            setSelectedUoM(gramUoMObject)
        }
    }, [selectedUoM])

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-header"
                aria-describedby="modal-modal-body"
            >
                <StyledModalBox style={{width:"75%", minHeight:"60%", maxHeight:"100%", overflow:"auto"}}>
                    <StyledModalHeader id="modal-modal-header" >
                        <Typography variant="h6" component="h6">{bLocateContainers ? 'Locate Container' : 'Locate MIDAS Number'}</Typography>
                        
                        <Box display="flex" alignItems={"center"} marginLeft="auto" marginRight="20px">
                            <CloseIcon onClick={() => setOpen(false)}></CloseIcon>
                        </Box>
                    </StyledModalHeader>
                    <StyledModalBody id="modal-modal-body">

                <label>
                    {`Select the ${bLocateContainers ? 'containers' : 'samples'} that you would like to recommend for your blend.`}
                </label>

                <div style={{
                    display:"flex",
                    marginBottom:"20px",
                    marginTop:"20px"}}>

                    <TextField size="small" margin="dense" label="ChemID" style={{width:"250px", marginTop:"15px"}}
                    inputProps={{ style: { fontSize: 14 }}} 
                    disabled={true}
                    value={SetChemIDTextFieldValue()}
                    >
                    </TextField>

                    <Autocomplete
                        disablePortal
                        options={availableSites}
                        onChange={(e, value) => setSelectedSite(value)}
                        getOptionLabel={(option) => option.siteName}
                        value={selectedSite}
                        isOptionEqualToValue={(option, value) => value.siteName === option.siteName}
                        autoHighlight
                        style={{marginTop:"3px"}}
                        renderInput={(params) => <TextField {...params} style={{width:"300px", marginTop:"13px", marginLeft:"25px"}} variant="outlined" size="small" label="Select Site" error = {false}  
                        inputProps={{ ...params.inputProps,  style: { fontSize: 14 }}} InputProps={{ ...params.InputProps }}/>} 
                    />

                    <Autocomplete
                        disablePortal
                        noOptionsText={"Loading UoMs..."}
                        options={availableUOMs}
                        getOptionLabel={(option) => option.uoMName}
                        onChange={(e, value) => setSelectedUoM(value)}
                        autoHighlight
                        autoSelect
                        value={selectedUoM}
                        isOptionEqualToValue={(option, value) => value.uoMName === option.uoMName}
                        style={{marginTop:"3px"}}
                        renderInput={(params) => <TextField {...params} style={{width:"150px", marginTop:"13px", marginLeft:"25px"}} variant="outlined" size="small" label="Unit of Measure" error={false}
                        inputProps={{ ...params.inputProps,  style: { fontSize: 14 }}} InputProps={{ ...params.InputProps }}/>}  
                    />

                    <GlobalButton
                        disabled={selectedChemID == null || selectedSite == null}
                        variant="contained" 
                        style={{ textTransform: "none", marginTop:"17px", marginLeft:"25px", height:"30px"}}
                        onClick={() => SearchForSamples()}
                    >Search</GlobalButton>
                </div>

                <div>

                    <UXDataTableWithoutBody
                        tableWidth='95%' 
                        cols={bLocateContainers ? myContainerCols : mySampleCols} 
                        blankFirstHeader = {true}
                        tableId= {`locateMIDASNumber`}
                        rowLength={bLocateContainers ? availableContainers.length : availableSamples.length}
                        enablePaging = {true}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        noDataFoundMessage={'No Containers Found'}
                        enableAddIcon = {false}
                        addFunction={null}
                        addToolTipText="Add new component"
                        isDataLoading={isDataLoading}
                    >
                {!(bLocateContainers) &&
                    availableSamples.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((oSample, index) => {
                        return(
                        <StyledTableBody key={`SearchedSamples~${index}`}>
                            <TableRow> 
                                <StyledTableCell style={{width: "60px"}}>
                                    <Checkbox 
                                        disabled = {checkedRows.filter(result => result === true).length >= maxSelectableSamples && checkedRows[index + (page * rowsPerPage)] === false}
                                        checked = {checkedRows[index + (page * rowsPerPage)] === true}
                                        onChange = {e => handleCheckChange(e.target.checked, index + (page * rowsPerPage))}>
                                    </Checkbox>
                                </StyledTableCell>

                                <StyledTableCell style={{width: "135px"}}>
                                    {formatMidasNumber(oSample.sampleName)}
                                </StyledTableCell>
                                <StyledTableCell style={{width:"180px"}}>
                                    {!oSample.supplierLot || oSample.supplierLot === null ? 'N/A' : oSample.supplierLot.certificationStatusName}
                                </StyledTableCell>

                                <StyledTableCell>
                                    {oSample.description}
                                </StyledTableCell>

                            {selectedUoM.type === 'weight' ?
                                (<StyledTableCell style={{width: "150px"}}>
                                    {`${RoundNumber(ConvertUOMs(oSample, oSample.substance, oSample.totalCurrentContainerVolInGrams, gramUoMObject, selectedUoM), 3).toLocaleString()} ${selectedUoM ? selectedUoM.uoMName : ''}`}
                                </StyledTableCell>)
                                : (<StyledTableCell style={{width: "150px"}}>
                                     {`${RoundNumber(ConvertUOMs(oSample, oSample.substance, oSample.totalCurrentContainerVolInGrams, mililiterUoMObject, selectedUoM), 3).toLocaleString()} ${selectedUoM ? selectedUoM.uoMName : ''}`}
                                </StyledTableCell>)
                            }                                   
                            </TableRow>
                        </StyledTableBody>
                        )
                    })
                }
                {bLocateContainers &&
                    availableContainers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((oContainer, index) => {
                        return(
                        <StyledTableBody key={`SearchedContainers~${index}`}>
                            <TableRow> 
                                <StyledTableCell style={{width: "60px"}}>
                                    <Checkbox 
                                        disabled = {checkedRows.filter(result => result === true).length >= maxSelectableSamples && checkedRows[index + (page * rowsPerPage)] === false}
                                        checked = {checkedRows[index + (page * rowsPerPage)] === true}
                                        onChange = {e => handleCheckChange(e.target.checked, index + (page * rowsPerPage))}>
                                    </Checkbox>
                                </StyledTableCell>


                                <StyledTableCell style={{width: "135px"}}>
                                    {formatMidasNumber(oContainer.sampleName)}
                                </StyledTableCell>

                                 <StyledTableCell style={{width:"180px"}}>
                                    {!oContainer.sample || oContainer.sample.supplierLot === null ? 'N/A' : oContainer.sample.supplierLot.certificationStatusName}
                                </StyledTableCell>

                                <StyledTableCell style={{width: "90px"}}>
                                    {oContainer.containerNumber}
                                </StyledTableCell>

                                <StyledTableCell style={{width: "120px"}}>
                                    {!oContainer.sample || oContainer.sample.supplierLot === null ? 'N/A' : oContainer.sample.supplierLot.shelfLifeExpirationDate}
                                </StyledTableCell>

                                <StyledTableCell >
                                    {oContainer.sample?.description}
                                </StyledTableCell>

                                <StyledTableCell>
                                    {oContainer.locationName}
                                </StyledTableCell>

                                <StyledTableCell >
                                    {oContainer.subLocation}
                                </StyledTableCell>

                                <StyledTableCell style={{width: "150px"}}>
                                    {`${RoundNumber(ConvertUOMs(oContainer.sample, oContainer.sample?.substance, oContainer.currentAmount, oContainer.uom, selectedUoM), 3).toLocaleString()} ${selectedUoM ? selectedUoM.uoMName : ''}`}
                                </StyledTableCell>
                            
                            </TableRow>
                        </StyledTableBody>
                        )
                    })
                    
                }
                    </UXDataTableWithoutBody>

                </div>    

                    <Box display="flex" alignItems={"center"} marginLeft="auto">
                        <GlobalButton 
                            variant="contained" 
                            style={{ textTransform: "none", marginTop:"17px", marginLeft:"auto", marginRight:"5%", height:"30px"}}
                            disabled = {checkedRows.filter(result => result === true).length < 1}
                            onClick={() => AddMidasNumbers()}
                        >{bLocateContainers ? 'Select Container': 'Add MIDAS Numbers'}</GlobalButton>
                    </Box>

                    </StyledModalBody>
                </StyledModalBox>
            </Modal>

            {/* Informational Messages */}
            <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
                <label>
                {modalMessagesText}
                </label>     
            </ModalMessages>
        </>
    );
};

export default LocateMidasNumber;