import React, { useContext } from "react";
import {
  Box,
  Button,
  FormControl,
  styled,
  MenuItem,
  Checkbox,
  TextField,
  TableRow,
  Autocomplete,
  Menu,
  Select,
  InputLabel
} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import BacklogApi from "../../api/LIMS/Backlog";
import MethodApi from "../../api/LIMS/Method";
import MethodFacilityApi from "../../api/LIMS/MethodFacility";
import { UXDataTableWithoutBody, StyledTableBody, StyledTableCell } from "../../components/UXDataTable";
import MoreOptions from "@mui/icons-material/MoreVert";
import ModalTwoButtons from '../../components/Modal/ModalTwoButtons';
import ModalSimpleButton from '../../components/Modal/ModalSimpleButton';
import TestApi from '../../api/LIMS/Test';
import { exportToCsv, formatMidasNumber, getMessage, convertToLocalTime, hasRole, Roles } from "../../global";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import TestPriority from "../../api/LIMS/TestPriority";
import UserContext from "../../context/UserContext";
import { NotificationContext } from "../../context/NotificationContext";
import EmailAddressTextField from "../../components/EmailAddressField";

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

const StyledBox = styled(Box)(() => ({
  display: 'inline-box',
  marginRight: "25px"
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  display: 'flex'
}));

const StyledTextField = styled(TextField)(() => ({
  width: "20rem"
}));

const StyledButton = styled(Button)(() => ({
  widht: '200px',
  textTransform: 'none',
  marginRight: "25px",
  height: '2.2rem'
}));

const StyledBodyModalEmail = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  marginTop: '5px'
}));


const ViewBacklogs = ({ ...props }) => {

  const searchCategory = [
    {
      name: "",
      id: 0
    },
    {
      name: "Method",
      id: 1
    },
    {
      name: "MIDAS Number",
      id: 2
    },
    {
      name: "Requester",
      id: 3
    },
    {
      name: "Submitter",
      id: 4
    },
    {
      name: "Sample Owner",
      id: 5
    },
    {
      name: "Test Owner",
      id: 6
    },
    {
      name: "Facility",
      id: 7
    }
  ]

  const searchCriteriaInitialState = {
    methodName: "",
    facility: "",
    sampleName: "",
    submitterEmail: "",
    requesterEmail: "",
    ownerEmail: "",
    testOwnerEmail: "",
    condition: "",
    showPending: false
  }

  const validationsInitialState =
  {
    methodName: "",
    sampleName: "",
    submitterEmail: "",
    requesterEmail: "",
    ownerEmail: "",
    testOwnerEmail: "",
    facility: ""
  };

  const [testPriorities, setTestPriorities] = React.useState([])
  const [searchCategorySelected, setSearchCategorySelected] = React.useState(null);
  const [searchCriteria, setSearchCriteria] = React.useState(searchCriteriaInitialState);
  const [methodList, setMethodList] = React.useState(null);
  const [methodFacilities, setMethodFacilities] = React.useState(null);
  const [backLogs, setBackLogs] = React.useState([]);
  const [modalTwoButtonsOpen, setModalTwoButtonsOpen] = React.useState(false);
  const [cancelMessage, setCancelMessage] = React.useState('');
  const [conditionList, setConditionList] = React.useState(null);
  const [invalidField, setInvalidField] = React.useState(validationsInitialState);
  const [newPriority, setNewPriority] = React.useState(false);

  const [modalSimpleButton, setModalSimpleButton] = React.useState(false);
  const [modalSimpleButtonText, setModalSimpleButtonText] = React.useState('');
  const [modalSimpleTitle, setModalSimpleTitle] = React.useState('');
  const [modalSimpleText, setModalSimpleText] = React.useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [moreOptionsSelected, setMoreOptionsSelected] = React.useState(null);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');

  const [modalEmailOpen, setModalEmailOpen] = React.useState(false);
  const [messageSubmitter, setMessageSubmitter] = React.useState(null);
  const [emailToNotify, setEmailToNotify] = React.useState('');

  const [modalChangePriority, setModalChangePriority] = React.useState(false);

  const [dataIsLoading, setDataIsLoading] = React.useState(false);
  const [actionBacklog, setActionBacklog] = React.useState('');

  const [requesterInvalid, setRequesterInvalid] = React.useState(false);
  const [submitterInvalid, setSubmitterInvalid] = React.useState(false);
  const [ownerInvalid, setOwnerInvalid] = React.useState(false);
  const [testOwnerInvalid, setTestOwnerInvalid] = React.useState(false);

  const currentUser = useContext(UserContext)
  const roles = currentUser?.idTokenClaims.roles
  const developer = hasRole(Roles.Developer, roles);
  const testActivator = hasRole(Roles.TestActivator, roles);
  const testReviewer = hasRole(Roles.TestReviewer, roles);
  const analyst = hasRole(Roles.Analyst, roles);

  const {
    updateNotification
  } = useContext(NotificationContext)

  const backlogColHeaders = [
   { id: '', label: '', notSorteable: true},
   { id: 'age', label: 'Age' },
   { id: 'methodName', label: 'Method' },
   { id: 'testFacilityAbv', label: 'Facility' },
   { id: 'conditionOfferingName', label: 'Condition' },
   { id: 'testEstimate', label: 'Estimate' },
   { id: 'sampleName', label: 'MIDAS Number' },
   { id: 'containerNumber', label: 'Container' },
   { id: 'testSequenceNumber', label: 'TSN' },
   { id: 'requesterEmail', label: 'Requester' },
   { id: 'testPriorityName', label: 'Priority' },
   { id: 'requestedCompletionDate', label: 'Requested Completion Date' },
   { id: 'testStatusName', label: 'Status' },
   { id: 'isRetest', label: 'IsRetest' },
  ];

  function handleSearch() {
    if (validateFields()) {
      setRowsPerPage(10);
      setPage(0);
      setDataIsLoading(true)
      BacklogApi.get(
        searchCriteria.methodName,
        searchCriteria.facility,
        searchCriteria.sampleName,
        searchCriteria.submitterEmail,
        searchCriteria.requesterEmail,
        searchCriteria.ownerEmail,
        searchCriteria.testOwnerEmail,
        searchCriteria.condition,
        searchCriteria.showPending).then((res) => {
          let compare = 1;
          if (res.length > 0) {

            res.forEach(item => {
              item.sampleName = formatMidasNumber(item.sampleName);
              item.requestedCompletionDate = item.requestedCompletionDate ? convertToLocalTime(item.requestedCompletionDate) : null;
            });


            setBackLogs(res.map(el => compare === 1 ? { ...el, selected: false } : el));
          }
          else
            setBackLogs([]);

          setDataIsLoading(false)
        });
    }
  }

  function loadMethodFacilityFilter() {

    MethodApi.getAll().then((res) => {
      if (res.length > 0) {
        let array = [];
        array.push({ id: undefined, name: "" });
        array.push(...res);

        setMethodList(array);
      }
    });
  }

  function loadFacilityFilter() {
    MethodFacilityApi.getFacilitiesByMethodName("").then((result) => {
      if ((result !== undefined && result !== null)) {
        let array = [];
        array.push({ testFacilityAbv: "" });
        result.forEach(item => {
          if (!array.find(i => i.testFacilityAbv === item.testFacilityAbv))
            array.push(item);
        })

        setMethodFacilities(array);
      }
    });
  }

  function closeModalSimpleButton() {
    setModalSimpleButton(false);
  }

  function handleChangeMethod(methodName) {

    if (methodName) {
      setSearchCriteria(() => ({
        ...searchCriteria,
        methodName: methodName,
        facility: "",
        condition: ""
      }));

      setInvalidField({
        ...invalidField,
        methodName: ''
      });

      setMethodFacilities(null);
      setConditionList(null);

      if (methodName) {
        MethodApi.get(methodName).then((methodExists) => {
          if ((methodExists !== undefined && methodExists !== null)) {
            let array = [];
            array.push({ testFacilityAbv: "" });
            array.push(...methodExists.methodFacilities);
            setMethodFacilities(array);
          }
        });
      }
    }
    else {
      setSearchCriteria(() => ({
        ...searchCriteria,
        methodName: "",
        facility: "",
        condition: ""
      }));

      setInvalidField({
        ...invalidField,
        methodName: getMessage('REQUIRED_FIELD')
      });

    }
  }

  function handleChangeFacilities(selected) {
    if (selected) {
      setSearchCriteria((facility) => ({
        ...searchCriteria,
        facility: selected,
        condition: ""
      }));
      getConditions(selected);
    } else {
      setSearchCriteria((facility) => ({
        ...searchCriteria,
        facility: "",
        condition: ""
      }));

      setConditionList(null);
    }
  }

  function openModalSimpleButton(title, text, buttonText) {
    setModalSimpleButtonText(buttonText)
    setModalSimpleButton(true);
    setModalSimpleTitle(title);
    setModalSimpleText(text);
  }

  function validateFields(field) {
    if (field) {
      setInvalidField({
        ...invalidField,
        [field]: searchCriteria[field] === "" || searchCriteria[field] === undefined ? getMessage('REQUIRED_FIELD') : "",
      });

      return (
        (searchCriteria[field] !== "")
      );

    }
    else {
      setInvalidField({
        ...invalidField,
        methodName: searchCriteria.methodName === "" || searchCriteria.methodName === undefined ? getMessage('REQUIRED_FIELD') : "",
        sampleName: searchCriteria.sampleName === "" || searchCriteria.sampleName === undefined ? getMessage('REQUIRED_FIELD') : "",
        requesterEmail: searchCriteria.requesterEmail === "" || searchCriteria.requesterEmail === undefined ? getMessage('REQUIRED_FIELD') : "",
        submitterEmail: searchCriteria.submitterEmail === "" || searchCriteria.submitterEmail === undefined ? getMessage('REQUIRED_FIELD') : "",
        ownerEmail: searchCriteria.ownerEmail === "" || searchCriteria.ownerEmail === undefined ? getMessage('REQUIRED_FIELD') : "",
        testOwnerEmail: searchCriteria.testOwnerEmail === "" || searchCriteria.testOwnerEmail === undefined ? getMessage('REQUIRED_FIELD') : "",
        facility: searchCriteria.facility === "" || searchCriteria.facility === undefined ? getMessage('REQUIRED_FIELD') : ""
      });

      return (
        (searchCriteria.methodName !== "")
        || (searchCriteria.sampleName !== "")
        || (searchCriteria.requesterEmail !== "")
        || (searchCriteria.submitterEmail !== "")
        || (searchCriteria.ownerEmail !== "")
        || (searchCriteria.testOwnerEmail !== "")
        || (searchCriteria.facility !== "")
      );
    }
  }

  function handleApply(action) {
    if (listBacklogSelected.length > 0) {
      if (action === 'Cancel') {
        setModalTwoButtonsOpen(true);
      } else if (action === 'Backlog') {
        updateStatus(listBacklogSelected.map((item) => item.testID.toString()), "Backlogged")
      } else if (action === 'InProgress') {
        updateStatus(listBacklogSelected.map((item) => item.testID.toString()), "InProgress")
      }
    }
  }

  function closeModalTwoButtonsOpen() {
    setModalTwoButtonsOpen(false);
  }

  function closeModalEmailOpen() {
    setModalEmailOpen(false);
    setEmailToNotify('')
    setMessageSubmitter(null)
  }

  function closeModalChangePriority() {
    setModalChangePriority(false);
  }

  function cancelTests() {

    closeModalTwoButtonsOpen();

    TestApi.cancelTests(listBacklogSelected.map((item) => item.testID.toString()), cancelMessage).then((res) => {
      if (res) {
        openModalSimpleButton('Success', 'Backlog Canceled with success', 'Ok');
        handleSearch();
      }
      else
        openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
    });
  }

  async function updateStatus(ids, status) {
    await TestApi.updateStatus(ids, status).then(async (res) => {
      await handleSearch();
      if (res.errors) {
        openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
      } else {
        openModalSimpleButton('Success', 'Tests Saved with success', 'Ok');
        handleCloseMoreOptions(null)
      }
    });
  }

  function getConditions(facility) {

    if (facility === undefined || facility === null || facility === "") {
      setConditionList(null);
    }
    else {
      // let oFacility = methodFacilities.filter(function (item) {
      //   return item.testFacilityAbv === facility
      // });

      let oFacility = methodFacilities.find(result => result.testFacilityAbv === facility)
      let conditionsResult = [];

      if (oFacility?.testConditionOfferings.length > 0) {
        oFacility?.testConditionOfferings.forEach(conditionOffering => {
          conditionsResult.push(conditionOffering.conditionOfferingName)
          // conditionOffering.testConditionSetupValues.forEach(tcsv => {
          //   conditionsResult.push(`${tcsv.testConditionName}: ${tcsv.discreteValue}`)
          //   //conditionsResult.push(`${tcsv.testConditionName}: ${tcsv.discreteValue} ${(tcsv.unitOfMeasure === null ? '' : tcsv.unitOfMeasure)}`)
          // })
       });

        if (conditionsResult.length > 0 && conditionsResult[0].length > 0)
          setConditionList(conditionsResult.sort());
        else
          setConditionList(null);
      }
    }
  }

  function copyToClipBoard(el) {
    var body = document.body, range, sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("Copy");
      sel.removeAllRanges();
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
      range.removeAllRanges();
    }
  }

  React.useMemo(() => {
    if (methodList === null) {
      loadMethodFacilityFilter();
    }
  }, [methodList])

  React.useEffect(() => {
    if (searchCategorySelected === 7) {
      loadFacilityFilter();
    }
  }, [searchCategorySelected])


  React.useEffect(() => {
    let cancelPromise = false

    TestPriority.getAllTestPriorities().then((res) => {
      if (cancelPromise) return
      setTestPriorities(res)
    })

    return () => {
      cancelPromise = true
    }
  }, []);

  function handleChangeOtherFilters(field, value) {
    setSearchCriteria(() => ({
      ...searchCriteria,
      [field]: value
    }));
  }

  const determineStartArray = () => {
    return (stableSort(backLogs, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function handleCloseMoreOptions() {
    setMoreOptionsSelected(null)

    setAnchorEl(null)
  }

  function handleOpenMoreOptions(e, backlog) {
    setMoreOptionsSelected(backlog)

    setAnchorEl(e.currentTarget)
  }

  async function sendEmail(test) {
    if (messageSubmitter === null || messageSubmitter === '' || emailToNotify === '') {
      return;
    }

    try {
      updateNotification({
        notificationSubject: `Message of test id ${test.testID}`,
        notificationToEmail: emailToNotify,
        notificationContent: messageSubmitter
      })
      openModalSimpleButton('Success', 'Email sent with success', 'Ok');
      closeModalEmailOpen()
    } catch (error) {
      openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
    }
  }

  async function changePriority(id) {
    await TestApi.updatePriority(id, newPriority).then(async (res) => {
      await handleSearch();
      if (res.errors) {
        openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
      } else {
        openModalSimpleButton('Success', 'Tests Saved with success', 'Ok');
        handleCloseMoreOptions(null)
        closeModalChangePriority()
      }
    });
  }

  const searchComponent = () => {

    switch (searchCategorySelected) {
      case 1:
        return <>
          <StyledBox>
            <Autocomplete
              size="small"
              autoHighlight
              autoSelect
              noOptionsText={"Loading Methods..."}
              value={searchCriteria.methodName === '' ? null : searchCriteria.methodName}
              onChange={(e, value) => handleChangeMethod(value)}
              disablePortal
              options={methodList ? methodList.map((method) => method.name.toString()) : []}
              sx={{ width: "15rem" }}
              renderInput={(params) => <TextField size="small" {...params} label="Method Name"
                error={invalidField.methodName.length === 0 ? false : true}
                helperText={invalidField.methodName} />}
            />
          </StyledBox>

          <StyledBox>
            {
              methodFacilities && searchCriteria.methodName &&
              <Autocomplete
                size="small"
                autoHighlight
                autoSelect
                noOptionsText={"Loading Facilities..."}
                value={searchCriteria.facility === '' ? null : searchCriteria.facility}
                onChange={(e, value) =>
                  handleChangeFacilities(value)
                }
                disablePortal
                options={methodFacilities.map((method) => method.testFacilityAbv.toString())}
                sx={{ width: "10rem" }}
                renderInput={(params) => <TextField size="small" {...params} label="Facility"
                />}
              />
            }

          </StyledBox>

          <StyledBox>
            {
              conditionList && searchCriteria.methodName &&
              <Autocomplete
                size="small"
                autoHighlight
                autoSelect
                noOptionsText={"Loading Conditions..."}
                value={searchCriteria.condition === '' ? null : searchCriteria.condition}
                onChange={(e, value) => {
                  handleChangeOtherFilters("condition", value === null ? "" : value)
                }}
                disablePortal
                options={conditionList}
                sx={{ width: "15rem" }}
                renderInput={(params) => <TextField size="small" {...params} label="Conditions"
                />}
              />
            }

          </StyledBox>

        </>
      case 2:
        return <StyledBox>
          <StyledTextField
            label="MIDAS Number"
            variant="outlined"
            size="small"
            value={searchCriteria.sampleName}
            error={invalidField.sampleName.length === 0 ? false : true}
            helperText={invalidField.sampleName}
            onBlur={e => validateFields("sampleName")}
            onChange={e =>
              handleChangeOtherFilters("sampleName", e.target.value)
            }
          />
        </StyledBox>
      case 3:
        return <StyledBox>
          <EmailAddressTextField
            fontSize={16}
            fieldWidth={"20rem"}
            validatedUserEmail={searchCriteria.requesterEmail}
            setValidatedUserEmail={value => {
              handleChangeOtherFilters('requesterEmail', value)
            }}
            setHasErrors={(value) => setRequesterInvalid(value)}
            hasErrors={requesterInvalid}
            labelText="Requester"
            placeholderText="Requester"
            margin={"none"}
            showPlusMeButton={true}>
          </EmailAddressTextField>
        </StyledBox>

      case 4:
        return <StyledBox>
          <EmailAddressTextField
            fontSize={16}
            fieldWidth={"20rem"}
            validatedUserEmail={searchCriteria.submitterEmail}
            setValidatedUserEmail={value => {
              handleChangeOtherFilters('submitterEmail', value)
            }}
            setHasErrors={(value) => setSubmitterInvalid(value)}
            hasErrors={submitterInvalid}
            labelText="Submitter"
            placeholderText="Submitter"
            margin={"none"}
            showPlusMeButton={true}>
          </EmailAddressTextField>
        </StyledBox>

      case 5:
        return <StyledBox>
          <EmailAddressTextField
            fontSize={16}
            fieldWidth={"20rem"}
            validatedUserEmail={searchCriteria.ownerEmail}
            setValidatedUserEmail={value => {
              handleChangeOtherFilters('ownerEmail', value)
            }}
            setHasErrors={(value) => setOwnerInvalid(value)}
            hasErrors={ownerInvalid}
            labelText="Sample Owner"
            placeholderText="Sample Owner"
            margin={"none"}
            showPlusMeButton={true}>
          </EmailAddressTextField>
        </StyledBox>

      case 6:
        return <StyledBox>
          <EmailAddressTextField
            fontSize={16}
            fieldWidth={"20rem"}
            validatedUserEmail={searchCriteria.testOwnerEmail}
            setValidatedUserEmail={value => {
              handleChangeOtherFilters('testOwnerEmail', value)
            }}
            setHasErrors={(value) => setTestOwnerInvalid(value)}
            hasErrors={testOwnerInvalid}
            labelText="Requester"
            placeholderText="Requester"
            margin={"none"}
            showPlusMeButton={true}>
          </EmailAddressTextField>
        </StyledBox>
      case 7:
        return <StyledBox>
          <Autocomplete
            size="small"
            autoHighlight
            autoSelect
            noOptionsText={"Loading Facilities..."}
            value={searchCriteria.facility}
            onChange={(e, value) =>
              handleChangeFacilities(value)
            }
            disablePortal
            options={methodFacilities !== null ? methodFacilities.map((method) => method.testFacilityAbv.toString()) : []}
            sx={{ width: "10rem" }}
            renderInput={(params) => <TextField size="small" {...params} label="Facility"
            />}
          />
        </StyledBox>
      default:
        return <></>
    }
  }

  function handleChangeSelected(id) {
    let newList = backLogs.map(backlog => {
      if (backlog.testID === id) {
        return {
          ...backlog,
          selected: backlog.selected ? false : true
        }
      } else {
        return backlog;
      }
    });
    setBackLogs([...newList]);
  }

  const handleSelectAllClick = () => {
    const displayed = determineStartArray()

    const allSelected = backLogs.filter(note => note.selected).length === backLogs.length
    const allDisplayedSelected = displayed.filter(note => note.selected).length === displayed.length

    if (allSelected) {
      setBackLogs(prevState => {
        const updatedArray = prevState.map(backlog => {
          return { ...backlog, selected: false }
        })

        return updatedArray
      })

    } else if (allDisplayedSelected) {
      setBackLogs(prevState => {
        const updatedArray = prevState.map(backlog => {
          if (displayed.filter(item => item.testID === backlog.testID).length > 0) {
            return { ...backlog, selected: false }
          }
          return backlog
        })

        return updatedArray
      })

    } else {
      setBackLogs(prevState => {
        const updatedArray = prevState.map(backlog => {
          if (displayed.filter(item => item.testID === backlog.testID).length > 0) {
            return { ...backlog, selected: true }
          }
          return backlog
        })

        return updatedArray
      })
    }

  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const listBacklogSelected = backLogs.filter(x => x.selected === true);

  return (

    <div>
      <Box>
        <StyledFormControl fullWidth>
          <StyledBox>
            <Autocomplete
              onChange={(e, value) => {
                const selected = searchCategory.find(s => s.name === value)?.id
                if (selected) {
                  setBackLogs([]);
                  setSearchCriteria(() => ({ ...searchCriteriaInitialState }))
                  setSearchCategorySelected(() => (selected))
                  setInvalidField({
                    ...validationsInitialState
                  });
                }
              }}
              label="Search Category"
              size="small"
              value={searchCategory.length > 0 && searchCategorySelected !== null ? searchCategory.find(s => s.id === searchCategorySelected)?.name : ''}
              disablePortal
              options={searchCategory.map((search) => search.name)}
              sx={{ width: "35rem" }}
              renderInput={(params) => <TextField size="small" {...params} label="Search Category" />}
              autoSelect
              disableClearable
            />
          </StyledBox>
          {
            searchCategorySelected ?
              searchComponent()
              : <></>
          }

          {
            searchCategorySelected ?
              <>
                <StyledButton sx={{ width: "8rem" }}
                  variant='contained'
                  type="submit"
                  onClick={handleSearch}
                >Search</StyledButton>

                <StyledBox>
                  <StyledFormControlLabel
                    label="Show Pending Submissions"
                    onChange={e => setSearchCriteria(() => ({
                      ...searchCriteria,
                      showPending: e.target.checked
                    }))}
                    checked={searchCriteria.showPending}
                    control={<Checkbox />}
                  />
                </StyledBox>
              </>
              : <></>
          }

        </StyledFormControl>

        {
          <div style={{ marginTop: '2rem' }}>
            {
              backLogs.length > 0 ?
                <div style={{ width: "100%", justifyContent: 'end', display: 'flex' }}>
                  <FileDownloadIcon sx={{ m: 1 }} style={{ cursor: "pointer" }} onClick={e => exportToCsv(backLogs.map((element) => {
                    return {
                      methodName: element.methodName,
                      testFacilityAbv: element.testFacilityAbv,
                      condition: element.conditionOfferingName,
                      estimate: element.testEstimate,
                      sampleName: element.sampleName,
                      testSequenceNumber: element.testSequenceNumber,
                      containerNumber: element.containerNumber,
                      age: element.age ? element.age : 0,
                      testStatusName: element.testStatusName,
                      testPriorityName: element.testPriorityName,
                      requestedCompletionDate: element.requestedCompletionDate,
                      requesterEmail: element.requesterEmail,
                      submittedDate: element.submittedDate,
                      sampleOwnerEmail: element.sampleOwnerEmail,
                      testOwnerEmail: element.testOwnerEmail,
                      isRetest: element.isRetest
                    };
                  }), "BackLogs")} />
                  <ContentCopyIcon sx={{ m: 1 }} style={{ cursor: "pointer" }} onClick={e => copyToClipBoard(document.getElementById('backLogTable'))} />
                </div> : <></>
            }

            <UXDataTableWithoutBody
              tableWidth='100%'
              cols={backlogColHeaders}
              blankFirstHeader={false}
              tableId="backLogTable"
              rowLength={backLogs.length}
              enablePaging={true}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              noDataFoundMessage={'There is no backlog data available'}
              enableAddIcon={false}
              addFunction={null}
              addToolTipText={null}
              isDataLoading={dataIsLoading}
              enableCheckbox={true}
              selected={listBacklogSelected}
              handleSelectAllClick={handleSelectAllClick}
              enableSorteable={true}
              order={order}
              orderBy={orderBy}
              handleRequestSort={handleRequestSort}
            >
              <StyledTableBody key={"myTablebody"}>
                {determineStartArray().map((backlog, rowIndex) => {

                  return (
                    <TableRow
                      hover
                      key={`custom-row-${rowIndex}`}>
                      <StyledTableCell padding="checkbox"><Checkbox checked={backlog.selected} disabled={false}
                        onChange={(e) => {
                          handleChangeSelected(backlog.testID)
                        }}
                      /></StyledTableCell>
                      <StyledTableCell><IconButton aria-label="more options" onClick={(e) => handleOpenMoreOptions(e, backlog)}><MoreOptions color='black' /></IconButton></StyledTableCell>
                      <StyledTableCell>{backlog.age ? backlog.age : 0}</StyledTableCell>
                      <StyledTableCell>{backlog.methodName}</StyledTableCell>
                      <StyledTableCell>{backlog.testFacilityAbv}</StyledTableCell>
                      <StyledTableCell>{backlog.conditionOfferingName}</StyledTableCell>
                      <StyledTableCell>{backlog.testEstimate}</StyledTableCell>
                      <StyledTableCell>{backlog.sampleName}</StyledTableCell>
                      <StyledTableCell>{backlog.containerNumber}</StyledTableCell>
                      <StyledTableCell>{backlog.testSequenceNumber}</StyledTableCell>
                      <StyledTableCell>{backlog.requesterEmail}</StyledTableCell>
                      <StyledTableCell>{backlog.testPriorityName}</StyledTableCell>
                      <StyledTableCell>{backlog.requestedCompletionDate}</StyledTableCell>
                      <StyledTableCell>{backlog.testStatusName}</StyledTableCell>
                      <StyledTableCell><Checkbox checked={backlog.isRetest} disabled={true} /></StyledTableCell>
                      {/* <StyledTableCell><Checkbox onChange={e => setCancel(rowIndex, e.target.checked)} checked={backLogs['shouldCancel']} disabled={!developer && !analyst} /></StyledTableCell> */}

                    </TableRow>
                  );
                })}

              </StyledTableBody>

            </UXDataTableWithoutBody>

            <div style={{ display: "flex" }}>
              {
                (developer || analyst) && listBacklogSelected.length > 0 ? <StyledBox sx={{ mb: 1, mt: 2, gap: "20px", display: 'flex', alignItems: 'center' }}>
                  <FormControl size="small" fullWidth>
                    <InputLabel id="action-backlog-select-label">Actions</InputLabel>
                    <Select
                      labelId="action-backlog"
                      id="action-backlog-select"
                      value={actionBacklog}
                      label="Actions"
                      onChange={e => setActionBacklog(e.target.value)}
                    >
                      <MenuItem value={''}></MenuItem>
                      <MenuItem value={'Cancel'}>Cancel test</MenuItem>
                      <MenuItem value={'InProgress'}>Set to InProgress</MenuItem>
                      <MenuItem value={'Backlog'}>Set to Backlog</MenuItem>

                    </Select>
                  </FormControl>
                  <StyledButton sx={{ width: "8rem" }}
                    variant='contained'
                    type="submit"
                    onClick={() => handleApply(actionBacklog)}
                    disabled={actionBacklog === ''}
                  >Apply</StyledButton>
                </StyledBox>
                  : <></>

              }
            </div>
          </div>
        }
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={anchorEl && moreOptionsSelected ? true : false}
          onClose={() => handleCloseMoreOptions(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => setModalChangePriority(true)} disabled={!developer && !testReviewer}>Change Priority</MenuItem>
          <MenuItem onClick={() => updateStatus([moreOptionsSelected.testID], moreOptionsSelected?.testStatusName === "Backlogged" ? "InProgress" : "Backlogged")} disabled={!developer && !testActivator}>{moreOptionsSelected?.testStatusName === "Backlogged" ? "Set To InProgress" : "Set To Backlogged"}</MenuItem>
          <MenuItem onClick={() => { setModalEmailOpen(true); setEmailToNotify(moreOptionsSelected.submitterEmail) }} disabled={!developer && !analyst}>Notify Submitter</MenuItem>
          <MenuItem onClick={() => { setModalEmailOpen(true); setEmailToNotify(moreOptionsSelected.requesterEmail) }} disabled={!developer && !analyst}>Notify Requester</MenuItem>
        </Menu>
      </Box>

      <ModalTwoButtons title={'Please provide a reason for cancel.'} button1Text={'No'} button1Action={closeModalTwoButtonsOpen} button2Text={'Yes'} button2Action={cancelTests} open={modalTwoButtonsOpen} setOpen={setModalTwoButtonsOpen}>
        <StyledTextField
          onChange={e => setCancelMessage(e.target.value)}
          label="Reason for cancel"
          size="small"
          value={cancelMessage} />
      </ModalTwoButtons>

      <ModalTwoButtons title={'Please provide a message and e-mail for send.'} button1Text={'Back'} button1Action={closeModalEmailOpen} button2Text={'Send'} button2Action={() => sendEmail(moreOptionsSelected)} open={modalEmailOpen} setOpen={setModalEmailOpen} setClose={closeModalEmailOpen}>
        <StyledBodyModalEmail style={{minWidth: '450px'}}>
          <TextField
            multiline={true}
            onChange={e => setMessageSubmitter(e.target.value)}
            label="Message"
            size="small"
            minRows={4}
            required={true}
            value={messageSubmitter === null ? '' : messageSubmitter} />
        </StyledBodyModalEmail>
      </ModalTwoButtons>

      <ModalTwoButtons title={'Please provide a new priority.'} button1Text={'Cancel'} button1Action={closeModalChangePriority} button2Text={'Ok'} button2Action={() => changePriority(moreOptionsSelected.testID)} open={modalChangePriority} setOpen={setModalChangePriority} setClose={closeModalChangePriority}>
        <Autocomplete
          disablePortal
          autoHighlight
          autoSelect
          options={testPriorities.map((label) => label.testPriorityName.toString())}
          sx={{ width: "20rem" }}
          renderInput={(params) => <TextField {...params} />}
          onChange={(e, value) => {
            setNewPriority(value)
          }}
        />
      </ModalTwoButtons>

      <ModalSimpleButton title={modalSimpleTitle} buttonText={modalSimpleButtonText} buttonAction={closeModalSimpleButton} open={modalSimpleButton} setOpen={setModalSimpleButton}>
        <label>
          {modalSimpleText}
        </label>
      </ModalSimpleButton>

    </div>
  );
};

export default ViewBacklogs;
