import { useState, useEffect, useContext } from "react";
import {TextField, Autocomplete, Checkbox, FormControlLabel, Divider, Tooltip, TableRow, TableCell, Menu, MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { UXDataTableWithoutBody, StyledTableBody, StyledTableCell } from "../../../components/UXDataTable";
import { ChemIDSearchField } from "../../../components/ChemIDSearch";
import { formatCreatedAndModifiedDateInfo, isNumber, formatMidasNumber, EvaluateOptionalNumberField, RoundNumber, downloadFile, DatePickerKeyDownEvent, hasRole, Roles} from "../../../global";
import ValidatedMidasNumberTextField from  "../../../components/ValidatedMidasNumberTextField";
import CompareBlends from "../NewBlends/CompareBlends";
import BlendPriority from "../../../api/Formulations/BlendPriority";
import BlendProductGroup from "../../../api/Formulations/BlendProductGroup";
import BlendFacility from "../../../api/Formulations/BlendFacility";
import Batch from "../../../api/Formulations/Batch";
import Attachments from "../../../api/Formulations/Attachments";
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";
import Location from "../../../api/Admin/Location";
import TrashIcon from '@mui/icons-material/Delete';
import ContentCopy from '@mui/icons-material/ContentCopy';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import ModalMessages from "../../../components/Modal/ModalSimpleButton";
import SettingsIcon from '@mui/icons-material/Settings';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import LocateMidasNumber from "./LocateMidasNumber";
import MethodSelection from "../../../components/MethodSelection";
import { GlobalTabCollection, GlobalButton, GlobalSecondaryButton, StyledTab } from "../../../pages/styles";
import UserContext from "../../../context/UserContext";
import FileDragAndDrop from "../../../components/FileDragAndDrop";
import BillingInfoField from "../../../components/BillingInfoField"; 

const autoCompleteFontSize = 16
const componentGridFontSize = 12
const bottomMargins = "5px"
const errorsModalTitle = "Errors Detected"

const NoComponentsMessage = "No Components Added"
const helperLabel = "Required Input"
const helperLabelNumber = "Invalid"

const StyledShortTextField = styled(TextField)({
    id:"outlined-normal",
    width:"15%",
    marginRight:"15px",
    marginBottom:bottomMargins
});

const StyledMediumTextField = styled(TextField)({
    id:"outlined-normal",
    width:"30%",
    marginRight:"15px",
    marginBottom:bottomMargins
});

const StyledMediumPlusTextField = styled(TextField)({
    id:"outlined-normal",
    width:"31%",
    marginRight:"15px",
    marginBottom:bottomMargins
});

const StyledLargeTextField = styled(TextField)({
    id:"outlined-normal",
    width:"46%",
    marginRight:"15px",
    marginBottom:bottomMargins
});

const StyledAutocomplete = styled(Autocomplete)({
    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
      fontSize: autoCompleteFontSize,
    },
    width:"15%",
    marginRight:"15px",
    marginBottom:bottomMargins
});

const StyledDivider = styled(Divider)({
    marginTop:"10px"
});

const StyledDiv = styled('div')({
    display:"flex", 
    marginTop:"5px"
});

const Option = styled('li')({
    fontSize: componentGridFontSize,
  });

const MyComponentCols = ['Balance', 'Chem ID', 'Description', 'Locate Sample', 'MIDAS #','Alt. MIDAS #', 'Units',  'Target', 'Add Order', 'Instructions', 'Add Temp (C)']
const MyDestinations = ['Internal', 'Shipping']
const BlendNames = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']

const batchComponentCols = ['ID', 'MIDAS #', 'Container', 'Chem ID', 'Substituation Reason', 'Actual Amount',  'Order Added', 'Actual Temp (C)']

const BlendsAndBatches = ({ myStudy, setMyStudy, errorChecks, setErrorChecks, defaultBlendRequest, defaultBlendRequestErrorCheck, defaultBlendComponent, 
                            defaultBlendComponentErrorCheck, defaultBatch, defaultBatchErrorCheck, GetTotalNeededForTesting }) => {

    const currentUser = useContext(UserContext)
    const [tabValue, setTabValue] = useState(0)
    const [tabValueBatch, setTabValueBatch] = useState(0)
    const [blendComponentIndex, setBlendComponentIndex] = useState(0)

    const [availableProductGroups, setAvailableProductGroups] = useState([])
    const [availableUOMs, setAvailableUOMs] = useState([])
    const [availableLocations, setAvailableLocations] = useState([])
    const [availableBlendPriorities, setAvailableBlendPriorities] = useState([])
    const [availableBlendFacilities, setAvailableBlendFacilities] = useState([])

    const [modalInstructionsOpen, setModalInstructionsOpen] = useState(false)
    const [modalInstructionsButton1Text, setModalInstructionsButton1Text] = useState('')
    const [modalInstructionsButton2Text, setModalInstructionsButton2Text] = useState('')
    const [modalInstructionsTitle, setModalInstructionsTitle] = useState('')
    const [modalInstructionsText, setModalInstructionsText] = useState('')
    const [tempComponentInstruction, setTempComponentInstruction] = useState('')
    const [tempComponentIndex, setTempComponentIndex] = useState(-1)

    const [modalDupeBatchOpen, setModalDupeBatchOpen] = useState(false)
    const [modalDupeBatchButton1Text, setModalDupeBatchButton1Text] = useState('')
    const [modalDupeBatchButton2Text, setModalDupeBatchButton2Text] = useState('')
    const [modalDupeBatchTitle, setModalDupeBatchTitle] = useState('')
    const [modalDupeBatchText, setModalDupeBatchText] = useState('')

    const [modalDupeBatchTestingOpen, setModalDupeBatchTestingOpen] = useState(false)
    const [modalDupeBatchTestingButton1Text, setModalDupeBatchTestingButton1Text] = useState('')
    const [modalDupeBatchTestingButton2Text, setModalDupeBatchTestingButton2Text] = useState('')
    const [modalDupeBatchTestingTitle, setModalDupeBatchTestingTitle] = useState('')
    const [modalDupeBatchTestingText, setModalDupeBatchTestingText] = useState('')

    const [modalTestsOpen, setModalTestsOpen] = useState(false)
    const [modalTestsButton1Text, setModalTestsButton1Text] = useState('')
    const [modalTestsButton2Text, setModalTestsButton2Text] = useState('')
    const [modalTestsTitle, setModalTestsTitle] = useState('')
    const [modalTestsText, setModalTestsText] = useState('')

    const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
    const modalMessagesButtonText = 'Ok'
    const [modalMessagesTitle, setModalMessagesTitle] = useState('');
    const [modalMessagesText, setModalMessagesText] = useState('');

    const [modalDeleteAttachOpen, setModalDeleteAttachOpen] = useState(false);
    const [modalDeleteAttachButton1Text, setModalDeleteAttachButton1Text] = useState('');
    const [modalDeleteAttachButton2Text, setModalDeleteAttachButton2Text] = useState('');
    const [modalDeleteAttachTitle, setModalDeleteAttachTitle] = useState('');
    const [modalDeleteAttachText, setModalDeleteAttachText] = useState('');

    const [tempCopyToBlendName, setTempCopyToBlendName] = useState(null)
    const [tempCopyToBlendHasErrors, setTempCopyToBlendHasErrors] = useState(false)
    const [duplicateType, setDuplicateType] = useState(null)

    const [tempCopyToBatchNumber, setTempCopyToBatchNumber] = useState(null)
    const [tempCopyToBatchNumberHasErrors, setTempCopyToBatchNumberHasErrors] = useState(false)

    const [modalCompareBlendsOpen, setModalCompareBlendsOpen] = useState(false)

    const [modalLocateMIDASNumberOpen, setModallocateMIDASNumberOpen] = useState(false)

    const [anchorEl, setAnchorEl] = useState(PopulateAnchorEl());
    const [anchorElTesting, setAnchorElTesting] = useState(PopulateAnchorElTesting());

    const [tempSelectedMethods, setTempSelectedMethods] = useState(myStudy.blendRequests[tabValue].batches[tabValueBatch].analyticalTestingObject)

    const roles = currentUser?.idTokenClaims.roles;
    const isDeveloper = hasRole(Roles.Developer, roles);
    const isBlendMaintainer = hasRole(Roles.BlendMaintainer, roles);

    //disable the add batch and blend buttons if you arent the owner
    const userDisabled = (myStudy.ownerEmail !== currentUser.username && myStudy.ownerEmail !== '' && myStudy.ownerEmail !== null && isDeveloper === false && isBlendMaintainer === false)

    //determines when to disable the reqeuests and batches
    const masterDisableRequests = (myStudy.blendRequests[tabValue].blendStatusName !== 'Submitted' 
                                    && myStudy.blendRequests[tabValue].blendStatusName !== 'Needs Review' 
                                    && myStudy.blendRequests[tabValue].blendStatusName !== 'Migrated' 
                                    && myStudy.blendRequests[tabValue].blendStatusName !== 'InProgress' 
                                    && myStudy.blendRequests[tabValue].blendStatusName !== 'OnHold' 
                                    && myStudy.blendRequests[tabValue].blendStatusName !== '' 
                                    && myStudy.blendRequests[tabValue].blendStatusName !== null) || userDisabled

    const masterDisableComponents = (myStudy.blendRequests[tabValue].blendStatusName !== 'Submitted' 
                                    && myStudy.blendRequests[tabValue].blendStatusName !== 'Needs Review' 
                                    && myStudy.blendRequests[tabValue].blendStatusName !== '' 
                                    && myStudy.blendRequests[tabValue].blendStatusName !== null) || userDisabled

    const masterDisableBatch = (myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName !== 'Submitted' 
                                    && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName !== 'Needs Review' 
                                    && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName !== 'Migrated' 
                                    && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName !== '' 
                                    && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName !== null) || userDisabled

    const [attachmentDeleteOptions, setAttachmentDeleteOptions] = useState(null)

  function PopulateAnchorEl ()
  {
    let newAnchorEl = []

    for(var i = 0; i <  myStudy.blendRequests.length; i++) 
    {
        newAnchorEl.push({batch:[null]})
    }    
    return newAnchorEl
  }

  function PopulateAnchorElTesting ()
  {
    let newAnchorEl = []

    for(var i = 0; i <  myStudy.blendRequests.length; i++) 
    {
        newAnchorEl.push(null)
    }    
    return newAnchorEl
  }
   //get all the enums on render
  useEffect(() => {
        let cancelPromise = false

        BlendProductGroup.getAllBlendProductGroups().then((res) => {
            if (cancelPromise) return
            setAvailableProductGroups(res.filter(result => result.isActive === true).sort((a, b) => a.name.localeCompare(b.name)))
        });

        BlendPriority.getAllBlendPriorities().then((res) => {
            if (cancelPromise) return
            setAvailableBlendPriorities(res.filter(result => result.isActive === true).sort((a, b) => a.blendPriorityName.localeCompare(b.blendPriorityName)))
        });

        BlendFacility.getAllFacilities().then((res) => {
            if (cancelPromise) return
            setAvailableBlendFacilities(res.filter(result => result.isActive === true).sort((a, b) => a.blendFacilityName.localeCompare(b.blendFacilityName)))
        });

        UnitOfMeasure.getAll().then((res) => {
            if (cancelPromise) return
            setAvailableUOMs(res.filter(result => ((result.type === 'weight' || result.type === 'volume') && result.metricStandardConversion !== null && result.isActive === true) || (result.uoMName === 'wt%' || result.uoMName ==='vol%')).sort((a, b) => a.uoMName.localeCompare(b.uoMName)))
        // || result.type === 'blending-weight' || result.type === 'blending-volume'
        });
           
        Location.getAll().then((res) => {
            if (cancelPromise) return
            setAvailableLocations(res.filter(result => result.isActive === true).sort((a, b) => a.locationName.localeCompare(b.locationName)))
        });
        
        return () => {
            cancelPromise = true
        }

  }, [])

  function closeModalDeleteAttach() {
    setModalDeleteAttachOpen(false);
  }

  function openModalDeleteAttach(title, text, buttonText, button2Text) {
    setModalDeleteAttachButton1Text(buttonText)
    setModalDeleteAttachButton2Text(button2Text)
    setModalDeleteAttachOpen(true);
    setModalDeleteAttachTitle(title);
    setModalDeleteAttachText(text);
  }

  function closeModalInstructions() {
    setModalInstructionsOpen(false);
    setTempComponentInstruction('')
    setTempComponentIndex(-1)
  }

  function openModalInstructions(title, text, buttonText, button2Text, componentIndex) {
    setModalInstructionsButton1Text(buttonText)
    setModalInstructionsButton2Text(button2Text)
    setModalInstructionsOpen(true);
    setModalInstructionsTitle(title);
    setModalInstructionsText(text);

    setTempComponentIndex(componentIndex)
    setTempComponentInstruction(myStudy.blendRequests[tabValue].blendComponents[componentIndex].componentInstructions)
  }

  function closeModalDupeBatch() {
    setModalDupeBatchOpen(false);
  }

  function openModalDupeBatch(title, text, buttonText, button2Text) {
    setModalDupeBatchButton1Text(buttonText)
    setModalDupeBatchButton2Text(button2Text)
    setModalDupeBatchOpen(true);
    setModalDupeBatchTitle(title);
    setModalDupeBatchText(text);
  }

  function closeModalDupeBatchTesting() {
    setModalDupeBatchTestingOpen(false);
  }

  function openModalDupeBatchTesting(title, text, buttonText, button2Text) {
    setModalDupeBatchTestingButton1Text(buttonText)
    setModalDupeBatchTestingButton2Text(button2Text)
    setModalDupeBatchTestingOpen(true);
    setModalDupeBatchTestingTitle(title);
    setModalDupeBatchTestingText(text);
  }

  function HandleCancelButtonOnTestModal()
  {
    setTempSelectedMethods([])
    closeModalTests()
  }

  function closeModalTests() {
    setModalTestsOpen(false);
  }

  function openModalTests(title, text, buttonText, button2Text) {
    setTempSelectedMethods(myStudy.blendRequests[tabValue].batches[tabValueBatch].analyticalTestingObject)

    setModalTestsButton1Text(buttonText)
    setModalTestsButton2Text(button2Text)
    setModalTestsOpen(true);
    setModalTestsTitle(title);
    setModalTestsText(text);
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
    setTabValueBatch(0)
  };

  const handleTabChangeBatches = (event, newValue) => {
    setTabValueBatch(newValue);
  };

  function updateErrorChecks_BlendComponent (property, value, componentIndex)
  {
    const copyErrors = structuredClone(errorChecks)

    copyErrors.blendRequests[tabValue].blendComponents[componentIndex][property] = value

    if (property === 'preferredSample')
    {
        if (myStudy.blendRequests[tabValue].blendComponents[componentIndex].preferredSample?.substance !== null)
        {
            copyErrors.blendRequests[tabValue].blendComponents[componentIndex].substance = false      
        }
    }

    setErrorChecks(copyErrors)
  }

  function updateErrorChecks_Batch (property, value)
  {
    const copyErrors = structuredClone(errorChecks)

    copyErrors.blendRequests[tabValue].batches[tabValueBatch][property] = value

    setErrorChecks(copyErrors)
  }

  function updateData (property, value)
  {
    const copyStudyData = structuredClone(myStudy)

    copyStudyData.blendRequests[tabValue][property] = value

    if (property === 'chargeCode')
    {
        updateErrorChecks(property, copyStudyData.blendRequests[tabValue][property] === "" || copyStudyData.blendRequests[tabValue][property] === null)
    }

    setMyStudy(copyStudyData)
  }

  function updateErrorChecks (property, value)
  {
    const copyErrors = structuredClone(errorChecks)

    copyErrors.blendRequests[tabValue][property] = value

    setErrorChecks(copyErrors)
  }

  function updateData_BlendComponent (property, value, componentIndex)
  {
    const copyStudyData = structuredClone(myStudy)

    copyStudyData.blendRequests[tabValue].blendComponents[componentIndex][property] = value

    if (property === 'preferredSample')
    {
        if (copyStudyData.blendRequests[tabValue].blendComponents[componentIndex].substance === null)
        {
            if (copyStudyData.blendRequests[tabValue].blendComponents[componentIndex].preferredSample !== null)
            {
                copyStudyData.blendRequests[tabValue].blendComponents[componentIndex].substance = copyStudyData.blendRequests[tabValue].blendComponents[componentIndex].preferredSample.substance 
            }
        }
    }

    if (property === 'substance')
    {
        if (copyStudyData.blendRequests[tabValue].blendComponents[componentIndex].preferredSample !== null)
        {
            copyStudyData.blendRequests[tabValue].blendComponents[componentIndex].preferredSample = null
        }
        if (copyStudyData.blendRequests[tabValue].blendComponents[componentIndex].secondaryPreferredSample !== null)
        {
            copyStudyData.blendRequests[tabValue].blendComponents[componentIndex].secondaryPreferredSample = null
        }
    }

    if (property === 'isBalance')
    {
        const copyErrors = structuredClone(errorChecks)

        if (value === true)
        {
            const balanceValue = GetBalanceValue(copyStudyData.blendRequests[tabValue].blendComponents)
            copyStudyData.blendRequests[tabValue].blendComponents[componentIndex].targetAmountLower = balanceValue

            copyErrors.blendRequests[tabValue].blendComponents[componentIndex].targetAmountLower = false

        } else {
            copyStudyData.blendRequests[tabValue].blendComponents[componentIndex].targetAmountLower = null
                        
            copyErrors.blendRequests[tabValue].blendComponents[componentIndex].targetAmountLower = null
        }
            
        setErrorChecks(copyErrors)
    }

    if (property === 'targetAmountLower')// || property === 'targetAmountUpper')
    {
        const copyErrors = structuredClone(errorChecks)

        copyStudyData.blendRequests[tabValue].blendComponents.forEach((oComponent, index) => 
        {
            if (oComponent.isBalance === true)
            {
                const balanceValue = GetBalanceValue(copyStudyData.blendRequests[tabValue].blendComponents)
  
                copyStudyData.blendRequests[tabValue].blendComponents[index].targetAmountLower = balanceValue
            }

            copyErrors.blendRequests[tabValue].blendComponents[index].targetAmountLower = EvaulateLowerAmountPercentages(oComponent, copyStudyData)    
        })

        setErrorChecks(copyErrors)
    }

    setMyStudy(copyStudyData)
  }

  function GetBalanceValue(arrComponents){
    let targetValue = 100
    const workingUOM = myStudy.blendRequests[tabValue].requestUoM

    arrComponents.forEach((oComponent) => {
      if(isNumber(oComponent.targetAmountLower))
      {
        if (oComponent.isBalance === false && workingUOM.uoMName === oComponent.componentUoM.uoMName)
        {
            targetValue = RoundNumber(targetValue - RoundNumber(oComponent.targetAmountLower, 3), 3)
        }
      }
    })

    return targetValue
  }

  function updateData_Batch (property, value, batchIndex)
  {
    const copyStudyData = structuredClone(myStudy)

    copyStudyData.blendRequests[tabValue].batches[batchIndex][property] = value

    setMyStudy(copyStudyData)
  }
 
  function AutoCompleteChangeAndValidate_Batch (property, value, batchIndex)
  {
    const copyMyStudy = structuredClone(myStudy)
    const copyErrors = structuredClone(errorChecks)

    copyMyStudy.blendRequests[tabValue].batches[batchIndex][property] = value
    copyErrors.blendRequests[tabValue].batches[batchIndex][property] = value === null ? true : false 
    
    if (property === 'blendPriority')
    {
        if (value)
        {
            if (value.blendPriorityName === 'Normal')
            {
                copyMyStudy.blendRequests[tabValue].batches[batchIndex].priorityReason = ''   
                copyErrors.blendRequests[tabValue].batches[batchIndex].priorityReason = false    
            } else {
                if (copyMyStudy.blendRequests[tabValue].batches[batchIndex].priorityReason.trim() === '')
                {
                    copyErrors.blendRequests[tabValue].batches[batchIndex].priorityReason = true
                } else {
                    copyErrors.blendRequests[tabValue].batches[batchIndex].priorityReason = false                    
                }
            }
        } else {
            copyMyStudy.blendRequests[tabValue].batches[batchIndex].priorityReason = ''   
            copyErrors.blendRequests[tabValue].batches[batchIndex].priorityReason = false         
        }
    }
    setMyStudy(copyMyStudy)
    setErrorChecks(copyErrors)
  }

  function EvaluatePriorityReason (batchIndex)
  {
    if (myStudy.blendRequests[tabValue].batches[batchIndex].blendPriority)
    {
       if (myStudy.blendRequests[tabValue].batches[batchIndex].priorityReason.trim() === '')
       {
           return true
       } else {
           return false
       }
    } else {
       return false          
    }
  }

  function AutoCompleteChangeAndValidate_BlendRequest (property, value)
  {
    const copyMyStudy = structuredClone(myStudy)
    const copyErrors = structuredClone(errorChecks)

    copyMyStudy.blendRequests[tabValue][property] = value
    copyErrors.blendRequests[tabValue][property] = value === null ? true : false 

    if (property === 'requestUoM')
    {
        for(var iComponent = 0; iComponent <  copyMyStudy.blendRequests[tabValue].blendComponents.length; iComponent++) {
            if (copyMyStudy.blendRequests[tabValue].blendComponents[iComponent].componentUoM !== null)
            {
                if (value === null)
                {
                    copyMyStudy.blendRequests[tabValue].blendComponents[iComponent].componentUoM = null
                    copyMyStudy.blendRequests[tabValue].blendComponents[iComponent].isBalance = false

                } else {
                    if (value.type !==  copyMyStudy.blendRequests[tabValue].blendComponents[iComponent].componentUoM.type)
                    {
                        copyMyStudy.blendRequests[tabValue].blendComponents[iComponent].componentUoM = null
                        copyMyStudy.blendRequests[tabValue].blendComponents[iComponent].isBalance = false
                    }
                }
            }
        }
    }

    setMyStudy(copyMyStudy)
    setErrorChecks(copyErrors)
  }

  function AutoCompleteChangeAndValidate_BlendComponent (property, value, componentIndex)
  {
    const copyMyStudy = structuredClone(myStudy)
    const copyErrors = structuredClone(errorChecks)

    copyMyStudy.blendRequests[tabValue].blendComponents[componentIndex][property] = value
    copyErrors.blendRequests[tabValue].blendComponents[componentIndex][property] = value === null ? true : false 

    if (property === 'componentUoM')
    {
        if (value === null)
        {
            copyMyStudy.blendRequests[tabValue].blendComponents[componentIndex].isBalance = false       
        }

        //copyMyStudy.blendRequests[tabValue].blendComponents[componentIndex].targetAmountUpper = null
        copyMyStudy.blendRequests[tabValue].blendComponents[componentIndex].targetAmountLower = null
                    
        //copyErrors.blendRequests[tabValue].blendComponents[componentIndex].targetAmountUpper = null
        copyErrors.blendRequests[tabValue].blendComponents[componentIndex].targetAmountLower = null
    }

    setMyStudy(copyMyStudy)
    setErrorChecks(copyErrors)
  }

  function GetAdditionOrderValues ()
  {
    let additionOrderValues = []

    for(var i = 0; i <  myStudy.blendRequests[tabValue].blendComponents.length; i++) {
       additionOrderValues.push(i + 1)
    }

    myStudy.blendRequests[tabValue].blendComponents.forEach(component => {
        if (component.addOrder && component.addOrder > 0)
        {
            additionOrderValues = additionOrderValues.filter(number => number !== component.addOrder)
        }
    });

    return additionOrderValues
  }

  function AddNewBlend (blendObject, errorChecksObject)
  {
    const newBlendObj = structuredClone(blendObject)
    const newErrorObj = structuredClone(errorChecksObject)

    const copyMyStudy = structuredClone(myStudy)
    const copyErrors = structuredClone(errorChecks)
    const copyAnchorEl = structuredClone(anchorEl)
    const copyAnchorElTesting = structuredClone(anchorElTesting)

    newBlendObj.id = 0
    newBlendObj.blendStatusName = null
    newBlendObj.blendSubstanceName = null
    newBlendObj.createdByEmail = ''
    newBlendObj.createdDate = null
    newBlendObj.lastModifiedByEmail = null
    newBlendObj.lastModifiedDate = null

    for(var x = 0; x <  newBlendObj.blendComponents.length; x++) 
    {
        newBlendObj.blendComponents[x].id = 0
        newBlendObj.blendComponents[x].blendID = 0
    }

    for(var z = 0; z <  newBlendObj.batches.length; z++) 
    {
        newBlendObj.batches[z].id = 0
        newBlendObj.batches[z].actualAmount = null
        newBlendObj.batches[z].actualAmountUoM = null
        newBlendObj.batches[z].blendID = 0
        newBlendObj.batches[z].batchStatusName = null
        newBlendObj.batches[z].batchCertificationStatusName = null
        newBlendObj.batches[z].batchComponents = null
        newBlendObj.batches[z].lastCertifiedByEmail = null
        newBlendObj.batches[z].lastCertifiedDate = null
        newBlendObj.batches[z].preparedByEmail = null
        newBlendObj.batches[z].preparedDate = null
        newBlendObj.batches[z].preparedSample = null
        newBlendObj.batches[z].preparedSampleName = null
        newBlendObj.batches[z].shelfLifeStartDate = null
        newBlendObj.batches[z].shelfLifeEndDate = null
        newBlendObj.batches[z].requestedCompletionDate = null
        newBlendObj.batches[z].lastModifiedByEmail = null
        newBlendObj.batches[z].lastModifiedDate = null
        newBlendObj.batches[z].createdByEmail = null
        newBlendObj.batches[z].createdDate = null
    }

    copyErrors.blendRequests.push(newErrorObj)
    copyAnchorEl.push({batch:[null]})
    copyAnchorElTesting.push(null)

    const newestBlend = myStudy.blendRequests.sort(function(a, b) {
        return b.blendName.length - a.blendName.length || b.blendName.localeCompare(a.blendName)
      }).slice(0, 1).shift();

    if (newestBlend.blendName.length > 2)
    {
        openModalMessages("Blend Error", "Cannot go past blend ZZ!")
        return
    }

    if (newestBlend.blendName.length > 1)
    {
        var arr = newestBlend.blendName.match(/.{1}/g)

        var firstLetter = arr[0];
        var secondLetter = arr[1];

        if (secondLetter === 'Z')
        {
            newBlendObj.blendName = `${BlendNames[BlendNames.indexOf(firstLetter) + 1]}${BlendNames[0]}`;
        } else {
            newBlendObj.blendName = `${BlendNames[BlendNames.indexOf(firstLetter)]}${BlendNames[BlendNames.indexOf(secondLetter) + 1]}`;
        }
    }
    else
    { 
        if (newestBlend.blendName === 'Z')
        {
            newBlendObj.blendName = `${BlendNames[0]}${BlendNames[0]}`;
        } else {
            newBlendObj.blendName = `${BlendNames[BlendNames.indexOf(newestBlend.blendName) + 1]}`;
        }
    }

    copyMyStudy.blendRequests.push(newBlendObj)

    setMyStudy(copyMyStudy)
    setErrorChecks(copyErrors)
    setAnchorEl(copyAnchorEl)
    setAnchorElTesting(copyAnchorElTesting)

    setTabValue(copyMyStudy.blendRequests.length -1)
    setTabValueBatch(0)
  }

  function AddNewComponent ()
  {
    const newBlendComponentObj = structuredClone(defaultBlendComponent)
    const newErrorObj = structuredClone(defaultBlendComponentErrorCheck)

    const copyMyStudy = structuredClone(myStudy)
    const copyErrors = structuredClone(errorChecks)

    copyMyStudy.blendRequests[tabValue].blendComponents.push(newBlendComponentObj)
    copyErrors.blendRequests[tabValue].blendComponents.push(newErrorObj)

    setMyStudy(copyMyStudy)
    setErrorChecks(copyErrors)
  }

  function AddNewBatch (batchObject, errorChecksObject, blendIndex)
  {
    const newBatchObj = structuredClone(batchObject)
    const newErrorObj = structuredClone(errorChecksObject)

    const copyMyStudy = structuredClone(myStudy)
    const copyErrors = structuredClone(errorChecks)
    const copyAnchorEl = structuredClone(anchorEl)

    newBatchObj.id = 0
    newBatchObj.actualAmount = null
    newBatchObj.actualAmountUoM = null
    newBatchObj.blendID = 0
    newBatchObj.batchStatusName = null
    newBatchObj.batchCertificationStatusName = null
    newBatchObj.batchComponents = null
    newBatchObj.lastCertifiedByEmail = null
    newBatchObj.lastCertifiedDate = null
    newBatchObj.preparedByEmail = null
    newBatchObj.preparedDate = null
    newBatchObj.preparedSample = null
    newBatchObj.preparedSampleName = null
    newBatchObj.shelfLifeStartDate = null
    newBatchObj.shelfLifeEndDate = null
    newBatchObj.requestedCompletionDate = null
    newBatchObj.lastModifiedByEmail = null
    newBatchObj.lastModifiedDate = null
    newBatchObj.createdByEmail = null
    newBatchObj.createdDate = null

    const newestBatch = myStudy.blendRequests[blendIndex].batches.sort((a, b) => b.batchSequenceNumber - a.batchSequenceNumber).slice(0, 1).shift();

    newBatchObj.batchSequenceNumber = newestBatch.batchSequenceNumber + 1;

    copyMyStudy.blendRequests[blendIndex].batches.push(newBatchObj)
    copyErrors.blendRequests[blendIndex].batches.push(newErrorObj)
    copyAnchorEl[blendIndex].batch.push(null)

    setMyStudy(copyMyStudy)
    setErrorChecks(copyErrors)
    setTabValue(blendIndex)
    setTabValueBatch(copyMyStudy.blendRequests[blendIndex].batches.length -1)
    setAnchorEl(copyAnchorEl)
  }

  function DeleteBlend ()
  {
    const copyMyStudy = structuredClone(myStudy)
    const copyErrors = structuredClone(errorChecks)
    const copyAnchorEl = structuredClone(anchorEl)
    const copyAnchorElTesting = structuredClone(anchorElTesting)

    copyMyStudy.blendRequests.splice(tabValue, 1)
    copyErrors.blendRequests.splice(tabValue, 1)
    copyAnchorEl.splice(tabValue, 1)
    copyAnchorElTesting.splice(tabValue, 1)

    let i = 0
    for(i = tabValue; i < copyMyStudy.blendRequests.length; i++)
    {
        //set the blend name for everything downstream
        copyMyStudy.blendRequests[i].blendName = BlendNames[i]
    }

    setMyStudy(copyMyStudy)
    setErrorChecks(copyErrors)
    setTabValue(0)
    setTabValueBatch(0)
    setAnchorEl(copyAnchorEl)
    setAnchorElTesting(copyAnchorElTesting)
  }

  function DeleteComponent (componentIndex)
  {
    const copyMyStudy = structuredClone(myStudy)
    const copyErrors = structuredClone(errorChecks)

    copyMyStudy.blendRequests[tabValue].blendComponents.splice(componentIndex, 1)
    copyErrors.blendRequests[tabValue].blendComponents.splice(componentIndex, 1)

    setMyStudy(copyMyStudy)
    setErrorChecks(copyErrors)
  }

  function DeleteBatch (batchIndex)
  {
    const copyMyStudy = structuredClone(myStudy)
    const copyErrors = structuredClone(errorChecks)
    const copyAnchorEl = [...anchorEl]

    copyMyStudy.blendRequests[tabValue].batches.splice(batchIndex, 1)
    copyErrors.blendRequests[tabValue].batches.splice(batchIndex, 1)
    copyAnchorEl[tabValue].batch.splice(batchIndex, 1)

    let i = 0
    for(i = tabValue; i < copyMyStudy.blendRequests[tabValue].batches.length; i++)
    {
        //set the blend name for everything downstream
        copyMyStudy.blendRequests[tabValue].batches[i].batchSequenceNumber = i + 1
    }

    setMyStudy(copyMyStudy)
    setErrorChecks(copyErrors)
    setTabValueBatch(0)
    setAnchorEl(copyAnchorEl)
  }

  function EvaulateLowerAmountPercentages (oComponent, study)
  {
    const value = oComponent.targetAmountLower
    const workingUOM = study.blendRequests[tabValue].requestUoM

    if (workingUOM === null)
    {
        return true
    }

    if (value === null || value === '') 
    {
        return false
    } else
    {
        let numberCheck = isNumber(value)
        let totalPercentage = 0

        if (numberCheck)
        {
            if (RoundNumber(value, 3) < 0)
            {
                return true
            }

            study.blendRequests[tabValue].blendComponents.forEach((oComponent) => {
                if(isNumber(oComponent.targetAmountLower)  && workingUOM.uoMName === oComponent.componentUoM?.uoMName)
                {
                    totalPercentage = RoundNumber(totalPercentage + RoundNumber(oComponent.targetAmountLower, 3), 3)
                    //totalPercentage = RoundNumber(totalPercentage + RoundNumber(oComponent.isBalance ? oComponent.targetAmountUpper : oComponent.targetAmountLower, 3), 3)
                }
            })

            //check to make sure the lower is not greater than the upper
            // if (isNumber(oComponent.targetAmountUpper))
            // {
            //     if (RoundNumber(value, 3) > RoundNumber(oComponent.targetAmountUpper, 3)) 
            //     {
            //         return true
            //     }
            // }

            if (isNumber(oComponent.targetAmountLower))
            {
                if (workingUOM.uoMName !== oComponent.componentUoM?.uoMName){
                    return false
                }
            } else {
                return true 
            }

            return(totalPercentage > 100)
        }
        else
        {
            return(!(numberCheck))
        }
    }

  }

  function SaveOrUpdateComponentInstructions ()
  {
      updateData_BlendComponent('componentInstructions', tempComponentInstruction, tempComponentIndex)
      closeModalInstructions()
  }

  function SetBatchStatus (batchID, newBatchStatusName)
  {
    Batch.updateBatchStatus(batchID, newBatchStatusName, '').then((res) => {

        if (res.message === 'Success') {
          openModalMessages('Batch Update Complete', `Batch set to ${newBatchStatusName} state!`)

          const copyMyStudy = structuredClone(myStudy)

          copyMyStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName = newBatchStatusName
          copyMyStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatus = {batchStatusName: newBatchStatusName, isActive: true , displayOrder: 0}

          setMyStudy(copyMyStudy)
        }
        else{
          if (res.message)
          {
            openModalMessages('Batch Update Failed', `${res.message}. Contact support if you feel this is an error.`);
          } else {
            openModalMessages('Batch Update Failed', `Unspecified Error, Contact support if you feel this is an error.`);
          }
            
        }
    });
  }

  function ConfirmDuplicateBatch ()
  {
      if ((!(tempCopyToBlendHasErrors) && tempCopyToBlendName !== null && duplicateType !== 'Testing') || (!(tempCopyToBatchNumberHasErrors) && tempCopyToBatchNumber !== null && duplicateType === 'Testing')) 
      {
        if (duplicateType === 'Batch')
        {
            const myIndex = myStudy.blendRequests.findIndex(obj => obj.blendName === tempCopyToBlendName.blendName)

            AddNewBatch(myStudy.blendRequests[tabValue].batches[tabValueBatch], errorChecks.blendRequests[tabValue].batches[tabValueBatch], myIndex)

        } else if (duplicateType === 'Testing')
        {
            const myIndex = myStudy.blendRequests[tabValue].batches.findIndex(obj => obj.batchSequenceNumber === tempCopyToBatchNumber.batchSequenceNumber)

            const copyMyStudy = structuredClone(myStudy)

            copyMyStudy.blendRequests[tabValue].batches[myIndex].analyticalTestingObject = copyMyStudy.blendRequests[tabValue].batches[tabValueBatch].analyticalTestingObject

            setMyStudy(copyMyStudy)

        } else if (duplicateType === 'Components')
        {
            const myIndex = myStudy.blendRequests.findIndex(obj => obj.blendName === tempCopyToBlendName.blendName)

            const copyMyStudy = structuredClone(myStudy)
            const copyErrors = structuredClone(errorChecks)

            const newBlendComponentObj = structuredClone(copyMyStudy.blendRequests[tabValue].blendComponents)
            const newErrorObj = structuredClone(errorChecks.blendRequests[tabValue].blendComponents)         
        
            copyMyStudy.blendRequests[myIndex].blendComponents = newBlendComponentObj
            copyErrors.blendRequests[myIndex].blendComponents = newErrorObj
        
            setMyStudy(copyMyStudy)
            setErrorChecks(copyErrors)
        }
       
        closeModalDupeBatch()
        closeModalDupeBatchTesting()

        setTempCopyToBlendName(null)
        setTempCopyToBatchNumber(null)
      }     
  }

  const openMenuTesting = () => {
    return anchorElTesting[tabValue] != null
  }

  const handleClickMenuTesting = (event) => {
    const newAnchorEl = [...anchorElTesting]

    newAnchorEl[tabValue] = event.currentTarget
    
    setAnchorElTesting(newAnchorEl)
  }

  const handleCloseMenuTesting = (e) => {
    const newAnchorEl = [...anchorElTesting]

    newAnchorEl[tabValue] = null
    
    setAnchorElTesting(newAnchorEl)
  }

  const openMenu = () => {
    return anchorEl[tabValue].batch[tabValueBatch] != null
  }

  const handleClickMenu = (event) => {
    const newAnchorEl = [...anchorEl]

    newAnchorEl[tabValue].batch[tabValueBatch] = event.currentTarget
    
    setAnchorEl(newAnchorEl)
  }

  const handleCloseMenu = (e) => {
    const newAnchorEl = [...anchorEl]

    newAnchorEl[tabValue].batch[tabValueBatch] = null
    
    setAnchorEl(newAnchorEl)
  }

  function ConfirmTestsSelection ()
  {
    let errorMessages = []

    //check the method data for completness
    tempSelectedMethods.forEach((oMethod) =>
    {
        if (oMethod.method === null)
        {
            errorMessages.push('You must select a method for all entries.')
        }

        if (oMethod.method.isPackageTest === false)
        {
            if (oMethod.methodFacility === null)
            {
                errorMessages.push('You must select a method facility for all entries.')   
            } else {
                if (oMethod.methodFacility.requiresConditions)
                {
                    if (oMethod.conditionOffering === null)
                    {
                        errorMessages.push(`A condition is required for method ${oMethod.method.name} and method facility ${oMethod.methodFacility.testFacilityAbv}`)  
                    } else {
                    oMethod.conditionOffering.testConditionSetupValues.forEach((oTCSV, index) => {
                        if (oTCSV.discreteValue === null || oTCSV.discreteValue.trim() === '')
                        {
                            errorMessages.push(`A user defined condition value is required for method ${oMethod.method.name} and method facility ${oMethod.methodFacility.testFacilityAbv}`) 
                        }
                    })
                    }

                    if (oMethod.methodFacility.testFacilityAbv === 'EXT')
                    {
                        if(oMethod.externalLaboratory === null)
                        {
                            errorMessages.push(`An external lab selection is required for method ${oMethod.method.name} and method facility ${oMethod.methodFacility.testFacilityAbv}`)                  
                        }
                    }
                }

                if (oMethod.methodFacility.requiresEstimates)
                {
                    if (oMethod.estimate.trim() === '')
                    {
                        errorMessages.push(`An estimate is required for method ${oMethod.method.name} and method facility ${oMethod.methodFacility.testFacilityAbv}`)  
                    }
                }
            }

            if (oMethod.testPriority === null)
            {
                errorMessages.push('You must select a test priority for all entries.')
            }

            var today = new Date()
            var formattedDate = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`

            var comparDate = new Date(formattedDate)
            var reqDate = new Date(oMethod.requestedCompletionDate)

            if (oMethod.requestedCompletionDate && reqDate < comparDate){
                errorMessages.push(`Requested completion date for ${oMethod.method.name} cannot be a future date`)
            }
        }   
    })

    if (errorMessages.length === 0)
    {
        const copyMyStudy = structuredClone(myStudy)

        copyMyStudy.blendRequests[tabValue].batches[tabValueBatch].analyticalTestingObject = tempSelectedMethods
        //copyMyStudy.blendRequests[tabValue].analyticalTestingObject = tempSelectedMethods

        setMyStudy(copyMyStudy)
        setModalTestsOpen(false)
    } else {
        openModalMessages(errorsModalTitle, errorMessages, "Ok")
    }

  }

  function DetermineNewStateToSet ()
  {
    let newState = ''

    if ((myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedByEmail !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedByEmail !== '') && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchComponents.length > 0)
    {
        newState = 'InProgress'
    } else if (myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedByEmail !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedByEmail !== '')
    {
        newState = 'Assigned'
    } else {
        newState = 'Submitted'
    }

    return newState
  }

  function HandleFileAdd (newFiles) {
    const listFiles = newFiles.map(item => {
        return {
            id: 0,
            blendID: myStudy.blendRequests[tabValue].id,
            fileName: item.name,
            storagePath: '',
            fileObject: item,
            base64String: null
        }
    })

    updateData('blendAttachments', myStudy.blendRequests[tabValue].blendAttachments.concat(listFiles))
  }

  function deleteFile () {
    let newFiles

    closeModalDeleteAttach()

    if (attachmentDeleteOptions === null)
    { 
        return
    }

    const property = attachmentDeleteOptions.property

    if (property === 'blendAttachments')
    {
        if (attachmentDeleteOptions.file.id > 0)
        {
            Attachments.deleteBlendAttachment(attachmentDeleteOptions.file.id).then((res) => {
                if (res && res.message === 'Success') {
                    newFiles = myStudy.blendRequests[tabValue].blendAttachments.filter(f => f !== attachmentDeleteOptions.file)            
                    setAttachmentDeleteOptions(null)
                    updateData(property, newFiles)  

                    openModalMessages("Attachment Deleted", `Attachment successfully deleted.`) 
                } else {
                    openModalMessages("Error Removing Attachment", `${res ? res.message : 'Error removing attachment'}.  Please try again or contact support.`)
                    return
                }
            })
        } else {
            newFiles = myStudy.blendRequests[tabValue].blendAttachments.filter(f => f !== attachmentDeleteOptions.file)  
            setAttachmentDeleteOptions(null)
            updateData(property, newFiles)   
        }        
    } else if (property === 'batchAttachments')
    {
        if (attachmentDeleteOptions.file.id > 0)
        {
            Attachments.deleteBatchAttachment(attachmentDeleteOptions.file.id).then((res) => {
                if (res && res.message === 'Success') {
                    newFiles = myStudy.blendRequests[tabValue].batches[tabValueBatch].batchAttachments.filter(f => f !== attachmentDeleteOptions.file)     
                    setAttachmentDeleteOptions(null)
                    updateData_Batch(property, newFiles, tabValueBatch)       
                } else {
                    openModalMessages("Error Removing Attachment", `${res ? res.message : 'Error removing attachment'}.  Please try again or contact support.`)
                    return
                }
            })
        } else {
            newFiles = myStudy.blendRequests[tabValue].batches[tabValueBatch].batchAttachments.filter(f => f !== attachmentDeleteOptions.file)    
            setAttachmentDeleteOptions(null)
            updateData_Batch(property, newFiles, tabValueBatch)
        }  
    }
  }

  function HandleFileDownload (file) {
    downloadFile('blendrequestfiles', file.fileName, file.storagePath, openModalMessages)
  }

  function HandleFileAddBatch (newFiles) {
    const listFiles = newFiles.map(item => {
        return {
          id: 0,
          batchID: myStudy.blendRequests[tabValue].batches[tabValueBatch].id,
          fileName: item.name,
          storagePath: '',
          fileObject: item,
          base64String: null
        }
    })

    updateData_Batch('batchAttachments', myStudy.blendRequests[tabValue].batches[tabValueBatch].batchAttachments.concat(listFiles), tabValueBatch)
  }

  function HandleFileDownloadBatch (file) {
    downloadFile('batchfiles', file.fileName, file.storagePath, openModalMessages)
  }

  const ConfirmRemoveAttachmentBlend = (file) => {  
    setAttachmentDeleteOptions({property:'blendAttachments', file:file}) 
    openModalDeleteAttach(`Remove Attachment?`, "Are you sure you want to permanently remove the attachment?", "Yes", "No")
  }

  const ConfirmRemoveAttachmentBatch = (file) => {   
    setAttachmentDeleteOptions({property:'batchAttachments', file:file})
    openModalDeleteAttach(`Remove Attachment?`, "Are you sure you want to permanently remove the attachment?", "Yes", "No")
  }

  const SumOfBlendTargets = () => {

    let sum = 0;

    for(var iComponent = 0; iComponent <  myStudy.blendRequests[tabValue].blendComponents.length; iComponent++) 
    {
        if (isNumber(myStudy.blendRequests[tabValue].blendComponents[iComponent].targetAmountLower) && myStudy.blendRequests[tabValue].blendComponents[iComponent].componentUoM 
        && (myStudy.blendRequests[tabValue].blendComponents[iComponent].componentUoM.uoMName === 'wt%' || myStudy.blendRequests[tabValue].blendComponents[iComponent].componentUoM.uoMName === 'vol%'))
        {
            sum = sum + Number(myStudy.blendRequests[tabValue].blendComponents[iComponent].targetAmountLower)
        }
    }

    return RoundNumber(sum, 2);
  }

  return (
    <div style={{marginTop:"0px"}}>

    <StyledDiv>
    {/* <GlobalTabCollection style={{width: myStudy.blendRequests.length > 10 ? "60%" :  `${myStudy.blendRequests.length * 6}%` }} value={tabValue} onChange={handleTabChange} variant={myStudy.blendRequests.length > 10 ? "scrollable":"standard"} scrollButtons="auto" aria-label='ant example'> */}
        <GlobalTabCollection style={{width: myStudy.blendRequests.length > 10 ? "60%" :  `${myStudy.blendRequests.length * 6}%` }} value={tabValue} onChange={handleTabChange} variant={"scrollable"} scrollButtons="auto" aria-label='ant example'>
            {myStudy.blendRequests.map((oBlend, index) => {
                return(
                    <StyledTab key={index}
                    label={`Blend ${oBlend.blendName}`} />
                )
            })}
             
        </GlobalTabCollection>

        <Box>
             <GlobalButton 
                disabled={userDisabled}
                variant="contained" 
                style={{ textTransform: "none", marginTop:"-5px", marginLeft:"15px", height:"30px"}}
                onClick={e => {AddNewBlend(defaultBlendRequest, defaultBlendRequestErrorCheck)}}
            >Add New Blend</GlobalButton>
        </Box>
    </StyledDiv>

        <div style={{display:"flex"}}>
             <p style={{
               fontFamily:"EMprint",
               fontWeight:"600",
               fontSize:"16px",
               color:"#545459",
               textTransform: 'none',
               textAlign:"left",
               paddingTop:"30px"
              }}>Blend Details</p>

                <Box display="flex" alignItems={"center"} marginLeft="auto" marginRight="100px">

                    <Tooltip title={"Copy Blend"} placement="bottom">
                        <ContentCopy style={{
                            paddingBottom:"5px",
                            paddingLeft:"15px",
                            fontSize:40,
                            marginTop:"10px"
                        }}
                        onClick={e => {AddNewBlend(myStudy.blendRequests[tabValue], errorChecks.blendRequests[tabValue])}}
                        ></ContentCopy>
                    </Tooltip>

                {myStudy.blendRequests[tabValue].id === 0 && myStudy.blendRequests.length > 1 ? //&& tabValue > 0
                (
                    <Tooltip title={"Delete Blend"} placement="bottom">
                        <TrashIcon style={{
                            paddingBottom:"5px",
                            paddingLeft:"15px",
                            fontSize:40,
                            marginTop:"10px"
                        }}
                        onClick={()=> {DeleteBlend()}}                  
                        ></TrashIcon>
                    </Tooltip>
                 ) : (<></>)
                } 
                </Box>
        </div>

    {/* Blend Details */}
    <div>
        <StyledDiv>
            <StyledMediumTextField size="small" margin="normal" variant="outlined" label="* Description" InputLabelProps={{shrink: true}}
                disabled={masterDisableRequests}
                onChange={e => {updateData('description', e.target.value)}}
                value={myStudy.blendRequests[tabValue].description === null ? '' : myStudy.blendRequests[tabValue].description}
                error = {errorChecks.blendRequests[tabValue].description === null ? false : errorChecks.blendRequests[tabValue].description}       
                onBlur={e => {updateErrorChecks('description', myStudy.blendRequests[tabValue].description === "" || myStudy.blendRequests[tabValue].description === null)}}
                helperText = {errorChecks.blendRequests[tabValue].description ? helperLabel : ''}
            ></StyledMediumTextField>

            <StyledMediumPlusTextField size="small" margin="normal" variant="outlined" label="Purpose" InputLabelProps={{shrink: true}}
                disabled={masterDisableRequests}
                onChange={e => {updateData('blendPurpose', e.target.value)}}
                value={myStudy.blendRequests[tabValue].blendPurpose === null ? '' : myStudy.blendRequests[tabValue].blendPurpose}
            ></StyledMediumPlusTextField>

            <StyledAutocomplete
                disabled={masterDisableRequests}
                disablePortal
                noOptionsText={"Loading Groups..."}
                options={availableProductGroups}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => AutoCompleteChangeAndValidate_BlendRequest("blendProductGroup", value)}
                autoHighlight
                autoSelect
                value={myStudy.blendRequests[tabValue].blendProductGroup}
                isOptionEqualToValue={(option, value) => value.name === option.name}
                renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="* Product Group" 
                error={errorChecks.blendRequests[tabValue].blendProductGroup === null ? false : errorChecks.blendRequests[tabValue].blendProductGroup}
                helperText = {errorChecks.blendRequests[tabValue].blendProductGroup ? helperLabel : ''}
                InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
            />

            <StyledShortTextField size="small" margin="normal" variant="outlined" label="CMA Oil Code" InputLabelProps={{shrink: true}}
                disabled={masterDisableRequests}
                inputProps={{ maxLength: 32 }}
                onChange={e => {updateData('cmaOilCode', e.target.value)}}
                value={myStudy.blendRequests[tabValue].cmaOilCode === null ? '' : myStudy.blendRequests[tabValue].cmaOilCode}
            >
            </StyledShortTextField>

        </StyledDiv>

        <StyledDiv>
            <StyledMediumTextField size="small" margin="normal" variant="outlined" label="Safety Information" InputLabelProps={{shrink: true}}
                disabled={masterDisableRequests}
                onChange={e => {updateData('warning', e.target.value)}}
                value={myStudy.blendRequests[tabValue].warning === null ? '' : myStudy.blendRequests[tabValue].warning}
            ></StyledMediumTextField>

            <StyledShortTextField size="small" margin="normal" variant="outlined" label="ProMIS R Code" InputLabelProps={{shrink: true}}
                disabled={masterDisableRequests}
                inputProps={{ maxLength: 30 }}
                onChange={e => {updateData('proMISRCode', e.target.value)}}
                value={myStudy.blendRequests[tabValue].proMISRCode === null ? '' : myStudy.blendRequests[tabValue].proMISRCode}
            ></StyledShortTextField>

            {/* <StyledShortTextField size="small" margin="normal" variant="outlined" label="* Cost Code" InputLabelProps={{shrink: true}}
                disabled={masterDisableRequests}
                onChange={e => {updateData('chargeCode', e.target.value)}}
                value={myStudy.blendRequests[tabValue].chargeCode === null ? '' : myStudy.blendRequests[tabValue].chargeCode}
                error = {errorChecks.blendRequests[tabValue].chargeCode === null ? false : errorChecks.blendRequests[tabValue].chargeCode}       
                onBlur={e => {updateErrorChecks('chargeCode', myStudy.blendRequests[tabValue].chargeCode === "" || myStudy.blendRequests[tabValue].chargeCode === null)}}
                helperText = {errorChecks.blendRequests[tabValue].chargeCode ? helperLabel : ''}
            ></StyledShortTextField> */}

            <BillingInfoField
                billingValue={myStudy.blendRequests[tabValue].chargeCode}
                handleChange={value => {
                    updateData('chargeCode', value);
                }}
                //isDisabled={masterDisableRequests}
                isDisabled={userDisabled}
                margin="normal"
                marginLeft="0px"
                marginRight="15px"
                fieldWidth="15%"
                hasErrors={errorChecks.blendRequests[tabValue].chargeCode === null ? false : errorChecks.blendRequests[tabValue].chargeCode}
                helperText="JADE is Required"        
                required       
            >
            </BillingInfoField>

            <StyledShortTextField size="small" margin="normal" variant="outlined" label="Reference" InputLabelProps={{shrink: true}}
                disabled={masterDisableRequests}
                onChange={e => {updateData('reference', e.target.value)}}
                value={myStudy.blendRequests[tabValue].reference === null ? '' : myStudy.blendRequests[tabValue].reference}
            ></StyledShortTextField>
        </StyledDiv>

        <StyledDiv>
            <StyledLargeTextField size="small" margin="normal" variant="outlined" label="Add Keywords" InputLabelProps={{shrink: true}}
                disabled={masterDisableRequests}
                onChange={e => {updateData('keywords', e.target.value)}}
                value={myStudy.blendRequests[tabValue].keywords === null ? '' : myStudy.blendRequests[tabValue].keywords}
            ></StyledLargeTextField>

            <StyledLargeTextField multiline size="small" margin="normal" variant="outlined" label="Blend Comments" InputLabelProps={{shrink: true}}
                disabled={masterDisableRequests}
                onChange={e => {updateData('blendComments', e.target.value)}}
                value={myStudy.blendRequests[tabValue].blendComments === null ? '' : myStudy.blendRequests[tabValue].blendComments}
            ></StyledLargeTextField>
        </StyledDiv>

        <StyledDiv>
            <FormControlLabel control={<Checkbox checked={myStudy.blendRequests[tabValue].isMetalsFree}
                disabled={masterDisableRequests}
                onChange={e => {updateData('isMetalsFree', e.target.checked)}}
            ></Checkbox>} label="Metals Free"/>

            <FormControlLabel control={<Checkbox checked={myStudy.blendRequests[tabValue].isISOBlend}
                disabled={masterDisableRequests}
                onChange={e => {updateData('isISOBlend', e.target.checked)}}
            ></Checkbox>} label="ISO Blend"/>
        </StyledDiv>

        <div style={{ display: "flex", width: "46%" }}>
            <FileDragAndDrop files={myStudy.blendRequests[tabValue].blendAttachments} disabled={masterDisableRequests} showFiles={true} handleFiles={HandleFileAdd} handleDeleteFile={(file) => ConfirmRemoveAttachmentBlend(file)} handleFileDownload={HandleFileDownload}></FileDragAndDrop>
        </div>

        {formatCreatedAndModifiedDateInfo(myStudy.blendRequests[tabValue].createdDate === null ? 'N/A' : myStudy.blendRequests[tabValue].createdDate, 
                                myStudy.blendRequests[tabValue].createdByEmail === null ||  myStudy.blendRequests[tabValue].createdByEmail.trim() === '' ? 'N/A' : myStudy.blendRequests[tabValue].createdByEmail,
                                myStudy.blendRequests[tabValue].lastModifiedDate === null ? 'N/A' : myStudy.blendRequests[tabValue].lastModifiedDate, 
                                myStudy.blendRequests[tabValue].lastModifiedByEmail === null || myStudy.blendRequests[tabValue].lastModifiedByEmail.trim() === '' ? 'N/A' : myStudy.blendRequests[tabValue].lastModifiedByEmail)}
    </div>

        <StyledDivider></StyledDivider>

        <StyledDiv>
            <p style={{
               fontFamily:"EMprint",
               fontWeight:"600",
               fontSize:"16px",
               color:"#545459",
               textTransform: 'none',
               textAlign:"left",
               paddingTop:"20px"
              }}>Blend Design</p>
        </StyledDiv>

    {/* Blend Type */}
        <StyledDiv>
            <GlobalButton 
                variant="contained" 
                style={{ marginTop:"20px", marginLeft:"15px", marginRight:"20px"}}
                onClick={() => setModalCompareBlendsOpen(true)}
            >Compare All Blends
            </GlobalButton>

            {/* <GlobalSecondaryButton 
                variant='contained'
                style={{ marginTop:"20px", marginRight:"20px" }}
                onClick={(e) => openModalTests("Select Test Methods for Blend", "You can select the methods from scratch or use an existing template", 'Confirm', 'Cancel')}
            >{masterDisableRequests ? 'View Testing' : 'Add Testing to Blend'}
            </GlobalSecondaryButton> */}

            <StyledAutocomplete
                disabled={masterDisableComponents}
                disablePortal
                noOptionsText={"Loading UoMs..."}
                options={availableUOMs.filter(result => result.uoMName === 'wt%' || result.uoMName ==='vol%').sort((a, b) => a.uoMName.localeCompare(b.uoMName))}
                getOptionLabel={(option) => option.uoMName}
                onChange={(e, value) => AutoCompleteChangeAndValidate_BlendRequest("requestUoM", value)}
                autoHighlight
                autoSelect
                value={myStudy.blendRequests[tabValue].requestUoM}
                isOptionEqualToValue={(option, value) => value.uoMName === option.uoMName}
                renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="* Measurement" 
                error={errorChecks.blendRequests[tabValue].requestUoM === null ? false : errorChecks.blendRequests[tabValue].requestUoM}
                helperText = {errorChecks.blendRequests[tabValue].requestUoM ? helperLabel : ''}
                InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
            />

            <Typography style={{marginTop:"20px"}} fontSize={16}>
                {`Current Cumulative Target: ${SumOfBlendTargets()}%`}
            </Typography>

            <Box display="flex" alignItems={"center"} marginLeft="auto" marginRight="100px">
                <SettingsIcon style={{
                    paddingTop:"10px",
                    paddingLeft:"15px",
                    fontSize:40,
                    marginTop:"10px"
                    }}
                    id="gearicon"
                    onClick={e => {handleClickMenuTesting(e, tabValue)}}
                >
                </SettingsIcon>
                    <Menu
                        key={`TestingMenu${tabValue}`}
                        anchorEl={anchorElTesting[tabValue]}
                        open={openMenuTesting()}
                        onClose={(e) => handleCloseMenuTesting(e)}    
                    >
                        {/* <MenuItem 
                            key={`menuItem${tabValue}~DupeTestsToAntoherBlend`}
                            onClick={() => {openModalDupeBatch("Select Blends", "Select which blend you want to dupliate this testing on to.", "Save", "Cancel"); handleCloseMenuTesting(); setDuplicateType('Testing')}}
                        >Duplicate Testing to Another Blend</MenuItem> */}

                        <MenuItem 
                            key={`menuItem${tabValue}~DupeComponentsToAnotherBlend`}
                            onClick={() => {openModalDupeBatch("Select Blends", "Select which blend you want to duplicate the components to.", "Save", "Cancel"); handleCloseMenuTesting(); setDuplicateType('Components')}}
                        >Duplicate Components to Another Blend</MenuItem>
                    </Menu>
            </Box>
        </StyledDiv>

    {/* Blend Components */}
        <StyledDiv>
            
            <UXDataTableWithoutBody
            tableWidth='95%' 
            cols={MyComponentCols} 
            blankFirstHeader = {true}
            tableId= {`componentTable${tabValue}`}
            rowLength={myStudy.blendRequests[tabValue].blendComponents.length}
            enablePaging = {false}
            rowsPerPage={0}
            page={0}
            handleChangePage={null}
            handleChangeRowsPerPage={null}
            noDataFoundMessage={NoComponentsMessage}
            enableAddIcon = {!(masterDisableComponents)}
            addFunction={() => AddNewComponent()}
            addToolTipText="Add new component"
            isDataLoading={false}
            >
            
            {myStudy.blendRequests[tabValue].blendComponents.map((oComponent, index) => {

            return(
                <StyledTableBody key={`ComponentsTable${tabValue}~${index}`}>
                    <TableRow> 
                        <StyledTableCell component='th' scope='row'>

                    {index > 0 && oComponent.id === 0 &&
                        <Tooltip title="Delete Component" placement="right">
                            <TrashIcon onClick ={() => DeleteComponent(index)}>
                            </TrashIcon>
                        </Tooltip>
                    }       
                        </StyledTableCell>
                        <StyledTableCell>
                            <FormControlLabel style={{marginLeft:"5px", width:"20px"}} 
                                control={<Checkbox 
                                    disabled={masterDisableComponents || 
                                            (myStudy.blendRequests[tabValue].blendComponents.filter(result => result.isBalance === true).length > 0 && oComponent.isBalance === false) ||
                                            oComponent.componentUoM === null || (oComponent.componentUoM?.uoMName !== 'wt%' && oComponent.componentUoM?.uoMName !== 'vol%')} 
                                    checked={oComponent.isBalance}
                                onChange={e => {updateData_BlendComponent('isBalance', e.target.checked, index)}}
                            ></Checkbox>} label=""/>
                         </StyledTableCell>

                        {/* CHEM ID */}
                        <StyledTableCell align='left'>
                            <ChemIDSearchField 
                                fontSize = {12}
                                fieldWidth = {"175px"} 
                                selectedChemIDObject = {oComponent.substance}
                                setSelectedChemIDObject = {(e) => updateData_BlendComponent('substance', e, index)}
                                hasErrors = {errorChecks.blendRequests[tabValue].blendComponents[index].substance === null ? false : errorChecks.blendRequests[tabValue].blendComponents[index].substance}
                                setHasErrors = {(e) => updateErrorChecks_BlendComponent('substance', e, index)}
                                isDisabled={masterDisableComponents}
                                allowInactiveChemIDs={false}
                                returnSubstanceStrucureData={false}
                            ></ChemIDSearchField>
                        </StyledTableCell> 

                        {/* Description */}
                        <StyledTableCell style={{width:"200px", fontSize: componentGridFontSize}} align='left'>{oComponent.substance !== null ?  oComponent.substance.substanceName : ''}</StyledTableCell>

                        {/* Locate Midas number */}
                        <TableCell align='center' style={{width:"75px"}}>
                            {oComponent.substance !== null && !masterDisableComponents &&
                                <ManageSearchIcon 
                                    onClick={() => {setModallocateMIDASNumberOpen(true); setBlendComponentIndex(index)}}>
                                </ManageSearchIcon>
                            }
                        </TableCell>

                        {/* MIDAS Number */}
                        <StyledTableCell align='left'>
                            <ValidatedMidasNumberTextField
                                isDisabled = {masterDisableComponents}
                                margin = {"none"}
                                showLabel = {true}
                                fontSize = {12}
                                fieldWidth = {"150px"}
                                midasNumberObject = {oComponent.preferredSample}
                                setMidasNumberObject = {(e) => updateData_BlendComponent('preferredSample', e, index)}
                                hasErrors = {errorChecks.blendRequests[tabValue].blendComponents[index].preferredSample === null ? false : errorChecks.blendRequests[tabValue].blendComponents[index].preferredSample}
                                setHasErrors = {(e) => updateErrorChecks_BlendComponent('preferredSample', e, index)}
                                chemIDToMatch = {oComponent.substance ? oComponent.substance.chemID : null}
                            ></ValidatedMidasNumberTextField>
                        </StyledTableCell>

                        {/* MIDAS Number Alt */}
                        <StyledTableCell align='left'>
                            <ValidatedMidasNumberTextField
                                isDisabled = {oComponent.substance === null || masterDisableComponents}
                                margin = {"none"}
                                showLabel = {true}
                                fontSize = {12}
                                fieldWidth = {"150px"}
                                midasNumberObject = {oComponent.secondaryPreferredSample}
                                setMidasNumberObject = {(e) => updateData_BlendComponent('secondaryPreferredSample', e, index)}
                                hasErrors = {errorChecks.blendRequests[tabValue].blendComponents[index].secondaryPreferredSample === null ? false : errorChecks.blendRequests[tabValue].blendComponents[index].secondaryPreferredSample}
                                setHasErrors = {(e) => updateErrorChecks_BlendComponent('secondaryPreferredSample', e, index)}
                                chemIDToMatch = {oComponent.substance ? oComponent.substance.chemID : null}
                            ></ValidatedMidasNumberTextField>
                        </StyledTableCell>

                        {/* component UOM */}
                        <StyledTableCell align='left'>
                            <StyledAutocomplete
                                renderOption={(props2, option) => (
                                    <Option {...props2}>{option.uoMName}</Option>
                                    )} 
                                disabled = {oComponent.substance === null || myStudy.blendRequests[tabValue].requestUoM === null || masterDisableComponents}
                                disablePortal
                                noOptionsText={"Loading UoMs..."}
                                options={(myStudy.blendRequests[tabValue].requestUoM && myStudy.blendRequests[tabValue].requestUoM.uoMName === 'wt%') ?
                                    availableUOMs.filter(result => result.type === 'weight').sort((a, b) => a.uoMName.localeCompare(b.uoMName)) :
                                    availableUOMs.filter(result => result.type ==='volume').sort((a, b) => a.uoMName.localeCompare(b.uoMName))}
                                onChange={(e, value) => AutoCompleteChangeAndValidate_BlendComponent("componentUoM", value, index)}
                                getOptionLabel={(option) => option.uoMName}
                                value={oComponent.componentUoM}
                                isOptionEqualToValue={(option, value) => value.uoMName === option.uoMName}
                                autoHighlight
                                autoSelect
                                style={{marginTop:"3px"}}
                                renderInput={(params) => <TextField {...params} style={{width:"140px", marginTop:"3px"}} variant="outlined" size="small" 
                                error={errorChecks.blendRequests[tabValue].blendComponents[index].componentUoM === null ? false : errorChecks.blendRequests[tabValue].blendComponents[index].componentUoM}
                                helperText = {errorChecks.blendRequests[tabValue].blendComponents[index].componentUoM ? helperLabel : ''}
                                inputProps={{ ...params.inputProps,  style: { fontSize: componentGridFontSize }}} InputProps={{ ...params.InputProps }}/>}  
                            />
                        </StyledTableCell>
       
                        {/* Target Amount */}
                        <StyledTableCell align='left'>
                            <TextField style={{width:"75px", marginTop:"5px"}}
                                disabled = {oComponent.substance === null || masterDisableComponents || oComponent.isBalance || myStudy.blendRequests[tabValue].requestUoM === null}
                                size="small"
                                margin="dense" 
                                variant="outlined"
                                inputProps={{ style: { fontSize: componentGridFontSize} }}
                                InputLabelProps={{shrink: true}}
                                value = {oComponent.targetAmountLower === null ? '' : oComponent.targetAmountLower}
                                onChange = {e => {updateData_BlendComponent('targetAmountLower', e.target.value, index)}}
                                error = {errorChecks.blendRequests[tabValue].blendComponents[index].targetAmountLower === null ? false : errorChecks.blendRequests[tabValue].blendComponents[index].targetAmountLower}
                                onBlur={e => {updateErrorChecks_BlendComponent('targetAmountLower', EvaulateLowerAmountPercentages(oComponent, myStudy), index)}}
                                helperText = {errorChecks.blendRequests[tabValue].blendComponents[index].targetAmountLower ? helperLabelNumber : ''}
                            />
                        </StyledTableCell>

                         {/* Upper Amount */}
                         {/* <StyledTableCell align='left'>
                            <TextField style={{width:"75px", marginTop:"5px"}}
                                disabled = {oComponent.substance === null || masterDisableRequests || oComponent.isBalance || myStudy.blendRequests[tabValue].requestUoM === null}
                                size="small"
                                margin="dense" 
                                variant="outlined"
                                inputProps={{ style: { fontSize: componentGridFontSize} }}
                                InputLabelProps={{shrink: true}}
                                value = {oComponent.targetAmountUpper === null ? '' : oComponent.targetAmountUpper}
                                onChange = {e => {updateData_BlendComponent('targetAmountUpper', e.target.value, index)}}
                                error = {errorChecks.blendRequests[tabValue].blendComponents[index].targetAmountUpper === null ? false : errorChecks.blendRequests[tabValue].blendComponents[index].targetAmountUpper}
                                onBlur={e => {updateErrorChecks_BlendComponent('targetAmountUpper', EvaulateMaxUpperAmountPercentages(oComponent, myStudy), index)}}
                                helperText = {errorChecks.blendRequests[tabValue].blendComponents[index].targetAmountUpper ? helperLabelNumber : ''}
                            />
                        </StyledTableCell> */}

                        {/* Addition Order */}
                        <StyledTableCell align='left'>
                            <StyledAutocomplete
                                renderOption={(props2, option) => (
                                    <Option {...props2}>{option}</Option>
                                    )} 
                                disabled = {oComponent.substance === null || masterDisableComponents}
                                disablePortal
                                options={GetAdditionOrderValues()}
                                onChange={(e, value) => updateData_BlendComponent("addOrder", value, index)}
                                getOptionLabel={(option) => option.toString()}
                                value={oComponent.addOrder}
                                isOptionEqualToValue={(option, value) => value === option}
                                autoHighlight
                                autoSelect
                                style={{marginTop:"3px"}}
                                renderInput={(params) => <TextField {...params} style={{width:"75px", marginTop:"3px"}} variant="outlined" size="small" 
                                error = {false}  
                                inputProps={{ ...params.inputProps,  style: { fontSize: componentGridFontSize }}} InputProps={{ ...params.InputProps }}/>} 
                                />
                        </StyledTableCell>

                        {/* Instructions */}
                        <TableCell align='center'>
                            {oComponent.substance !== null && !masterDisableComponents &&
                                <IntegrationInstructionsIcon 
                                    onClick={() => openModalInstructions("View/Edit Component Instructions", "You can add/edit an existing set of instructions for this component from here.", "Save", "Cancel", index)}>
                                </IntegrationInstructionsIcon>
                            }
                        </TableCell>

                        {/* Addition Temp */}
                        <StyledTableCell align='left'>
                            <TextField style={{width:"100px", marginTop:"5px"}}
                                disabled = {oComponent.substance === null || masterDisableComponents}
                                size="small"
                                margin="dense" 
                                variant="outlined"
                                inputProps={{ style: { fontSize: componentGridFontSize} }}
                                InputLabelProps={{shrink: true}}
                                value = {oComponent.blendAdditionTemperatureC === null ? '' : oComponent.blendAdditionTemperatureC}
                                onChange = {e => {updateData_BlendComponent('blendAdditionTemperatureC', e.target.value, index)}}
                                error = {errorChecks.blendRequests[tabValue].blendComponents[index].blendAdditionTemperatureC === null ? false : errorChecks.blendRequests[tabValue].blendComponents[index].blendAdditionTemperatureC}
                                onBlur={e => {updateErrorChecks_BlendComponent('blendAdditionTemperatureC', EvaluateOptionalNumberField(oComponent.blendAdditionTemperatureC), index)}}
                                helperText = {errorChecks.blendRequests[tabValue].blendComponents[index].blendAdditionTemperatureC ? helperLabelNumber : ''}
                            />
                        </StyledTableCell>
                    </TableRow>
                    
                </StyledTableBody>
            )
            })}
            </UXDataTableWithoutBody>
        </StyledDiv>

        <StyledDivider></StyledDivider>

    {/* Batch Information */}
        <div style={{marginTop:"20px"}}>
        <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }} display="flex">
            <GlobalTabCollection style={{width: myStudy.blendRequests[tabValue].batches.length > 10 ? "80%" :  `${myStudy.blendRequests[tabValue].batches.length * 8}%` }} value={tabValueBatch} onChange={handleTabChangeBatches} variant={"scrollable"} scrollButtons="auto" aria-label='ant example'>
                {myStudy.blendRequests[tabValue].batches.map((oBatch, index) => {
                    return(
                        <StyledTab key={`${tabValue}~${index}`}
                        label={`Batch ${oBatch.batchSequenceNumber}`} />
                        // label={`Batch ${oBatch.batchSequenceNumber} - ${oBatch.batchStatusName === null || oBatch.batchStatusName === '' ? 'N/A' : oBatch.batchStatusName}`} />
                    )
                })}      
            </GlobalTabCollection>

                <Box>
                    <GlobalButton
                        disabled={userDisabled}
                        variant="contained" 
                        style={{marginTop:"-5px", marginLeft:"15px"}}
                        onClick={() => AddNewBatch(defaultBatch, defaultBatchErrorCheck, tabValue)}
                    >Add New Batch</GlobalButton>

                    <GlobalSecondaryButton 
                        variant='contained'
                        style={{ marginTop:"-5px", marginLeft:"15px" }}
                        onClick={(e) => openModalTests("Select Test Methods for Batch", "You can select the methods from scratch or use an existing template", 'Confirm', 'Cancel')}
                    >{masterDisableBatch ? 'View Testing' : 'Add Testing to Batch'}
                    </GlobalSecondaryButton>
                </Box>
        </Box>

            <StyledDiv>
                <p style={{
                fontFamily:"EMprint",
                fontWeight:"600",
                fontSize:"16px",
                color:"#545459",
                textTransform: 'none',
                textAlign:"left",
                paddingTop:"20px"
                }}>{`Batch Information - ${myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName === null || myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName === '' ? 'N/A' : myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName}`}</p>

            <Typography style={{marginTop:"20px", marginLeft:"1rem"}} fontSize={16}>
                {`Min batch size for testing: ${GetTotalNeededForTesting(tabValue, tabValueBatch, myStudy.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested)} ${myStudy.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested ? myStudy.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested.uoMName : 'g'}`}

            </Typography>

                <Box display="flex" alignItems={"center"} marginLeft="auto" marginRight="100px">
                    <SettingsIcon style={{
                        paddingBottom:"5px",
                        paddingLeft:"15px",
                        fontSize:40,
                        marginTop:"10px"
                        }}
                        id="gearicon"
                        onClick={e => {handleClickMenu(e, tabValue, tabValueBatch)}}
                    >
                    </SettingsIcon>
                </Box>

                <Menu
                    key={`batch-menu${tabValue}~${tabValueBatch}`}
                    anchorEl={anchorEl[tabValue].batch[tabValueBatch]}
                    open={openMenu()}
                    onClose={(e) => handleCloseMenu(e)}    
                    >
                    <MenuItem 
                        key={`menuItem${tabValue}~${tabValueBatch}~DupeToOther`}
                        onClick={() => {handleCloseMenu(); openModalDupeBatch("Select Blends", "Select which blend you want to dupliate this batch onto.", "Save", "Cancel"); setDuplicateType('Batch')}}
                    >Duplicate Batch to Other Blends</MenuItem>

                    {myStudy.blendRequests[tabValue].id === 0 &&
                        <MenuItem 
                            key={`menuItem${tabValue}~${tabValueBatch}~DupeToSelf`}
                            onClick={() => {handleCloseMenu(); AddNewBatch(myStudy.blendRequests[tabValue].batches[tabValueBatch], errorChecks.blendRequests[tabValue].batches[tabValueBatch], tabValue)}}
                        >Duplicate Batch to this Blend</MenuItem>
                    }

                    <MenuItem 
                        key={`menuItem${tabValue}~DupeTestsToAnotherBatch`}
                        onClick={() => {openModalDupeBatchTesting("Select Batch", "Select which batch you want to dupliate this testing on to.", "Save", "Cancel"); handleCloseMenu(); setDuplicateType('Testing')}}
                    >Duplicate Testing to Another Batch</MenuItem>

                    {myStudy.blendRequests[tabValue].batches[tabValueBatch].id === 0 && myStudy.blendRequests[tabValue].batches.length > 1 ? //tabValueBatch > 0 ?
                    (
                    <MenuItem
                        key={`menuItem${tabValue}~${tabValueBatch}~Delete`}
                        onClick={() => {handleCloseMenu(); DeleteBatch(tabValueBatch)}}
                    >Delete Batch</MenuItem>
                    ) : ([])
                    }
                    <MenuItem 
                        disabled={!(myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatusName === 'Needs Review')}
                        key={`menuItem${tabValue}~${tabValueBatch}~UpdateStatusToSubmitted`}
                        onClick={() => {handleCloseMenu(); SetBatchStatus(myStudy.blendRequests[tabValue].batches[tabValueBatch].id, DetermineNewStateToSet())}}
                    >{`Set Batch to ${DetermineNewStateToSet()}`}</MenuItem>
                </Menu>

            </StyledDiv>
        </div>

        <div>
            <StyledDiv>
                <StyledShortTextField size="small" margin="normal" variant="outlined" label="* Target Amount" InputLabelProps={{shrink: true}}
                    disabled = {masterDisableBatch}
                    onChange={e => {updateData_Batch('requestedAmount', e.target.value, tabValueBatch)}}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedAmount === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedAmount}
                    error = {errorChecks.blendRequests[tabValue].batches[tabValueBatch].requestedAmount === null ? false : errorChecks.blendRequests[tabValue].batches[tabValueBatch].requestedAmount}       
                    onBlur={e => {updateErrorChecks_Batch('requestedAmount', !(isNumber(myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedAmount)))}}
                    helperText = {errorChecks.blendRequests[tabValue].batches[tabValueBatch].requestedAmount ? helperLabelNumber : ''}
                >
                </StyledShortTextField>

                <StyledAutocomplete
                    disabled = {masterDisableBatch}
                    disablePortal
                    options={[0, 1, 2, 3, 4]}
                    getOptionLabel={(option) => option.toString()}
                    onChange={(e, value) => {AutoCompleteChangeAndValidate_Batch('batchPrecision', value, tabValueBatch)}}
                    autoHighlight
                    autoSelect
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].batchPrecision}
                    isOptionEqualToValue={(option, value) => value === option}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label = "* Precision" 
                        error={errorChecks.blendRequests[tabValue].batches[tabValueBatch].batchPrecision === null ? false : errorChecks.blendRequests[tabValue].batches[tabValueBatch].batchPrecision}
                        helperText = {errorChecks.blendRequests[tabValue].batches[tabValueBatch].batchPrecision ? helperLabel : ''}
                    InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />

                {/* <StyledShortTextField size="small" margin="normal" variant="outlined" label="Precision (decimals for rounding)" InputLabelProps={{shrink: true}}
                    disabled = {masterDisableBatch}
                    onChange={e => {updateData_Batch('batchPrecision', e.target.value, tabValueBatch)}}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].batchPrecision === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].batchPrecision}
                    error = {errorChecks.blendRequests[tabValue].batches[tabValueBatch].batchPrecision === null ? false : errorChecks.blendRequests[tabValue].batches[tabValueBatch].batchPrecision}       
                    onBlur={e => {updateErrorChecks_Batch('batchPrecision', EvaluateOptionalNumberField(myStudy.blendRequests[tabValue].batches[tabValueBatch].batchPrecision))}}
                    helperText = {errorChecks.blendRequests[tabValue].batches[tabValueBatch].batchPrecision ? helperLabelNumber : ''}
                ></StyledShortTextField> */}

                <StyledAutocomplete
                    disabled = {masterDisableBatch}
                    disablePortal
                    noOptionsText={"Loading UoMs..."}
                    options={availableUOMs}
                    getOptionLabel={(option) => option.uoMName}
                    onChange={(e, value) => {AutoCompleteChangeAndValidate_Batch('unitOfMeasureRequested', value, tabValueBatch)}}
                    autoHighlight
                    autoSelect
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested}
                    isOptionEqualToValue={(option, value) => value.uoMName === option.uoMName}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label = "* UoM" 
                        error={errorChecks.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested === null ? false : errorChecks.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested}
                        helperText = {errorChecks.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested ? helperLabel : ''}
                    InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        disabled = {masterDisableBatch}
                        size="small"
                        inputFormat="MM/dd/yyyy"
                        label="Requested Completion Date"
                        onChange={(e) => {updateData_Batch('requestedCompletionDate', e, tabValueBatch)}}
                        minDate={new Date()}
                        value={myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedCompletionDate}
                        renderInput={(params) => <StyledShortTextField onKeyDown={DatePickerKeyDownEvent} {...params} margin="normal" variant="outlined" size="small" InputLabelProps={{shrink: true}} inputProps={{ ...params.inputProps }} InputProps={{ ...params.InputProps }}/>} 
                    />
                </LocalizationProvider>

                <StyledAutocomplete
                    disabled = {masterDisableBatch}
                    disablePortal
                    noOptionsText={"Loading Locations..."}
                    options={availableLocations}
                    getOptionLabel={(option) => option.locationName}
                    onChange={(e, value) => {AutoCompleteChangeAndValidate_Batch('batchRetainLocation', value, tabValueBatch)}}
                    autoHighlight
                    autoSelect
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].batchRetainLocation}
                    isOptionEqualToValue={(option, value) => value.locationName === option.locationName}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="* Retain Location" 
                        error={errorChecks.blendRequests[tabValue].batches[tabValueBatch].batchRetainLocation === null ? false : errorChecks.blendRequests[tabValue].batches[tabValueBatch].batchRetainLocation}
                        helperText = {errorChecks.blendRequests[tabValue].batches[tabValueBatch].batchRetainLocation ? helperLabel : ''}
                    InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />

            </StyledDiv>

            <StyledDiv>

                <StyledAutocomplete
                    disabled = {masterDisableBatch}
                    disablePortal
                    noOptionsText={"Loading Priorities..."}
                    options={availableBlendPriorities}
                    getOptionLabel={(option) => option.blendPriorityName}
                    onChange={(e, value) => {AutoCompleteChangeAndValidate_Batch('blendPriority', value, tabValueBatch)}}
                    autoHighlight
                    autoSelect
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].blendPriority}
                    isOptionEqualToValue={(option, value) => value.blendPriorityName === option.blendPriorityName}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="* Priority" 
                        error={errorChecks.blendRequests[tabValue].batches[tabValueBatch].blendPriority === null ? false : errorChecks.blendRequests[tabValue].batches[tabValueBatch].blendPriority}
                        helperText = {errorChecks.blendRequests[tabValue].batches[tabValueBatch].blendPriority ? helperLabel : ''}
                    InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />
                <StyledLargeTextField size="small" margin="normal" variant="outlined" label="Reason for Priority" InputLabelProps={{shrink: true}}
                    inputProps={{ maxLength: 100 }}
                    onChange={e => {updateData_Batch('priorityReason', e.target.value, tabValueBatch)}}
                    onBlur={e => {updateErrorChecks_Batch('priorityReason', EvaluatePriorityReason(tabValueBatch))}}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].priorityReason === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].priorityReason}
                    error={errorChecks.blendRequests[tabValue].batches[tabValueBatch].priorityReason === null ? false : errorChecks.blendRequests[tabValue].batches[tabValueBatch].priorityReason}
                    helperText = {errorChecks.blendRequests[tabValue].batches[tabValueBatch].priorityReason ? helperLabel : ''}
                >
                </StyledLargeTextField>

                <StyledAutocomplete
                    disabled = {masterDisableBatch}
                    disablePortal
                    noOptionsText={"Loading Destinations..."}
                    options={MyDestinations}
                    getOptionLabel={(option) => option}
                    onChange={(e, value) => {AutoCompleteChangeAndValidate_Batch('destination', value, tabValueBatch)}}
                    autoHighlight
                    autoSelect
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].destination}
                    isOptionEqualToValue={(option, value) => value === option}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="* Destination" 
                        error={errorChecks.blendRequests[tabValue].batches[tabValueBatch].destination === null ? false : errorChecks.blendRequests[tabValue].batches[tabValueBatch].destination}
                        helperText = {errorChecks.blendRequests[tabValue].batches[tabValueBatch].destination ? helperLabel : ''}
                    InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />           

                <StyledAutocomplete
                    disabled = {masterDisableBatch}
                    disablePortal
                    noOptionsText={"Loading Faclities..."}
                    options={availableBlendFacilities}
                    getOptionLabel={(option) => option.blendFacilityName}
                    onChange={(e, value) => {AutoCompleteChangeAndValidate_Batch('blendFacility', value, tabValueBatch)}}
                    autoHighlight
                    autoSelect
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].blendFacility}
                    isOptionEqualToValue={(option, value) => value.blendFacilityName === option.blendFacilityName}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="* Blend Facility" 
                        error={errorChecks.blendRequests[tabValue].batches[tabValueBatch].blendFacility === null ? false : errorChecks.blendRequests[tabValue].batches[tabValueBatch].blendFacility}
                        helperText = {errorChecks.blendRequests[tabValue].batches[tabValueBatch].blendFacility ? helperLabel : ''}
                    InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />   
            </StyledDiv>

            <StyledDiv>
                <StyledLargeTextField size="small" margin="normal" variant="outlined" label="Comments" InputLabelProps={{shrink: true}}
                    multiline
                    onChange={e => {updateData_Batch('batchComments', e.target.value, tabValueBatch)}}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].batchComments === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].batchComments}
                ></StyledLargeTextField>

                <StyledShortTextField size="small" margin="normal" variant="outlined" label="Prepared MIDAS #" InputLabelProps={{shrink: true}}
                    disabled = {true}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedSampleName === null ? '' : formatMidasNumber(myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedSampleName)}
                ></StyledShortTextField>

                <StyledShortTextField size="small" margin="normal" variant="outlined" label="Prepared ChemID" InputLabelProps={{shrink: true}}
                    disabled = {true}
                    value={myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedSampleName === null ? '' : myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedSample.chemID}
                ></StyledShortTextField>
            </StyledDiv>

            <div style={{ display: "flex", width: "46%", marginTop:"5px" }}>
                <FileDragAndDrop files={myStudy.blendRequests[tabValue].batches[tabValueBatch].batchAttachments} disabled={masterDisableRequests} showFiles={true} handleFiles={HandleFileAddBatch} handleDeleteFile={ConfirmRemoveAttachmentBatch} handleFileDownload={HandleFileDownloadBatch}></FileDragAndDrop>
                {/* <FileDragAndDrop handleFiles={handleFiles} files={files.map((item) => item.file)} disabled={masterDisableRequests} showFiles={true} handleDeleteFile={(file) => deleteFile(file)}></FileDragAndDrop>                     */}
            </div>

            {myStudy.blendRequests[tabValue].batches[tabValueBatch].batchComponents && 
            <>
                <StyledDiv>
                <p style={{
                    fontFamily:"EMprint",
                    fontWeight:"600",
                    fontSize:"16px",
                    color:"#545459",
                    textTransform: 'none',
                    textAlign:"left",
                    paddingTop:"20px"
                    }}>{`Batch Component Information`}</p>
                </StyledDiv>

                 <StyledDiv>
                    <UXDataTableWithoutBody
                    tableWidth='95%' 
                    cols={batchComponentCols} 
                    blankFirstHeader = {false}
                    tableId= {`batchComponentTable${tabValue}_${tabValueBatch}`}
                    rowLength={myStudy.blendRequests[tabValue].batches[tabValueBatch].batchComponents ? myStudy.blendRequests[tabValue].batches[tabValueBatch].batchComponents.length - 1 : 0}
                    enablePaging = {false}
                    rowsPerPage={0}
                    page={0}
                    handleChangePage={null}
                    handleChangeRowsPerPage={null}
                    noDataFoundMessage={'No batch components found'}
                    enableAddIcon = {false}
                    addFunction={null}
                    addToolTipText="Add new component"
                    isDataLoading={false}
                    >
                    {myStudy.blendRequests[tabValue].batches[tabValueBatch].batchComponents.sort((a,b) => a.orderAdded - b.orderAdded).map((oBatchComponent, index) => {
                        return(
                            <StyledTableBody key={`batchComponentsTable${tabValue}_${tabValueBatch}~${index}`}>
                                <TableRow> 
                                    <StyledTableCell>{oBatchComponent.id}</StyledTableCell>
                                    <StyledTableCell>{formatMidasNumber(oBatchComponent.container.sampleName)}</StyledTableCell>
                                    <StyledTableCell>{oBatchComponent.container.containerNumber}</StyledTableCell>
                                    <StyledTableCell>{oBatchComponent.container.sample.chemID}</StyledTableCell>
                                    <StyledTableCell>{oBatchComponent.substitutionReason}</StyledTableCell>
                                    <StyledTableCell>{`${oBatchComponent.actualAmount} ${oBatchComponent.uoMName}`}</StyledTableCell>
                                    <StyledTableCell>{oBatchComponent.orderAdded}</StyledTableCell>
                                    <StyledTableCell>{oBatchComponent.actualTemperatureC}</StyledTableCell>
                                </TableRow>
                            </StyledTableBody>
                          
                    )})}
                    </UXDataTableWithoutBody>
                </StyledDiv>
            </>
            }
        </div>

        {/* just used for spacing at the bottom of the page */}
        <div style={{display:"flex", marginTop:"100px"}}>
        </div>

            <ModalTwoButtons title={modalInstructionsTitle} button1Text={modalInstructionsButton1Text} button1Action={SaveOrUpdateComponentInstructions} button2Text={modalInstructionsButton2Text} button2Action={closeModalInstructions} open={modalInstructionsOpen} setOpen={setModalInstructionsOpen}>
                <label>
                    {modalInstructionsText}
                </label>

                <div></div>
        
                <TextField
                    size="small"
                    multiline
                    label = "Instructions"
                    value = {tempComponentInstruction}
                    onChange = {e => {setTempComponentInstruction(e.target.value)}}
                    margin = "normal"
                    style={{width:500, marginTop:40}}
                    inputProps={{ maxLength: 250 }}
                ></TextField>  
            </ModalTwoButtons>

            <ModalTwoButtons title={modalDupeBatchTitle} button1Text={modalDupeBatchButton1Text} button1Action={ConfirmDuplicateBatch} button2Text={modalDupeBatchButton2Text} button2Action={closeModalDupeBatch} open={modalDupeBatchOpen} setOpen={setModalDupeBatchOpen}>
                <label>
                    {modalDupeBatchText}
                </label>

                <div></div>

                <Autocomplete
                    disablePortal
                    //options={myStudy.blendRequests.filter(e => e.id === 0 && e.blendName !== myStudy.blendRequests[tabValue].blendName)}
                    options={myStudy.blendRequests.filter(e => e.blendName !== myStudy.blendRequests[tabValue].blendName)}
                    getOptionLabel={(option) => `Blend ${option.blendName}`}
                    onChange={(e, value) => {setTempCopyToBlendName(value); value !== null ? setTempCopyToBlendHasErrors(false) : setTempCopyToBlendHasErrors(true)}}
                    autoHighlight
                    value={tempCopyToBlendName}
                    isOptionEqualToValue={(option, value) => value.blendName === option.blendName}
                    style={{width:"75%", marginTop:"10px"}}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="Blend Name" 
                    error={tempCopyToBlendHasErrors}
                    helperText = {tempCopyToBlendHasErrors ? helperLabel : ''}
                    InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />   
            </ModalTwoButtons>

            <ModalTwoButtons title={modalDupeBatchTestingTitle} button1Text={modalDupeBatchTestingButton1Text} button1Action={ConfirmDuplicateBatch} button2Text={modalDupeBatchTestingButton2Text} button2Action={closeModalDupeBatchTesting} open={modalDupeBatchTestingOpen} setOpen={setModalDupeBatchTestingOpen}>
                <label>
                    {modalDupeBatchTestingText}
                </label>

                <div></div>

                <Autocomplete
                    disablePortal
                    options={myStudy.blendRequests[tabValue].batches.filter(e => (e.batchStatusName === null || e.batchStatusName === 'Submitted') && e.batchSequenceNumber !== myStudy.blendRequests[tabValue].batches[tabValueBatch].batchSequenceNumber)}
                    getOptionLabel={(option) => `Batch ${option.batchSequenceNumber}`}
                    onChange={(e, value) => {setTempCopyToBatchNumber(value); value !== null ? setTempCopyToBatchNumberHasErrors(false) : setTempCopyToBatchNumberHasErrors(true)}}
                    autoHighlight
                    value={tempCopyToBatchNumber}
                    isOptionEqualToValue={(option, value) => value.batchSequenceNumber === option.batchSequenceNumber}
                    style={{width:"75%", marginTop:"10px"}}
                    renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="Batch Number" 
                    error={tempCopyToBatchNumberHasErrors}
                    helperText = {tempCopyToBatchNumberHasErrors ? helperLabel : ''}
                    InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
                />   
            </ModalTwoButtons>

            <ModalTwoButtons title={modalTestsTitle} button1Text={modalTestsButton1Text} button1Action={ConfirmTestsSelection} button2Text={modalTestsButton2Text} button2Action={HandleCancelButtonOnTestModal} open={modalTestsOpen} setOpen={setModalTestsOpen}>
                <label>
                    {modalTestsText}
                </label>

                <div style={{paddingTop:"30px"}}></div>
                <MethodSelection 
                    width={'1500px'} 
                    selectedMethods={tempSelectedMethods} 
                    setSelectedMethods={setTempSelectedMethods} 
                    showCompletionDate={false} 
                    showContainerGrouping={false}
                    incomingTemplate={null}
                    isReadOnly={masterDisableBatch}
                    showSearchTemplateName={!(masterDisableBatch)}
                    showEstimateAndPriority={true}
                    maxMethodsAllowed={999}
                >
                </MethodSelection>
            </ModalTwoButtons>

            {/* Informational Messages */}
            <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>

                {modalMessagesTitle === errorsModalTitle ?
                (modalMessagesText && modalMessagesText.map((text, index) => {
                    return (
                        <div style={{display:"flex"}} key={`mySamples${index}`}>
                            <label>
                            {text}
                            </label>
                        </div>
                    )
                    })
                )
                :
                ( <label>
                    {modalMessagesText}
                </label>
                )}      
            </ModalMessages>

            {/* Attachment Removal */}
            <ModalTwoButtons title={modalDeleteAttachTitle} button1Text={modalDeleteAttachButton1Text} button1Action={deleteFile} button2Text={modalDeleteAttachButton2Text} button2Action={closeModalDeleteAttach} open={modalDeleteAttachOpen} setOpen={setModalDeleteAttachOpen}>
                <label>
                    {modalDeleteAttachText}
                </label>

                <div></div>
            </ModalTwoButtons>

            <CompareBlends open={modalCompareBlendsOpen} setOpen={setModalCompareBlendsOpen} myBlends={myStudy.blendRequests}>
            </CompareBlends>

            <LocateMidasNumber 
                open={modalLocateMIDASNumberOpen} 
                setOpen={setModallocateMIDASNumberOpen} 
                myStudy={myStudy} 
                setMyStudy={setMyStudy} 
                blendIndex={tabValue} 
                blendComponentIndex={blendComponentIndex}
                batchComponents={null}
                batchSampleComponentIndex={null}
                setBatchComponents={null}
                bLocateContainers={false}
                incomingUoMObject={null}
                onlyISOSamples={myStudy.blendRequests[tabValue].isISOBlend ? true : false}
            >
            </LocateMidasNumber>
    </div>
  );
};

export default BlendsAndBatches;