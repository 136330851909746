import { useState, useEffect, useContext } from "react";
import STLCSearch from "../../../components/STLCSearch";
import { Divider, Grid, TextField, Checkbox, FormControl, Select, MenuItem, InputLabel, Autocomplete } from "@mui/material";
import { GlobalButton, GlobalSecondaryButton } from "../../styles";
import "./index.css";
import AddIcon from '@mui/icons-material/Add';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FileDragAndDrop from "../../../components/FileDragAndDrop";
import LotCertificationStatus from "../../../api/ChemInv/LotCertificationStatus";
import WaiverFacility from "../../../api/ChemInv/WaiverFacility";
import SupplierLot from "../../../api/ChemInv/SupplierLot";
import SupplierLotFileAttachment from "../../../api/ChemInv/SupplierLotFileAttachment";
import CertificationMethodModel from "../../../api/ChemInv/CertificationMethodModel";
import CertificationResultComponent from "../../../api/ChemInv/CertificationResultComponent";
import CertificationMethod from "../../../api/ChemInv/CertificationMethod";
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";
import ModalMessages from "../../../components/Modal/ModalSimpleButton";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import { getMessage, isNumber } from "../../../global";
import { StyledTableRow, StyledTableBody, StyledTableCell, UXDataTableWithoutBody } from "../../../components/UXDataTable";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import UserContext from "../../../context/UserContext";
import { Roles, hasRole, DatePickerKeyDownEvent } from "../../../global";
// import AddIcon from '@mui/icons-material/AddCircleOutline';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const initialNewLot = {
  id: 0,
  supplierTradenameID: null,
  certificationStatusName: null,
  lotCertificationStatus: null,
  lotName: null,
  createdByEmail: null,
  createdDate: new Date(),
  lastModifiedByEmail: null,
  lastModifiedDate: null,
  shelfLifeStartDate: null,
  shelfLifeExpirationDate: null,
  comments: null,
  supplierLocation: null,
  waiverReason: null,
  lastCertifiedByEmail: null,
  lastCertifiedDate: new Date(),
  canBeRecertified: false,
  certificationTestResult: [],
  waiverFacilityList: [],
}

const defaultErrorChecks =
{
  lotName: null,
  certificationStatusName: null,
  shelfLifeStartDate: null,
};

const initialCertificationResult = {
  id: 0,
  methodName: null,
  description: null,
  result: null,
  units: null,
  comments: null,
  certificationResultName: null,
  minimumTextValue: null,
  maximumTextValue: null,
  typicalTextValue: null,
}

export default function STLCManagement() {
  const [openSTLCSearch, setOpenSTLCSerach] = useState(false);
  const [currentLot, setCurrentLot] = useState(null);
  const [currentSupplier, setCurrentSupplier] = useState(null);
  const [currentTradename, setCurrentTradename] = useState(null);
  const [listLotCertificationStatus, setListLotCertificationStatus] = useState([]);
  const [listWaiverFacility, setListWaiverFacility] = useState([]);
  const [resetSearchSTLC, setResetSearchSTLC] = useState(false);
  const [errorChecks, setErrorChecks] = useState(defaultErrorChecks);
  const [canChangeReCertify, setCanChangeReCertify] = useState(true);
  const [listCertificationResult, setListCertificationResult] = useState([]);
  const [listMethod, setListMethod] = useState([]);
  const [certificationResultIsLoading, setCertificationResultIsLoading] = useState([]);
  const [listCertificationResultComponent, setListCertificationResultComponent] = useState([]);
  const [listUnitOfMeasure, setListUnitOfMeasure] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = 'Ok'
  const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  const [modalMessagesText, setModalMessagesText] = useState('');

  const [modalTwoButtonsOpen, setModalTwoButtonsOpen] = useState(false);
  const [modalTwoButtonsButton1Text, setModalTwoButtonsButton1Text] = useState("");
  const [modalTwoButtonsButton2Text, setModalTwoButtonsButton2Text] = useState("");
  const [modalTwoButtonsTitle, setModalTwoButtonsTitle] = useState("");
  const [nameToCreate, setNameToCreate] = useState("");

  const [files, setFiles] = useState([]);

  const currentUser = useContext(UserContext);
  const userRoles = currentUser?.idTokenClaims.roles;

  const isDeveloper = hasRole(Roles.Developer, userRoles);

  const isSTLCMaintainer = hasRole(Roles.STLCMaintainer, userRoles) || isDeveloper;
  const isLotCertifier = hasRole(Roles.LotCertifier, userRoles) || isDeveloper;

  useEffect(() => {
    async function getLotCertificationStatus() {
      const response = await LotCertificationStatus.getAll();
      if (response) {
        setListLotCertificationStatus(response.filter(f => f.isActive === true));
      } else {
        setListLotCertificationStatus([]);
      }
    }
    getLotCertificationStatus();

    async function getWaiverFacility() {
      const response = await WaiverFacility.getAll();
      if (response) {
        setListWaiverFacility(response.filter(f => f.isActive === true));
      } else {
        setListWaiverFacility([]);
      }
    }
    getWaiverFacility();

    async function getCertificationResultComponent() {
      const response = await CertificationResultComponent.getAll();
      if (response) {
        setListCertificationResultComponent(response);
      } else {
        setListCertificationResultComponent([]);
      }
    }
    getCertificationResultComponent();

    async function getUnitOfMeasure() {
      const response = await UnitOfMeasure.getAll();
      if (response) {
        setListUnitOfMeasure(response);
      } else {
        setListUnitOfMeasure([]);
      }
    }
    getUnitOfMeasure();
  }, [])

  const colHeadersCertificationResults = [
    'Method',
    'Description',
    'Certification Result',
    'Result',
    'Units',
    'Comments',
    'Min',
    'Typical',
    'Max',
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  function reset() {
    setResetSearchSTLC(true);
    handleChangeCurrentLot(initialNewLot);
    setCurrentSupplier(null);
    setCurrentTradename(null);
    setFiles([]);
  }

  const validateFields = () => {
    let lotName = null;
    let certificationStatusName = null;
    let shelfLifeStartDate = null;

    if (!currentLot.lotName) lotName = getMessage('REQUIRED_FIELD');
    if (!currentLot.certificationStatusName) certificationStatusName = getMessage('REQUIRED_FIELD');
    if (!currentLot.shelfLifeStartDate) shelfLifeStartDate = getMessage('REQUIRED_FIELD');

    setErrorChecks({
      ...errorChecks,
      lotName: lotName,
      certificationStatusName: certificationStatusName,
      shelfLifeStartDate: shelfLifeStartDate,
    })

    return ((lotName !== null) || (certificationStatusName !== null) || (shelfLifeStartDate !== null));
  }

  function sendFiles(id) {
    const filesToCreate = files.filter(f => !f.file?.id)
    // const filesToUpdate = files.filter(f => f.file?.id)
    const lastIndexToCreate = filesToCreate.length - 1;
    // const lastIndexsToUpdate = filesToUpdate.length - 1;
    let finishUpdate = true
    // let finishCreate = true
    let error = false;
    filesToCreate.forEach((file, index) => {
      // finishCreate = false
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("supplierLotID", id);

      SupplierLotFileAttachment.postAttachs(formData)
        .then((res) => {
          if (lastIndexToCreate === index && finishUpdate) {
            const message = error ? `Lot ${currentLot.lotName} has been updated, but Something went wrong with the attatchments, please try after sometime` : `Lot ${currentLot.lotName} has been updated`
            openModalMessages('Lot Saved Successfully', message);
            reset()
            // finishCreate = true
          }
        })
        .catch((resp) => {
          error = true
          if (lastIndexToCreate === index && finishUpdate) {
            const message = error ? `Lot ${currentLot.lotName} has been updated, but Something went wrong with the attatchments, please try after sometime` : `Lot ${currentLot.lotName} has been updated`
            openModalMessages('Lot Saved Successfully', message);
            reset()
            // finishCreate = true
          }
        });
    })

    if (filesToCreate.length < 1) {
      openModalMessages('Lot Saved Successfully', `Lot ${currentLot.lotName} has been updated!`);
      reset()
    }
  }

  async function save() {
    if (validateFields()) return;

    const data = {
      ...currentLot,
      waiverFacilityList: currentLot.waiverFacilityList.join("|"),
      certificationTestResult: listCertificationResult.filter(l => (l.id === 0 || l.edit === true) && l.certificationResultName).map(item => {
        const number = isNumber(item.result)
        return {
          id: item.id ?? 0,
          methodName: item.methodName,
          resultUoMName: item.units,
          certificationResultName: item.certificationResultName,
          textResultValue: number ? null : item.result,
          numericResultValue: number ? item.result : null,
          comments: item.comments,
          supplierLotID: currentLot?.id
        }
      })
    }

    if (data.id) {
      const response = await SupplierLot.update(data)
      if (response && response.message === "Success") {
        if (files.length > 0) {
          sendFiles(response.result.id)
        } else {
          openModalMessages('Lot Saved Successfully', `Lot ${data.lotName} has been updated!`);
          reset()
        }
      } else {
        openModalMessages('Lot Failed to Save', `Contact support if you feel this is an error.`);
      }
    } else {
      const response = await SupplierLot.create({
        ...data,
        supplierTradenameID: currentTradename?.id
      })
      if (response && response.message === "Success") {
        if (files.length > 0) {
          sendFiles(response.result.id)
        } else {
          openModalMessages('Lot Saved Successfully', `Lot ${data.lotName} has been updated!`);
          reset()
        }
      } else {
        openModalMessages('Lot Failed to Save', `Contact support if you feel this is an error.`);
      }
    }
  }

  function deleteFile(file) {
    const newFiles = files.filter(f => f.file !== file)

    setFiles(newFiles)
  }

  function handleFiles(newFiles) {
    const listFiles = newFiles.map(item => {
      return {
        file: item,
      }
    })
    setFiles(files.concat(listFiles))
  }

  async function getCertificationMethodModel(supplierTradenameID, supplierLotID) {
    setCertificationResultIsLoading(true);
    const response = await CertificationMethodModel.getBySupplierTradenameIDWithSupplierLotID(supplierTradenameID, supplierLotID);

    if (response && response.length > 0) {
      setListCertificationResult(response);
      setListMethod(response);
    } else {
      setListCertificationResult([]);
      setListMethod([]);
    }
    setCertificationResultIsLoading(false);
  }

  async function handleChangeCurrentLot(lot, supplierTradenameID) {
    setCurrentLot({
      ...lot,
      waiverFacilityList: lot?.id && typeof lot.waiverFacilityList === 'string' ? lot.waiverFacilityList.split('|') : []
    })
    setCanChangeReCertify(lot?.id === 0 || lot?.canBeRecertified ? false : true);

    getCertificationMethodModel(supplierTradenameID ?? currentTradename?.id, lot?.id ?? 0)

    if (lot?.id) {
      const response = await SupplierLotFileAttachment.getAll(lot?.id);
      if (response && response?.length > 0) {
        setFiles(response.map(file => {
          return {
            file: {
              ...file,
              name: file.fileName,
              canDelete: false
            },
          }
        }))
      }
    } else {
      setFiles([]);
    }
  }

  function handleChangeCertificationResult(field, value, index) {
    const newList = listCertificationResult.map((item, idx) => {
      if (index === idx) {
        if (field === 'method') {
          return {
            ...item,
            methodName: value.methodName,
            description: value.description,
            minimumTextValue: value.minimumTextValue,
            maximumTextValue: value.maximumTextValue,
            typicalTextValue: value.typicalTextValue,
          }
        }
        return {
          ...item,
          [field]: value,
          edit: item.id === 0 ? false : true
        }
      } else {
        return item;
      }
    })
    setListCertificationResult(newList)
  }

  function openNewMethod() {
    setModalTwoButtonsOpen(true);
    setModalTwoButtonsButton1Text('Save');
    setModalTwoButtonsButton2Text('Cancel');
    setModalTwoButtonsTitle('Method');
  }

  function openNewCertificationResult() {
    setModalTwoButtonsOpen(true);
    setModalTwoButtonsButton1Text('Save');
    setModalTwoButtonsButton2Text('Cancel');
    setModalTwoButtonsTitle('Certification Result');
  }


  function closeModalTwoButtons() {
    setModalTwoButtonsOpen(false);
    setModalTwoButtonsButton1Text('');
    setModalTwoButtonsButton2Text('');
    setModalTwoButtonsTitle('');
    setNameToCreate('');
  }

  async function createMethod() {
    if (nameToCreate && nameToCreate !== '') {
      const response = await CertificationMethod.create({
        methodName: nameToCreate,
        createdByEmail: null,
        createdDate: new Date(),
        methodLimits: [
          {
            supplierTradenameID: currentTradename?.id,
            methodName: nameToCreate,
            createdByEmail: null,
            createdDate: new Date(),
          }
        ]
      })

      if (response && response.message === "Success") {
        setListMethod([...listMethod, response.result])
        openModalMessages('Method Saved Successfully', `Method ${nameToCreate} has been created!`);
        closeModalTwoButtons()

      } else {
        openModalMessages('Method Failed to Save', `Contact support if you feel this is an error.`);
      }
    }
  }

  async function createCertificationResult() {
    if (nameToCreate && nameToCreate !== '') {
      const response = await CertificationResultComponent.create({
        certificationResultName: nameToCreate,
        createdByEmail: null,
        createdDate: new Date(),
      })

      if (response && response.message === "Success") {
        setListCertificationResultComponent([...listCertificationResultComponent, response.result])
        openModalMessages('Certification Result Saved Successfully', `Certification Result ${nameToCreate} has been created!`);
        closeModalTwoButtons()

      } else {
        openModalMessages('Certification Result Failed to Save', `Contact support if you feel this is an error.`);
      }
    }
  }

  return (
    <div className="container-management-stlc">
      <span className='pageheader'>STLC Management</span>
      <Divider className='dividerbar' />
      <div className='header'>
        <STLCSearch
          open={openSTLCSearch}
          setOpen={setOpenSTLCSerach}
          setLot={(lot, supplier, tradename) => {
            handleChangeCurrentLot(lot ?? initialNewLot, tradename?.id)
            setCurrentSupplier(supplier)
            setCurrentTradename(tradename)
          }}
          setReset={setResetSearchSTLC}
          reset={resetSearchSTLC}
          showExternalSearch={true}
        />
        <div className="buttons">
          <GlobalSecondaryButton
            variant='contained'
            onClick={() => handleChangeCurrentLot(initialNewLot)}
            disabled={!currentSupplier || !currentTradename || !isSTLCMaintainer}
          ><AddIcon /> New Lot</GlobalSecondaryButton>
          <GlobalButton
            variant='contained'
            onClick={() => save()}
            disabled={!currentSupplier || !currentTradename || !(isSTLCMaintainer || isLotCertifier)}
          >Save</GlobalButton>
          <GlobalSecondaryButton
            variant='contained'
            onClick={() => reset()}
          >Clear</GlobalSecondaryButton>
        </div>
      </div>
      {currentSupplier && currentTradename && <div className="content">
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, md: 10 }}>
          <Grid item xs={2} md={2} className="column">
            <label className="label">Lot Details</label>
            <span className="lot-name">{currentLot?.lotName ?? '-'}</span>
          </Grid>
          <Grid item xs={2} md={2} className="column">
            <label className="label">Supplier</label>
            <span className="label">{currentSupplier?.supplierName ?? '-'}</span>
          </Grid>
          <Grid item xs={2} md={2} className="column">
            <label className="label">Tradename</label>
            <span className="label">{currentTradename?.tradename?.tradenameName ?? '-'}</span>
          </Grid>
          <Grid item xs={2} md={2} className="column">
            <label className="label">ChemID</label>
            <span className="label">{currentTradename?.tradename?.chemID ?? '-'}</span>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, md: 10 }}>
          <Grid item xs={2} md={5}>
            <TextField size="small" id="lot-name" label="Lot Name*" variant="outlined" fullWidth
              onChange={e => setCurrentLot(() => ({
                ...currentLot,
                lotName: e.target.value
              }))}
              value={currentLot?.lotName ?? ''}
              inputProps={{ maxLength: 200 }}
              error={errorChecks.lotName === null ? false : errorChecks.lotName}
              helperText={errorChecks.lotName ? errorChecks.lotName : ''}
              disabled={!isSTLCMaintainer}
            />
          </Grid>
          <Grid item xs={2} md={3}>
            <TextField size="small" id="lot-location" label="Location" variant="outlined" fullWidth
              onChange={e => setCurrentLot(() => ({
                ...currentLot,
                supplierLocation: e.target.value
              }))}
              value={currentLot?.supplierLocation ?? ''}
              inputProps={{ maxLength: 100 }}
              disabled={!isSTLCMaintainer}
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, md: 10 }}>
          <Grid item xs={4} md={8}>
            <TextField size="small" id="comment" label="Comment" variant="outlined" fullWidth
              onChange={e => setCurrentLot(() => ({
                ...currentLot,
                comments: e.target.value
              }))}
              value={currentLot?.comments ?? ''}
              inputProps={{ maxLength: 200 }}
              multiline={true}
              row={3}
              minRows={3}
              disabled={!isSTLCMaintainer}
            />
          </Grid>
        </Grid>
        <p>Certification</p>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, md: 10 }}>
          <Grid item xs={4} md={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                size="small"
                inputFormat="MM/dd/yyyy"
                label="Shelf Life Start Date"
                onChange={e => {
                  const months = !currentLot.certificationStatusName?.includes("Recertified") ? currentTradename.tradename.standardShelfLifeMonths : (currentTradename.tradename.standardShelfLifeMonths / 2)
                  const dateExpiration = new Date(e);
                  dateExpiration.setMonth(dateExpiration.getMonth() + months);

                  setCurrentLot(() => ({
                    ...currentLot,
                    shelfLifeStartDate: e,
                    shelfLifeExpirationDate: dateExpiration
                  }))
                }}
                value={currentLot?.shelfLifeStartDate}
                renderInput={(params) => <TextField
                  onKeyDown={DatePickerKeyDownEvent}
                  {...params}
                  variant="outlined"
                  size="small"
                  error={errorChecks.shelfLifeStartDate === null ? false : errorChecks.shelfLifeStartDate}
                  helperText={errorChecks.shelfLifeStartDate ? errorChecks.shelfLifeStartDate : ''}
                  fullWidth
                />}
                disabled={!isSTLCMaintainer}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2} md={2}>
            <FormControl size="small" sx={{ minWidth: '100%' }} >
              <InputLabel id="status-select-label">Status*</InputLabel>
              <Select
                labelId="status"
                id="status-select"
                value={currentLot?.certificationStatusName ?? ''}
                label="Status"
                onChange={e => {
                  const value = e.target.value;
                  const months = !value.includes("Recertified") ? currentTradename.tradename.standardShelfLifeMonths : (currentTradename.tradename.standardShelfLifeMonths / 2)
                  const dateExpiration = new Date(currentLot.shelfLifeStartDate);
                  dateExpiration.setMonth(dateExpiration.getMonth() + months);

                  setCurrentLot(() => ({
                    ...currentLot,
                    certificationStatusName: e.target.value,
                    shelfLifeExpirationDate: currentLot.shelfLifeStartDate ? dateExpiration : null
                  }))
                }}
                error={errorChecks.certificationStatusName === null ? false : errorChecks.certificationStatusName}
                helperText={errorChecks.certificationStatusName ? errorChecks.certificationStatusName : ''}
                disabled={!(isSTLCMaintainer || isLotCertifier)}
              >
                {listLotCertificationStatus.map(item => {
                  return <MenuItem disabled={!canChangeReCertify && item.statusName.includes("Recertified")} value={item.statusName}>{item.statusName}</MenuItem>
                })
                }

              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} md={1.5}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                size="small"
                inputFormat="MM/dd/yyyy"
                label="Shelf Life Expiration Date"
                readOnly={true}
                value={currentLot?.shelfLifeExpirationDate}
                renderInput={(params) => <TextField
                  onKeyDown={DatePickerKeyDownEvent}
                  {...params}
                  variant="outlined"
                  size="small"
                />}
                disabled={!isSTLCMaintainer}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, md: 10 }} alignItems="center">
          <Grid item xs={4} md={2.5}>
            <Autocomplete
              size="small"
              id="facility-select"
              multiple
              limitTags={2}
              disableCloseOnSelect
              noOptionsText={"No Waiver Facilities..."}
              options={listWaiverFacility.map((facility) => facility.facilityName)}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                setCurrentLot(() => ({
                  ...currentLot,
                  waiverFacilityList: newValue,
                }));
              }}
              value={currentLot.waiverFacilityList}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              style={{ width: '100%' }}
              autoHighlight
              disabled={(currentLot?.certificationStatusName !== 'Waivered' && currentLot?.certificationStatusName !== 'Recertified-Waivered') || !isSTLCMaintainer}
              renderInput={(params) => <TextField {...params} variant="outlined" size="small" label="Waiver Facility" InputProps={{ ...params.InputProps }} />}
            />
          </Grid>
          <Grid item xs={4} md={5.5}>
            <TextField size="small" id="waiverReason" label="Waiver Comment" variant="outlined" fullWidth
              // style={{
              //   width: 'calc(100% - 23px)',
              //   marginLeft: '23px'
              // }}
              onChange={e => setCurrentLot(() => ({
                ...currentLot,
                waiverReason: e.target.value
              }))}
              value={currentLot?.waiverReason ?? ''}
              inputProps={{ maxLength: 200 }}
              disabled={(currentLot?.certificationStatusName !== 'Waivered' && currentLot?.certificationStatusName !== 'Recertified-Waivered') || !isSTLCMaintainer}
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, md: 10 }}>
          <Grid item xs={4} md={10}>
            <FormGroup>
              <FormControlLabel control={
                <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                  onChange={e => {
                    const months = currentLot?.canBeRecertified ? currentTradename.tradename.standardShelfLifeMonths : (currentTradename.tradename.standardShelfLifeMonths / 2)
                    const dateExpiration = new Date(currentLot.shelfLifeStartDate);
                    dateExpiration.setMonth(dateExpiration.getMonth() + months);

                    setCurrentLot(() => ({
                      ...currentLot,
                      canBeRecertified: !currentLot?.canBeRecertified,
                      shelfLifeExpirationDate: currentLot.shelfLifeStartDate ? dateExpiration : null
                    }))
                  }}
                  checked={currentLot?.canBeRecertified}
                />}
                label="Shelf Life ReCertify"
                disabled={!canChangeReCertify}
              />
            </FormGroup>
          </Grid>
        </Grid> */}
        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, md: 10 }}>
          <Grid item xs={4} md={8}>
            <FileDragAndDrop
              handleFiles={handleFiles}
              files={files.map((item) => item.file)}
              showFiles={true}
              handleDeleteFile={(file) => deleteFile(file)}
              container={'midasanalyticalfiles'}
              disabled={!isSTLCMaintainer}
            />
          </Grid>
        </Grid>
      </div>}
      {currentLot && currentSupplier && currentTradename && <div style={{ paddingBottom: '20px' }}>
        <UXDataTableWithoutBody
          tableWidth='100%'
          cols={colHeadersCertificationResults}
          blankFirstHeader={false}
          tableId="simpleResultTable"
          rowLength={listCertificationResult.length}
          noDataFoundMessage={'There is no supplier data available'}
          addToolTipText={null}
          enableSorteable={false}
          isDataLoading={certificationResultIsLoading}
          enableAddIcon={isSTLCMaintainer || isLotCertifier}
          addFunction={() => setListCertificationResult([{ ...initialCertificationResult }, ...listCertificationResult])}
          enablePaging={true}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >

          <StyledTableBody key={"myTablebody"}>
            {listCertificationResult.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((certificationResult, rowIndex) => {
              return (
                [
                  <StyledTableRow
                    hover
                    key={`custom-row-${rowIndex}`}>
                    {certificationResult.id === 0 ? (
                      <StyledTableCell style={{ display: 'flex' }}>
                        <Autocomplete
                          size="small"
                          id="method-select"
                          disablePortal
                          noOptionsText={"No Options"}
                          options={listMethod.filter(f => f.methodName).filter((item, index) => listMethod.findIndex(x => x.methodName === item.methodName) === index).sort((a, b) => a.methodName.localeCompare(b.methodName))}
                          getOptionLabel={(option) => option?.methodName ?? option}
                          onChange={(e, value) => {
                            value = value === null ? "" : value
                            handleChangeCertificationResult('method', value, rowIndex)
                          }}
                          autoHighlight
                          autoSelect
                          fullWidth
                          value={certificationResult.methodName === '' ? null : certificationResult.methodName}
                          renderInput={(params) => <TextField {...params} variant="outlined" size="small" InputProps={{ ...params.InputProps }} fullWidth />}
                        />
                        <AddIcon style={{
                          paddingLeft: "15px",
                          fontSize: 40
                        }}
                          onClick={(e) => openNewMethod(e)}></AddIcon>
                      </StyledTableCell>
                    ) : <StyledTableCell>{certificationResult.methodName}</StyledTableCell>
                    }
                    <StyledTableCell>{certificationResult.description}</StyledTableCell>
                    {certificationResult.id === 0 || !certificationResult.id ? (
                      <StyledTableCell style={{ display: 'flex' }}>
                        <Autocomplete
                          size="small"
                          id="certificationResultName-select"
                          disablePortal
                          noOptionsText={"No Options"}
                          options={listCertificationResultComponent.filter(f => f.certificationResultName)}
                          getOptionLabel={(option) => option?.certificationResultName ?? option}
                          onChange={(e, value) => {
                            value = value === null ? "" : value?.certificationResultName
                            handleChangeCertificationResult('certificationResultName', value, rowIndex)
                          }}
                          autoHighlight
                          autoSelect
                          fullWidth
                          value={certificationResult.certificationResultName === '' ? null : certificationResult.certificationResultName}
                          renderInput={(params) => <TextField {...params} variant="outlined" size="small" InputProps={{ ...params.InputProps }} fullWidth />}
                        />
                        <AddIcon style={{
                          paddingLeft: "15px",
                          fontSize: 40
                        }}
                          onClick={(e) => openNewCertificationResult(e)}></AddIcon>
                      </StyledTableCell>
                    ) : <StyledTableCell>{certificationResult.certificationResultName}</StyledTableCell>
                    }
                    <StyledTableCell>
                      <TextField size="small" id="result" variant="outlined" fullWidth
                          onChange={e => handleChangeCertificationResult('result', e.target.value, rowIndex)}
                          value={certificationResult.result ?? ''}
                          inputProps={{ maxLength: 100 }}>
                      </TextField>
                       {/* {certificationResult.id === 0 || !certificationResult.id ? (
                      <TextField size="small" id="result" variant="outlined" fullWidth
                        onChange={e => handleChangeCertificationResult('result', e.target.value, rowIndex)}
                        value={certificationResult.result ?? ''}
                        inputProps={{ maxLength: 100 }}
                      />) : certificationResult.result} */}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Autocomplete
                          size="small"
                          id="units-select"
                          disablePortal
                          noOptionsText={"No Options"}
                          options={listUnitOfMeasure.filter(f => f.uoMName)}
                          getOptionLabel={(option) => option?.uoMName ?? option}
                          onChange={(e, value) => {
                            value = value === null ? "" : value?.uoMName
                            handleChangeCertificationResult('units', value, rowIndex)
                          }}
                          autoHighlight
                          autoSelect
                          value={certificationResult.units === '' ? null : certificationResult.units}
                          renderInput={(params) => <TextField {...params} variant="outlined" size="small" InputProps={{ ...params.InputProps }} fullWidth />}
                        />

                      {/* {certificationResult.id === 0 || !certificationResult.id ? (
                      <Autocomplete
                        size="small"
                        id="units-select"
                        disablePortal
                        noOptionsText={"No Options"}
                        options={listUnitOfMeasure.filter(f => f.uoMName)}
                        getOptionLabel={(option) => option?.uoMName ?? option}
                        onChange={(e, value) => {
                          value = value === null ? "" : value?.uoMName
                          handleChangeCertificationResult('units', value, rowIndex)
                        }}
                        autoHighlight
                        autoSelect
                        value={certificationResult.units === '' ? null : certificationResult.units}
                        renderInput={(params) => <TextField {...params} variant="outlined" size="small" InputProps={{ ...params.InputProps }} fullWidth />}
                      />) : certificationResult.units} */}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField size="small" id="comments" variant="outlined" fullWidth
                        onChange={e => handleChangeCertificationResult('comments', e.target.value, rowIndex)}
                        value={certificationResult.comments ?? ''}
                        inputProps={{ maxLength: 100 }}
                      />

                      {/* {certificationResult.id === 0 || !certificationResult.id ? (
                      <TextField size="small" id="comments" variant="outlined" fullWidth
                        onChange={e => handleChangeCertificationResult('comments', e.target.value, rowIndex)}
                        value={certificationResult.comments ?? ''}
                        inputProps={{ maxLength: 100 }}
                      />) : certificationResult.comments} */}
                    </StyledTableCell>
                    <StyledTableCell>{certificationResult.minimumNumericValue ? certificationResult.minimumNumericValue : certificationResult.minimumTextValue}</StyledTableCell>
                    <StyledTableCell>{certificationResult.typicalNumericValue ? certificationResult.typicalNumericValue : certificationResult.typicalTextValue}</StyledTableCell>
                    <StyledTableCell>{certificationResult.maximumNumericValue ? certificationResult.maximumNumericValue : certificationResult.maximumTextValue}</StyledTableCell>
                  </StyledTableRow>
                ]
              );
            })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </div>}
      <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
        {Array.isArray(modalMessagesText) ? (
          modalMessagesText && modalMessagesText.map((text, index) => (
            <div style={{ display: "flex" }} key={`stlc${index}`}>
              <label>
                {text}
              </label>
            </div>
          ))
        ) : (
          <div style={{ textAlign: 'center' }}>
            <label>
              {modalMessagesText}
            </label>
          </div>
        )
        }
      </ModalMessages >
      <ModalTwoButtons
        title={modalTwoButtonsTitle}
        button1Text={modalTwoButtonsButton1Text}
        button1Action={modalTwoButtonsTitle === 'Method' ? createMethod : createCertificationResult}
        button2Text={modalTwoButtonsButton2Text}
        button2Action={closeModalTwoButtons}
        open={modalTwoButtonsOpen}
        setOpen={setModalTwoButtonsOpen}
      >
        <label className="label">Enter the name for a new Certification Method</label>

        <TextField style={{marginTop:"1rem"}} size="small" id="name" label={`${modalTwoButtonsTitle} Name*`} variant="outlined" fullWidth
          onChange={e => setNameToCreate(e.target.value)}
          value={nameToCreate ?? ''}
          inputProps={{ maxLength: 50 }}
        />
      </ModalTwoButtons>
    </div >
  );
}
