import React, { useState, useEffect, useContext } from "react";
import { UXDataTable } from "../../components/UXDataTable";
import { applyFiltersToArray, hasRole, Roles } from "../../global";
import ModalTwoButtons from "../../components/Modal/ModalTwoButtons";
import ModalMessages from "../../components/Modal/ModalSimpleButton";
import BlendStudy from "../../api/Formulations/BlendStudy";
import UserContext from "../../context/UserContext";
import EmailAddressTextField from "../../components/EmailAddressField";

 const MyStudyCols = [
    { field: 'id', headerName: 'ID', type: 'number'},
    { field: 'studyName', headerName: 'Study Name', type: 'label' },
    { field: 'studyPurpose', headerName: 'Description', type: 'label' },
    { field: 'ownerEmail', headerName: 'Owner', type: 'label' },
    { field: 'createdDate', headerName: 'Date Created', type: 'datetime' },
    { field: 'lastModifiedDate', headerName: 'Last Modified', type: 'datetime' },
    { field: 'blendStudyStatusName', headerName: 'Status', type: 'label' },
    { field: 'notes', headerName: 'Notes', type: 'label' },
  ];

const TableMyStudies = ({filters, myStudies, isDataLoading, userEmail, refreshData, setRefreshData}) => {
    const [filteredStudies, setFilteredStudies] = useState([])
    const noDataMessage = `No Studies found for owner = ${userEmail}`

    const [modalCancelItemOpen, setModalCancelItemOpen] = useState(false);
    const [modalCancelItemTitle, setModalCancelItemTitle] = useState("");
    const [modalCancelItemText, setModalCancelItemText] = useState("");
    const [modalCancelItemButton1Text, setModalCancelItemButton1Text] = useState("");
    const [modalCancelItemButton2Text, setModalCancelItemButton2Text] = useState("");
    const [cancelBlendItem, setCancelBlendItem] = useState();

    const [modalTwoBtnOpen, setModalTwoBtnOpen] = useState(false);
    const [modalTwoBtnButton1Text, setModalTwoBtnButton1Text] = useState('');
    const [modalTwoBtnButton2Text, setModalTwoBtnButton2Text] = useState('');
    const [modalTwoBtnTitle, setModalTwoBtnTitle] = useState('');
    const [modalTwoBtnText, setModalTwoBtnText] = useState('');

    const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
    const modalMessagesButtonText = 'Ok'
    const [modalMessagesTitle, setModalMessagesTitle] = useState('');
    const [modalMessagesText, setModalMessagesText] = useState('');

    const currentUser = useContext(UserContext) 
    const roles = currentUser?.idTokenClaims.roles;
    const hasBlendMaintainer = hasRole(Roles.BlendMaintainer, roles) || hasRole(Roles.Developer, roles)

    const [tempStudyID, setTempStudyID] = useState(0);
    const [tempEmail, setTempEmail] = useState('')
    const [blenderEmailError, setBlenderEmailError] = useState(false)

    function closeModalMessages() {
      setModalMessagesOpen(false);
    }
  
    function openModalMessages(title, text) {
      setModalMessagesOpen(true);
      setModalMessagesTitle(title);
      setModalMessagesText(text);
    }

    const menuItems = [{
      menuType: 'link-duplicateStudy',
      redirectPath: '/blendstudy',
      text: 'Duplicate Study',
      onClickFunction: null
    }, 
    {
      menuType: 'link-editStudy',
      redirectPath: '/blendstudy',
      text: 'Edit this Study',
      onClickFunction: null
    }, 
    {
      menuType: 'text',
      redirectPath: '',
      text: 'Cancel this Study',
      onClickFunction: (item) => ConfirmRemoveItem(item, 'Draft')
    },
    { menuType: 'text',
      redirectPath: '',
      text: 'Transfer Ownership',
      onClickFunction: TransferStudyOwnership,
      isDisabled: !(hasBlendMaintainer)
    }]

    const ConfirmRemoveItem = (item) => {
      if (item) {
        openModalCancelItem(`Cancel Study`, `Are you sure you want to cancel study ${item.id}?`, "Yes", "No", item);
      }
    };

    function closeModalCancelItem() {
      setModalCancelItemOpen(false);
    }
  
    function openModalCancelItem(title, text, button1, button2, item) {
      setModalCancelItemOpen(true);
      setModalCancelItemTitle(title);
      setModalCancelItemText(text);
      setModalCancelItemButton1Text(button1);
      setModalCancelItemButton2Text(button2);
      setCancelBlendItem(item);
    }

    const CancelStudy = () => {
      if (cancelBlendItem && cancelBlendItem.id) {
        BlendStudy.CancelBlendStudy(cancelBlendItem.id).then((res) => {
          closeModalCancelItem();

          if (res.message === "Success") 
          {
            openModalMessages("Blend Study Cancelled", "Blend Study successfully cancelled!");

            setRefreshData(!refreshData)
          } else {
            openModalMessages("Blend Study Cancelled", `${res.message}. Contact support if you feel this is an error.`);
          }
        });
      }
    };
  
    useEffect(() => {
      var filteredArray = applyFiltersToArray(filters, myStudies)
      setFilteredStudies(filteredArray)
    }, [filters, myStudies])

    function TransferStudyOwnership(study){
      setTempStudyID(study.id)
      openModalTwoButtons("Set New Study Owner", "Enter the email address of the person you want to assign as the new study owner.","Ok", "Cancel")
    }
  
    function closeModalTwoButtons() {
      setModalTwoBtnOpen(false)
    }
  
    function openModalTwoButtons(title, text, buttonText, button2Text) {
      setModalTwoBtnButton1Text(buttonText)
      setModalTwoBtnButton2Text(button2Text)
      setModalTwoBtnOpen(true)
      setModalTwoBtnTitle(title)
      setModalTwoBtnText(text)
    }
    
    function ValidateEnteredEmail(){
      if (!(blenderEmailError))
      {
        UpdateAssignedBlender()
      }
    }
  
    function UpdateAssignedBlender(){
      if (tempStudyID > 0){  
        BlendStudy.updateBlendStudyOwner(tempStudyID, tempEmail).then((res) => {
          if (res.message === 'Success')
          {
            openModalMessages("Study Updated", "Study successfully updated!")
  
            setRefreshData(!refreshData)
            closeModalTwoButtons()
            setTempEmail('')
            setTempStudyID(0)
  
          } else {
            openModalMessages('Study Failed to Update', `${res.message}. Contact support if you feel this is an error.`);
            
            closeModalTwoButtons()
            setTempEmail('')
            setTempStudyID(0)
          }
        })
      }
    }
 
    return (
      <div>
        <UXDataTable tableWidth='100%' cols={MyStudyCols} rows={filteredStudies.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))} moreOptionsCell={true} enablePaging={true} 
                      noDataMessage={noDataMessage} menuProps={menuItems} defaultRowsPerPage={10} isDataLoading={isDataLoading} tableName={'myStudies'} enableSorting={true}>
        </UXDataTable>

        <ModalTwoButtons
          title={modalCancelItemTitle}
          button1Text={modalCancelItemButton1Text}
          button1Action={CancelStudy}
          button2Text={modalCancelItemButton2Text}
          button2Action={closeModalCancelItem}
          open={modalCancelItemOpen}
          setOpen={setModalCancelItemOpen}
        >
        <div style={{ textAlign: "center" }}>
          <label>{modalCancelItemText}</label>
        </div>
        </ModalTwoButtons>

          {/* New Owner */}
          <ModalTwoButtons title={modalTwoBtnTitle} button1Text={modalTwoBtnButton1Text} button1Action={ValidateEnteredEmail} isButton1Disabled={tempEmail === ''} button2Text={modalTwoBtnButton2Text} button2Action={closeModalTwoButtons} open={modalTwoBtnOpen} setOpen={setModalTwoBtnOpen}>
            <label>
              {modalTwoBtnText}
            </label>

            <div></div>

            <EmailAddressTextField
              fontSize = {16}
              fieldWidth = {"350px"} 
              validatedUserEmail = {tempEmail}
              setValidatedUserEmail = {(e) => setTempEmail(e)}
              hasErrors={blenderEmailError}
              setHasErrors = {(e) => setBlenderEmailError(e)}
              isDisabled={false}
              labelText="Blender Email"
              placeholderText="Blender Email"
              showPlusMeButton={true}
              size="small"
              margin="normal">
            </EmailAddressTextField>
          </ModalTwoButtons>

        {/* Informational Messages */}
        <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
          <label>
              {modalMessagesText}
          </label>     
        </ModalMessages>
      </div>
    );
};

export default TableMyStudies;