import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, FormControl, TextField, Checkbox, FormControlLabel, Switch } from "@mui/material";
import { StyledTableRow, StyledTableBody, StyledTableCell, UXDataTableWithoutBody } from "../../../components/UXDataTable";
import ContainerApi from '../../../api/LIMS/Container';
import { formatMidasNumber, exportToCsv, getMessage, applyFiltersToArray, hasRole, Roles } from "../../../global";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import UnitOfMeasureApi from "../../../api/Admin/UnitOfMeasure";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import ValidatedMidasNumberTextField from "../../../components/ValidatedMidasNumberTextField";
import LocationField from "../../../components/LocationField";
import { GlobalButton, GlobalSecondaryButton } from "../../styles";
import FilterMenu from "../../../components/FilterMenu";
// import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import UserContext from "../../../context/UserContext";
import { LocationContext } from "../../../context/LocationContext";
import ReceiveContainerAutoScan from "./ReceiveContainerAutoScan";

const StyledContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
}));

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: ' space-between'
}));

const StyledDivSearch = styled('div')(() => ({
  display: 'flex'
}));

const StyledBox = styled(Box)(() => ({
  display: 'inline-box',
  marginRight: "25px"
}));

const StyledDivAdvancedExport = styled('div')(() => ({
  display: 'flex',
}));

const StyledTextField = styled(TextField)(() => ({
  width: "20rem"
}));

const StyledButton = styled(Button)(() => ({
  marginTop: '2px',
  widht: '200px',
  textTransform: 'none',
  height: '2.2rem',
  marginRight: "25px"
}));

// const StyledAutocomplete = styled(Autocomplete)({
//   '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
//     fontSize: 16,
//   },
//   width: "20rem",
// });
const initialStateContainer = {
  locationName: "",
  subLocation: "",
  // currentAmount: null,
  // containerSizeUoM: "",
  // expirationDate: "",
};

const initialStateError = {
  locationName: ""
  //subLocation: "",
};

const ReceiveContainer = ({ ...props }) => {
  const [searchMidas, setSearchMidas] = React.useState(null);
  const [listContainer, setListContainer] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [searchMidasValid, setSearchMidasValid] = React.useState(true);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');

  const [dataIsLoading, setDataIsLoading] = React.useState(false);
  const [newContainer, setNewContainer] = React.useState(initialStateContainer);
  const [errors, setErrors] = React.useState(initialStateError);
  // const [availableUOMs, setAvailableUOMs] = React.useState([]);

  const [modalSimpleButton, setModalSimpleButton] = React.useState(false);
  const [modalSimpleButtonText, setModalSimpleButtonText] = React.useState('');
  const [modalSimpleTitle, setModalSimpleTitle] = React.useState('');
  const [modalSimpleText, setModalSimpleText] = React.useState('');

  const [filteringOpen, setFilteringOpen] = React.useState(false);
  const [filters, setFilters] = React.useState([{ name: null, displayName: null, operator: null, enumValues: [], value: '' }])
  const [filteredRequests, setFilteredRequests] = React.useState([]);
  const [scannedContainer, setScannedContainer] = React.useState(null)

  const [locationBarcode, setLocationBarcode] = React.useState(null);
  const { locations } = useContext(LocationContext);
  const [autoScan, setAutoScan] = React.useState(false);

  const currentUser = useContext(UserContext)
  const roles = currentUser?.idTokenClaims.roles
  const developer = hasRole(Roles.Developer, roles);
  const containerReceiver = hasRole(Roles.ContainerReceiver, roles);
  const containerDrummaintainer = hasRole(Roles.ContainerDrummaintainer, roles);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const colHeaders = [
    { id: 'sampleName', label: 'MIDAS Number' },
    { id: 'containerNumber', label: 'Container Number' },
    { id: 'locationName', label: 'Current Location' },
    { id: 'subLocation', label: 'Current Sub Location' },
    { id: 'containerTypeName', label: 'Container Type' },
    { id: 'site', label: 'Site' },
    { id: 'ownerEmail', label: 'Owner' },
    // { id: 'expirationDate', label: 'Expiration Date' },
    { id: 'containerSizeUoM', label: 'Current Amount - UoM' },
    { id: 'testsBackloggedOrPending', label: '# of Pending Backlogged Tests' },
  ];

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const validSearchField = () => {
    const validMidas = searchMidas !== null && searchMidas !== '' && searchMidas?.sampleName !== '';
    setSearchMidasValid(validMidas)
    return validMidas
  }

  async function handleSearch(midas, containerNumber) {
    let isChecked = false

    //setListContainer([])

    if (validSearchField() || midas) {
      midas = midas ?? searchMidas;
      setDataIsLoading(true)
      ContainerApi.getByMidasNumber(midas?.sampleName).then((res) => {
        if (res) {
          let list = res;
          if (scannedContainer || (containerNumber !== null && containerNumber >= 0)) {
            list = list.filter(f => f.containerNumber === scannedContainer?.containerNumber || f.containerNumber === containerNumber)
            isChecked = true
            //list = list.filter(f => f.containerNumber === scannedContainer?.containerNumber && !listContainer.some(s => s.id === f.id))

          } else {
            list = list.filter(c => !listContainer.some(s => s.id === c.id))
          }

          
          setListContainer(
            list.map(item => {
              return {
                ...item,
                selected: isChecked
              }
            })
          );

          // setListContainer([
          //   ...listContainer,
          //   ...list.map(item => {
          //     return {
          //       ...item,
          //       selected: isChecked
          //     }
          //   })
          // ]);
        }
        setDataIsLoading(false)
      });
    }
  }

  const handleSelectAllClick = () => {
    const displayed = determineStartArray(listContainer)

    const allSelected = listContainer.filter(note => note.selected).length === listContainer.length
    const allDisplayedSelected = displayed.filter(note => note.selected).length === displayed.length

    if (allSelected) {
      setListContainer(prevState => {
        const updatedArray = prevState.map(container => {
          return { ...container, selected: false }
        })

        return updatedArray
      })

    } else if (allDisplayedSelected) {
      setListContainer(prevState => {
        const updatedArray = prevState.map(container => {
          if (displayed.filter(item => item.id === container.id).length > 0) {
            return { ...container, selected: false }
          }
          return container
        })

        return updatedArray
      })

    } else {
      setListContainer(prevState => {
        const updatedArray = prevState.map(container => {
          if (container.locationName === "CDRUMS" && !containerDrummaintainer && !developer) {
            openModalSimpleButton('Unauthorized', 'You are not allowed to receive containers from CDRUMS', 'Ok');
            return container;
          } 
          
          if (displayed.filter(item => item.id === container.id).length > 0) {
            return { ...container, selected: developer || containerReceiver || container.ownerEmail === currentUser.username ? true : false }
          }
          
          return container
        })

        return updatedArray
      })
    }

  }

  function determineStartArray(arrayContainers) {
    if (arrayContainers) {
      return stableSort(arrayContainers, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    } else {
      return []
    }
  }

  function handleChangeSelected(id) {
    let newList = listContainer.map(container => {
      if (container.id === id) {
        return {
          ...container,
          selected: container.selected ? false : true
        }
      } else {
        return container;
      }
    });
    setListContainer([...newList]);
  }

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!newContainer.locationName) {
      newErrors.locationName = getMessage('REQUIRED_FIELD');
      isValid = false;
    }
    // if (!newContainer.subLocation) {
    //   newErrors.subLocation = getMessage('REQUIRED_FIELD');
    //   isValid = false;
    // }
    // if (!newContainer.containerSizeUoM) {
    //   newErrors.containerSizeUoM = getMessage('REQUIRED_FIELD');
    //   isValid = false;
    // }
    // if (!newContainer.currentAmount) {
    //   newErrors.currentAmount = getMessage('REQUIRED_FIELD');
    //   isValid = false;
    // }

    setErrors(newErrors);
    return isValid;
  }

  function openModalSimpleButton(title, text, buttonText) {
    setModalSimpleButtonText(buttonText)
    setModalSimpleButton(true);
    setModalSimpleTitle(title);
    setModalSimpleText(text);
  }

  function closeModalSimpleButton() {
    setModalSimpleButton(false);
  }

  async function handleReceive() {
    if (!validateForm()) return;

    const res = await ContainerApi.sendReceive({
      ...newContainer,
      // containerSizeUoM: listContainerSelected.length > 1 ? initialStateContainer.containerSizeUoM : newContainer.containerSizeUoM,
      // currentAmount: listContainerSelected.length > 1 ? initialStateContainer.currentAmount : newContainer.currentAmount,
      listContainerId: listContainerSelected.map(container => container.id)
    })

    if (res) {
      openModalSimpleButton('Success', 'Containers have been received successfully.', 'Ok');
      setListContainer([]);
      setSearchMidas(null);
      setNewContainer(initialStateContainer);
    }
    else
      openModalSimpleButton('Fail', 'Something went wrong, please try after sometime', 'Ok');
  }

  const filterOptions = [{ name: "containerNumber", displayName: "Container Number", type: "integer", enumValues: [] },
  { name: "locationName", displayName: "Current Location", type: "string", enumValues: [] },
  { name: "subLocation", displayName: "Current Sub Location", type: "string", enumValues: [] },
  { name: "containerTypeName", displayName: "Container Type", type: "string", enumValues: [] },
  { name: "site", displayName: "Site", type: "string", enumValues: [] },
  { name: "ownerEmail", displayName: "Owner", type: "string", enumValues: [] },
  // { name: "expirationDate", displayName: "Expiration Date", type: "date", enumValues: [] },
  { name: "containerSizeUoM", displayName: "Current Amount - UoM", type: "integer", enumValues: [] },
  { name: "testsBackloggedOrPending", displayName: "# of Pending Backlogged Tests", type: "integer", enumValues: [] }
  ]

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  const filterClick = (event) => {
    setFilteringOpen(true);
  }

  const clearFiltersClick = (event) => {
    setFilters([{ name: null, displayName: null, operator: null, enumValues: [], value: null }])
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      barcodeLeave()
    }
  }

  const barcodeLeave = () => {
    const oContainer = JSON.parse(JSON.stringify(newContainer))
    const newErrors = JSON.parse(JSON.stringify(errors))

    if (locationBarcode !== null) {
      if (locationBarcode.includes('/')) {
        let locationArray = locationBarcode.split('/');

        oContainer.locationName = locationArray[0]
        oContainer.subLocation = locationArray[1]

        if (!(locations.some(result => result.locationName === locationArray[0]))) {
          newErrors.locationName = getMessage('INVALID_VALUE')
        } else {
          newErrors.locationName = null
        }
      } else {
        oContainer.locationName = locationBarcode

        if (!(locations.some(result => result.locationName === locationBarcode))) {
          newErrors.locationName = getMessage('INVALID_VALUE')
        }
      }
    }

    setErrors(newErrors);
    setNewContainer(oContainer)
  }

  React.useEffect(() => {
    var filteredArray = applyFiltersToArray(filters, listContainer)
    setFilteredRequests(filteredArray)
  }, [filters, listContainer])

  const listContainerSelected = filteredRequests.filter(c => c.selected);

   if (autoScan) return <>
    <ReceiveContainerAutoScan
      setAutoScan={setAutoScan}
      autoScan={autoScan}
    />
  </>

  return (
    <StyledContainer>
      <StyledFormControl fullWidth>
        <StyledDivSearch>
          <StyledBox>
            <ValidatedMidasNumberTextField
              margin={"none"}
              showLabel={true}
              fontSize={12}
              midasNumberObject={searchMidas}
              setMidasNumberObject={(e, containerNumber) => {
                setSearchMidas(e)
                if (e) {
                  handleSearch(e, containerNumber)
                }
              }}
              hasErrors={searchMidasValid}
              setHasErrors={setSearchMidasValid}
              chemIDToMatch={null}
              fieldWidth={"20rem"}
              setScannedContainer={setScannedContainer}
            ></ValidatedMidasNumberTextField>
          </StyledBox>
          <StyledButton sx={{ width: "8rem" }}
            variant='contained'
            type="submit"
            onClick={() => handleSearch()}
            disabled={searchMidas === null || !searchMidas?.sampleName}
          >Search</StyledButton>
        </StyledDivSearch>
        <StyledDivAdvancedExport>
          <Box display="flex" alignItems={"center"} marginLeft="auto" marginTop="-.8rem">
            <GlobalButton style={{ marginRight: "1rem" }} variant="contained"
              onClick={() => filterClick()}>Filters</GlobalButton>

            {!(filters[0].name === null) &&
              <GlobalSecondaryButton variant="contained"
                onClick={() => clearFiltersClick()}>Clear Filters</GlobalSecondaryButton>}
          </Box>
          <FileDownloadIcon sx={{ m: 1 }} style={{ cursor: "pointer" }} onClick={e => exportToCsv(listContainer.map(container => {
            return {
              sampleName: formatMidasNumber(container.sampleName),
              containerNumber: container.containerNumber,
              locationName: container.locationName,
              subLocation: container.subLocation,
              containerTypeName: container.containerTypeName,
              site: container.location?.siteName,
              ownerEmail: container.ownerEmail,
              // expirationDate: convertDateFormat(container.expirationDate),
              currentAmount: `${container.currentAmount} ${container.containerSizeUoM}`,
              testsBackloggedOrPending: container.testsBackloggedOrPending
            }
          }), "Receive Containers")} />
          <FormControlLabel style={{
          minWidth: '130px',
          marginRight: '0px'
        }} control={<Switch onChange={() => setAutoScan(true)} checked={false} />} label="Auto Scan" />
        </StyledDivAdvancedExport>
      </StyledFormControl>
      <div>
        <UXDataTableWithoutBody
          tableWidth='100%'
          cols={colHeaders}
          blankFirstHeader={true}
          tableId="simpleResultTable"
          rowLength={filteredRequests.length}
          enablePaging={true}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          noDataFoundMessage={'There is no container data available'}
          enableAddIcon={false}
          addFunction={null}
          addToolTipText={null}
          enableSorteable={true}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
          isDataLoading={dataIsLoading}
          enableCheckbox={true}
          selected={filteredRequests.filter(c => c.selected)}
          handleSelectAllClick={handleSelectAllClick}
        >

          <StyledTableBody key={"myTablebody"}>
            {determineStartArray(listContainer).map((container, rowIndex) => {
                return (
                  [
                    <StyledTableRow
                      hover
                      key={`custom-row-${rowIndex}`}>
                      <StyledTableCell padding="checkbox"><Checkbox checked={container.selected}
                        disabled={!developer && !containerReceiver && container.ownerEmail !== currentUser.username}
                        onChange={(e) => {
                          if (container.locationName === "CDRUMS" && !containerDrummaintainer && !developer) openModalSimpleButton('Unauthorized', 'You are not allowed to receive containers from CDRUMS', 'Ok');
                          else handleChangeSelected(container.id)
                        }}
                      /></StyledTableCell>
                      <StyledTableCell>{formatMidasNumber(container.sampleName)}</StyledTableCell>
                      <StyledTableCell>{container.containerNumber}</StyledTableCell>
                      <StyledTableCell>{container.locationName}</StyledTableCell>
                      <StyledTableCell>{container.subLocation}</StyledTableCell>
                      <StyledTableCell>{container.containerTypeName}</StyledTableCell>
                      <StyledTableCell>{container.location?.siteName}</StyledTableCell>
                      <StyledTableCell>{container.ownerEmail}</StyledTableCell>
                      {/* <StyledTableCell>{convertDateFormat(container.expirationDate)}</StyledTableCell> */}
                      <StyledTableCell style={{ width: '13rem' }}>{`${container.currentAmount} ${container.containerSizeUoM}`}</StyledTableCell>
                      <StyledTableCell style={{ width: '11rem' }}>{container.testsBackloggedOrPending}</StyledTableCell>
                    </StyledTableRow>
                  ]
                );
              })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </div>
      {listContainerSelected.length > 0 && <div style={{ display: 'flex', gap: '20px', flexDirection: 'column', marginBottom: "5rem" }}>
        <h4>New Location</h4>

        <div style={{ display: 'flex', gap: '20px' }}>
          <StyledTextField
            label="Scan Shelf Label"
            variant="outlined"
            size="small"

            onKeyDown={e => handleKeyPress(e)}
            onChange={e => setLocationBarcode(e.target.value)}
            onBlur={() => barcodeLeave()}
          />
          <div style={{ marginTop: '8px' }}><h4>Or</h4></div>

          <LocationField
            value={newContainer.locationName === '' ? null : newContainer.locationName}
            handleChange={(value) => {
              if (value === "CDRUMS" && !containerDrummaintainer && !developer) openModalSimpleButton('Unauthorized', 'You are not allowed to receive containers from CDRUMS', 'Ok');
              else {
                setNewContainer({
                  ...newContainer,
                  locationName: value
                })
              }
            }}
            hasErrors={errors.locationName}
            helperText={Boolean(errors.locationName) ? errors.locationName : ''}
          // helperText={Boolean(errors.locationName) ? getMessage('REQUIRED_FIELD') : ''}
          />
          <StyledTextField
            label="Sublocation"
            variant="outlined"
            size="small"
            value={newContainer.subLocation}
            //required={true}
            onChange={e => {
              setNewContainer({
                ...newContainer,
                subLocation: e.target.value
              })
            }}
          // error={Boolean(errors.subLocation)}
          // helperText={Boolean(errors.subLocation) ? getMessage('REQUIRED_FIELD') : ''}
          />
          <StyledButton sx={{ width: "10rem" }}
            variant='contained'
            type="submit"
            onClick={handleReceive}
          >Receive Container</StyledButton>
        </div>

        {/* <div style={{ display: 'flex', gap: '20px' }}>
          { <LocationField
            value={newContainer.locationName === '' ? null : newContainer.locationName}
            handleChange={(value) => {
              if (value === "CDRUMS" && !containerDrummaintainer && !developer) openModalSimpleButton('Unauthorized', 'You are not allowed to receive containers from CDRUMS', 'Ok');
              else {
                setNewContainer({
                  ...newContainer,
                  locationName: value
                })
              }
            }}
            hasErrors={errors.locationName}
            helperText={Boolean(errors.locationName) ? getMessage('REQUIRED_FIELD') : ''}
          />
          <StyledTextField
            label="Sublocation"
            variant="outlined"
            size="small"
            value={newContainer.subLocation}
            required={true}
            onChange={e => {
              setNewContainer({
                ...newContainer,
                subLocation: e.target.value
              })
            }}
            error={Boolean(errors.subLocation)}
            helperText={Boolean(errors.subLocation) ? getMessage('REQUIRED_FIELD') : ''}
          />

          {listContainerSelected.length === 1 &&
            <>
              <StyledTextField
                label="New current amount"
                size="small"
                variant="outlined"
                value={newContainer.currentAmount}
                onChange={e => {
                  setNewContainer({
                    ...newContainer,
                    currentAmount: e.target.value
                  })
                }}
              />
              <StyledAutocomplete
                disablePortal
                noOptionsText={"Loading UoMs..."}
                options={availableUOMs.filter(result => (result.type === 'weight' || result.type === 'volume' || result.type === 'unit')).map((type) => type.uoMName)}
                getOptionLabel={(option) => option}
                onChange={(e, value) => {
                  value = value === null ? "" : value
                  setNewContainer({
                    ...newContainer,
                    containerSizeUoM: value
                  })
                }}
                value={newContainer.containerSizeUoM}
                autoHighlight
                autoSelect
                renderInput={(params) => <TextField {...params} variant="outlined" size="small"
                  label="UoM"
                  InputProps={{ ...params.InputProps }} />}
              />
              <span style={{ height: '38px', display: 'flex', alignItems: 'center' }}>{newContainer.size > 0 ? (newContainer.currentAmount / listContainerSelected.reduce((a, b) => a + b.size, 0) * 100).toFixed(2) : 0}%</span>
            </>} 
        </div> */}
      </div>}
      <ModalSimpleButton title={modalSimpleTitle} buttonText={modalSimpleButtonText} buttonAction={closeModalSimpleButton} open={modalSimpleButton} setOpen={setModalSimpleButton}>
        <label>
          {modalSimpleText}
        </label>
      </ModalSimpleButton>
      <FilterMenu
        open={filteringOpen}
        setOpen={setFilteringOpen}
        applyBtnAction={applyFilters}
        cancelButtonAction={closeFiltering}
        filteringInfo={filterOptions}
        appliedFilters={filters}
        setAppliedFilters={setFilters}
      />
    </StyledContainer >
  );
};

export default ReceiveContainer;