import BlendDetails from "./BlendDetails"
import {useLocation} from 'react-router-dom';
import React, { useEffect, useState, useContext } from "react";
import { styled } from "@mui/material/styles";
import ModalNoButton from "../../../components/Modal/ModalNoButton";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import Batch from "../../../api/Formulations/Batch";
import { formatMidasNumber, isNumber, EvaluateOptionalNumberField, RoundNumber, ConvertUOMs, hasRole, Roles, downloadFile, BuildTestingPayload } from "../../../global";
import { Divider, TableRow, FormGroup, FormControlLabel, Switch, TextField, Autocomplete, IconButton, TableCell, Menu, MenuItem, CircularProgress } from "@mui/material";
import { UXDataTableWithoutBody, StyledTableCell, StyledTableBody, UXDataTableNested } from "../../../components/UXDataTable";
import MethodSelection from "../../../components/MethodSelection";
import MoreOptions from "@mui/icons-material/MoreVert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";
import ValidatedMidasNumberTextField from "../../../components/ValidatedMidasNumberTextField";
import LocateMidasNumber from "../NewBlends/LocateMidasNumber";
import { ChemIDSearchField } from "../../../components/ChemIDSearch";
import { GlobalButton, GlobalSecondaryButton } from "../../../pages/styles";
import BatchComponent from "../../../api/Formulations/BatchComponent";
import Attachments from "../../../api/Formulations/Attachments";
import UserContext from "../../../context/UserContext";
import ContainerType from "../../../api/LIMS/ContainerType";
import Location from "../../../api/Admin/Location";
import BatchSubmission from "../../../api/Formulations/BatchSubmission";
import PrintInformation from "../../../components/PrintInformation";
import PrintLabel from "../../../api/LIMS/PrintLabel";
import Container from "../../../api/LIMS/Container"
import BlendPrepSheet from "./BlendPrepSheet"
import FileDragAndDrop from "../../../components/FileDragAndDrop";
import ContainerTable from "../../../components/ContainerTable";
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

//import { useSerial } from "../../../context/SerialProvider";


const blendComponentCols = ['', '#', 'Notes', 'Component', 'Prefered MIDAS #', 'Alt. MIDAS #', 'Is Balance', 'Range', 'Remaining', 'Actual', 'Requested', 'Add Order', 'Addition Temp (C)']
const batchComponentCols = ['ChemID', 'Locate Container', 'MIDAS #', 'Container', 'Volume', 'Amount', 'Add Order', 'Actual Temp (C)', 'Substitution Reason']

const autoCompleteFontSize = 16
const componentGridFontSize = 12

const cellColoringValues = {1:'pink', 2:'#90EE90', 3:'#fff'}

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
    fontSize: autoCompleteFontSize,
  },
  width:"8%",
  marginRight:"15px"
});

const Option = styled('li')({
  fontSize: componentGridFontSize,
});

const StyledDivider = styled(Divider)({
  marginTop:"10px"
});

const StyledDiv = styled('div')({
  display:"flex", 
  marginTop:"0px"
});

 const defaultErrorCheck = 
 {
   toPrepareAmount: false, //value gets set on initial load
   toPrepareUoM: false, //value gets set on initial load
   batchErrorChecks: []
 } 

 const defaultContainerErrorCheck = 
 {
   containerType: false,
   size: false,
   currentAmount: false, 
   uom: false,
   location: null, 
   returnLocation: false, 
   ownerEmail: false
 } 
 
 const errorsModalTitle = "Errors Detected"
 const gramUoMObject = {uoMName: 'g', type: 'weight', metricStandardUoMName: 'g', metricStandardConversion: 1}

const EditBatch = ({ ...props }) => {
  const currentUser = useContext(UserContext)
  const roles = currentUser?.idTokenClaims.roles;

  //const {connection} = useSerial();

  const hasBatchComponentSubstituter = hasRole(Roles.BatchComponentSubstituter, roles) || hasRole(Roles.Developer, roles)
  const hasBlendStatusMaintainer = hasRole(Roles.BatchStatusMaintainer, roles) || hasRole(Roles.Developer, roles)

  const location = useLocation()
  const incomingBatchID  = location.state && location.state.incomingBatchID ? location.state.incomingBatchID : -1
  const [reloadBatch, setReloadBatch] = useState(false)

  const [myBatchInfo, setMyBatchInfo] = useState([])
  const [myMethods, setMyMethods] = useState([])
  const [switchShowTests, setSwitchShowTests] = useState(false)
  const [decimalsToRound, setDecimalsToRound] = useState(3)

  const [modalNoButtonOpen, setModalNoButtonOpen] = useState(false);
  const [modalNoButtonTitle, setModalNoButtonTitle] = useState('');
  const [modalNoButtonText, setModalNoButtonText] = useState('');

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = 'Ok'
  const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  const [modalMessagesText, setModalMessagesText] = useState('');

  const [modalDeleteAttachOpen, setModalDeleteAttachOpen] = useState(false);
  const [modalDeleteAttachButton1Text, setModalDeleteAttachButton1Text] = useState('');
  const [modalDeleteAttachButton2Text, setModalDeleteAttachButton2Text] = useState('');
  const [modalDeleteAttachTitle, setModalDeleteAttachTitle] = useState('');
  const [modalDeleteAttachText, setModalDeleteAttachText] = useState('');

  const [modalLocateMIDASNumberOpen, setModallocateMIDASNumberOpen] = useState(false)

  const [workingBatchComponentID, setWorkingBatchComponentID] = useState(null)
  const [workingBlendComponent, setWorkingBlendComponent] = useState(null)

  const [anchorEl, setAnchorEl] = useState(null)

  const [availableUOMs, setAvailableUOMs] = useState([])
  const [containerTypes, setContainerTypes] = useState([])
  const [availableLocations, setAvailableLocations] = useState([])

  const [toPrepareAmount, setToPrepareAmount] = useState(null)
  const [toPrepareUoM, setToPrepareUoM] = useState(null)

  const [errorChecks, setErrorChecks] = useState(defaultErrorCheck)
  const [containerErrorChecks, setContainerErrorChecks] = useState([])

  const [testingContainers, setTestingContainers] = useState([])

  const [rowOpen, setRowOpen] = useState([]);

  const [isBatchSaving, setIsBatchSaving] = useState(false)
  const [isComponentsSaving, setIsComponentsSaving] = useState(false)

  const [printInfoOpen, setPrintInfoOpen] = useState(false);
  const [printLabelInfo, setPrintLabelInfo] = useState(null)

  const isRowOpen = (componentName) => rowOpen.indexOf(componentName) !== -1;

  //determines when to disable the reqeuests and batches
  const masterDisableBatch = myBatchInfo.batchStatusName !== 'Submitted' 
                              && myBatchInfo.batchStatusName !== 'Assigned'
                              && myBatchInfo.batchStatusName !== 'Needs Review' 
                              && myBatchInfo.batchStatusName !== '' 
                              && myBatchInfo.batchStatusName !== null

  //const userDisabled = myBatchInfo.preparedByEmail !== currentUser.username && myBatchInfo.preparedByEmail !== null
  const [attachmentDeleteOptions, setAttachmentDeleteOptions] = useState(null)

  const handleRowExpand = (componentName) => {
    const rowOpenIndex = rowOpen.indexOf(componentName);
    let newTestRowOpen = [];

    if (rowOpenIndex === -1) {
      newTestRowOpen = newTestRowOpen.concat(rowOpen, componentName);
    } else if (rowOpenIndex === 0) {
      newTestRowOpen = newTestRowOpen.concat(rowOpen.slice(1));
    } else if (rowOpenIndex === rowOpen.length - 1) {
      newTestRowOpen = newTestRowOpen.concat(rowOpen.slice(0, -1));
    } else if (rowOpenIndex > 0) {
      newTestRowOpen = newTestRowOpen.concat(
        rowOpen.slice(0, rowOpenIndex),
        rowOpen.slice(rowOpenIndex + 1)
      );
    }
    setRowOpen(newTestRowOpen);
  };

  const openMenu = () => {
    return anchorEl != null
  }

  const handleOpenMoreOptions = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  function handlePrint() {
    window.print();
  }

  useEffect(() => {
    let sumContainerAmounts = 0
    const substanceObj = myBatchInfo.blendRequest?.blendSubstance ? myBatchInfo.blendRequest.blendSubstance : null
    const copyTestingContainers = structuredClone(testingContainers)

    testingContainers.forEach((oContainer) => {
      if (isNumber(oContainer.currentAmount) && oContainer.uom !== null && oContainer._originalAmount >= 0)
      {
        sumContainerAmounts = sumContainerAmounts + ConvertUOMs(null, substanceObj, oContainer.currentAmount, oContainer.uom, toPrepareUoM)
      }
    })

    let myRetainIndex = testingContainers.findIndex(result => result._originalAmount === -1)

    if (myRetainIndex >= 0)
    {
      //determine the retain container size
      let retainContainerAmount = 0
      let retainContainerType = containerTypes.find(o => o.name === "Unknown")
      
      retainContainerAmount = toPrepareAmount - sumContainerAmounts

      if (retainContainerAmount < 0 )
      {
        retainContainerAmount = 0
      }

      containerTypes.forEach(oContainerType => {
        if (oContainerType.defaultContainerSize !== null || oContainerType.defaultContainerUoMName !== null)
        {
          var myContainerSize = ConvertUOMs(null, substanceObj, retainContainerAmount, toPrepareUoM, availableUOMs.find(obj => obj.uoMName === oContainerType.defaultContainerUoMName));

          if (oContainerType.defaultContainerSize >= myContainerSize && retainContainerType.name === 'Unknown')
          {
            retainContainerType = containerTypes.find(o => o.name === oContainerType.name);
          
          }
        }
      });

      copyTestingContainers[myRetainIndex].containerType = retainContainerType
      copyTestingContainers[myRetainIndex].uom = toPrepareUoM
      copyTestingContainers[myRetainIndex].containerSizeUoM = toPrepareUoM.uoMName
      copyTestingContainers[myRetainIndex].containerType = containerTypes.find(obj => obj.name === retainContainerType.name)
      copyTestingContainers[myRetainIndex].containerTypeName = retainContainerType.name
      copyTestingContainers[myRetainIndex].currentAmount = retainContainerAmount
      copyTestingContainers[myRetainIndex].size = ConvertUOMs(null, substanceObj, retainContainerType.defaultContainerSize, availableUOMs.find(obj => obj.uoMName === retainContainerType.defaultContainerUoMName), toPrepareUoM)
 
      setTestingContainers(copyTestingContainers)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toPrepareAmount])

  useEffect(() => {
    let cancelPromise = false

    UnitOfMeasure.getAll().then((res) => {
      if (cancelPromise) return
      setAvailableUOMs(res.filter(result => (result.type === 'weight' || result.type ==='volume') && result.metricStandardConversion !== null && result.isActive === true).sort((a, b) => a.uoMName.localeCompare(b.uoMName)))
    });

    Location.getAll().then((res) => {
      if (cancelPromise) return
      setAvailableLocations(res.filter(result => result.isActive === true).sort((a, b) => a.locationName.localeCompare(b.locationName)))
    });

    ContainerType.getAll().then((res) => {
        if (cancelPromise) return
        setContainerTypes(res.filter(result => result.isActive === true || result.name === "Unknown").sort(function(a, b) {
          return (b.sizeOrder != null) - (a.sizeOrder != null) || a.sizeOrder - b.sizeOrder;
        }))
    });

  return () => {
    cancelPromise = true
  }
  }, [])

  useEffect(() => {
    (async () => {
      await LoadContainers();
    })();  

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myMethods, currentUser, myBatchInfo.blendRequest?.chargeCode, containerTypes, availableUOMs, availableLocations, myBatchInfo.batchRetainLocationName])

  useEffect(() => {
    if (incomingBatchID > -1 || reloadBatch === true)
    {
      let cancelPromise = false
      
      //open loading modal
      openModalNoButton("Loading Blend Data", "Please wait while blend data is loading...")

      Batch.getBatchByID(incomingBatchID).then((res) => {

        closeModalNoButton()

        if (cancelPromise) return

        if (res.message === "Success")
        {
          setToPrepareAmount(res.result.actualAmount ? res.result.actualAmount : res.result.requestedAmount)
          setToPrepareUoM(res.result.unitOfMeasureActual ? res.result.unitOfMeasureActual : res.result.unitOfMeasureRequested)
          
          //recalculate the requested percentages
          res.result.blendRequest.blendComponents.forEach((oBlendComponent) => {
            if (oBlendComponent.componentUoMName === 'wt%' || oBlendComponent.componentUoMName === 'vol%')
            {
              if (isNumber(oBlendComponent.targetAmountLower))
              {
                const componentIndex = res.result.blendRequest.blendComponents.findIndex(obj => obj.id === oBlendComponent.id)

                res.result.blendRequest.blendComponents[componentIndex].targetAmountLower = ((res.result.blendRequest.blendComponents[componentIndex].toPrepareValue / (res.result.actualAmount ? res.result.actualAmount : res.result.requestedAmount)) * 100)
              }
            }
          })

          setDecimalsToRound(res.result.batchPrecision ? res.result.batchPrecision : 3)
          setMyBatchInfo(InitializeBatch(res.result))
          setMyMethods(res.result.analyticalTesting ? JSON.parse(res.result.analyticalTesting) : [])      

        } else {
          openModalMessages("Error Loading", `There was an error loading the Batch ${incomingBatchID}, please try again.  ${res.message} `)
          setToPrepareAmount(0)
          setToPrepareUoM(gramUoMObject)
          setDecimalsToRound(3)
          setMyBatchInfo(InitializeBatch([]))
          setMyMethods([])
        } 
      });
      
      setReloadBatch(false)

      return () => {
        cancelPromise = true
      }
    
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingBatchID, reloadBatch])

  async function LoadContainers () {
    let cancelPromise = false

    if (myMethods.length > 0)
    {
      let newTests = []

      for (const oMethodInfo of myMethods)
      {
        const tests = await BuildTestingPayload(oMethodInfo, null, null, myBatchInfo.blendRequest.chargeCode, currentUser.username)

        if (tests === null || typeof tests === 'string' || typeof tests[0] === 'string')
        {
          if (Array.isArray(tests))
          {
            openModalMessages("Error Building Tests", tests)        
            return
          } else {
            openModalMessages("Error Building Tests", `There was an error building the test payload. ${tests ? tests : ''}`)        
            return
          }
        }
        for (const oTest of tests)
        {
          newTests.push(oTest)  
        }
      }
  
      let arrayContainerErrorChecks = []

      var testingInfo = {
        tests: newTests,
        containeringMethod: "MIDAS",
        requireParentContainer: false
      }

      Container.GetContainersToCreate(JSON.stringify(testingInfo)).then((res) => {
        if (cancelPromise) return

          let arrayContainers = []

          if (res.message === 'Success') 
          {
            res.result.forEach(oContainer => {
    
              oContainer.locationName = null
              oContainer.returnLocationName = 'DISCARD'
              oContainer.returnLocation = availableLocations.find(obj => obj.locationName === oContainer.returnLocationName)

              oContainer.ownerEmail = myBatchInfo.blendRequest.blendStudy.ownerEmail
              //oContainer.ownerEmail = currentUser.username
        
              //these properties are added to keep track of the min size, in case the user changes volumes
              oContainer._originalAmount = oContainer.currentAmount
              oContainer._originalUoM = oContainer.uom
              oContainer._isRetainContainer = false
              
              //set up the error checking on the containers          
              arrayContainers.push(oContainer)
              arrayContainerErrorChecks.push(structuredClone(defaultContainerErrorCheck))
            });
    
            //need to add a retain container, if necessary
            if (myBatchInfo.batchRetainLocationName !== 'DISCARD')
            {
              //determine the retain container size
              let sumContainerAmounts = 0
              let retainContainerAmount = 0
              let retainContainerType = containerTypes.find(o => o.name === "Unknown")
              const substanceObj = myBatchInfo.blendRequest.blendSubstance ? myBatchInfo.blendRequest.blendSubstance : null
    
              arrayContainers.forEach((oContainer) => {
                if (isNumber(oContainer.currentAmount) && oContainer.uom !== null)
                {
                  sumContainerAmounts = sumContainerAmounts + ConvertUOMs(null, substanceObj, oContainer.currentAmount, oContainer.uom, toPrepareUoM)
                }
              })
    
              retainContainerAmount = toPrepareAmount - sumContainerAmounts

              if (retainContainerAmount < 0 )
              {
                retainContainerAmount = 0
              }
    
              containerTypes.forEach(oContainerType => {
                if (oContainerType.defaultContainerSize !== null || oContainerType.defaultContainerUoMName !== null)
                {
                  var myContainerSize = ConvertUOMs(null, substanceObj, retainContainerAmount, toPrepareUoM, availableUOMs.find(obj => obj.uoMName === oContainerType.defaultContainerUoMName));

                  if (oContainerType.defaultContainerSize >= myContainerSize && retainContainerType.name === 'Unknown')
                  {
                    retainContainerType = containerTypes.find(o => o.name === oContainerType.name);
                  }
                }
    
              });
    
              arrayContainers.push({
                containerNumber: arrayContainers.length,
                uom: toPrepareUoM,
                containerSizeUoM: toPrepareUoM.uoMName,
                containerStatus: null,
                containerStatusName: "Confirmed",
                containerType: containerTypes.find(obj => obj.name === retainContainerType.name), //need routine to determine container type
                containerTypeName: retainContainerType.name,
                createdByEmail: null, 
                createdDate: null, 
                currentAmount: retainContainerAmount, // need to set this based off other containers and what is left in the blend
                cylinderSerialNumber: null, 
                discardOnDisposition: false,
                expirationDate: null, 
                id: 0,
                lastModifiedByEmail: null, 
                lastModifiedDate: null, 
                location: null, 
                locationName: null,
                nextDispositionDate: null, 
                ownerEmail: myBatchInfo.blendRequest.blendStudy.ownerEmail,
                parentContainer: null, 
                parentSampleContainer: null, 
                returnLocation:  availableLocations.find(obj => obj.locationName === myBatchInfo.batchRetainLocationName),
                returnLocationName: myBatchInfo.batchRetainLocationName,
                sample: null, 
                sampleName: null, 
                size: ConvertUOMs(null, substanceObj, retainContainerType.defaultContainerSize, availableUOMs.find(obj => obj.uoMName === retainContainerType.defaultContainerUoMName), toPrepareUoM), //need to set this based off container type
                subLocation: null, 
                tareWeight: null, 
                tareWeightUoM: null, 
                tests: null, 
                testsBackloggedOrPending: null, 
                testsInProgress: null,
                _isRetainContainer: true,
                _originalAmount: -1,
                _originalUoM: -1
              })
    
              arrayContainerErrorChecks.push(structuredClone(defaultContainerErrorCheck))
            }
          } else {
            openModalMessages("Error Loading Containers", `There was an error loading the testing/retain containers for ${incomingBatchID}. ${res.message}, please try again.`)
          }
          

        setTestingContainers(arrayContainers)
        setContainerErrorChecks(arrayContainerErrorChecks)
      });
    }

  return () => {
    cancelPromise = true
  }
  }

  function InitializeBatch (batchInfo) {
    const copyBatchInfo = structuredClone(batchInfo)
    const copyErrors = structuredClone(errorChecks)

    copyBatchInfo.blendRequest?.blendComponents?.forEach((oBlendComponent) => {

      const myBatchComponents = batchInfo.batchComponents.filter(obj => obj.blendComponentID === oBlendComponent.id)

      if(myBatchComponents.length === 0)
      {
        const newID = DetermineNewBatchComponentID(copyBatchInfo.batchComponents)

        copyBatchInfo.batchComponents.push({
          id: newID,
          container: null,
          batchID: batchInfo.id,
          batch: null,
          substitutionReason: null,
          actualAmount: null,
          orderAdded: null,
          uomName: null,
          uoM: oBlendComponent.componentUoMName === 'vol%' || oBlendComponent.componentUoMName === 'wt%' ? batchInfo.unitOfMeasureRequested : oBlendComponent.componentUoM,
          //uom: batchInfo.unitOfMeasureRequested,
          blendComponentID: oBlendComponent.id,
          blendComponent: oBlendComponent,
          actualTemperatureC: null,
          selectedSubstance: oBlendComponent.substance,
          selectedSample: null,      
        })

        copyErrors.batchErrorChecks.push({ 
          batchComponentID: newID,
          blendComponentID: oBlendComponent.id,
          container: null,
          substitutionReason: false,
          actualAmount: null,
          actualTemperatureC: false,
          selectedSubstance: false,
          selectedSample: null
        })
      } else 
      {

        myBatchComponents.forEach(oComponent => {
          copyErrors.batchErrorChecks.push({ 
            batchComponentID: oComponent.id,
            blendComponentID: oBlendComponent.id,
            container: oComponent.container === null,
            substitutionReason: EvaulateSubtitutionReasonField(oComponent.substitutionReason, oComponent.selectedSubstance?.chemID, oBlendComponent),
            actualAmount: EvaluateComponentAmountField(oComponent, null, null),
            actualTemperatureC:  EvaluateOptionalNumberField(oComponent.actualTemperatureC),
            selectedSubstance: oComponent.selectedSubstance === null,
            selectedSample: oComponent.selectedSample === null
          })
        });
      }
    });

    setErrorChecks(copyErrors)
    return copyBatchInfo
  }

  function closeModalNoButton() {
    setModalNoButtonOpen(false);
  }

  function openModalNoButton(title, text) {
    setModalNoButtonOpen(true);
    setModalNoButtonTitle(title);
    setModalNoButtonText(text);
  }

  function closeModalDeleteAttach() {
    setModalDeleteAttachOpen(false);
  }

  function openModalDeleteAttach(title, text, buttonText, button2Text) {
    setModalDeleteAttachButton1Text(buttonText)
    setModalDeleteAttachButton2Text(button2Text)
    setModalDeleteAttachOpen(true);
    setModalDeleteAttachTitle(title);
    setModalDeleteAttachText(text);
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  function CalculateComponentActuals(oBlendComponent){
    let displayValue = 0

    myBatchInfo.batchComponents.forEach((oBatchComponent) => {
      if (oBatchComponent.blendComponentID === oBlendComponent.id)
      {
        if(isNumber(oBatchComponent.actualAmount))
        {
          //displayValue = RoundNumber(displayValue + Number(oBatchComponent.actualAmount), decimalsToRound)
          displayValue = displayValue + Number(oBatchComponent.actualAmount)
        }
      }
    })

    return displayValue
  }

  function CalculatePrepareAmounts(toPrepareValue)
  {
    if (!(isNumber(toPrepareValue)))
    {
      return
    }

    const copyMyBatchInfo = structuredClone(myBatchInfo)

    for(var i = 0; i <  copyMyBatchInfo.blendRequest.blendComponents.length; i++) 
    {
      if (copyMyBatchInfo.blendRequest.blendComponents[i].componentUoMName === "wt%" || copyMyBatchInfo.blendRequest.blendComponents[i].componentUoMName === "vol%")
      {
        copyMyBatchInfo.blendRequest.blendComponents[i].toPrepareValue = Number(toPrepareValue) * (copyMyBatchInfo.blendRequest.blendComponents[i].targetAmountLower / 100);
      }
    }
    setToPrepareAmount(toPrepareValue)
    setMyBatchInfo(copyMyBatchInfo)
  }

  function AutoCompleteChangeAndValidate_ToPrepareUOM (value)
  {
    const copyMyBatchInfo = structuredClone(myBatchInfo)  
    const copyErrors = structuredClone(errorChecks)

    if (value === null)
    { 
      value = gramUoMObject
    }
    copyErrors.toPrepareUoM = (value === null ? true : false)
    
    //convert the to prepare amount based on UOM change
    if (value !== null && toPrepareUoM.uoMName !== value.uoMName)
    {
      let newToPrepareAmount = 0

      //get each new converted prepare amount
      myBatchInfo.blendRequest.blendComponents.forEach((oBlendComponent) => {
        if (oBlendComponent.componentUoMName === 'wt%' || oBlendComponent.componentUoMName === 'vol%')
        {
          let convertedValue = ConvertUOMs(null, oBlendComponent.substance, oBlendComponent.toPrepareValue, toPrepareUoM, value)

          if (isNumber(convertedValue))
          {
            const componentIndex = copyMyBatchInfo.blendRequest.blendComponents.findIndex(obj => obj.id === oBlendComponent.id)

            copyMyBatchInfo.blendRequest.blendComponents[componentIndex].toPrepareValue = convertedValue

            newToPrepareAmount = newToPrepareAmount + convertedValue
          }
        }
      })

      //recalculate the requested percentages
      myBatchInfo.blendRequest.blendComponents.forEach((oBlendComponent) => {
        if (oBlendComponent.componentUoMName === 'wt%' || oBlendComponent.componentUoMName === 'vol%')
        {
          if (isNumber(oBlendComponent.targetAmountLower))
          {
            const componentIndex = copyMyBatchInfo.blendRequest.blendComponents.findIndex(obj => obj.id === oBlendComponent.id)

            copyMyBatchInfo.blendRequest.blendComponents[componentIndex].targetAmountLower = ((copyMyBatchInfo.blendRequest.blendComponents[componentIndex].toPrepareValue / newToPrepareAmount) * 100)
          }
        }
      })

      for(var i = 0; i <  copyMyBatchInfo.batchComponents.length; i++) 
      {
        if (toPrepareUoM.uoMName === copyMyBatchInfo.batchComponents[i].uoM.uoMName)
        {
          copyMyBatchInfo.batchComponents[i].uoM = value
        }
      }

    setToPrepareAmount(RoundNumber(newToPrepareAmount, decimalsToRound))
    }

    setMyBatchInfo(copyMyBatchInfo)
    setToPrepareUoM(value)
    setErrorChecks(copyErrors)
  }

  function AutoCompleteChangeAndValidate_BatchComponents (property, value, batchComponentID)
  {
    const myBatchComponentIndex = myBatchInfo.batchComponents.findIndex(obj => obj.id === batchComponentID)
    const myBatchComponentIndex_Errors = errorChecks.batchErrorChecks.findIndex(obj => obj.batchComponentID === batchComponentID)

    const copyMyBatchInfo = structuredClone(myBatchInfo)
    const copyErrors = structuredClone(errorChecks)

    copyMyBatchInfo.batchComponents[myBatchComponentIndex][property] = value
    copyErrors.batchErrorChecks[myBatchComponentIndex_Errors][property] = value = value === null ? true : false 

    if (property === 'container')
    {
      if (copyMyBatchInfo.batchComponents[myBatchComponentIndex].container === null)
      {
        copyMyBatchInfo.batchComponents[myBatchComponentIndex].actualAmount = ''
        copyErrors.batchErrorChecks[myBatchComponentIndex_Errors].actualAmount = null
      }
    }
    
    setMyBatchInfo(copyMyBatchInfo)
    setErrorChecks(copyErrors)
  }

  function updateData_BatchComponents (property, value, batchComponentID)
  {
    const myBatchComponentIndex = myBatchInfo.batchComponents.findIndex(obj => obj.id === batchComponentID)

    const copyMyBatchInfo = structuredClone(myBatchInfo)

    copyMyBatchInfo.batchComponents[myBatchComponentIndex][property] = value

    if (property === 'selectedSample')
    {
      if (copyMyBatchInfo.batchComponents[myBatchComponentIndex].selectedSubstance === null)
      {
        if (copyMyBatchInfo.batchComponents[myBatchComponentIndex].selectedSample !== null)
        {
          copyMyBatchInfo.batchComponents[myBatchComponentIndex].selectedSubstance = copyMyBatchInfo.batchComponents[myBatchComponentIndex].selectedSample.substance 
        }
      }

      copyMyBatchInfo.batchComponents[myBatchComponentIndex].container = null
    }

    if (property === 'selectedSubstance')
    {
      if (copyMyBatchInfo.batchComponents[myBatchComponentIndex].selectedSample !== null)
      {
        copyMyBatchInfo.batchComponents[myBatchComponentIndex].selectedSample = null
        copyMyBatchInfo.batchComponents[myBatchComponentIndex].container = null
      }
    }
      setMyBatchInfo(copyMyBatchInfo)
  }

  function updateData_SampleAndContainer (sampleObject, containerObject, batchComponentID)
  {
    const myBatchComponentIndex = myBatchInfo.batchComponents.findIndex(obj => obj.id === batchComponentID)
    const myBatchComponentIndex_Errors = errorChecks.batchErrorChecks.findIndex(obj => obj.batchComponentID === batchComponentID)

    const copyMyBatchInfo = structuredClone(myBatchInfo)
    const copyErrors = structuredClone(errorChecks)

    copyMyBatchInfo.batchComponents[myBatchComponentIndex].selectedSample = sampleObject
    copyMyBatchInfo.batchComponents[myBatchComponentIndex].container = containerObject

    copyErrors.batchErrorChecks[myBatchComponentIndex_Errors].selectedSample = sampleObject = sampleObject === null ? true : false 
    copyErrors.batchErrorChecks[myBatchComponentIndex_Errors].container = containerObject = containerObject === null ? true : false 
    
    setMyBatchInfo(copyMyBatchInfo)
    setErrorChecks(copyErrors)
  }

  function RoundBatchComponentAmount (value, batchComponentID, decimals)
  {
    if(isNumber(value))
    {
      const myBatchComponentIndex = myBatchInfo.batchComponents.findIndex(obj => obj.id === batchComponentID)
      const copyMyBatchInfo = structuredClone(myBatchInfo)

      copyMyBatchInfo.batchComponents[myBatchComponentIndex].actualAmount = RoundNumber(value, decimals, true)
      setMyBatchInfo(copyMyBatchInfo)
    }
  }

  function updateErrorChecks_BatchComponents (property, value,  batchComponentID)
  {
    const myBatchComponentIndex = errorChecks.batchErrorChecks.findIndex(obj => obj.batchComponentID === batchComponentID)
    const copyErrors = structuredClone(errorChecks)

    copyErrors.batchErrorChecks[myBatchComponentIndex][property] = value

    setErrorChecks(copyErrors)
  }

  function EvaulateSubtitutionReasonField (value, selectedChemID, myBlendComponent)
  {
    if (selectedChemID === null)
    {
      return false
    }

    const originalChemID = myBlendComponent.substance.chemID 

    if (originalChemID === selectedChemID) 
    {
        return false
    } else
    {
        return (value === null || value.trim() === '')
    }
  }

  function EvaluateComponentAmountField (oBatchComponent)
  {
    //throw error if it isn't a number
    if (!(isNumber(oBatchComponent.actualAmount)))
    {
      return true
    } else {
      //const convertedAmt = ConvertUOMs(oBatchComponent.selectedSample, oBatchComponent.selectedSample.substance, oBatchComponent.container.currentAmount, oBatchComponent.container.uom, toPrepareUoM)
      const convertedAmt = ConvertUOMs(oBatchComponent.selectedSample, oBatchComponent.selectedSample.substance, oBatchComponent.container.currentAmount, oBatchComponent.container.uom, oBatchComponent.uoM)

      if (Number(RoundNumber(oBatchComponent.actualAmount, decimalsToRound)) > RoundNumber(convertedAmt, decimalsToRound))
      {
        return true
      } else {
        return false
      }
    }
  }

  function DetermineNewBatchComponentID (oBatchComponents)
  {
    let newID = -1

    if (oBatchComponents.length === 0)
    {
      return newID
    }

    const newComponents = oBatchComponents.filter(obj => obj.id < 0).sort((a, b) => b.id - a.id)

    if (newComponents.length === 0)
    {
      return newID
    }

    let iCount = -1

    newComponents.forEach((oBatchComponent) => {
      if (iCount === oBatchComponent.id)
      {
        iCount -= 1
      } else {
        return iCount
      }
    })

    return iCount
  }

  function AddNewBatchComponent (oBlendComponent) {
    const copyBatchInfo = structuredClone(myBatchInfo)
    const copyErrors = structuredClone(errorChecks)

    const newID = DetermineNewBatchComponentID(copyBatchInfo.batchComponents)

    copyBatchInfo.batchComponents.push({
      id: newID,
      container: null,
      batchID: myBatchInfo.id,
      batch: null,
      substitutionReason: null,
      actualAmount: null,
      orderAdded: null,
      uomName: null,
      uoM: toPrepareUoM,
      blendComponentID: oBlendComponent.id,
      blendComponent: oBlendComponent,
      actualTemperatureC: null,
      selectedSubstance: oBlendComponent.substance,
      selectedSample: null,      
    })

    copyErrors.batchErrorChecks.push({ 
      batchComponentID: newID,
      blendComponentID: oBlendComponent.id,
      container: null,
      substitutionReason: false,
      actualAmount: null,
      uom: null,
      actualTemperatureC: false,
      selectedSubstance: false,
      selectedSample: null
    })

    setErrorChecks(copyErrors)
    setMyBatchInfo(copyBatchInfo)
  }

  function DeleteBatchComponent (oBatchComponentID) {

    const copyBatchInfo = structuredClone(myBatchInfo)
    const copyErrors = structuredClone(errorChecks)

    const myBatchComponentIndex = copyBatchInfo.batchComponents.findIndex(obj => obj.id === oBatchComponentID)
    const myBatchErrorComponentIndex = copyErrors.batchErrorChecks.findIndex(obj => obj.batchComponentID === oBatchComponentID)

    const batchComponentID = copyBatchInfo.batchComponents[myBatchComponentIndex].id

    if (batchComponentID >= 0)
    {
      BatchComponent.delete(batchComponentID).then((res) => {

        if ((res && res.result && res.result === 'Success')) {  
          copyBatchInfo.batchComponents.splice(myBatchComponentIndex, 1)
          copyErrors.batchErrorChecks.splice(myBatchErrorComponentIndex, 1)
  
          setErrorChecks(copyErrors)
          setMyBatchInfo(copyBatchInfo)
    
        } else {
          openModalMessages("Error Deleting Component", `${res ? res.message : 'There were problems deleting the Batch Component'}.  Please try again or contact support.`)
        }
      })
    } else {
      copyBatchInfo.batchComponents.splice(myBatchComponentIndex, 1)
      copyErrors.batchErrorChecks.splice(myBatchErrorComponentIndex, 1)

      setErrorChecks(copyErrors)
      setMyBatchInfo(copyBatchInfo)
    }
    
  }

  function DetermineBlendComponentActualColoring (oComponent, actualValue) {
    if (actualValue > 0)
    { 
      if (actualValue < DetermineToPrepareLowerRange(oComponent) || actualValue >  DetermineToPrepareUpperRange(oComponent))
      {
        //return ('pink')
        return 1
      } else {
        //return ('#90EE90')
        return 2
      }
    } else {
      //return ('#fff')
      return 3
    }
  }

  function DetermineToPrepareUpperRange (oComponent)
  {   
    let lowerValue = RoundNumber(oComponent.toPrepareValue + (oComponent.toPrepareValue * (myBatchInfo.blendFacility.preparationThresholdPercent / 100)), decimalsToRound)
    let upperValue = RoundNumber(oComponent.toPrepareValue - (oComponent.toPrepareValue * (myBatchInfo.blendFacility.preparationThresholdPercent / 100)), decimalsToRound)
    let i = 1;

    if (lowerValue === upperValue && lowerValue !== 0)
    {
      do {
        lowerValue = RoundNumber(oComponent.toPrepareValue + (oComponent.toPrepareValue * (myBatchInfo.blendFacility.preparationThresholdPercent / 100)), decimalsToRound + i)
        upperValue = RoundNumber(oComponent.toPrepareValue - (oComponent.toPrepareValue * (myBatchInfo.blendFacility.preparationThresholdPercent / 100)), decimalsToRound)

        i += 1
      } while (lowerValue === upperValue);
    }

    return lowerValue;
    //return (RoundNumber(oComponent.toPrepareValue + (oComponent.toPrepareValue * (myBatchInfo.blendFacility.preparationThresholdPercent / 100)), decimalsToRound))
  }

  function DetermineToPrepareLowerRange (oComponent)
  { 
    let lowerValue = RoundNumber(oComponent.toPrepareValue + (oComponent.toPrepareValue * (myBatchInfo.blendFacility.preparationThresholdPercent / 100)), decimalsToRound)
    let upperValue = RoundNumber(oComponent.toPrepareValue - (oComponent.toPrepareValue * (myBatchInfo.blendFacility.preparationThresholdPercent / 100)), decimalsToRound)
    let i = 1;

    if (lowerValue === upperValue && upperValue !== 0)
    {
      do {
        lowerValue = RoundNumber(oComponent.toPrepareValue + (oComponent.toPrepareValue * (myBatchInfo.blendFacility.preparationThresholdPercent / 100)), decimalsToRound)
        upperValue = RoundNumber(oComponent.toPrepareValue - (oComponent.toPrepareValue * (myBatchInfo.blendFacility.preparationThresholdPercent / 100)), decimalsToRound + i)

        i += 1
      } while (lowerValue === upperValue);
    }

    return upperValue;

    //return (RoundNumber(oComponent.toPrepareValue - (oComponent.toPrepareValue * (myBatchInfo.blendFacility.preparationThresholdPercent / 100)), decimalsToRound))
  }

  function HowManyDecimalsToRound (oBlendComponent)
  {
    let lowerLimit = DetermineToPrepareLowerRange(oBlendComponent);

    let splitString = lowerLimit.toString().split('.')

    if (splitString.length < 2)
    {
      return decimalsToRound;

    } else {
      return lowerLimit.toString().split('.')[1].length;

    }
  }

  function DetermineUoMDisplay (oComponent)
  {
    if (oComponent.componentUoMName === 'wt%' || oComponent.componentUoMName === 'vol%')
    {
      return (toPrepareUoM !== null ? toPrepareUoM.uoMName : '')
    } else {
      return (oComponent.componentUoMName)
    }
  }

  function PopulateSelectedContainerVolume(oBatchComponent)
  {
    if (oBatchComponent.container)
    {
      return(`${RoundNumber(ConvertUOMs(oBatchComponent.selectedSample, oBatchComponent.selectedSample?.substance, oBatchComponent.container.currentAmount, oBatchComponent.container.uom, oBatchComponent.uoM), decimalsToRound, true)} ${oBatchComponent.uoM.uoMName}`)
      //return(`${RoundNumber(ConvertUOMs(oBatchComponent.selectedSample, oBatchComponent.selectedSample?.substance, oBatchComponent.container.currentAmount, oBatchComponent.container.uom, toPrepareUoM), decimalsToRound).toLocaleString()} ${toPrepareUoM.uoMName}`)

    } else {
      return('')
    }
  }

  function CheckBatchComponentsForErrors ()
  {
    let hasErrors = []
    let myBlendComponent 

    //check sum of batch component actuals to ensure they fall within the range for Blend Component
    myBatchInfo.blendRequest.blendComponents.forEach(oBlendComponent => {
      let componentActual = CalculateComponentActuals(oBlendComponent)
      let coloringIndex = DetermineBlendComponentActualColoring(oBlendComponent, componentActual)

      switch (coloringIndex) {
        case 1:
          hasErrors.push(`The batch component amounts for ${oBlendComponent.substanceName} are not within the allowed range.`)
          break;
      
        case 2:
          //this means it is good
          break;

        case 3:
          //this means blank which would be caught below
          break;
      
        default:
          break;
      }

    })

    errorChecks.batchErrorChecks.forEach(oError => {
      myBlendComponent = myBatchInfo.blendRequest.blendComponents.find(obj => obj.id === oError.blendComponentID)    
      
      if (oError.selectedSubstance === true || oError.selectedSubstance === null)
      {
        hasErrors.push(`A ChemID is required on Blend Component ${myBlendComponent.substanceName}.`)
      }

      if (oError.selectedSample === true || oError.selectedSample === null)
      {
        hasErrors.push(`A MIDAS number is required on Blend Component ${myBlendComponent.substanceName}.`)
      }

      if (oError.container === true || oError.container === null)
      {
        hasErrors.push(`A container is required on Blend Component ${myBlendComponent.substanceName}.`)
      }

      if (oError.actualAmount === true || oError.actualAmount === null)
      {
        hasErrors.push(`The actual amount is required/invalid on Blend Component ${myBlendComponent.substanceName}.`)
      }

      if (oError.actualTemperatureC === true || oError.actualTemperatureC === null)
      {
        hasErrors.push(`The actual temp (C) is required/invalid on Blend Component ${myBlendComponent.substanceName}.`)
      }
    });

    if (errorChecks.toPrepareAmount === true || errorChecks.toPrepareAmount === null)
    {
        hasErrors.push(`The to prepare amount is invalid.`)
    }

    if (errorChecks.toPrepareUoM === true || errorChecks.toPrepareUoM === null)
    {
        hasErrors.push(`The to prepare UoM is invalid.`)
    }

    //if all checks were passed add it to the collection to register
    if (hasErrors.length === 0) {
      return false
    } else {
      openModalMessages(errorsModalTitle, hasErrors, "Ok")
      return true
    }
  }

  function CheckContainersForErrors ()
  {
    let hasErrors = []

    containerErrorChecks.forEach((oError, index) => {      
      if (oError.containerType === true || oError.containerType === null)
      {
        hasErrors.push(`A Container Type is required on Container ${index}.`)
      }

      if (oError.size === true || oError.size === null)
      {
        hasErrors.push(`A Container Size is required/invalid on Container ${index}.`)
      }

      if (oError.currentAmount === true || oError.currentAmount === null)
      {
        hasErrors.push(`A Container Amount is required/invalid on Container ${index}.`)
      }

      if (oError.location === true || oError.location === null)
      {
        hasErrors.push(`A Container Initial Location is required on Container ${index}.`)
      }

      if (oError.returnLocation === true || oError.returnLocation === null)
      {
        hasErrors.push(`A Container Return Location is required on Container ${index}.`)
      }

      if (oError.ownerEmail === true || oError.ownerEmail === null)
      {
        hasErrors.push(`A Container Owner Email is required/invalid on Container ${index}.`)
      }
    });

    let sumContainerAmounts = 0
    const substanceObj = myBatchInfo.blendRequest.blendSubstance ? myBatchInfo.blendRequest.blendSubstance : null

    testingContainers.forEach((oContainer, index) => {
      if (isNumber(oContainer.currentAmount) && oContainer.uom !== null)
      {
        sumContainerAmounts = sumContainerAmounts + ConvertUOMs(null, substanceObj, oContainer.currentAmount, oContainer.uom, toPrepareUoM)

        //check to make sure they didn't reduce lower than the original amount
        if (oContainer._originalAmount && oContainer._isRetainContainer === false)
        {
          if (ConvertUOMs(null, substanceObj, oContainer.currentAmount, oContainer.uom, oContainer._originalUoM) < oContainer._originalAmount)
          {
            hasErrors.push(`The current amount of container ${index} (${oContainer.currentAmount} ${oContainer.uom.uoMName}) is less than the necessary amount for testing (${oContainer._originalAmount} ${oContainer._originalUoM.uoMName}).`)
          }
        }
      }
    })

    if (sumContainerAmounts > toPrepareAmount)
    {
      hasErrors.push(`The sum of the container volumes (${RoundNumber(sumContainerAmounts, 2)} ${toPrepareUoM.uoMName}) exceed the batch prepare amount of (${toPrepareAmount} ${toPrepareUoM.uoMName}).`)      
    }

    //if all checks were passed add it to the collection to register
    if (hasErrors.length === 0) {
      return false
    } else {
      openModalMessages(errorsModalTitle, hasErrors, "Ok")
      return true
    }
  }

  function SaveBatchComponents()
  {

    if (CheckBatchComponentsForErrors())
    {
      return
    }

    //set the batch components uom to be the toPrepareUoM
    //const copyMyBatchInfo = structuredClone(myBatchInfo)

    // for(var i = 0; i <  copyMyBatchInfo.batchComponents.length; i++) 
    // {
    //   copyMyBatchInfo.batchComponents[i].uom = toPrepareUoM
    // }

    setIsComponentsSaving(true)

    Batch.updateBatchActuals(myBatchInfo.id, toPrepareAmount, toPrepareUoM.uoMName).then((response) => {

      if (response.message === 'Success')
      {
        BatchComponent.CreateBatchComponents(myBatchInfo.batchComponents).then((res) => {

          if (res.message === 'Success') {
            openModalMessages('Save Complete', `Batch Components saved successfully!`)
            setReloadBatch(true)
          } else{
            if (res.message)
            {
              openModalMessages('Components Failed to Save', `${res ? res.message : ''}. Contact support if you feel this is an error.`);
            } else {
              openModalMessages('Components Failed to Save', `Unspecified Error, Contact support if you feel this is an error.`);
            } 
          }
    
          setIsComponentsSaving(false)
        });
      } else {
        if (response.message)
        {
          openModalMessages('Components Failed to Save', `${response ? response.message : ''}. Contact support if you feel this is an error.`);
        } else {
          openModalMessages('Components Failed to Save', `Unspecified Error, Contact support if you feel this is an error.`);
        }
      }
    })
  }

  function CreateBlend_Sample_Containers_Tests()
  {
    if (CheckContainersForErrors())
    {
      return
    }

    if (CheckBatchComponentsForErrors())
    {
      return
    }

    setIsBatchSaving(true)

    let copyMyBatch = structuredClone(myBatchInfo)

    copyMyBatch.actualAmount = toPrepareAmount
    copyMyBatch.unitOfMeasureActual = toPrepareUoM

    let tempBatchSubmission = new BatchSubmission({
      batchToSubmit: copyMyBatch,
      containersWithTests: testingContainers       
    })

    const formData = new FormData();

    formData.append("batchSubmission", JSON.stringify(tempBatchSubmission))

    copyMyBatch.batchAttachments.forEach(oAttachment => {
      if (oAttachment.id === null || oAttachment.id < 1) {
        formData.append("batchFiles", oAttachment.fileObject, oAttachment.fileName)
      }
    })

    Batch.SubmitPreparedBatch(formData).then((res) => {

      if (res.message === 'Success') {
        openPrintInfo(res.result)
      }
      else{
        openModalMessages('Batch Failed to Save', `${res.message}. Contact support if you feel this is an error.`, 'Ok');
      }

      setIsBatchSaving(false)
    });
  }

  function openPrintInfo(returnedBatchInfo) {
    let arrayPrintInfo = []
    let myContainerNumbers = []

    returnedBatchInfo.preparedSample.containers.forEach((oContainer) => {
      myContainerNumbers.push(oContainer.containerNumber)   
    })

    let newPrintInfo = new PrintLabel({
      sampleName: returnedBatchInfo.preparedSample.sampleName,
      containerNumbers: myContainerNumbers,
      includeAdditionalSampleInformation: false,
      chemIDOnly: false,
      printLabelSizeType: null,
      isBlindCoded: false,
      blindDescription: null,
      blindSampleName: null,
      isShelfLabel: false,
      shelfLabelText: null,
      includeShelfBarcode: false
    })

    arrayPrintInfo.push(newPrintInfo)
  
    setPrintLabelInfo(arrayPrintInfo)
    setPrintInfoOpen(true)  
  }

  function closePrintInformation() {
    setPrintInfoOpen(false)
    window.location.href = "/blends"
  }

  const ConfirmRemoveAttachmentBatch = (file) => {   
    setAttachmentDeleteOptions({property:'batchAttachments', file:file})
    openModalDeleteAttach(`Remove Attachment?`, "Are you sure you want to remove the attachment?", "Yes", "No")
  }

  function HandleFileAdd (newFiles) {
    const listFiles = newFiles.map(item => {
      return {
        id: 0,
        batchID: myBatchInfo.id,
        fileName: item.name,
        storagePath: '',
        fileObject: item,
        base64String: null
      }
    })

    const copyMyBatchInfo = structuredClone(myBatchInfo)

    copyMyBatchInfo.batchAttachments = copyMyBatchInfo.batchAttachments.concat(listFiles)

    setMyBatchInfo(copyMyBatchInfo)
  }

  function deleteFile (file) {

    let newFiles

    closeModalDeleteAttach()

    if (attachmentDeleteOptions === null)
    { 
        return
    }

    const property = attachmentDeleteOptions.property
    const copyMyBatchInfo = structuredClone(myBatchInfo)

    if (property === 'batchAttachments')
    {
        if (attachmentDeleteOptions.file.id > 0)
        {
            Attachments.deleteBatchAttachment(attachmentDeleteOptions.file.id).then((res) => {
                if (res && res.message === 'Success') {
                    newFiles = myBatchInfo.batchAttachments.filter(f => f !== attachmentDeleteOptions.file)     
                    setAttachmentDeleteOptions(null)

                    copyMyBatchInfo.batchAttachments = newFiles
                    setMyBatchInfo(copyMyBatchInfo)      
                } else {
                    openModalMessages("Error Removing Attachment", `${res ? res.message : 'Error removing attachment'}.  Please try again or contact support.`)
                    return
                }
            })
        } else {
            newFiles = myBatchInfo.batchAttachments.filter(f => f !== attachmentDeleteOptions.file)    
            setAttachmentDeleteOptions(null)

            copyMyBatchInfo.batchAttachments = newFiles
            setMyBatchInfo(copyMyBatchInfo)
        }  
    }
  }

  function HandleFileDownloadBatch (file) {
    downloadFile('batchfiles', file.fileName, file.storagePath, openModalMessages)
  }

  function IsDeleteDisabled (oBlendComponent) {

    if (oBlendComponent === null) {
      return true
    }

    const filteredArray = myBatchInfo.batchComponents.filter(obj => obj.blendComponentID === oBlendComponent.id)

    if (filteredArray && filteredArray.length <= 1)
    {
      return true
    } else {
      return false
    }
  }

  function GetAdditionOrderValues ()
  {
    let additionOrderValues = []

    for(var i = 0; i <  myBatchInfo.batchComponents.length; i++) {
      additionOrderValues.push(i + 1)
    }

    myBatchInfo.batchComponents.forEach(component => {
      if (component.orderAdded && component.orderAdded > 0)
      {
        additionOrderValues = additionOrderValues.filter(number => number !== component.orderAdded)
      }
    });

    return additionOrderValues
  }

  function GetRequestedUOMDisplay (componentInfo) {

    if (componentInfo.componentUoMName === 'wt%' || componentInfo.componentUoMName === 'vol%')
    {
      if (toPrepareUoM)
      {
        if (toPrepareUoM.type === 'weight') {
          return 'wt%'
        } else {
          return 'vol%'
        }
      } else {
        return 'wt%'
      }
    } else {
      return componentInfo.componentUoMName
    }
  }

    return (
        <div style={{paddingBottom:"2rem"}}>

        <span className='pageheader'>{'Blend Preparer'}</span>
            <Divider className='dividerbar' />

            <BlendDetails 
              batchInfo={myBatchInfo} 
              setBatchInfo={setMyBatchInfo}
              setReloadBatchInfo={setReloadBatch}
              hasBlendStatusMaintainer={hasBlendStatusMaintainer}
              >
            </BlendDetails>

            {/* <GlobalButton onClick={() => connection.connect()}> Connection to COMS</GlobalButton> */}

            <StyledDiv>
              <p style={{
                fontFamily:"EMprint",
                fontWeight:"600",
                fontSize:"16px",
                color:"#545459",
                textTransform: 'none',
                textAlign:"left",
                paddingTop:"20px"
                }}>Batch Components</p>
            </StyledDiv>

            <StyledDiv>
              <TextField style={{ id:"outlined-normal", width:"8%", marginRight:"15px", marginTop:"1rem"}} size="small" margin="normal" variant="outlined" label="To Prepare Amt." 
                inputProps={{ maxLength: 10 }}
                InputLabelProps={{shrink: true}}
                onChange={e => CalculatePrepareAmounts(e.target.value)}
                value={toPrepareAmount === null ? '' : toPrepareAmount}
                onBlur={e => setErrorChecks(() => ({
                  ...errorChecks,
                  toPrepareAmount: !(isNumber(toPrepareAmount))
                }))}
                error={errorChecks.toPrepareAmount === null ? false : errorChecks.toPrepareAmount}
                helperText = {errorChecks.toPrepareAmount ? 'This is required or invalid' : ''}
              ></TextField>

              <StyledAutocomplete
                disablePortal
                noOptionsText={"Loading UoMs..."}
                options={availableUOMs}
                getOptionLabel={(option) => option.uoMName}
                onChange={(e, value) => AutoCompleteChangeAndValidate_ToPrepareUOM(value)}
                autoHighlight
                autoSelect
                value={toPrepareUoM}
                isOptionEqualToValue={(option, value) => value.uoMName === option.uoMName}
                renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label="Unit of Measure" error={errorChecks.toPrepareUoM === null ? false : errorChecks.toPrepareUoM}
                helperText = {errorChecks.toPrepareUoM ? 'This is required' : ''}
                InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
              />

              <GlobalSecondaryButton 
                variant='contained'
                style={{ marginTop:"20px", marginRight:"20px" }}
                onClick={(e) => handlePrint()}
              >{'Print Prep Sheet'}
              </GlobalSecondaryButton>
            </StyledDiv>

          <StyledDiv>


            <UXDataTableWithoutBody 
              tableWidth='100%' 
              cols={blendComponentCols} 
              blankFirstHeader={false}
              tableId="containerTable"
              rowLength={myBatchInfo.blendRequest ? myBatchInfo.blendRequest.blendComponents.length : 0}
              enablePaging={false}
              rowsPerPage={0}
              page={0}
              handleChangePage={null}
              handleChangeRowsPerPage={null}
              noDataFoundMessage={'No components found!'}
              enableAddIcon={false}
              addFunction={null}
              addToolTipText={''}  
              isDataLoading={false}
            >                  
              <StyledTableBody>
                {myBatchInfo.blendRequest && myBatchInfo.blendRequest.blendComponents.map((oComponentInfo, index) => {

                  let componentActual = CalculateComponentActuals(oComponentInfo)
                  let componentDecimals = HowManyDecimalsToRound(oComponentInfo)

                  return(
                    <React.Fragment key={index}>
                      <TableRow key={`ComponentRow${index}`}>
                        <StyledTableCell align='right' style={{width:'5rem'}}>
                          <IconButton
                            aria-label='expand row'
                            size='small'
                            onClick={() => handleRowExpand(oComponentInfo.substanceName)}>
                            {isRowOpen(oComponentInfo.substanceName) ? (
                              <KeyboardArrowUpIcon/>
                            ) : (
                              <KeyboardArrowDownIcon/>
                            )}
                          </IconButton>
                        </StyledTableCell>

                        <StyledTableCell style={{width:"14px"}}>
                          {index + 1}
                        </StyledTableCell>
                          
                        <StyledTableCell style={{width:"50px"}}>
                          {oComponentInfo.componentInstructions &&
                            <IntegrationInstructionsIcon 
                              onClick={() => openModalMessages("Component Instructions", oComponentInfo.componentInstructions)}
                            >
                            </IntegrationInstructionsIcon>
                          }
                        </StyledTableCell>

                        <StyledTableCell>                      
                          {oComponentInfo.substanceName}
                        </StyledTableCell>
                          
                        <StyledTableCell>                        
                          {oComponentInfo.preferredSampleName && oComponentInfo.preferredSampleName !== '' ? formatMidasNumber(oComponentInfo.preferredSampleName) : '-'}
                        </StyledTableCell>

                        <StyledTableCell>                        
                          {oComponentInfo.preferredSampleName && oComponentInfo.secondaryPreferredSampleName !== '' ? formatMidasNumber(oComponentInfo.secondaryPreferredSampleName) : '-'}
                        </StyledTableCell>

                        <StyledTableCell>                        
                          {oComponentInfo.isBalance ? 'Yes' : 'No'}
                        </StyledTableCell>

                        <StyledTableCell>
                          {`${DetermineToPrepareLowerRange(oComponentInfo)} ${DetermineUoMDisplay(oComponentInfo)} - ${DetermineToPrepareUpperRange(oComponentInfo)} ${DetermineUoMDisplay(oComponentInfo)} `}
                        </StyledTableCell>

                        <StyledTableCell>
                          {`${RoundNumber((oComponentInfo.toPrepareValue - componentActual), componentDecimals)} ${DetermineUoMDisplay(oComponentInfo)}`}    
                          {/* {`${RoundNumber((oComponentInfo.toPrepareValue - componentActual), decimalsToRound)} ${DetermineUoMDisplay(oComponentInfo)}`}*/}
                        </StyledTableCell>

                        <StyledTableCell style={{background: cellColoringValues[DetermineBlendComponentActualColoring(oComponentInfo, componentActual)]}}>
                          {`${componentActual} ${DetermineUoMDisplay(oComponentInfo)}`}                           
                        </StyledTableCell>

                        <StyledTableCell>
                          {`${RoundNumber(oComponentInfo.targetAmountLower, decimalsToRound)} ${GetRequestedUOMDisplay(oComponentInfo)}`}
                          {/* {`${RoundNumber(oComponentInfo.targetAmountLower, decimalsToRound)} ${oComponentInfo.componentUoM.uoMName}`} */}
                        </StyledTableCell>

                        <StyledTableCell>
                          {oComponentInfo.addOrder && oComponentInfo.addOrder !== '' ? oComponentInfo.addOrder : '-'}
                        </StyledTableCell>

                        <StyledTableCell>
                          {oComponentInfo.blendAdditionTemperatureC && oComponentInfo.blendAdditionTemperatureC !== '' ? oComponentInfo.blendAdditionTemperatureC : '-'}
                        </StyledTableCell>
                          
                      </TableRow>

                      <UXDataTableNested 
                        tableWidth={'100%'} 
                        cols={batchComponentCols} 
                        blankFirstHeader={true} 
                        colSpan={blendComponentCols.length + 1} 
                        isOpenComparator={oComponentInfo.substanceName} 
                        isOpenArray={rowOpen}>
        
                        <StyledTableBody key={`batchComponentTable${index}`}>
                          {myBatchInfo.batchComponents && myBatchInfo.batchComponents.filter(result => result.blendComponentID === oComponentInfo.id).map((oBatchComponent, batchComponentIndex) => {
                            return(
                              <TableRow key={`BatchComponent${index}-${batchComponentIndex}`}>
                                <StyledTableCell component='th' scope='row' style={{width:'2rem'}}>
                                  <IconButton aria-label="more options" 
                                    onClick={(e) => {handleOpenMoreOptions(e); setWorkingBlendComponent(oComponentInfo); setWorkingBatchComponentID(oBatchComponent.id)}}>
                                      <MoreOptions color='black'/>
                                  </IconButton>
                                </StyledTableCell>
                                <StyledTableCell  style={{width:'10rem'}}>
                                  <ChemIDSearchField 
                                    fontSize = {12}
                                    fieldWidth = {"165px"} 
                                    selectedChemIDObject = {oBatchComponent.selectedSubstance}
                                    setSelectedChemIDObject = {(e) => updateData_BatchComponents('selectedSubstance', e, oBatchComponent.id)}
                                    hasErrors = {errorChecks.batchErrorChecks[errorChecks.batchErrorChecks.findIndex(obj => obj.batchComponentID === oBatchComponent.id)].selectedSubstance === null 
                                        ? false 
                                        : errorChecks.batchErrorChecks[errorChecks.batchErrorChecks.findIndex(obj => obj.batchComponentID === oBatchComponent.id)].selectedSubstance}
                                    setHasErrors = {(e) => updateErrorChecks_BatchComponents('selectedSubstance', e, oBatchComponent.id)}
                                    isDisabled = {!(hasBatchComponentSubstituter)}
                                    allowInactiveChemIDs={false}
                                    returnSubstanceStrucureData={false}
                                  ></ChemIDSearchField>
                                </StyledTableCell>

                                {/* Locate Midas number */}
                                <TableCell align='center' style={{width:"150px"}}>
                                    <ManageSearchIcon 
                                      onClick={() => {setModallocateMIDASNumberOpen(true); setWorkingBatchComponentID(oBatchComponent.id)}}
                                    >
                                    </ManageSearchIcon>
                                </TableCell>

                                <StyledTableCell style={{width:"150px"}}>
                                  <ValidatedMidasNumberTextField
                                    margin = {"none"}
                                    showLabel = {true}
                                    fontSize = {12}
                                    fieldWidth = {"150px"}
                                    midasNumberObject = {oBatchComponent.selectedSample}
                                    setMidasNumberObject = {(e) => updateData_BatchComponents('selectedSample', e, oBatchComponent.id)}
                                    hasErrors = {errorChecks.batchErrorChecks[errorChecks.batchErrorChecks.findIndex(obj => obj.batchComponentID === oBatchComponent.id)].selectedSample === null 
                                      ? false 
                                      : errorChecks.batchErrorChecks[errorChecks.batchErrorChecks.findIndex(obj => obj.batchComponentID === oBatchComponent.id)].selectedSample}
                                    setHasErrors = {(e) => updateErrorChecks_BatchComponents('selectedSample', e,  oBatchComponent.id)}
                                    chemIDToMatch = {oBatchComponent.selectedSubstance ? oBatchComponent.selectedSubstance.chemID : null}
                                    setScannedContainer = {(e) => AutoCompleteChangeAndValidate_BatchComponents("container", e, oBatchComponent.id)}
                                  ></ValidatedMidasNumberTextField>
                                </StyledTableCell>

                                {/* Container Selection */}
                                <StyledTableCell style={{width:"100px"}}>
                                  <StyledAutocomplete
                                    renderOption={(props2, option) => (
                                        <Option {...props2}>{option.containerNumber.toString()}</Option>
                                    )} 
                                    disablePortal
                                    disabled={oBatchComponent.selectedSample === null}
                                    options={oBatchComponent.selectedSample ? oBatchComponent.selectedSample.containers.filter(myContainer => myContainer.containerStatusName === 'Confirmed') : []}
                                    onChange={(e, value) => AutoCompleteChangeAndValidate_BatchComponents("container", value, oBatchComponent.id)}
                                    getOptionLabel={(option) => option.containerNumber.toString()}
                                    value={oBatchComponent.container}
                                    isOptionEqualToValue={(option, value) => value.containerNumber === option.containerNumber}
                                    autoHighlight
                                    style={{marginTop:"3px"}}
                                    renderInput={(params) => <TextField {...params} style={{width:"85px", marginTop:"3px"}} variant="outlined" size="small" error = {false}  inputProps={{ ...params.inputProps,  style: { fontSize: componentGridFontSize }}} InputProps={{ ...params.InputProps }}/>} 
                                  />
                                </StyledTableCell>
                                               
                                <StyledTableCell style={{width:"100px"}}>
                                  {PopulateSelectedContainerVolume(oBatchComponent)}
                                </StyledTableCell>

                                {/* Actual Amt. */}
                                <StyledTableCell style={{width:"100px"}}>
                                  <TextField style={{width:"85px", marginTop:"5px"}}
                                    disabled={oBatchComponent.container === null}
                                    size="small"
                                    margin="dense" 
                                    variant="outlined"
                                    inputProps={{ style: { fontSize: componentGridFontSize} }}
                                    InputLabelProps={{shrink: true}}
                                    value = {oBatchComponent.actualAmount === null ? '' : oBatchComponent.actualAmount}
                                    onChange = {e => {updateData_BatchComponents('actualAmount', e.target.value, oBatchComponent.id)}}
                                    error = {errorChecks.batchErrorChecks[errorChecks.batchErrorChecks.findIndex(obj => obj.batchComponentID === oBatchComponent.id)].actualAmount === null 
                                      ? false 
                                      : errorChecks.batchErrorChecks[errorChecks.batchErrorChecks.findIndex(obj => obj.batchComponentID === oBatchComponent.id)].actualAmount}     
                                    onBlur={e => {updateErrorChecks_BatchComponents('actualAmount', EvaluateComponentAmountField(oBatchComponent, oComponentInfo, componentActual), oBatchComponent.id); RoundBatchComponentAmount(oBatchComponent.actualAmount, oBatchComponent.id, componentDecimals)}}
                                    helperText = {errorChecks.batchErrorChecks[errorChecks.batchErrorChecks.findIndex(obj => obj.batchComponentID === oBatchComponent.id)].actualAmount ? "Required/Invalid" : ''}
                                  />
                                </StyledTableCell>

                                {/* Addition Order */}
                                <StyledTableCell align='left'>
                                  <StyledAutocomplete
                                    renderOption={(props2, option) => (
                                      <Option {...props2}>{option}</Option>
                                    )} 
                                    options={GetAdditionOrderValues()}
                                    onChange = {(e, value) => {updateData_BatchComponents('orderAdded', value, oBatchComponent.id)}}
                                    getOptionLabel={(option) => option.toString()}
                                    value={oBatchComponent.orderAdded}
                                    isOptionEqualToValue={(option, value) => value === option}
                                    autoHighlight
                                    autoSelect
                                    style={{marginTop:"3px"}}
                                    renderInput={(params) => <TextField {...params} style={{width:"75px", marginTop:"3px"}} variant="outlined" size="small" 
                                    error = {false}  
                                    inputProps={{ ...params.inputProps,  style: { fontSize: componentGridFontSize }}} InputProps={{ ...params.InputProps }}/>} 
                                  />
                                </StyledTableCell>


                                {/* Actual Temp */}
                                <StyledTableCell style={{width:"135px"}}>
                                  <TextField style={{width:"75px", marginTop:"5px"}}
                                    size="small"
                                    margin="dense" 
                                    variant="outlined"
                                    inputProps={{ style: { fontSize: componentGridFontSize} }}
                                    InputLabelProps={{shrink: true}}
                                    value = {oBatchComponent.actualTemperatureC === null ? '' : oBatchComponent.actualTemperatureC}
                                    onChange = {e => {updateData_BatchComponents('actualTemperatureC', e.target.value, oBatchComponent.id)}}
                                    error = {errorChecks.batchErrorChecks[errorChecks.batchErrorChecks.findIndex(obj => obj.batchComponentID === oBatchComponent.id)].actualTemperatureC === null 
                                      ? false 
                                      : errorChecks.batchErrorChecks[errorChecks.batchErrorChecks.findIndex(obj => obj.batchComponentID === oBatchComponent.id)].actualTemperatureC}
                                    onBlur={e => {updateErrorChecks_BatchComponents('actualTemperatureC', EvaluateOptionalNumberField(oBatchComponent.actualTemperatureC), oBatchComponent.id)}}
                                    helperText = {errorChecks.batchErrorChecks[errorChecks.batchErrorChecks.findIndex(obj => obj.batchComponentID === oBatchComponent.id)].actualTemperatureC ? "Invalid" : ''}
                                  />
                                </StyledTableCell>

                                <StyledTableCell component='th' scope='row'>
                                  <TextField style={{width:"500px", marginTop:"5px"}}
                                    label={oBatchComponent.selectedSample && oBatchComponent.selectedSample.substance.chemID !== oComponentInfo.substance.chemID ? '' : 'Not Required'}
                                    disabled={oBatchComponent.selectedSample ? oBatchComponent.selectedSample.substance.chemID === oComponentInfo.substance.chemID : true}
                                    multiline
                                    size="small"
                                    margin="dense" 
                                    variant="outlined"
                                    inputProps={{ style: { fontSize: componentGridFontSize} }}
                                    InputLabelProps={{shrink: true}}
                                    value = {oBatchComponent.substitutionReason === null ? '' : oBatchComponent.substitutionReason.trim()}
                                    onChange = {e => {updateData_BatchComponents('substitutionReason', e.target.value, oBatchComponent.id)}}
                                    error = {errorChecks.batchErrorChecks[errorChecks.batchErrorChecks.findIndex(obj => obj.batchComponentID === oBatchComponent.id)].substitutionReason === null 
                                      ? false 
                                      : errorChecks.batchErrorChecks[errorChecks.batchErrorChecks.findIndex(obj => obj.batchComponentID === oBatchComponent.id)].substitutionReason}
                                    onBlur={e => {updateErrorChecks_BatchComponents('substitutionReason', EvaulateSubtitutionReasonField(oBatchComponent.substitutionReason, oBatchComponent.selectedSubstance.chemID, oComponentInfo), oBatchComponent.id)}}
                                    helperText = {errorChecks.batchErrorChecks[errorChecks.batchErrorChecks.findIndex(obj => obj.batchComponentID === oBatchComponent.id)].substitutionReason ? 'Reason is Required' : ''}
                                  />
                                </StyledTableCell>
                              </TableRow>  
                            )
                          })}    
                        </StyledTableBody>
                      </UXDataTableNested>

                      <LocateMidasNumber 
                        open={modalLocateMIDASNumberOpen} 
                        setOpen={setModallocateMIDASNumberOpen} 
                        myStudy={null} 
                        setMyStudy={null} 
                        blendIndex={null} 
                        blendComponentIndex={null}
                        batchComponents={myBatchInfo.batchComponents}
                        batchComponentID={workingBatchComponentID}
                        setBatchComponents={updateData_SampleAndContainer}
                        bLocateContainers={true}
                        incomingUoMObject={toPrepareUoM}
                        onlyISOSamples={myBatchInfo.blendRequest?.isISOBlend ? true : false}
                      >
                      </LocateMidasNumber>
                    </React.Fragment>
                  )
                })}
              </StyledTableBody>
            </UXDataTableWithoutBody>
          </StyledDiv>

          <div>
            {isComponentsSaving ? (
              <>
                <CircularProgress style={{textTransform: "none",  marginTop:"50px", marginLeft:"15px"}}></CircularProgress>
                <GlobalSecondaryButton 
                  variant='contained'
                  style={{ marginTop:"20px", marginRight:"20px" }}
                  //onClick={(e) => SaveBatchComponents()}
                >{'Save Batch Components'}
                </GlobalSecondaryButton>
              </>
            ):(
              <GlobalSecondaryButton 
                variant='contained'
                style={{ marginTop:"20px", marginRight:"20px" }}
                onClick={(e) => SaveBatchComponents()}
              >{'Save Batch Components'}
              </GlobalSecondaryButton>
            )}
          </div>

          <StyledDivider></StyledDivider>

          <StyledDiv>
            <p style={{
              fontFamily:"EMprint",
              fontWeight:"600",
              fontSize:"16px",
              color:"#545459",
              textTransform: 'none',
              textAlign:"left",
              paddingTop:"10px"
              }}>Batch Testing</p>

            <FormGroup style={{marginLeft: "15px"}}>
              <FormControlLabel control={<Switch />} 
                  checked={switchShowTests}
                  onChange={e => setSwitchShowTests(e.target.checked)}
                  label= {myMethods.length > 0 ? "Show Tests" : 'No Methods'}
                  disabled = {!(myMethods.length > 0)}
              />
            </FormGroup>
          </StyledDiv>

          <StyledDiv>
            {(myMethods.length > 0 && switchShowTests === true) &&
              <MethodSelection 
                width={'1500px'} 
                selectedMethods={myMethods} 
                setSelectedMethods={setMyMethods} 
                showCompletionDate={true} 
                showContainerGrouping={false}
                incomingTemplate={null}
                isReadOnly={true}
                showSearchTemplateName={false}
                showEstimateAndPriority={true}
                maxMethodsAllowed={999}
              >
              </MethodSelection>
            }
          </StyledDiv>

          <StyledDivider></StyledDivider>


          <StyledDiv>
            <p style={{
              fontFamily:"EMprint",
              fontWeight:"600",
              fontSize:"16px",
              color:"#545459",
              textTransform: 'none',
              textAlign:"left",
              paddingTop:"20px"
              }}>Batch Containering</p>
          </StyledDiv>

          <div style={{marginTop: "1rem"}}>
            <ContainerTable
              width = {'100%'}
              showParentContainer = {false}
              showTests = {true}
              containers ={testingContainers}
              setContainers = {setTestingContainers} 
              containerErrorChecks = {containerErrorChecks} 
              setContainerErrorChecks = {setContainerErrorChecks}
              enableAdditions = {myBatchInfo.batchRetainLocationName !== 'DISCARD'}
              enableDeletions = {true} 
              enableCopy = {false}
              availableParentContainers = {null}
              sampleObject = {null}
              substanceObject = {myBatchInfo.blendRequest?.blendSubstance ? myBatchInfo.blendRequest.blendSubstance : null}
              lockContainerStatus={true}
              newContainerDefaultOwner = {myBatchInfo?.blendRequest?.blendStudy?.ownerEmail}
            >
            </ContainerTable>

            <div style={{ display: "flex", width: "46%", marginTop:"5px" }}>
              <FileDragAndDrop files={myBatchInfo.batchAttachments} disabled={masterDisableBatch} showFiles={true} handleFiles={HandleFileAdd} handleDeleteFile={ConfirmRemoveAttachmentBatch} handleFileDownload={HandleFileDownloadBatch}></FileDragAndDrop>
            </div>
          </div>
        
          {isBatchSaving ? (
              <>
                <CircularProgress style={{textTransform: "none",  marginTop:"20px", marginLeft:"15px"}}></CircularProgress>
                <GlobalButton 
                  variant='contained'
                  style={{ marginTop:"20px", marginRight:"20px" }}
                >{'Complete Batch and Register'}
                </GlobalButton>
              </>
            ):(
            <GlobalButton 
              disabled={testingContainers.length < 1}
              variant='contained'
              style={{ marginTop:"20px", marginRight:"20px" }}
              onClick={(e) => CreateBlend_Sample_Containers_Tests()}
            >{'Complete Batch and Register'}
            </GlobalButton>
            )}

            <Menu
              anchorEl={anchorEl}
              open={openMenu()}
              onClose={(e) => handleCloseMenu(e)}>
              <MenuItem 
                onClick={() => {handleCloseMenu(); AddNewBatchComponent(workingBlendComponent)}}
              >Add New Component</MenuItem>

              <MenuItem
                disabled={IsDeleteDisabled(workingBlendComponent)}
                onClick={() => {handleCloseMenu(); DeleteBatchComponent(workingBatchComponentID)}}
              >Delete Component</MenuItem>
            </Menu>


          {/* Informational Messages */}
          <ModalNoButton title={modalNoButtonTitle} open={modalNoButtonOpen} setOpen={setModalNoButtonOpen}>
              <label>
                {modalNoButtonText}
              </label>
          </ModalNoButton>

          <PrintInformation 
            open={printInfoOpen} 
            setOpen={setPrintInfoOpen} 
            button2Action={closePrintInformation} 
            button2Text={"View My Blends"}
            printLabelInfo={printLabelInfo}
            isShelfLabel={false}
            > 
          </PrintInformation>

          {/* Informational Messages */}
          <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
              {Array.isArray(modalMessagesText) ?
              (modalMessagesText && modalMessagesText.map((text, index) => {
                  return (
                      <div style={{display:"flex"}} key={`myErrorChecks${index}`}>
                          <label>
                          {text}
                          </label>
                      </div>
                  )
                  })
              )
              :
              ( <label>
                  {modalMessagesText}
                </label>
              )}      
          </ModalSimpleButton>

          {/* Attachment Removal */}
          <ModalTwoButtons title={modalDeleteAttachTitle} button1Text={modalDeleteAttachButton1Text} button1Action={deleteFile} button2Text={modalDeleteAttachButton2Text} button2Action={closeModalDeleteAttach} open={modalDeleteAttachOpen} setOpen={setModalDeleteAttachOpen}>
                <label>
                    {modalDeleteAttachText}
                </label>

                <div></div>
            </ModalTwoButtons>

          <BlendPrepSheet
            myBatchInfo={myBatchInfo} 
            myMethodInfo={myMethods}
            toPrepareUoM={toPrepareUoM}
            toPrepareValue={toPrepareAmount}
          ></BlendPrepSheet>

      </div>
    )
};


export default EditBatch;
