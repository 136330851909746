import React from "react";

const About = ({ ...props }) => {
  return (
    <div>
      <div>
        <h2>About MIDAS 3</h2>
        <h4>Mobil Integrated Data Aquisition System</h4>
        <h4>Copyright EMTEC IT 2023</h4>
      </div>
      <div>
        MIDAS is a custom developed Laboratory Information Management System
      </div>
      <div>
        <h3>Development Team</h3>
        <h4>Lead Architects</h4>
        <p>Jonathan Seidel (MIDAS)<br/>
        Thiago Rosa (LTDT)</p>
        <h4>Data Architects</h4>
        <p>Sven Penner<br/>
        Jonathan Seidel</p>
        <h4>Lead Developer</h4>
        <p>Eric Delich</p>
        <h4>Developers</h4>
        <p>Leonardo Oliveira<br />
              Rafael Boganika Maciel<br />
              Felipe Manfrin<br />
              Renan Ribeiro<br />
              Anderson Handa<br />
              Emilio Weba Filho<br/>
              Gary Lyons</p>
              <h4>Data Engineers</h4>
              <p>Pradhyuman Mehta<br />
              Gary Lyons<br />
              Mani Subramanian<br />
              Devananda Patro<br />
              Raj Veerma</p>
              <h4>Product Owner</h4>
              <p>Matthew McKinney</p>
              <h4> User Experience Team</h4>
              <p>Joel Garfield<br />
              Grace xx<br />
              x</p>
              <h4>Project Managers</h4>
              <p>Alan Snipes<br />
              Leandro Nogueira<br />
        Alexandre Maria </p>
      </div>
    </div>
  );
};

export default About;