import API from "..";

export default class SubstanceStructure {
  /**
   * The SubstanceStructure dimension table for M3
   * @param TODO
   */
  constructor({
    smilesString,
    chemicalFormula,
    molecularWeight,
    displayName,
    id
  }) {
    this.smilesString = smilesString;
    this.chemicalFormula = chemicalFormula;
    this.molecularWeight = molecularWeight;
    this.displayName = displayName;
    this.id = id;
  }

  static async get(smile= "", chemicalformula = "", displayname = "") {
    const api = await API();
    const { data } = await api.get(`/substancestructure?smile=${smile}&chemicalformula=${chemicalformula}&displayname=${displayname}`);
    return data.result.map((d) => new SubstanceStructure(d));
  }

  static async find(smile= "", chemicalformula = "") {
    const api = await API();
    const { data } = await api.get(`/substancestructure/find?smilestring=${smile}&chemicalformula=${chemicalformula}`);
    return data.result.map((d) => new SubstanceStructure(d));
  }

  static async create(substancestructure) {
    const api = await API();
    const { data } = await api.post(`/substancestructure`, substancestructure);
    return (data);
  }
}