import React, { useState, useEffect } from "react";
import { Box, CircularProgress, TextField, TableRow, IconButton, Autocomplete } from "@mui/material";
import { GlobalButton, GlobalSecondaryButton } from "../../styles";
//import BatchCertificationStatus from "../../../api/Formulations/BatchCertificationStatus";
import Batch from "../../../api/Formulations/Batch";
import { styled } from "@mui/material/styles";
import ValidatedMidasNumberTextField from "../../../components/ValidatedMidasNumberTextField";
import { StyledTableBody, StyledTableCell, UXDataTableWithoutBody, UXDataTableNested } from "../../../components/UXDataTable";
import ModalMessages from "../../../components/Modal/ModalSimpleButton"
import { formatMidasNumber, convertToLocalTime} from "../../../global";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import BatchCertificationStatus from "../../../api/Formulations/BatchCertificationStatus"

const instructionText = 'To audit batch samples enter a MIDAS number or Blend information.'
//const containerGridFontSize = 12

const auditCols = ["MIDAS #", "Container", "Chem ID/Batch", "Batch Certification Status", "Description", "Audit Comment"]

const StyledContainer = styled(Box)(() => ({
    display: 'flex',
    gap: '20px',
    marginBottom: "1rem"
}));

const StyledDiv = styled('div')(() => ({
    height: '2.2rem',
    alignItems: 'center',
    display: 'flex'
}));

const StyledAutocomplete = styled(Autocomplete)({
    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
      fontSize: 14,
    },
});

const Option = styled('li')({
    fontSize: 14,
});

const Audit = ({ ...props }) => {
    const [midasNumberObject, setMidasNumberObject] = useState(null)
    const [hasMidasErrors, setHasMidasErrors] = useState(null)
    const [studyBlend, setStudyBlend] = useState(null)
    const [studyBlendBatch, setStudyBlendBatch] = useState(null)

    const [auditInfo, setAuditInfo] = useState(null)

    const [isProcessing, setIsProcessing] = useState(false)

    const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
    const modalMessagesButtonText = 'Ok'
    const [modalMessagesTitle, setModalMessagesTitle] = useState('');
    const [modalMessagesText, setModalMessagesText] = useState('');

    const noDataMessage = `No batch samples found for MIDAS # ${midasNumberObject === null ? '' : midasNumberObject.sampleName} or Study Name-Blend Letter ${studyBlend === null ? '' : studyBlend} or Study Name-Blend Letter/Batch Number ${studyBlendBatch === null ? '' : studyBlendBatch}`

    const [sampleRowOpen, setSampleRowOpen] = useState([]);
    const isSampleRowOpen = (sampleName, chemID, batchSeq) => sampleRowOpen.indexOf(`${sampleName}~${chemID}/${batchSeq}`) !== -1;

    const [modalCertifyOpen, setModalCertifyOpen] = useState(false);
    const [modalCertifyButton1Text, setModalCertifyButton1Text] = useState('');
    const [modalCertifyButton2Text, setModalCertifyButton2Text] = useState('');
    const [modalCertifyTitle, setModalCertifyTitle] = useState('');
    const [modalCertifyText, setModalCertifyText] = useState('');

    const [certificationStatuses, setCertificationStatuses] = useState(null)
    const [reloadAuditInfo, setReloadAuditInfo] = useState(false)
    const [isCertificationSaving, setIsCertificationSaving] = useState(false)

    const handleSampleRowClick = (sampleName, chemID, batchSeq) => {
        const sampleRowOpenIndex = sampleRowOpen.indexOf(`${sampleName}~${chemID}/${batchSeq}`);
        let newResultRowOpen = [];
    
        if (sampleRowOpenIndex === -1) {
          newResultRowOpen = newResultRowOpen.concat(sampleRowOpen, `${sampleName}~${chemID}/${batchSeq}`);
        } else if (sampleRowOpenIndex === 0) {
          newResultRowOpen = newResultRowOpen.concat(sampleRowOpen.slice(1));
        } else if (sampleRowOpenIndex === sampleRowOpen.length - 1) {
          newResultRowOpen = newResultRowOpen.concat(sampleRowOpen.slice(0, -1));
        } else if (sampleRowOpenIndex > 0) {
          newResultRowOpen = newResultRowOpen.concat(
            sampleRowOpen.slice(0, sampleRowOpenIndex),
            sampleRowOpen.slice(sampleRowOpenIndex + 1)
          );
        }
        setSampleRowOpen(newResultRowOpen);
    };

    function closeModalCertify() {
        setModalCertifyOpen(false);
    }

    function openModalCertify(title, text, buttonText, button2Text) {
    setModalCertifyButton1Text(buttonText)
    setModalCertifyButton2Text(button2Text)
    setModalCertifyOpen(true);
    setModalCertifyTitle(title);
    setModalCertifyText(text);
    }

    useEffect(() => {
        let cancelPromise = false

        BatchCertificationStatus.getAllBatchCertificationStatuses().then((res) => {
            if (cancelPromise) return
            setCertificationStatuses(res.filter(result => result.isActive === true).sort((a, b) => a.statusName.localeCompare(b.statusName)))
          });    
          
          return () => {
            cancelPromise = true
          }
    }, [])

    useEffect(() => {
        if (midasNumberObject !== null)
        {
            setStudyBlend(null)
            setStudyBlendBatch(null)
        }        
    }, [midasNumberObject])

    useEffect(() => {
        if (reloadAuditInfo === true)
        {
            GetAuditSummary()
            setReloadAuditInfo(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadAuditInfo])

    function closeModalMessages() {
        setModalMessagesOpen(false);
    }
    
    function openModalMessages(title, text) {
        setModalMessagesOpen(true);
        setModalMessagesTitle(title);
        setModalMessagesText(text);
    }

    function ClearEntries () {
        setMidasNumberObject(null)
        setStudyBlend(null)
        setStudyBlendBatch(null)
    }

    function GetAuditSummary () {
        setIsProcessing(true)

        Batch.AuditBatch(midasNumberObject ? midasNumberObject.sampleName : '', studyBlend, studyBlendBatch).then((res) => {

            setIsProcessing(false)

            if (res && res.message === 'Success')
            {
                setAuditInfo(res.result)
             
            } else {
                setAuditInfo(null)
                openModalMessages("Error Retrieving Audit", `There was an error getting the audit details.  ${res ? res.message : ''}`)
            }
        })
    }

    function BuildChildTables (sampleToCopy)
    {
        const result = []
        let mySample = structuredClone(sampleToCopy);

            isSampleRowOpen(mySample.sample.sampleName, mySample.sample.chemID, mySample.batch.batchSequenceNumber) && (
                result.push(
                <UXDataTableNested tableWidth={'100%'} cols={auditCols} blankFirstHeader={true} colSpan={auditCols.length + 1} isOpenComparator={`${mySample.sample.sampleName}~${mySample.sample.chemID}/${mySample.batch.batchSequenceNumber}`} isOpenArray={sampleRowOpen}>
                    <StyledTableBody>
                    {mySample.containersUsed.map((oContainer, index) => ( 
                        <TableRow key={`${oContainer.id}_${index}_${mySample.sample.sampleName}~${mySample.sample.chemID}/${mySample.batch.batchSequenceNumber}`}>
                             <StyledTableCell style={{width:'5rem'}}>
                            {oContainer.sample.substance.chemIDCategoryName === "MBL"  &&
                                <IconButton
                                    aria-label='expand row'
                                    size='small'
                                    onClick={() => handleSampleRowClick(oContainer.sample.sampleName, oContainer.sample.chemID, mySample.childBlendSample.batch.batchSequenceNumber)}>
                                    {isSampleRowOpen(oContainer.sample.sampleName, oContainer.sample.chemID, mySample.childBlendSample.batch.batchSequenceNumber) ? (
                                        <KeyboardArrowUpIcon/>
                                    ) : (
                                        <KeyboardArrowDownIcon/>
                                    )}
                                </IconButton>
                            }
                            </StyledTableCell>
                            <StyledTableCell>{formatMidasNumber(oContainer.sample.sampleName)}</StyledTableCell>
                            <StyledTableCell>{oContainer.containerNumber}</StyledTableCell>
                            <StyledTableCell>{oContainer.sample.substance.chemIDCategoryName === "MBL" ? `${oContainer.sample.chemID}/${mySample.childBlendSample.batch.batchSequenceNumber}` : oContainer.sample.chemID}</StyledTableCell>
                            <StyledTableCell>{oContainer.sample.substance.chemIDCategoryName === "MBL" ? mySample.childBlendSample.batch.batchCertificationStatusName : ''}</StyledTableCell>
                            <StyledTableCell>{oContainer.sample.description}</StyledTableCell>
                            {/* <StyledTableCell>{oContainer.sample.substance.chemIDCategoryName === "MBL" ? mySample.childBlendSample.containersUsed.filter(container => container.batchAuditComment && container.batchAuditComment.toString().startsWith('Error:')).length > 0 ? `Failed audit, check containers for comments` : "Pass" : oContainer.batchAuditComment}</StyledTableCell> */}
                            <StyledTableCell>{oContainer.batchAuditComment}</StyledTableCell>
                        </TableRow>                            
                    ))}
                    </StyledTableBody>

                                
                    {mySample.childBlendSample && BuildChildTables(mySample.childBlendSample)}
                </UXDataTableNested>
                )
            )
        return result
    }

    function UpdateAuditBatchInfo (index, value, property)
    {
        const copyAuditInfo = structuredClone(auditInfo)   
        
        copyAuditInfo[index].batch[property] = value

        setAuditInfo(copyAuditInfo)
    }

    function UpdateBatchCertificationStatus () {
        let listBatches = []

        setIsCertificationSaving(true)

        auditInfo.forEach(oItem => {
            listBatches.push(new Batch({
                id: oItem.batch.id, 
                blendID: oItem.batch.blendID, 
                blendFacilityName: oItem.batch.blendFacilityName, 
                blendPriorityName: oItem.batch.blendPriorityName, 
                blendPriority: null, 
                batchComments: oItem.batch.batchComments, 
                requestedCompletionDate: oItem.batch.requestedCompletionDate, 
                gpaqsNumber: oItem.batch.gpaqsNumber, 
                actualAmount: oItem.batch.actualAmount, 
                unitOfMeasureActual: null, 
                preparedByEmail: oItem.batch.preparedByEmail, 
                preparedDate: oItem.batch.preparedDate, 
                lastModifiedByEmail: oItem.batch.lastModifiedByEmail, 
                lastModifiedDate: oItem.batch.lastModifiedDate, 
                createdByEmail: oItem.batch.createdByEmail, 
                createdDate: oItem.batch.createdDate, 
                destination: oItem.batch.destination, 
                requestedAmount: oItem.batch.requestedAmount, 
                requiresBlindCode: oItem.batch.requiresBlindCode, 
                shippingInfo: oItem.batch.shippingInfo, 
                preparedSampleName: oItem.batch.preparedSampleName, 
                preparedSample: null, 
                requestedAmountUoM: oItem.batch.requestedAmountUoM, 
                unitOfMeasureRequested: null, 
                batchStatusName: oItem.batch.batchStatusName, 
                batchStatus: null, 
                batchSequenceNumber: oItem.batch.batchSequenceNumber, 
                batchPrecision: oItem.batch.batchPrecision, 
                priorityReason: oItem.batch.priorityReason, 
                statusReason: oItem.batch.statusReason, 
                batchRetainLocationName: oItem.batch.batchRetainLocationName, 
                batchRetainLocation: null, 
                batchCertificationStatusName: oItem.batch.batchCertificationStatusName, 
                batchCertificationStatus: null, 
                lastCertifiedByEmail: oItem.batch.lastCertifiedByEmail, 
                lastCertifiedDate: oItem.batch.lastCertifiedDate, 
                shelfLifeStartDate: oItem.batch.shelfLifeStartDate, 
                shelfLifeEndDate: oItem.batch.shelfLifeEndDate, 
                waiverReason: oItem.batch.waiverReason, 
                retainReason: oItem.batch.retainReason, 
                batchPurpose: oItem.batch.batchPurpose, 
                batchComponents: null, 
                shippingAddressID: oItem.batch.shippingAddressID, 
                batchAttachments: null
            }))            
        });

        Batch.UpdateBatchCertificationStatus(listBatches).then((res) => {

            setIsCertificationSaving(false)

            if (res && res.message === 'Success') {
                openModalMessages('Save Complete', `Batch certifications saved successfully!`)
                setReloadAuditInfo(true)
                closeModalCertify()
            }
            else{
                if (res.message)
                {
                    openModalMessages('Certifications Failed to Save', `${res ? res.message : ''}. Contact support if you feel this is an error.`);
                } else {
                    openModalMessages('Certifications Failed to Save', `Unspecified Error, Contact support if you feel this is an error.`);
                }
            }
          });
    }

    return(
    <div>
        <p style={{
            color:"#111112",
            fontSize:"15px",
            fontFamily:"EMprint",
            marginTop:"2rem",
            marginBottom:"2rem"
        }}>{instructionText}</p>

        <StyledContainer>
            <ValidatedMidasNumberTextField
                margin={"none"}
                showLabel={true}
                fontSize={16}
                fieldWidth={"20rem"}
                midasNumberObject={midasNumberObject}
                setMidasNumberObject={setMidasNumberObject}
                hasErrors={hasMidasErrors}
                setHasErrors={setHasMidasErrors}
                chemIDToMatch={null}
            ></ValidatedMidasNumberTextField>

            <StyledDiv>
                <label>OR</label>
            </StyledDiv>

            <TextField 
                size="small" 
                margin="none" 
                variant="outlined" 
                label="Study Name-Blend Letter" 
                InputLabelProps={{shrink: true}} 
                style ={{width: "20rem", size:"small"}}
                onChange={(e) => {setStudyBlend(e.target.value); setStudyBlendBatch(null); setMidasNumberObject(null)}}
                value={studyBlend === null ? '' : studyBlend}
               >
            </TextField>

            <StyledDiv>
                <label>OR</label>
            </StyledDiv>

            <TextField 
                size="small" 
                margin="none" 
                variant="outlined" 
                label="Study Name-Blend Letter/Batch Number" 
                InputLabelProps={{shrink: true}} 
                style ={{width: "20rem", size:"small"}}
                onChange={(e) => {setStudyBlend(null); setStudyBlendBatch(e.target.value); setMidasNumberObject(null)}}
                value={studyBlendBatch === null ? '' : studyBlendBatch}
               >
            </TextField>

            {isProcessing === true ? (
                <>
                <CircularProgress style={{textTransform: "none"}}></CircularProgress>
                <GlobalButton
                    disabled={true}
                    variant="contained" 
                    >Create Audit</GlobalButton>
                </>
                ) : (
                <GlobalButton
                    disabled={studyBlend === null && studyBlendBatch === null && midasNumberObject === null}
                    variant="contained" 
                    onClick={() => GetAuditSummary()}
                >Create Audit</GlobalButton>
            )}

            <GlobalSecondaryButton 
                disabled={auditInfo === null}
                variant='contained'
                onClick={(e) => openModalCertify("Batches to Certify", "Change/update the certification status of the batches below.", "Save", "Cancel")}
            >Certify Batches</GlobalSecondaryButton>

            <GlobalSecondaryButton 
                variant='contained'
                onClick={(e) => ClearEntries()}
            >Clear</GlobalSecondaryButton>
        </StyledContainer>

        <UXDataTableWithoutBody 
            tableWidth='100%' 
            cols={auditCols} 
            blankFirstHeader={true}
            tableId="myAuditDetails"
            rowLength={auditInfo ? auditInfo.length : 0}
            enablePaging={false}
            rowsPerPage={null}
            page={null}
            handleChangePage={null}
            handleChangeRowsPerPage={null}
            noDataFoundMessage={noDataMessage}
            enableAddIcon={false}
            addFunction={null}
            addToolTipText={null}  
            isDataLoading={isProcessing}
        >

            <StyledTableBody> 
                {auditInfo && auditInfo.map((oInfo, index) => {
                return (
                    <>
                    <TableRow  key={`myAuditRows${index}_${oInfo.sample.sampleName}`}>
                        <StyledTableCell style={{width:'5rem'}}>
                            <IconButton
                                aria-label='expand row'
                                size='small'
                                onClick={() => handleSampleRowClick(oInfo.sample.sampleName, oInfo.sample.chemID, oInfo.batch.batchSequenceNumber)}>
                                {isSampleRowOpen(oInfo.sample.sampleName, oInfo.sample.chemID, oInfo.batch.batchSequenceNumber) ? (
                                    <KeyboardArrowUpIcon/>
                                ) : (
                                    <KeyboardArrowDownIcon/>
                                )}
                            </IconButton>
                        </StyledTableCell>
                        <StyledTableCell><b>{formatMidasNumber(oInfo.sample.sampleName)}</b></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>{`${oInfo.sample.chemID}/${oInfo.batch.batchSequenceNumber}`}</StyledTableCell>
                        <StyledTableCell>{oInfo.batch.batchCertificationStatusName}</StyledTableCell>
                        <StyledTableCell>{oInfo.sample.description}</StyledTableCell>
                        <StyledTableCell><b>{oInfo.containersUsed.filter(container => container.batchAuditComment && container.batchAuditComment.toString().startsWith('Error:')).length > 0 ? "Failed audit, check containers for comments" : "Pass"}</b></StyledTableCell>
                    </TableRow>

                    {BuildChildTables(oInfo)}
                    </>
                )
                })}                
                </StyledTableBody>

        </UXDataTableWithoutBody>

        {/* Informational Messages */}
        <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
            {modalMessagesTitle === "Audit Errors" ?
            (  modalMessagesText && modalMessagesText.map((text, index) => {
                return (
                    <div style={{display:"flex"}} key={`validationErrors${index}`}>
                        <label>
                        {text}
                        </label>
                    </div>
                )
                })
            )
            :
            ( <label>
                {modalMessagesText}
                </label>
            )}      
        </ModalMessages>

                  {/* Attachment Removal */}
        <ModalTwoButtons title={modalCertifyTitle} button1Text={modalCertifyButton1Text} button1Action={UpdateBatchCertificationStatus} isButton1Disabled={isCertificationSaving} button2Text={modalCertifyButton2Text} button2Action={closeModalCertify} open={modalCertifyOpen} setOpen={setModalCertifyOpen}>
            <label>
                {modalCertifyText}
            </label>

            <div style={{marginTop:"1rem"}}></div>

             <UXDataTableWithoutBody 
                tableWidth='1500px' 
                cols={["MIDAS #", "Batch Certification Status", "Waiver Reason", "Certified On", "Certified By", "Shelf Life Start Date", "Shelf Life End Date"]} 
                blankFirstHeader={false}
                tableId="myAuditDetails"
                rowLength={auditInfo ? auditInfo.length : 0}
                enablePaging={false}
                rowsPerPage={null}
                page={null}
                handleChangePage={null}
                handleChangeRowsPerPage={null}
                noDataFoundMessage={noDataMessage}
                enableAddIcon={false}
                addFunction={null}
                addToolTipText={null}  
                isDataLoading={false}
            >

                <StyledTableBody> 
                    {auditInfo && auditInfo.map((oInfo, index) => {
                    return (
                        <>
                            <TableRow  key={`myAuditRows${index}_${oInfo.sample.sampleName}`}>
                                <StyledTableCell>{formatMidasNumber(oInfo.sample.sampleName)}</StyledTableCell>
                                <StyledTableCell>
                                    <StyledAutocomplete
                                        renderOption={(props2, option) => (
                                            <Option {...props2}>{option}</Option>
                                        )} 
                                        disablePortal
                                        disabled={false}
                                        options={certificationStatuses.map((oStatus) => oStatus.statusName)}
                                        onChange={(e, value) => UpdateAuditBatchInfo(index, value, "batchCertificationStatusName")}
                                        getOptionLabel={(option) => option}
                                        value={oInfo.batch.batchCertificationStatusName}
                                        isOptionEqualToValue={(option, value) => value === option}
                                        autoHighlight
                                        style={{marginTop:"3px"}}
                                        renderInput={(params) => <TextField {...params} style={{width:"220px", marginTop:"3px"}} variant="outlined" size="small" error = {false}  inputProps={{ ...params.inputProps,  style: { fontSize: 14 }}} InputProps={{ ...params.InputProps }}/>} 
                                    />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TextField style={{width:"250px", marginTop:"5px"}}
                                        size="small"
                                        margin="dense" 
                                        variant="outlined"
                                        inputProps={{ style: { fontSize: 12} }}
                                        InputLabelProps={{shrink: true}}
                                        value={oInfo.batch.waiverReason}
                                        onChange={(e, value) => UpdateAuditBatchInfo(index, e.target.value, "waiverReason")}
                                        error = {false}
                                    />
                                </StyledTableCell>
                                <StyledTableCell>{convertToLocalTime(oInfo.batch.lastCertifiedDate)}</StyledTableCell>   
                                <StyledTableCell>{oInfo.batch.lastCertifiedByEmail}</StyledTableCell>   
                                <StyledTableCell>{convertToLocalTime(oInfo.batch.shelfLifeStartDate)}</StyledTableCell>     
                                <StyledTableCell>{convertToLocalTime(oInfo.batch.shelfLifeEndDate)}</StyledTableCell>                                
                            </TableRow>

                            {/* {oInfo.childBlendSample && BuildCertificationModal(oInfo.childBlendSample)}*/}
                        </>
                        )
                    })}

                </StyledTableBody>
            </UXDataTableWithoutBody>
             
            <div></div>
        </ModalTwoButtons>
    </div> 
    )};
export default Audit;